const parsers = {

    convertUTC(data) {
        
        const currentDate = new Date(data);
        
        const myDate = currentDate.toLocaleDateString("pt-BR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
        
        return myDate;
    }

}

export default parsers;
