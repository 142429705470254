import SecureLS from "secure-ls";
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
// IMPORTACAO DOS MODULOS
import amostras from "./modules/amostras";
import aplicacoes from "./modules/aplicacoes";
import auth from "./modules/auth";
import cidades from "./modules/cidades";
import erros from "./modules/erros";
import formularios from "./modules/formularios";
import grupos from "./modules/grupos";
import instituicao from "./modules/instituicao";
import materiaisDocumentos from "./modules/materiais-documentos";
import membros from "./modules/membros";
import modalLateral from "./modules/modalLateral";
import notificacao from "./modules/notificacao";
import turmas from "./modules/turmas";
import unidades from "./modules/unidades";
import boasPraticas from './modules/boasPraticas';
const ls = new SecureLS({ isCompression: false });

export default createStore({
  modules: [
    amostras,
    aplicacoes,
    auth,
    cidades,
    erros,
    formularios,
    grupos,
    instituicao,
    membros,
    modalLateral,
    turmas,
    unidades,
    notificacao,
    materiaisDocumentos,
    boasPraticas,
  ],
  plugins: [
    createPersistedState({
      // paths: [""], // PERMITE O SALVAMENTO DOS ESTADOS EM CACHE CRIPTOGRAFADO
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
