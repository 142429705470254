<template>

  <div class="container">
   
    <div>
        <h1 class="materiais-documentos title-info">Perguntas Frequentes</h1>
        <p class="text-info-personal">
            <br>
            Olá! 
            <br> 
            <br>       
            Listamos algumas dúvidas frequentes que recebemos, mas caso não encontre sua resposta, fique à vontade para entrar em contato com a equipe.
        </p>

        <div class="FAQCards-style" v-for="(FAQCard) in FAQCardInfos" :key="FAQCard.idFAQ">
            <FAQCard
                :idFAQ="FAQCard.idFAQ"
                :question="FAQCard.question"
                :answer="FAQCard.answer"
            />
        </div>

    </div>
    
  </div>
</template>

<script>
import FAQCard from "@components/FAQCard";

export default {
    name: 'FAQ',
    components: {
        FAQCard,
    },
    data(){
        return{
            searchByKeyword: '',
            options: [
                {
                id:1,
                value: 'Todos'
                },
            ],
            FAQCardInfos: [
                {
                idFAQ: 1,
                question: 'Por que não consigo acessar a tela de login?',
                answer: 'Para acessar a tela de login é necessário clicar no botão entrar no canto superior direito da tela.'
                },
                {
                idFAQ: 2,
                question: 'Por que não consigo acessar a página de Gerenciar Instituições?',
                answer: 'Esta página é restrita a usuários cadastrados no sistema. Você deve realizar o login na Plataforma para conseguir acessar a página desejada.'
                },
                {
                idFAQ: 3,
                question: 'Por que não consigo acessar a página dos Grupos de Pesquisa?',
                answer: 'Esta página é restrita a usuários cadastrados no sistema. Você deve realizar o login na Plataforma para conseguir acessar a página desejada.'
                },
                {
                idFAQ: 4,
                question: 'Como faço para entrar em uma Instituição existente?',
                answer: 'Após realizar o login na Plataforma, clique em "Dados" -> "Gerenciar Instituições" -> "Buscar Instituição" e insira o nome da Instituição desejada na barra de pesquisa. Após localizar a instituição desejada, clique em "Enviar Solicitação". Assim que possível, o Administrador da Instituição correspondente analisará sua solicitação de entrada.'
                },
                {
                idFAQ: 5,
                question: 'Como faço para criar uma Instituição dentro da Plataforma?',
                answer: 'Após realizar o login na Plataforma, clique em "Dados" -> "Gerenciar Instituições" -> "Criar Instituição". Preencha os campos solicitados pelo formulário e clique no botão "Criar Instituição".'
                },
                {
                idFAQ: 6,
                question: 'Como posso visualizar todos os membros pertencentes a uma Instituição?',
                answer: 'Após realizar o login na Plataforma, clique em "Dados" -> "Gerenciar Instituições" e clique na Instituição desejada. Dentro do Menu da Instituição, clique no card "Cadastrar Informações" -> "Membros". Nesta página, você poderá visualizar todos os membros pertencentes à Instituição correspondente.'
                },
                {
                idFAQ: 7,
                question: 'Por que não consigo alterar o tipo de permissão de um membro da Instituição?',
                answer: 'Apenas Coordenadores e Administradores conseguem alterar o tipo de permissão dos membros da Instituição.'
                },
                {
                idFAQ: 8,
                question: 'A página que desejo acessar não carrega. O que devo fazer?',
                answer: 'Por favor, verifique sua conexão com a internet e tente novamente. Se o problema persistir, entre em contato com nossa equipe.'
                },
                {
                idFAQ: 9,
                question: 'Como posso visualizar todas as turmas pertencentes a uma Instituição?',
                answer: 'Após realizar o login na Plataforma, clique em "Dados" -> "Gerenciar Instituições" e clique na Instituição desejada. Dentro do Menu da Instituição, clique no card "Cadastrar Informações" -> "Unidades Educacionais e Turmas" -> "Ver UE". Nesta página, você poderá visualizar todas as Turmas pertencentes a Unidade Educacional correspondente.'
                },
                {
                idFAQ: 10,
                question: 'Como posso visualizar todos os formulários de uma Instituição?',
                answer: 'Após realizar o login na Plataforma, clique em "Dados" -> "Gerenciar Instituições" e clique na Instituição desejada. Dentro do Menu da Instituição, clique no card "Cadastrar Informações" -> "Formulários". Nesta página, você poderá visualizar todos os Formulários da Instituição correspondente.'
                },
                
            ]
        }
    },
    methods: {
        setSelectedValue () {
            this.selectedValue = this.$refs.filtersearchname.value
        },
        setSelectedWord () {
            this.selectedWordFilter = this.$refs.filtersearchkeyword.value
        }
    }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/_variables.scss";

.container{
    margin: 2.5rem auto;
}

.FAQCards-style{
    justify-content: space-between;
}

.title-info {
    font-weight: 600;
    font-size: 1.6rem;
    color: #535353;
    margin: 0;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.materiais-documentos {
    color: $marrom-escuro-eapi;
}

.text-info-personal {
    color: #0a0909;
    font-size: 1.1rem;
    text-align: justify;
    padding: 0rem;
}

</style>
