<template>
    <VideoModal
        videoLink="kcUc8AAfGF0"
        id="videoModal"
    />

    <div class="bg">
        <div>
            <h2 class="emBreve">Disponível em breve...</h2>
            <div class="butContainer">
                <Button
                    data-target="#videoModal"
                    data-toggle="modal"
                    class="but"
                    value="Veja o vídeo" />
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
 import VideoModal from "@components/Forms/VideoModal";
 import Button from "@components/Button";

 export default {
     name: "GestoresEmBreve",
     components: {
         Button,
         VideoModal,
     },
 };
</script>

<style type="text/css" media="screen" scoped>
 .bg {
     padding: 1rem 1rem;
     width: 100%;
     background: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     min-height: 85vh;
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .emBreve {
     display: block;
     font-style: italic;
     background-clip: text;
     text-fill-color: transparent;
     background-image: url("~@/assets/img/Backgrounds/marsala.webp");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
 }

 .butContainer {
     width: 100%;
     display: flex;
     justify-content: flex-end;
 }

 .but {
     display: block;
     background: url("~@/assets/img/Backgrounds/verdeEscuro.png");
     background-repeat: repeat;
     background-size: 256px;
     box-shadow: inset -2px 2px 2px rgba(0, 0, 0, 0.25);
     border-radius: 8px;
     border: 0;
     color: #fcfcfc;
 }

 h2 {
     padding: 0 2em;
 }
</style>
