//---------------------------------------------
// Aplicações Module 
//---------------------------------------------
// Módulo para gerenciar as ações da aplicação

// import axios from 'axios';
// axios.defaults.baseURL = process.env.VUE_APP_API_ROOT ? process.env.VUE_APP_API_ROOT : 'http://localhost:3000'
import axios from '@middleware/api.js'
import Cookies from 'js-cookie'
var JWT = () => {
    return (Cookies.get("accessToken") ? Cookies.get("accessToken") : "JWT VAZIO")
}

// STATES
const state = () => ({
    listarAplicacao: [],
})

// MUTATIONS
const mutations = {
    //-- MUTATION PARA LISTAR APLICAÇÕES DO CAMPO/AMOSTRA
    setListarAplicacao(state, payload) {
        state.listarAplicacao = payload
    },
}

// ACTIONS
const actions = {
    //-- ACTION REQUISICAO LISTAR APLICAÇÕES DA AMOSTRA/CAMPO 
    async setListarAplicacao( {commit}, payload)  {
        await axios
            .post(`aplicacoes/listAplicacoes/${payload.idInstituicao}/${payload.idCampo}`, {},
                {headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then((response) => {
                commit('setListarAplicacao', response.data)
            })
            .catch((error) => console.log(error.message))
    },

    //-- ACTION DELETE APLICAÇÃO 
    async deleteAplicacao(_, payload) {
        await axios
            .delete(`aplicacoes/${payload.id_instituicao}/${payload.id_aplicacao}`, {
                idAmostraAplicacao: payload.idAmostraAplicacao
            },{
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .catch((error) => console.log(error.message))
    },
}

// GETTERS
const getters = {
    getListarAplicacao(state) {
        try {
            return state.listarAplicacao.data
        } catch (e) {
            return []
        }
    },
}

export default {
    state,
    mutations,
    actions,
    getters
}
