<!--
     |---------------------------------------------
     | DropdownMenu Component
     |---------------------------------------------
     | @usage Import the component and use, no params
     | A Dropdown Menu to the main Navbar
-->

<template>  
    <div>
        <div>
            <!-- OVERLAY PARA TODOS DROPDOWNMENU-->
            <div v-if="screenSize > 991" @click="closeDropdownMenu" class="overlay-invisible" :class="{'overlay-visible': !isActiveDropdownMenu}"></div>

            <!-------------- VERSAO DESKTOP ----------------->

            <!-- QUALIDADE NA EI -->
            <!-- DROPDOWN MAIOR E PADRÃO CASO CAIBA NA TELA -->
            <div v-if="getScreenSize() > 1500 && menu == 'qualidadeEi'" class="container popup-menu mt-3" :class="{'popup-menu-visible': !isActiveDropdownMenu}">
                <DisplayTriangle />
                <div class="row">
                    <div class="col-md-8">
                        <router-link class="row box-md" to="/boaspraticas/qualidade" @click="closeDropdownMenu">
                            <div class="col-md-2 pl-4 icone-dropdown">
                                <img src="@assets/img/App/Navbar/icone-oquee.webp" alt="">
                            </div>
                            <div class="col-md-10 info-dropdown">
                                <h1 class="secao-titulo-dropdown">O que é?</h1>
                                <p class="secao-texto-dropdown">Entenda sobre a importância da qualidade na Educação Infantil</p>
                            </div>
                        </router-link>

                        <router-link class="row box-md" to="/boaspraticas/docoficiais" @click="closeDropdownMenu">
                            <div class="col-md-2 pl-4 icone-dropdown">
                                <img src="@assets/img/App/Navbar/icone-acervo.webp" alt="">
                            </div>
                            <div class="col-md-10 info-dropdown dropdown-icone-vertical">
                                <h1 class="secao-titulo-dropdown">Documentos Oficiais</h1>
                                <p class="secao-texto-dropdown">Conheça os documentos oficiais em relação à qualidade na Educação Infanti</p>
                            </div>
                        </router-link>
                    </div>

                    <div class="col-md-4">
                        <router-link class="row box-bg" to="/boaspraticas/oquee" @click="closeDropdownMenu">
                            <div class="col-md-12 dropdown-vertical">
                                <div class="row icone-dropdown-vertical">
                                    <img src="@assets/img/App/Navbar/icone-projeto.webp" alt="">
                                </div>
                                <div class="row info-dropdown-vertical">
                                    <h1 class="secao-titulo-dropdown">Boas Práticas</h1>
                                    <p class="secao-texto-dropdown text-center">
                                        Conheça boas práticas na Educação Infantil
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </div>

                </div>
            </div>

            <!-- MATERIAIS -->
            <!-- DROPDOWN MAIOR E PADRÃO CASO CAIBA NA TELA -->
            <div v-if="getScreenSize() > 1500 && menu == 'materiais'" class="container popup-menu popup-menu-small-short mt-3" :class="{'popup-menu-visible': !isActiveDropdownMenu}">
                <DisplayTriangle />
                <div class="row">
                    <div class="col-md-12 pl-0 pr-0">
                        <router-link to="/materiaisedocumentos/avaliacao" class="btn-router-link" @click="closeDropdownMenu">
                            <div class="row box-md">
                                <div class="col-md-2 icone-dropdown pl-1 pr-0">
                                    <img src="@assets/img/App/Navbar/icone-oquee.webp" alt="">
                                </div>
                                <div class="col-md-10 info-dropdown pl-1">
                                    <h1 class="secao-titulo-dropdown">O que é?</h1>
                                    <p class="secao-texto-dropdown">Entenda a seção de Materiais e Documentos.</p>
                                </div>
                            </div>
                        </router-link>

                        <router-link to="/materiaisedocumentos/instrumento" class="btn-router-link" @click="closeDropdownMenu">
                            <div class="row box-md mt-0">
                                <div class="col-md-2 icone-dropdown pl-1 pr-0">
                                    <img src="@assets/img/App/Navbar/icone-projeto.webp" alt="">
                                </div>
                                <div class="col-md-10 info-dropdown pl-1">
                                    <h1 class="secao-titulo-dropdown">Instrumentos</h1>
                                    <p class="secao-texto-dropdown">Entenda os instrumentos da plataforma e seu uso.</p>
                                </div>
                            </div>
                        </router-link>

                        <div class="btn-router-link" @click="closeDropdownMenu">
                            <div class="row box-md mt-0 menu-disable">
                                <div class="col-md-2 icone-dropdown pl-1 pr-0">
                                    <img src="@assets/img/App/Navbar/icone-lupa.webp" alt="">
                                </div>
                                <div class="col-md-10 info-dropdown pl-1">
                                    <h1 class="secao-titulo-dropdown">Procedimentos</h1>
                                    <p class="secao-texto-dropdown">Em breve!</p>
                                </div>
                            </div>
                        </div>

                        <a href="http://certificacao.observatorioei.org.br/" target="_blank" class="btn-router-link" @click="closeDropdownMenu">
                            <div class="row box-md mt-0">
                                <div class="col-md-2 icone-dropdown pl-1 pr-0">
                                    <img src="@assets/img/App/Navbar/icone-gerenciar.webp" alt="">
                                </div>
                                <div class="col-md-10 info-dropdown pl-1">
                                    <h1 class="secao-titulo-dropdown">Certificação</h1>
                                    <p class="secao-texto-dropdown">Certificação no uso dos instrumentos da plataforma.</p>
                                </div>
                            </div>
                        </a>

                        <router-link to="/materiaisedocumentos/realizadas" class="btn-router-link" @click="closeDropdownMenu">
                            <div class="row box-md mt-0">
                                <div class="col-md-2 icone-dropdown pl-1 pr-0">
                                    <img src="@assets/img/App/Navbar/icone-mapa.webp" alt="">
                                </div>
                                <div class="col-md-10 info-dropdown pl-1">
                                    <h1 class="secao-titulo-dropdown">Avaliações Realizadas</h1>
                                    <p class="secao-texto-dropdown">Acompanhe as avaliações realizadas pela plataforma.</p>
                                </div>
                            </div>
                        </router-link>

                        <!--
                             <router-link to="/materiaisedocumentos/materiais" class="btn-router-link" @click="closeDropdownMenu">
                             <div class="row box-md mt-0">
                             <div class="col-md-2 icone-dropdown pl-1 pr-0">
                             <img src="@assets/img/App/Navbar/icone-acervo.webp" alt="">
                             </div>
                             <div class="col-md-10 info-dropdown pl-1">
                             <h1 class="secao-titulo-dropdown">Materiais e Documentos</h1>
                             <p class="secao-texto-dropdown">Acesse os materiais e documentos e estude para a certificação.</p>
                             </div>
                             </div>
                             </router-link> -->
                    </div>
                </div>
            </div>
        </div>

        <!-- DADOS -->
        <!-- DROPDOWN MAIOR E PADRÃO CASO CAIBA NA TELA -->
        <div v-if="getScreenSize() > 1500 && menu == 'dados'" class="container popup-menu popup-menu-highest-dados mt-3" :class="{'popup-menu-visible': !isActiveDropdownMenu}">
            <DisplayTriangle />
            <div class="row">
                <div class="col-md-12 pl-0 pr-0">
                    <router-link class="row box-md" to="/implementacao/gestao" @click="closeDropdownMenu">
                        <div class="col-md-2 pl-4 icone-dropdown">
                            <img src="@assets/img/App/Navbar/icone-acervo.webp" alt="">
                        </div>
                        <div class="col-md-10 info-dropdown dropdown-icone-vertical">
                            <h1 class="secao-titulo-dropdown">Gestão da avaliação</h1>
                            <p class="secao-texto-dropdown">Entenda a seção Gestão da Avaliação</p>
                        </div>
                    </router-link>

                    <div class="btn-router-link" @click="verificaProximaRotaInstituicao">
                        <div class="row box-md" :class="{'menu-disable': !isLogged}">
                            <div class="col-md-2 pl-4 icone-dropdown pr-0">
                                <img src="@assets/img/App/Navbar/icone-gerenciar.webp" alt="">
                            </div>
                            <div class="col-md-10 pl-4 info-dropdown pr-2">
                                <h1 class="secao-titulo-dropdown">Gerenciar Instituições</h1>
                                <p class="secao-texto-dropdown">Crie, edite ou visualize suas instituições.</p>
                            </div>
                        </div>
                    </div>

                    <div class="btn-router-link" @click="verificaProximaRotaGrupos">
                        <div class="row box-md" :class="{'menu-disable': !isLogged}">
                            <div class="col-md-2 pl-4 icone-dropdown pr-0">
                                <img src="@assets/img/App/Navbar/icone-lupa.webp" alt="">
                            </div>
                            <div class="col-md-10 pl-4 info-dropdown pr-2">
                                <h1 class="secao-titulo-dropdown">Grupos de Pesquisa</h1>
                                <p class="secao-texto-dropdown">Crie, edite ou visualize equipes de Pesquisa.</p>
                            </div>
                        </div>
                    </div>

                    <!-- <router-link to="/dados/exportardevolutiva" class="btn-router-link" @click="closeDropdownMenu">
                         <div class="row box-md">
                         <div class="col-md-2 pl-4 icone-dropdown pr-0">
                         <img src="@assets/img/App/Navbar/icone-exportar.webp" alt="">
                         </div>
                         <div class="col-md-10 pl-4 info-dropdown pr-2">
                         <h1 class="secao-titulo-dropdown">Exportar Devolutiva</h1>
                         <p class="secao-texto-dropdown">Gere uma devolutiva a partir de dados externos. Saiba Mais.</p>
                         </div>
                         </div>
                         </router-link>
                         <router-link to="/dados/baixardadosnacionais" class="btn-router-link" @click="closeDropdownMenu">
                         <div class="row box-md">
                         <div class="col-md-2 pl-4 icone-dropdown pr-0">
                         <img src="@assets/img/App/Navbar/icone-mapa.webp" alt="">
                         </div>
                         <div class="col-md-10 pl-4 info-dropdown pr-2">
                         <h1 class="secao-titulo-dropdown">Baixar Dados Nacionais</h1>
                         <p class="secao-texto-dropdown">Baixe a base nacional de dados coletados pela nossa equipe.</p>
                         </div>
                         </div>
                         </router-link> -->
                </div>
            </div>
        </div>

        <!-- AQUI -->

        <!-- IMPLEMENTAÇÃO -->
        <!-- DROPDOWN MAIOR E PADRÃO CASO CAIBA NA TELA -->
        <div v-if="getScreenSize() > 1500 && menu == 'implementacao'" class="container popup-menu popup-menu-highest-dados mt-3" :class="{'popup-menu-visible': !isActiveDropdownMenu}">
            <DisplayTriangle />
            <div class="row">
                <div class="col-md-12 pl-0 pr-0">
                    <a class="row box-md"
                       href="https://play.google.com/store/apps/details?id=com.LEPES.EAPI"
                       target="_blank"
                       @click="closeDropdownMenu">
                        <div class="col-md-2 pl-4 icone-dropdown pr-0">
                            <img src="@assets/img/App/Navbar/icone-projeto.webp" alt="">
                        </div>
                        <div class="col-md-10 pl-4 info-dropdown pr-2">
                            <h1 class="secao-titulo-dropdown">Baixe o app</h1>
                            <!-- <p class="secao-texto-dropdown">Entenda a seção de Boas Práticas.</p> -->
                        </div>
                    </a>

                    <div class="row box-md menu-disable" @click="closeDropdownMenu">
                        <div class="col-md-2 pl-4 icone-dropdown pr-0">
                            <img src="@assets/img/App/Navbar/icone-gerenciar.webp" alt="">
                        </div>
                        <div class="col-md-10 pl-4 info-dropdown pr-2">
                            <h1 class="secao-titulo-dropdown">Base de dados e análises</h1>
                            <p class="secao-texto-dropdown text-center">Em breve!</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- SOBRE -->
        <!-- DROPDOWN MAIOR E PADRÃO CASO CAIBA NA TELA -->
        <div v-if="getScreenSize() > 1500 && menu == 'sobre'" class="container popup-menu popup-menu-small-short mt-3" :class="{'popup-menu-visible': !isActiveDropdownMenu}">
            <DisplayTriangle />
            <div class="row">
                <div class="col-md-12 pl-0 pr-0">
                    <router-link class="row box-md" to="/sobre/oquee" @click="closeDropdownMenu">
                        <div class="col-md-2 pl-1 icone-dropdown pr-0">
                            <img src="@assets/img/App/Navbar/icone-oquee.webp" alt="">
                        </div>
                        <div class="col-md-10 info-dropdown pl-1">
                            <h1 class="secao-titulo-dropdown">O que é a plataforma?</h1>
                            <p class="secao-texto-dropdown">Entenda mais sobre o contexto da plataforma</p>
                        </div>
                    </router-link>

                    <router-link class="row box-md" to="/faq" @click="closeDropdownMenu">
                        <div class="col-md-2 pl-1 icone-dropdown pr-0">
                            <img src="@assets/img/App/Navbar/icone-lupa.webp" alt="">
                        </div>
                        <div class="col-md-10 info-dropdown pl-1">
                            <h1 class="secao-titulo-dropdown">FAQ</h1>
                            <p class="secao-texto-dropdown">Perguntas frequentes sobre a plataforma.</p>
                        </div>
                    </router-link>

                    <a class="row box-md" href="http://lepes.fearp.usp.br/" target="_blank" @click="closeDropdownMenu">
                        <div class="col-md-2 pl-1 icone-dropdown pr-0">
                            <img src="@assets/img/App/Navbar/icone-gerenciar.webp" alt="">
                        </div>
                        <div class="col-md-10 info-dropdown pl-1">
                            <h1 class="secao-titulo-dropdown">Contato</h1>
                            <p class="secao-texto-dropdown text-center">Entre em contato conosco.</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <!------------- TABLET ------------>

        <!-- QUALIDADE NA EI -->
        <!-- DROPDOWN MENOR E COMPACTO PARA CABER EM TELAS TABLET -->
        <div v-if="getScreenSize() > 991 && getScreenSize() < 1500 && menu == 'qualidadeEi'" class="container popup-menu popup-menu-small mt-3" :class="{'popup-menu-visible': !isActiveDropdownMenu}">
            <DisplayTriangle />
            <div class="row">
                <div class="col-md-12 pl-0 pr-0">

                    <router-link to="/boaspraticas/qualidade" class="row box-md" @click="closeDropdownMenu">
                        <div class="col-md-2 pl-4 icone-dropdown pr-0">
                            <img src="@assets/img/App/Navbar/icone-oquee.webp" alt="">
                        </div>
                        <div class="col-md-10 pl-4 info-dropdown pr-2">
                            <h1 class="secao-titulo-dropdown">O que é?</h1>
                            <p class="secao-texto-dropdown">Entenda sobre a importância da qualidade na Educação Infantil</p>
                        </div>
                    </router-link>

                    <router-link to="/boaspraticas/docoficiais" class="row box-md" @click="closeDropdownMenu">
                        <div class="col-md-2 pl-4 icone-dropdown pr-0">
                            <img src="@assets/img/App/Navbar/icone-acervo.webp" alt="">
                        </div>
                        <div class="col-md-10 pl-4 info-dropdown pr-2">
                            <h1 class="secao-titulo-dropdown">Documentos oficiais</h1>
                            <p class="secao-texto-dropdown">Conheça os documentos oficiais em relação à qualidade na Educação Infantil</p>
                        </div>
                    </router-link>

                    <router-link to="/boaspraticas/oquee" class="row box-md" @click="closeDropdownMenu">
                        <div class="col-md-2 pl-4 icone-dropdown pr-0">
                            <img src="@assets/img/App/Navbar/icone-projeto.webp" alt="">
                        </div>
                        <div class="col-md-10 pl-4 info-dropdown pr-2">
                            <h1 class="secao-titulo-dropdown">Boas Práticas</h1>
                            <p class="secao-texto-dropdown">
                                Conheça boas práticas na Educação Infantil
                            </p>
                        </div>
                    </router-link>
                    
                </div>
            </div>
        </div>

        <!-- MATERIAIS -->
        <!-- DROPDOWN MENOR E COMPACTO PARA CABER EM TELAS TABLET -->
        <div v-if="getScreenSize() > 991 && getScreenSize() < 1500 && menu == 'materiais'" class="container popup-menu popup-menu-small mt-3" :class="{'popup-menu-visible': !isActiveDropdownMenu}">
            <DisplayTriangle />
            <div class="row">
                <div class="col-md-12 pl-0 pr-0">
                    <router-link to="/materiaisedocumentos/avaliacao" class="btn-router-link" @click="closeDropdownMenu">
                        <div class="row box-md">
                            <div class="col-md-2 pl-4 icone-dropdown pr-0">
                                <img src="@assets/img/App/Navbar/icone-oquee.webp" alt="">
                            </div>
                            <div class="col-md-10 pl-4 info-dropdown pr-2">
                                <h1 class="secao-titulo-dropdown">O que é?</h1>
                                <p class="secao-texto-dropdown">Entenda a seção de Materiais e Documentos.</p>
                            </div>
                        </div>
                    </router-link>

                    <router-link to="/materiaisedocumentos/instrumento" class="btn-router-link" @click="closeDropdownMenu">
                        <div class="row box-md">
                            <div class="col-md-2 pl-4 icone-dropdown pr-0">
                                <img src="@assets/img/App/Navbar/icone-projeto.webp" alt="">
                            </div>
                            <div class="col-md-10 pl-4 info-dropdown pr-2">
                                <h1 class="secao-titulo-dropdown">Instrumentos</h1>
                                <p class="secao-texto-dropdown">Entenda os intrumentos da plataforma e seu uso.</p>
                            </div>
                        </div>
                    </router-link>

                    <div class="btn-router-link" @click="closeDropdownMenu">
                        <div class="row box-md menu-disable">
                            <div class="col-md-2 pl-4 icone-dropdown pr-0">
                                <img src="@assets/img/App/Navbar/icone-lupa.webp" alt="">
                            </div>
                            <div class="col-md-10 pl-4 info-dropdown pr-2">
                                <h1 class="secao-titulo-dropdown">Procedimentos</h1>
                                <p class="secao-texto-dropdown">Em breve!</p>
                            </div>
                        </div>
                    </div>

                    <a href="http://certificacao.observatorioei.org.br/" class="btn-router-link" @click="closeDropdownMenu" target="_blank">
                        <div class="row box-md">
                            <div class="col-md-2 pl-4 icone-dropdown pr-0">
                                <img src="@assets/img/App/Navbar/icone-gerenciar.webp" alt="">
                            </div>
                            <div class="col-md-10 pl-4 info-dropdown pr-2">
                                <h1 class="secao-titulo-dropdown">Certificação</h1>
                                <p class="secao-texto-dropdown">Certificação no uso dos instrumentos da plataforma.</p>
                            </div>
                        </div>
                    </a>

                    <router-link to="/materiaisedocumentos/realizadas" class="btn-router-link" @click="closeDropdownMenu">
                        <div class="row box-md">
                            <div class="col-md-2 pl-4 icone-dropdown pr-0">
                                <img src="@assets/img/App/Navbar/icone-mapa.webp" alt="">
                            </div>
                            <div class="col-md-10 pl-4 info-dropdown pr-2">
                                <h1 class="secao-titulo-dropdown">Avaliações Realizadas</h1>
                                <p class="secao-texto-dropdown">Acompanhe as avaliações realizadas pela plataforma.</p>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        <!-- DADOS -->
        <!-- DROPDOWN MENOR E COMPACTO PARA CABER EM TELAS TABLET -->
        <div v-if="getScreenSize() > 991 && getScreenSize() < 1500 && menu == 'dados'" class="container popup-menu popup-menu-highest-dados mt-3" :class="{'popup-menu-visible': !isActiveDropdownMenu}">
            <DisplayTriangle />
            <div class="row">
                <div class="col-md-12 pl-0 pr-0">
                    <router-link class="row box-md" to="/implementacao/gestao" @click="closeDropdownMenu">
                        <div class="col-md-2 pl-4 icone-dropdown pr-0">
                            <img src="@assets/img/App/Navbar/icone-acervo.webp" alt="">
                        </div>
                        <div class="col-md-10 pl-4 info-dropdown pr-2">
                            <h1 class="secao-titulo-dropdown">Gestão da avaliação</h1>
                            <p class="secao-texto-dropdown">Entenda a seção Gestão da Avaliação</p>
                        </div>
                    </router-link>

                    <div class="btn-router-link" @click="verificaProximaRotaInstituicao">
                        <div class="row box-md" :class="{'menu-disable': !isLogged}">
                            <div class="col-md-2 pl-4 icone-dropdown pr-0">
                                <img src="@assets/img/App/Navbar/icone-gerenciar.webp" alt="">
                            </div>
                            <div class="col-md-10 pl-4 info-dropdown pr-2">
                                <h1 class="secao-titulo-dropdown">Gerenciar Instituições</h1>
                                <p class="secao-texto-dropdown">Crie, edite ou visualize suas Institui</p>
                            </div>
                        </div>
                    </div>
                    <div  class="btn-router-link" @click="verificaProximaRotaGrupos">
                        <div class="row box-md" :class="{'menu-disable': !isLogged}">
                            <div class="col-md-2 pl-4 icone-dropdown pr-0">
                                <img src="@assets/img/App/Navbar/icone-lupa.webp" alt="">
                            </div>
                            <div class="col-md-10 pl-4 info-dropdown pr-2">
                                <h1 class="secao-titulo-dropdown">Grupos de Pesquisa</h1>
                                <p class="secao-texto-dropdown">Crie, edite ou visualize equipes de Pesquisa.</p>
                            </div>
                        </div>
                    </div>
                    <!-- <router-link to="/dados/exportardevolutiva" class="btn-router-link" @click="closeDropdownMenu">
                         <div class="row box-md">
                         <div class="col-md-2 pl-4 icone-dropdown pr-0">
                         <img src="@assets/img/App/Navbar/icone-exportar.webp" alt="">
                         </div>
                         <div class="col-md-10 pl-4 info-dropdown pr-2">
                         <h1 class="secao-titulo-dropdown">Exportar Devolutiva</h1>
                         <p class="secao-texto-dropdown">Gere uma devolutiva a partir de dados externos. Saiba Mais.</p>
                         </div>
                         </div>
                         </router-link>
                         <router-link to="/dados/baixardadosnacionais" class="btn-router-link" @click="closeDropdownMenu">
                         <div class="row box-md">
                         <div class="col-md-2 pl-4 icone-dropdown pr-0">
                         <img src="@assets/img/App/Navbar/icone-mapa.webp" alt="">
                         </div>
                         <div class="col-md-10 pl-4 info-dropdown pr-2">
                         <h1 class="secao-titulo-dropdown">Baixar Dados Nacionais</h1>
                         <p class="secao-texto-dropdown">Baixe a base nacional de dados coletados pela nossa equipe.</p>
                         </div>
                         </div>
                         </router-link> -->
                </div>
            </div>  
        </div>

        <!-- IMPLEMENTACAO -->
        <!-- DROPDOWN MENOR E COMPACTO PARA CABER EM TELAS TABLET -->
        <div v-if="getScreenSize() > 991 && getScreenSize() < 1500 && menu == 'implementacao'" class="container popup-menu popup-menu-small mt-3" :class="{'popup-menu-visible': !isActiveDropdownMenu}">
            <DisplayTriangle />
            <div class="row">
                <div class="col-md-12 pl-0 pr-0">

                    <a href="https://play.google.com/store/apps/details?id=com.LEPES.EAPI"
                       target="_blank"
                       class="row box-md"
                       @click="closeDropdownMenu">
                        <div class="col-md-2 pl-4 icone-dropdown pr-0">
                            <img src="@assets/img/App/Navbar/icone-projeto.webp" alt="">
                        </div>
                        <div class="col-md-10 pl-4 info-dropdown pr-2">
                            <h1 class="secao-titulo-dropdown">Baixe o app</h1>
                            <!-- <p class="secao-texto-dropdown">Entenda a seção de Boas Práticas.</p> -->
                        </div>
                    </a>

                    <div class="row box-md menu-disable" @click="closeDropdownMenu">
                        <div class="col-md-2 pl-4 icone-dropdown pr-0">
                            <img src="@assets/img/App/Navbar/icone-projeto.webp" alt="">
                        </div>
                        <div class="col-md-10 pl-4 info-dropdown pr-2">
                            <h1 class="secao-titulo-dropdown">Base de dados e análises</h1>
                            <p class="secao-texto-dropdown">Em breve!</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- SOBRE NÓS -->
        <!-- DROPDOWN MENOR E COMPACTO PARA CABER EM TELAS TABLET -->
        <div v-if="getScreenSize() > 991 && getScreenSize() < 1500 && menu == 'sobre'" class="container popup-menu popup-menu-small mt-3" :class="{'popup-menu-visible': !isActiveDropdownMenu}">
            <DisplayTriangle />
            <div class="row">
                <div class="col-md-12 pl-0 pr-0">

                    <router-link to="/sobre/oquee" class="row box-md" @click="closeDropdownMenu">
                        <div class="col-md-2 pl-4 icone-dropdown pr-0">
                            <img src="@assets/img/App/Navbar/icone-oquee.webp" alt="">
                        </div>
                        <div class="col-md-10 pl-4 info-dropdown pr-2">
                            <h1 class="secao-titulo-dropdown">O que é a plataforma?</h1>
                            <p class="secao-texto-dropdown">Entenda mais sobre o contexto da plataforma</p>
                        </div>
                    </router-link>

                    <router-link to="/faq" class="row box-md" @click="closeDropdownMenu">
                        <div class="col-md-2 pl-4 icone-dropdown pr-0">
                            <img src="@assets/img/App/Navbar/icone-lupa.webp" alt="">
                        </div>
                        <div class="col-md-10 pl-4 info-dropdown pr-2">
                            <h1 class="secao-titulo-dropdown">FAQ</h1>
                            <p class="secao-texto-dropdown">Perguntas frequentes sobre a plataforma.</p>
                        </div>
                    </router-link>

                    <a href="http://lepes.fearp.usp.br/" class="row box-md" @click="closeDropdownMenu" target="_blank">
                        <div class="col-md-2 pl-4 icone-dropdown pr-0">
                            <img src="@assets/img/App/Navbar/icone-gerenciar.webp" alt="">
                        </div>
                        <div class="col-md-10 pl-4 info-dropdown pr-2">
                            <h1 class="secao-titulo-dropdown">Contato</h1>
                            <p class="secao-texto-dropdown">Entre em contato conosco.</p>
                        </div>
                    </a>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
 import DisplayTriangle from "./DisplayTriangle";

 export default {
     name: 'DropdownMenu',
     data () {
         return {
             screenSize: Number,
             isActiveDropdownMenu: true,
             menu: this.menuDropdown
         }
     },
     components: {
         DisplayTriangle,
     },
     props: {
         menuDropdown: String
     },
     computed: {
         isLogged() {
             return this.$store.getters.getIsLogged
         }
     },
     methods: {
         verificaProximaRotaInstituicao: function() {
             if (this.isLogged) {
                 this.$router.push({path: "/dados/gerenciar/instituicao"})
                 this.closeDropdownMenu()
             }
         },
         verificaProximaRotaGrupos: function() {
             if (this.isLogged) {
                 this.$router.push({path: "/dados/gerenciar/grupos"})
                 this.closeDropdownMenu()
             }
         },
         showDropdownMenu: function() {
             this.isActiveDropdownMenu = false
         },
         closeDropdownMenu: function() {
             this.isActiveDropdownMenu = true
         },
         getScreenSize: function() {
             return window.innerWidth
         },
         getScreenSizeforOverlay: function() {
             this.screenSize = window.innerWidth
         }
     }
 }
</script>

<style scoped>
 /*
    |-----------------------------------------------------
    |--ADICIONANDO OVERLAY INVISIVEL 0 DE OPACITY
    |-----------------------------------------------------
  */

 .overlay-invisible {
     position: fixed;
     top: 0; right: 0; bottom: 0; left: 0;
     background-color: rgba(22, 22, 22, 0.0);
     z-index: 2;
     transition: all ease-in-out 0.3s;
     opacity: 0;
     pointer-events: none;
 }

 .overlay-visible {
     opacity: 1;
     pointer-events: all;
     cursor: default;
 }

 /*-----------------------------------------------------*/

 /*
    |-----------------------------------------------------
    |--RETIRANDO PADDINGS E MARGINS DAS CLASSES BOOTSTRAP
    |--DOS COMPONENTES QUE SERÃO USADOS NO DROPDOWN
    |-----------------------------------------------------
  */
 .container,
 .container .row,
 .container .row .col-md-6,
 .container .row .col-md-8,
 .container .row .col-md-4 {
     padding: 0;
     margin: 0;
 }
 /*-----------------------------------------------------*/

 /*
    |-----------------------------------------------------
    |--ESTILIZANDO O DROPDOWN DAS BOAS PRÁTICAS DO NAVBAR
    |-----------------------------------------------------
  */
 .popup-menu {
     opacity: 0;
     /* height: 16.25rem; */
     height: max-content;
     width: 30rem;
     position: absolute;
     background-color: white;
     border-radius: 0.563rem;
     z-index: 2;
     cursor: default;
     transition: all 0.4s ease;
     pointer-events: none;
     box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.25);
 }

 .popup-menu-small {
     height: max-content;
     width: 18rem;
 }

 .popup-menu-highest-dados {
     height: max-content;
     width: 18rem;
 }

 .popup-menu-small-short {
     height: max-content;
     width: 24rem;
 }

 .popup-menu-largest-dados {
     height: max-content;
     width: 34rem;
 }

 .popup-menu-visible {
     opacity: 1;
     pointer-events: all;
 }

 .box-md {
     background-color: #F5F5F5;
     height: 6.25rem;
     width: auto;
     border-radius: 0.563rem;
     margin: 1.25rem 1.25rem 1.25rem 1.25rem !important;
     cursor: pointer;
 }

 .box-md-ml-0 {
     background-color: #F5F5F5;
     height: 6.25rem;
     width: auto;
     border-radius: 0.563rem;
     margin: 1.25rem 1.25rem 1.25rem 0 !important;
     cursor: pointer;
 }

 .box-bg {
     background-color: #F5F5F5;
     height: 13.75rem;
     width: auto;
     border-radius: 9px;
     margin: 1.25rem 1.25rem 1.25rem 0rem !important;
     cursor: pointer;
 }

 .triangulo-1 {
     position: absolute;
     left: 30px;
     top: -18px;
 }

 .icone-dropdown {
     display: flex;
     align-items: center;
     justify-content: center;
 }

 .info-dropdown {
     display: flex;
     align-items: flex-start;
     justify-content: center;
     flex-direction: column;
 }

 .secao-titulo-dropdown{
     font-size: 1rem;
     font-weight: 800;
     color: #655D5D;
     text-transform: none;
     margin-bottom: 0.2rem;
     text-align: start;
 }

 .secao-texto-dropdown{
     font-size: 0.8rem;
     text-align: left;
     text-transform: none;
     color: #878787;
     margin-bottom: 0;
 }

 .dropdown-vertical {
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
 }

 .menu-disable {
     filter: grayscale(1);
     cursor: default;
     background-color: #ebebeb;
 }

 img {
     max-height: 50px;
     max-width: auto;
 }


 .row .box-md,
 .row .box-bg,
 .row .box-md-ml-0 {
     transition: background-color ease-in-out 0.2s;
 }

 .row .box-md:hover,
 .row .box-bg:hover,
 .row .box-md-ml-0:hover {
     background-color: #ebebeb;
 }

 /*-------/----------------------------------------------*/

</style>
