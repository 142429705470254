<template>
  <div class="frameContainer" v-for="c of power_bi" :key="c?.id_bi">
    <iframe class="dashFrame" frameborder="0" :src="c?.url" />
  </div>
</template>

<script type="text/javascript">
export default {
    name: "LoadIframe",
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    computed: {
        power_bi() { return this.data?.power_bi || []; },
    },
};
</script>

<style scoped>
.frameContainer {
    width: 100%;
    overflow: auto;
    resize: vertical;
    height: fit-content;
    margin: 1em 0 1em 0;
    aspect-ratio: 1 / 1;
}

.dashFrame {
    width: 100%;
    height: 100%;
}
</style>
