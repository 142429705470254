<template>
    <div class="bg">
        <div class="list-data">

            <ModalCentral
                componentName="AddClass"
                title="ADICIONAR TURMAS"
                idName="AddTurmas"
            />

            <ModalCentral
                componentName="AddClassByFile"
                title="ADICIONAR TURMAS"
                idName="AddTurmasByFile"
            />

            <div class="title-tree">
                <Breadcrumb :crumbs="crumbs" section="instituicao-cadastrar"/>
            </div>

            <InstitutionInfo class="title-style" :title="nomeInstituicao" section="instituicao-cadastrar"/>

            <p class="text-info-personal imgText">Nesta seção, você encontrará todas as Turmas pertencentes à Unidade Educacional. Caso necessário, utilize o filtro abaixo para localizar a Turma desejada.</p>

            <div class="itens-box">
                <div class="filter-box">
                    <!-- INICIO DO FORM DE FILTRAGEM -->
                    <form @submit.prevent="" class="form-inline">
                        <div class="form-group">

                            <!-- DROPDOWN DE SELECAO DE FILTRO -->
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomSelect">Filtrar por: </label>
                            <Filter ref="filtersearchname" @change="setSelectedValue()" :optionsSelect="options" nameSelect="filtro_instituicoes" id="inlineFormCustomSelect"/>
                        </div>

                        <!-- SE A SELECAO FOR NOME (2) ENTAO EXIBIRA OS SUBFILTROS DE NOME -->
                        <div class="form-group" v-if="selectedValue == 2">
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomName">Digite o nome: </label>
                            <input class="input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_instituicoes" id="inlineFormCustomName" autocomplete="off">
                        </div>

                        <!-- SE A SELECAO FOR POR GRUPOS (3) ENTAO EXIBIRA OS SUBFILTROS DOS GRUPOS -->
                        <div class="form-group" v-if="selectedValue == 3">
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomCodigo">Selecione o grupo: </label>
                            <Filter ref="filtersearchgroup" @click="setSelectedGroup()" :optionsSelect="group_options" nameSelect="filtro_grupo_instituicoes" id="inlineFormCustomCodigo"/>
                        </div>

                    </form>
                </div>
                <div class="lista-data-info">
                    <!-- INSERINDO @CLICK APENAS PARA TESTAR O POST CRIAR TURMA, RETIRAR DEPOIS!!!!!!!!!!!!!!! -->
                    <Button
                        v-if="userPerm.permissao != 'APLICADOR'"
                        class="btn mr-2 btnCtrl"
                        value="Adicionar Turmas"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="graduation-cap"
                        data-toggle="modal"
                        data-target="#AddTurmas"
                    />
                    <!-- INSERINDO @CLICK APENAS PARA TESTAR O POST ENVIAR ARQUIVO PRA CRIAR TURMA, RETIRAR DEPOIS!!!!!!!!!!!!!!! -->
                    <!-- <Button
                         v-if="userPerm.permissao != 'APLICADOR'"
                         class="btn"
                         section="instituicao-cadastrar"
                         value="Importar Lista de Turmas"
                         :isOutline="true"
                         :hasIcon="true"
                         iconName="clipboard-list"
                         data-toggle="modal"
                         data-target="#AddTurmasByFile"
                         /> -->
                </div>
            </div>
            <hr class="separator">

            <!-------------------------------- LISTANDO AS TURMAS ----------------------------------------------->

            <div v-if="isLoadedInfo">
                <MemberQnt turmas="turmas" :totalQnt="totalTurmas" :filteredQnt="turmas?.length || 0" icon="fa fa-graduation-cap" />

                <transition-group v-if="turmas?.length > 0" name="slide-fade">
                    <div class="box-header">
                        <span></span>
                        <span>Nome</span>
                        <span>Perído</span>
                        <span>Código</span>
                    </div>

                    <div class="box-lista" v-for="turma in turmas" :key="turma.counter">
                        <div>
                            <img  class="img-info-box-lista" src="@assets/img/Dados/ListarUE/icon-turmas.png" alt="">
                        </div>
                        <span>{{turma.nome}}</span>
                        <span>{{turma.periodo}}</span>
                        <span>{{turma.codigo || '-'}}</span>
                        <!-- <span class="third-info">Editar Turma</span> -->
                        <!-- <span class="last-info">Excluir Turma</span> -->
                    </div>
                </transition-group>
                <transition-group v-else>
                    <h2 class="title-style-box-lista">Nenhuma Turma encontrada.</h2>
                </transition-group>

            </div>

            <div v-else>
                <SkeletonList/>
            </div>

        </div>
    </div>

</template>

<script>
 import Filter from '@components/Filter'
 import Button from '@components/Button'
 import ModalCentral from '@components/ModalCentral'
 import Breadcrumb from '@components/Breadcrumb'
 import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
 import SkeletonList from '@components/SkeletonUI/SkeletonList'
 import MemberQnt from '@dados/VisualizarInstituicao/MemberQnt'

 export default {
     name: 'ListarFormularios',
     data (){
         return {
             nomeInstituicao: '',
             descricao: '',
             selectedValue: '',
             searchByName: '',
             isLoadedInfo: false,
             teste: [],
             options: [
                 {
                     id:1,
                     value: 'Todos'
                 },
                 {
                     id:2,
                     value: 'Nome'
                 }
             ],
             crumbs: [
                 {
                     id:1,
                     value: '',
                     iconName: 'th',
                     name_route: 'GerenciarInstituicoes'
                 },
                 {
                     id:2,
                     value: 'Instituição',
                     iconName: 'university',
                     name_route: 'InstituicaoMenu'
                 },
                 {
                     id:3,
                     value: 'Cadastrar Informações',
                     iconName: 'search-plus',
                     name_route: 'InstituicaoMenuCadastrar'
                 },
                 {
                     id:4,
                     value: 'Unidades Educacionais',
                     iconName: 'graduation-cap',
                     name_route: 'ListarUnidadesEducacionais'
                 },
                 {
                     id:5,
                     value: 'Turmas',
                     iconName: 'graduation-cap',
                     name_route: ''
                 }
             ]
         }
     },
     async created() {
         this.teams = this.$store.getters.getListTeams

         window.scrollTo(0, 0);
         const payload = {
             idInstituicao: this.$route.params.idInstituicao,
             idUnidade: this.$route.params.idUnidade
         }
         const payload2 = {
             idInstituicao: this.$route.params.idInstituicao
         }
         await this.$store.dispatch('setListTurmas', payload)

         this.isLoadedInfo = false
         await this.$store.dispatch('setUserPerm', payload2)
         this.isLoadedInfo = true
     },
     computed: {
         totalTurmas() {
             return this.$store.getters?.getListTurma.length || 0;
         },
         turmas() {
             var turmas = this.$store.getters.getListTurma

             // VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
             var query = turmas;
             //CASO SEJA SELECIONADO TODOS, EXIBE TODOS OS ITENS DO ARRAY
             if (this.selectedValue == 1) {
                 query = turmas;
             }
             //CASO SEJA SELECIONADO NOME, FILTRA POR NOME
             if (this.selectedValue == 2) {
                 //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
                 if (this.searchByName.trim() == '') {
                     query = turmas;
                 }
                 //SENAO ELE FILTRA PELO O QUE FOR DIGITADO
                 else {
                     var filtered = turmas?.filter(turma =>turma.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
                     if(filtered?.length != 0){
                         query = filtered
                     }

                     else{
                         query = false
                     }
                 }
             }
             return query;
         },
         userPerm() {
             return this.$store.getters.getUserPerm
         }
     },
     components: {
         Filter,
         Button,
         ModalCentral,
         Breadcrumb,
         InstitutionInfo,
         SkeletonList,
         MemberQnt,
     },
     methods: {
         setSelectedValue: function() {
             //! TODO: só pra parar de dar erro no console
             this.selectedValue = this.$refs.filtersearchname.value
         },
         async createTurma(){
             const payload = {
                 idUE: 3,
                 nome: 'Turma xxxx',
                 codigoTurma: 'Quinta',
                 periodo: 'Integral',
                 idade: '(5A - 5A E 11M)',
                 tipoTurma: 'regular'
             }
             await this.$store.dispatch('setCreateTurma', payload)
         },
         async sendClassFile(){
             const payload = {
                 id_instituicao: 2,
                 id_turma: 1,
                 file: 'filePathHere'
             }
             await this.$store.dispatch('setSendClassFile', payload)
         },

         async deleteTurma(){
             const payload = {
                 idInstituicao: 3,
                 idUE: 3,
                 idTurma: 8
             }
             await this.$store.dispatch('deleteTurma', payload)
         }
     },
     mounted: function() {
         window.scrollTo(0, 0);
         let idTeamSelected = this.$route.params.idInstituicao

         if (typeof idTeamSelected == "undefined"){
             this.nomeInstituicao = "Nome da Instituição"
         }
         else {
             let teamsSelected
             let teamSelected

             teamsSelected = this.teams.filter(team =>
                 team.idinstituicao == idTeamSelected
             )

             teamSelected = [...teamsSelected].shift()

             this.nomeInstituicao = teamSelected.name
             this.descricao = teamSelected.descricao
         }
     }
 }
</script>

<style scoped>
 .box-header {
     display: flex;
     justify-content: space-around;
     margin: 1rem 3rem 0 3rem;
 }

 .box-header > span {
     flex: 1;
     font-weight: 500;
     text-align: center;
 }

 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;
 }

 .imgText {
     background-image: url("~@/assets/img/Backgrounds/azulEapi.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .box-lista {
     background-repeat: repeat;
     background-size: 256px;
     background-image: url("~@/assets/img/Backgrounds/azulMaisClaro.webp");

     border: 1px solid #952713;
 }

 :deep(.box-lista span) {
     font-size: 1.1em;
 }

 .box-lista span {
     background-image: url("~@/assets/img/Backgrounds/marromEscuro.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }


 .text-info-personal {
     color: #656565;
     font-size: 1.1rem;
     text-align: justify;
     padding: 0rem;
     margin: 0 3rem 0 3rem;
 }

 .list-data {
     min-height: 90vh;
     padding-top: 2rem;
 }
 .label-filtro {
     font-weight: 600;
 }

 .filter-box {
     margin: 1rem 3rem;
 }

 .title-style{
     margin: 0 0 0 3rem;
 }

 .itens-box {
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .title-tree {
     margin: 1rem 3rem;
     font-weight: bold;
     display: flex;
 }

 .lista-data-info {
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;
     margin: 2rem 3rem;
 }

 .title-tree h1 {
     font-size: 1.2rem;
     font-weight: 700;
 }

 .main-link {
     outline:none;
     text-decoration: none;
     cursor: pointer;
     margin-right: .3rem;
 }

 .sub-link {
     margin-left: .3rem;
 }

 .separator {
     border: none;
     height: 1px;
     margin: 0.8rem 3rem;
     background: url("~@/assets/img/Backgrounds/azulEapi.webp");
 }

 a {
     color: #212529;
     text-decoration: none;
     transition: all ease-in-out 0.2s
 }

 a:hover {
     color: #4d4f50;
 }

 @media screen and (max-width: 1010px) {
     .lista-data-info {
         justify-content: center;
     }
 }


 @media screen and (max-width: 1012px) {
     .filter-box {
         display: flex;
         align-items: center;
         justify-content: center;
     }
 }

 @media screen and (min-width: 901px) {
     .first-info {
         flex-basis: 35%;
         text-align: left;
     }
 }

 @media screen and (max-width: 680px) {
     .itens-box{
         flex-direction: column;
     }
     .first-info {
         text-align: center;
         width: -webkit-fill-available;
     }
     .box-lista span, .box-lista a{
         width: 100%;
         text-align: center;
         margin-top: .5rem;
     }
     .box-lista{
         place-content: center;
     }
 }

 .btnCtrl {
     border: 1px solid #0e80c2;
     background-color: white;
 }

 :deep(.btnCtrl div) {
     background-image: url("~@/assets/img/Backgrounds/azulEapi.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
     transition: 0.4s;
 }

</style>
