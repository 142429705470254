<!--
|---------------------------------------------
| BreadCrumb Component 
|---------------------------------------------
| @usage Import the component and use <Breadcrumb crumb='Array()' section='Section Name'/>
| 
-->

<template>
<div id="crumbs">
	<ul :class="section">
		<li v-for="crumb in crumbs" :key="crumb.id"><router-link :to="{name: crumb.name_route}"><i :class="'fa fa-'+crumb.iconName" aria-hidden="true"></i>{{' '+crumb.value}}</router-link></li>
	</ul>
</div>
</template>


<script>
export default {
    props: {
        crumbs: Object,
        section: String
    }
}
</script>


<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";
    $crumbs-back:#F3F5FA;
    $text-color:#8093A7;

    #crumbs {
        /* text-align: center; */
        line-height: 0;
        a{
            padding:0 0 0px; 
            font-size: 0.9rem;
            font-weight:600;
            letter-spacing:.01rem;
            color: $text-color;
        }
        ul {
            list-style: none;
            display: inline-table;
            margin: 0;
            li {
                display: inline;
                a {
                    display: block;
                    float: left;
                    height: 40px;
                    background: white;
                
                    text-align: center;
                    padding: 10px 20px 0 30px;
                    position: relative;
                    margin: 0 12px 0 0; 
                    
                    font-size: 19px;
                    text-decoration: none;
                    color: $text-color;
                    -webkit-box-shadow: 10px 10px 29px -18px rgba(0,0,0,0.75);
                    -moz-box-shadow: 10px 10px 29px -18px rgba(0,0,0,0.75);
                    box-shadow: 10px 10px 29px -18px rgba(0,0,0,0.75);

                    user-select: none;

                    &:after {
                        content: "";  
                        border-top: 20px solid transparent;
                        border-bottom: 20px solid transparent;
                        border-left: 20px solid white;
                        position: absolute; 
                        right: -20px;
                        top: 0;
                        z-index: 1;
                    }
                    &:before {
                        content: "";  
                        border-top: 20px solid transparent;
                        border-bottom: 20px solid transparent;
                        border-left: 20px solid #f0f5f8;
                        position: absolute; 
                        left: 0; 
                        top: 0;
                    }
                }
            }
        } /* end of ul */
    } /*end of crumb */

    #crumbs ul li:first-child a {
        border-top-left-radius: 10px; border-bottom-left-radius: 10px;
    }
    #crumbs ul li:first-child a:before {
        display: none; 
    }

    /* Crumb last element style */

    #crumbs ul li:last-child a {
        padding-right: 40px;
        border-top-right-radius: 10px; border-bottom-right-radius: 10px;
    }
    #crumbs ul li:last-child a:after {
        display: none; 
    }   

    #crumbs ul li:not(:last-child) a, 
    #crumbs ul li:not(:last-child) a:after {
        transition: all 0.3s;
    }

 /* PADRAO DE CORES
    AZUL TURQUESA SEÇÃO DE DADOS
  */
    #crumbs .dados-section li:not(:last-child) a:hover {
        background: $verdeEscuro-eapi;
        color:#fff;
        cursor: pointer;
    }
    
    #crumbs .dados-section li a:hover:after {
        border-left-color: $verdeEscuro-eapi;
        color:#fff;
    }

    #crumbs .dados-section li:last-child a {
        background: $verdeEscuro-eapi;
        color:#fff;
        cursor: default;
    }
 /* AZUL DE CADASTRO */

    #crumbs .instituicao-cadastrar li:not(:last-child) a:hover {
        background: #676bd6;
        color:#fff;
        cursor: pointer;
    }
    
    #crumbs .instituicao-cadastrar li a:hover:after {
        border-left-color: #676bd6;
        color:#fff;
    }

    #crumbs .instituicao-cadastrar li:last-child a {
        background: #676bd6;
        color:#fff;
        cursor: default;
    }

 /* ROSA DE CAMPO */

     #crumbs .instituicao-campo li:not(:last-child) a:hover {
        background: #7647ac;
        color:#fff;
        cursor: pointer;
    }
    
    #crumbs .instituicao-campo li a:hover:after {
        border-left-color: #7647ac;
        color:#fff;
    }

    #crumbs .instituicao-campo li:last-child a {
        background: #7647ac;
        color:#fff;
        cursor: default;
    }

 /* LARANJA DE APLICACOES */

     #crumbs .instituicao-aplicacoes li:not(:last-child) a:hover {
        background: #FFAC30;
        color:#fff;
        cursor: pointer;
    }
    
    #crumbs .instituicao-aplicacoes li a:hover:after {
        border-left-color: #FFAC30;
        color:#fff;
    }

    #crumbs .instituicao-aplicacoes li:last-child a {
        background: #FFAC30;
        color:#fff;
        cursor: default;
    }

 /* VERMELHO DE DEVOLUTIVAS */

     #crumbs .instituicao-devolutivas li:not(:last-child) a:hover {
        background: #e43b3b;
        color:#fff;
        cursor: pointer;
    }
    
    #crumbs .instituicao-devolutivas li a:hover:after {
        border-left-color: #e43b3b;
        color:#fff;
    }

    #crumbs .instituicao-devolutivas li:last-child a {
        background: #e43b3b;
        color:#fff;
        cursor: default;
    }

 /* VERDE DE CSV */

     #crumbs .instituicao-csv li:not(:last-child) a:hover {
        background: #2edb53;
        color:#fff;
        cursor: pointer;
    }
    
    #crumbs .instituicao-csv li a:hover:after {
        border-left-color: #2edb53;
        color:#fff;
    }

    #crumbs .instituicao-csv li:last-child a {
        background: #2edb53;
        color:#fff;
        cursor: default;
    }

 /* VERMELHO DE MATERIAIS */

     #crumbs .materiais-documento li:not(:last-child) a:hover {
        background: #EB7071;
        color:#fff;
        cursor: pointer;
    }
    
    #crumbs .materiais-documentos li a:hover:after {
        border-left-color: #EB7071;
        color:#fff;
    }

    #crumbs .materiais-documento li:last-child a {
        background: #EB7071;
        color:#fff;
        cursor: default;
    }




 /* //DESATIVA BREADCRUMP PARA DISPOSITIVOS MOVEIS
    @media screen and (max-width: 992px) {
    #crumbs {
    display: none;
    }
    } */
</style>
