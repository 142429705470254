<template>
    <form @submit.prevent>
    <div class="container offset">
        <div class="custom-form">
            <div class="form-group">
                <label class="question-style">Nome:</label>
                <input
                    v-model.trim="nome"
                    type="name"
                    class="form-control"
                    placeholder="Insira aqui o nome do campo..."
                    required
                />
            </div>

            <div class="form-group">
                <label class="question-style">Ano:</label>
                <input
                    v-model.trim="ano"
                    type="number"
                    min="1900"
                    class="form-control"
                    placeholder="Insira aqui o ano do campo..."
                    required
                />
            </div>

            <div class="form-group">
                <label class="question-style">Descrição:</label>
                <textarea v-model.trim="descricao" class="form-control" rows=5 placeholder="Insira aqui a descrição do campo..." required></textarea>
            </div>

            <!-- <div class="form-group"> -->
            <!--     <label class="question-style">Formulário:</label> -->
            <!--     <FileInput ref="form" :types="[ 'application/pdf', ]" /> -->
            <!-- </div> -->
        </div> 
        <div class="button-div pt-3">
            <Button 
                @click="createAmostra"
                section="instituicao-campo"
                value="Salvar Campo"
                :isOutline="true"
                :hasIcon="true"
                iconName="save"
            /> 
        </div>
    </div>
    </form>
   
</template>

<script>
import Button from '@components/Button'
import { Notyf } from "notyf";
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: "left",
    y: "bottom",
  }
});

export default {
  name: "AddField",
  data() {
    return {
      nome: "",
      ano: "",
      descricao: "",
    }
  },
  components: {
    Button,
  },
  methods: {
    async createAmostra() {
      await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)

      if ((this.nome != '') && (this.ano != '') && (this.descricao != '')) {
        const payload = {
          idInstituicao: this.$route.params.idInstituicao,
          titulo: this.nome,
          ano: this.ano,
          descricao: this.descricao,
          // pdf: this.$refs.form.getFile(),
        }

        await this.$store.dispatch('setCreateAmostra', payload)
        if (!this.$store.getters.getIsAmostraCreated) {
          notyf.open({
            type: "error",
            message: "Erro ao criar campo!",
          });
          return;
        }

        await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)
        this.$emit('closeModal')
        notyf.open({
          type: "success",
          message: "Campo adicionado com sucesso!",
        });
      }
    }
  }
}
</script>

<style scoped>

.container{
    padding: 1rem;
    width: 100%;
    background-color: white;
    margin: 0;
    }

    .title-style{
        font-size: 1.5rem;
        font-weight: 600;
        color: #656565;
    }

    .word-style{
        color:  #656565;
    }

    .custom-form{
        margin: 0 auto;
    }

    .question-style{
        width: 100%;
        font-size: 1rem;
        color: #656565;
    }

    .button-div{
        text-align: right;
    }

</style>
