//---------------------------------------------
// Erros Module 
//---------------------------------------------
// Módulo para gerenciar os erros

// STATES
const state = () => ({
    doNotHavePermissionLogin: null
})

// MUTATIONS
const mutations = {
    //-- MUTATION PARA ALTERAR ESTADO DO ERRO GLOBAL DE PERMISSAO
    setDoNotHavePermissionLogin(state, payload) {
        state.doNotHavePermissionLogin = payload
    },
}

// ACTIONS
const actions = {

}

// GETTERS
const getters = {
    getDoNotHavePermissionLogin(state) {
        return state.doNotHavePermissionLogin
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
