<!--
|---------------------------------------------
| Hero Component 
|---------------------------------------------
| @usage Import the component and use, no params
| Map and Text on the Header of the page
-->

<template>
  <div class='container-fluid'>
    <div class='row'>
    
      <div class='hero-text col-md-6'>
        <h2>Educação que transforma</h2>
        <h6>
          O “Observatório da Educação Infantil” busca entender como a Educação Infantil no Brasil funciona e traz propostas de mudança, avaliando a qualidade das práticas pedagógicas por meio de pesquisas de campo em todo o país. O Banco de Práticas reúne um acervo de práticas pedagógicas que são submetidas por professores de todo o Brasil e avaliadas por profissionais do campo da Pedagogia e Psicologia 
        </h6>

        <Button section='main' value="Saiba Mais" />

      </div>
      <Map/>
   </div>
  </div>
</template>

<script>
import Button from '@components/Button';
import Map from './Map';

export default {
  name: 'Hero',
  components: {
    Button,
    Map
  }
}
</script>

<style scoped>

  .hero-text h6 {
    margin-bottom: 1rem;
  }

  h2,h6{
    text-align:justify;
  }

  .hero-text{
    padding: 200px 0px;
  }

      
  .hero-img{
    padding: 50px 0px 50px 120px;
  }

  .container-fluid {
    background-image: url('../../assets/img/background-hero.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1rem 10rem;
  }


  /*
 |-----------------------------------------------------
 |--ADICIONANDO MEDIA-QUERIES
 |-----------------------------------------------------
 */
  @media screen and (max-width: 768px) {
    .hero-img{
      padding: 1rem 0 1rem 0;
    }
    .container-fluid {
      padding: 1rem 2.5rem;
    }
    .hero-text{
      padding: 2rem 0px;
    }
    .row {
      flex-direction: column-reverse;
    }
  }

   @media screen and (min-width: 768px) and (max-width: 991px) {
    .hero-img{
      padding: 1rem 2rem;
    }
    .container-fluid {
      padding: 3rem 2.5rem;
    }
    .hero-text{
      padding: 2rem 0;
    }
  }

   @media screen and (min-width: 991px) and (max-width: 1400px) {
    .container-fluid {
      padding: 1rem 4rem;
    }
    .hero-img{
      padding: 5rem 3rem;
    }
  }

 /*-----------------------------------------------------*/
</style>
