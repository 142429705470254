<template>
    <div class="container">
        <div class="card-personal">
            <h1>Redefinir Senha</h1>
            <div class="new-password-form">
                <span>Nova senha: </span><input v-model="novaSenha" class="input-personal" type="password">
            </div>
            <div class="new-password-form mt-3 mb-2">
                <span>Repetir nova senha: </span><input v-model="repetirNovaSenha" class="input-personal" type="password">
            </div>
            
            <div class="verified-items-wrapper">
            <span :class="{'green-text': senhasIguais}" class="text-alert-match-passwords"><b>As senhas precisam ser iguais.</b></span>
            <span class="text-alert-match-passwords">
              <ul><span class="black-text">A senha precisa ter: </span>
                <li :class="{'green-text': senhaVerifiedMinusculo}">Pelo menos um caractere minúsculo</li>
                <li :class="{'green-text': senhaVerifiedMaiusculo}">Pelo menos um caractere maiúsculo</li>
                <li :class="{'green-text': senhaVerifiedEspecial}">Pelo menos um caractere especial</li>
                <li :class="{'green-text': senhaVerifiedNumero}">Pelo menos um caractere numérico</li>
                <li :class="{'green-text': senhaVerifiedTamanho}">Pelo menos oito caracteres</li>
              </ul>
            </span>
            </div>

            <div class="button-wrapper">
                <Button
                @click="resetPassword()"
                :isDisabled="!senhaVerified"
                value="Redefinir"
                iconName="save"
                section="main"
                :hasIcon="true"
                :hasShadow="false"
              />
            </div>
        </div>
    <!-- <h1>PARAMETROS <br/>{{queryParams.email}}  <br/>{{queryParams.token}}</h1> -->
    </div>
</template>

<script>
import Button from '@components/Button'

export default {
    data() {
        return {
            novaSenha: '',
            repetirNovaSenha: ''
        }
    },
    components: {
        Button
    },
    computed: {
        queryParams() {
            return this.$route.query
        },
        senhasIguais() {
            return (this.novaSenha == this.repetirNovaSenha) && (this.novaSenha !== '')
        },
        senhaVerified(){
            if ((this.novaSenha != '') && (this.repetirNovaSenha != '')){
                if(this.novaSenha == this.repetirNovaSenha){
                    if (this.validateSenha(this.novaSenha)) {
                        return true
                    }
                    else {
                        return false
                    }
                    }
                    else {
                        return false
                }
            }
            else {
                return false
            }
        },

        //VALIDADORES INDIVIDUAIS
        senhaVerifiedMinusculo() {
            const re = /^(?=.*[a-z])+/g;
            const match = re.exec(this.novaSenha);
            return match;
        },

        senhaVerifiedMaiusculo() {
            const re = /^(?=.*[A-Z])+/g;
            const match = re.exec(this.novaSenha);
            return match;
        },

        senhaVerifiedNumero() {
            const re = /^(?=.*[0-9])+/g;
            const match = re.exec(this.novaSenha);
            return match;
        },

        senhaVerifiedEspecial() {
            const re = /^(?=.*[!@#$&*_?<>/%-])+/g;
            const match = re.exec(this.novaSenha);
            return match;
        },

        senhaVerifiedTamanho() {
            const re = /^(?=.{8,})+/g;
            const match = re.exec(this.novaSenha);
            return match;
        }
    },
    methods: {
        validateSenha(senha) {
            const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$&*_?<>/%-])(?=.{8,})+/g;
            const match = re.exec(senha);
            return match;
        },

        async resetPassword() {
            if (this.validateSenha(this.novaSenha)) {
                
                const payload = {
                    email: this.queryParams.email,
                    token: this.queryParams.token,
                    senhaNova: this.novaSenha
                }
                await this.$store.dispatch('setResetPassword', payload)

                if (this.$store.getters.getIsResetPassword) {
                    this.$router.push({path: "/home", query: { resetedpassword: true}})
                } else {
                    this.$router.push({path: "/home", query: { resetedpassword: false}})
                }
            }       
        }
    }
}
</script>

<style scoped>
.container {
    min-height: 50vh;
    padding: 4rem 0 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-personal {
    min-height: 30rem;
    width: 25rem;
    background-color: white;
    border-radius: 14px;
    padding: 2rem 2rem;
    box-shadow: 12px 12px 23px -7px rgb(158 146 158 / 67%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
}

.card-personal h1 {
    font-size: 2rem;
}

.new-password-form {
    display: flex;
    flex-direction: column;
}

.verified-items-wrapper {
    height: 8rem;
    padding-left: 1rem;
}

.text-alert-match-passwords {
  color: rgb(224, 53, 53);
  font-weight: 100;
  font-size: 0.9rem;
}

.green-text {
  color: rgb(104, 158, 60);
}

.black-text {
  color: black;
}

.button-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
}


</style>
