<template>
<div>
  <!-- <Hero/> -->
<Carousel/>
<InfoCards/>
<InfoGraphs/>
<!-- <Topics/> -->
<!-- <External/> -->
</div>
</template>

<script>
 // import Hero from '@components/Home/Hero';
import Carousel from '@components/Home/Carousel';
import InfoCards from '@components/Home/InfoCards';
import InfoGraphs from '@components/Home/InfoGraphs';
 // import Topics from '@components/Home/Topics';
 // import External from '@components/Home/External';

export default {
  name: 'Home',
  components: {
    // Hero,
    Carousel,
    InfoCards,
    InfoGraphs,
    // Topics,
    // External,
  }
}
</script>
