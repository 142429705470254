<template>
<div class="bg">
    <div class="container">
        <InstitutionInfo class="imgText" :title="`Dashboards de '${nomeInstituicao}'`" :crumbs="crumbs" section="instituicao-campo" :text="descricao"/>
    </div>
    <br />
    <div class="frames">
        <div class="frameContainer" v-for="c of campo?.power_bi || []" :key="c?.id_bi">
            <iframe class="dashFrame" frameborder="0" :src="c?.url" />
        </div>
    </div>
</div>
</template>

<script>
import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo';
export default {
    name: "CampoDashboard",
    components: {
        InstitutionInfo,
    },
    data () {
        return {
            nomeInstituicao: '',
            descricao: '',
            crumbs: [
                {
                    id:1,
                    value: '',
                    iconName: 'th',
                    name_route: 'GerenciarInstituicoes'
                },
                {
                    id:2,
                    value: 'Instituição',
                    iconName: 'university',
                    name_route: 'InstituicaoMenu'
                },
                {
                    id:3,
                    value: 'Campos',
                    iconName: 'th-large',
                    name_route: 'ListarCampos'
                },
                {
                    id:4,
                    value: 'Gerenciar Campo',
                    iconName: 'chart-pie',
                    name_route: 'InstituicaoMenuCampo'
                },
                {
                    id:5,
                    value: 'Dashboard',
                    iconName: 'project-diagram',
                    name_route: ''
                },
            ]
        }
    },
    computed: {
        campos() {
            return this.$store.getters.getListCampos;
        },
        campo() {
            return this.campos.find(c => c?.idamostra == this.$route.params.idCampo);
        },
    },
    mounted() {
        window.scrollTo(0, 0);
        let idTeamSelected = this.$route.params.idCampo;

        if (typeof idTeamSelected == "undefined"){
            this.nomeInstituicao = "Nome do Campo";
        }
        else {
            let teamsSelected;
            let teamSelected;

            teamsSelected = this.campos.filter(team =>
                team.idamostra == idTeamSelected
            );

            teamSelected = [...teamsSelected].shift();

            this.nomeInstituicao = teamSelected.titulo;
            this.descricao = teamSelected.descricao;
        }
    }
};
</script>

<style>
.bg {
    background-image: url("~@/assets/img/Backgrounds/branco.webp");
    background-repeat: repeat;
    background-size: 256px;
    overflow: auto;
    box-sizing: border-box;
}

.container {
    margin: 3rem auto 0 auto;
}

.frames {
    width: 100%;
    max-width: 1600px;
    padding: 0 3em 0 3em;
    margin: 0 auto;
}

.frameContainer {
    width: 100%;
    overflow: auto;
    resize: vertical;
    height: fit-content;
    margin: 1em 0 1em 0;
    aspect-ratio: 4 / 3;
}

.dashFrame {
    width: 100%;
    height: 100%;
}
</style>
