<template>
    <div class="bg">

        <br /><br />

        <div class="infoCard">
            <!-- <img :src="require('../../assets/img/BoasPraticas/oquee.webp')" /> -->
            <div class="text text-img-clipper">
                <span>
                    <h2 class="titlePraticas text text-img-clipper">O que é o módulo Gestão da Avaliação?</h2>
                    <p>
                        O módulo Gestão da Avaliação pertirá acesso às ferramentas para coletar e mensurar a qualidade da
                        Educação Infantil na sua Rede ou Unidade Educacional. Gerencie as Unidades Educacionais, turmas e
                        todo escopo de aplicação da EAPI. Obtenha um panorama da sua Rede ou Unidade Educacional para
                        que decisões mais assertivas possam ser tomadas e, por consequencia, se possa obter mais qualidade
                        na Educação Infantil.
                    </p>
                </span>

                <div class="externoContainer" v-if="!isLogged">
                    <Button
                        class="butExterno"
                        @click="openLogin"
                        :isOutline="false"
                        value="Autentique-se na plataforma para acessar" />
                </div>
            </div>
        </div>

        <br /><br />

    </div>

</template>

<script>
 import Button from '@components/Button';
 export default {
     name: 'SobreOQueE',
     components: {
         Button,
     },
     methods: {
         openLogin() {
             this.$store.commit('setIsActiveModalLateralLogin', false);
         },
     },
     computed: {
         isLogged() {
             return this.$store.getters.getIsLogged;
         },
     }
 }
</script>

<style scoped>
 .bg {
     padding: 0 2rem;
     width: 100%;
     background: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     min-height: 90vh;
 }

 .infoCard {
     /* display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center; */
     padding: 2rem;
     background: url("~@/assets/img/Backgrounds/marsala.webp");
     background-repeat: repeat;
     background-size: 256px;
     border-radius: 2rem;
     box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
 }
 /*
    .infoCard > img {
    margin: 0 auto;
    flex: 1 0 30%;
    display: block;
    width: 100%;
    max-width: 400px;
    min-width: 300px;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  */
 .infoCard > .text {
     /* flex: 1 0 70%; */
     width: 100%;
     display: block;
     text-align: justify;
     font-size: 1.15em;
     box-sizing: border-box;
     padding: 10px;
 }

 .infoCard > .text p {
     text-indent: 1em;
     font-weight: 500;
 }

 .externoContainer {
     width: 100%;
     display: flex;
     flex-direction: row;
     justify-content: flex-end;
 }

 .butExterno {
     display: block;
     background: url("~@/assets/img/Backgrounds/verdeEapi.webp");
     background-repeat: repeat;
     background-size: 256px;
     border-radius: 20px;
 }

 :deep(.butExterno span) {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .categories {
     display: grid;
     grid-template-columns: repeat(2, 1fr);
     /* grid-template-rows: auto; */
     grid-auto-rows: 1fr;
     row-gap: 10px;
     column-gap: 10px;
     text-align: center;
     padding: 15px;
 }

 .category {
     margin: 0 auto;
     border: 0;
     border-radius: 2rem;
     padding: 0.5rem;
     width: 70%;
     display: flex;
     justify-content: center;
     align-items: center;
     cursor: pointer;
     font-weight: 600;
     box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
     background-repeat: repeat;
     background-size: 256px;
 }

 .titlePraticas {
     width: 100%;
     padding: 5px;
     text-align: right;
 }

 .titleAxis {
     width: 100%;
     padding: 20px;
     text-align: center;
     color: rgba(25, 89, 83, 0.8);
     text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
 }

 @media screen and (max-width: 800px) {
     .categories {
         grid-template-columns: 1fr;
     }

     .imgs > img {
         width: 40% !important;
         max-width: 350px;
     }
 }

 .text {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
 }
</style>
