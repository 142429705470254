<template>
    <div class="topics-container">
        <h2 class='info-title'>
            Tópicos para seleção
        </h2>

        <div class="inner-text">
            Nesta seção, você poderá consultar vídeos, artigos e publicações relacionadas ao
            monitoramento e estudo da qualidade da Educação Infantil no Brasil.
            Clique no <i>card</i> desejado para acessar o tipo de material que deseja consultar.
        </div>

        <div class="section-cards">
            <a class="section" href="">
                <img :src="require('../../assets/img/MateriaiseDocumentos/apresentacoes.svg')" alt="Apresentações" />
                <div>Apresentações</div>
            </a>

            <a class="section" href="">
                <img :src="require('../../assets/img/MateriaiseDocumentos/videos.svg')" alt="Apresentações" />
                <div>Vídeos para treino</div>
            </a>

            <a class="section" href="">
                <img :src="require('../../assets/img/MateriaiseDocumentos/material-campo.svg')" alt="Apresentações" />
                <div>Materiais de campo</div>
            </a>

            <a class="section" href="">
                <img :src="require('../../assets/img/MateriaiseDocumentos/documentos-oficiais.svg')" alt="Apresentações" />
                <div>Documentos oficiais</div>
            </a>
        </div>
    </div>
</template>

<style scoped>
 :root {
     --white: #f5f3ef;
 }

 .topics-container{
     background-image: url("~@/assets/img/Backgrounds/marsala.png");
     background-origin: border-box;
     box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.4);
     padding: 3rem;
     color: var(--white);
     font-size: 1.3em;
 }

 .info-title{
     color: var(--white);
     font-weight: bold;
 }

 .inner-text {
     text-align: justify;
     width: 100%;
 }

 .section-cards {
     margin-top: 2em;
     display: flex;
     justify-content: space-between;
 }

 .section {
     cursor: pointer;
     padding: 1em;
     border: 0;
     border-radius: 2em;
     background-color: var(--white);
     width: fit-content;
     display: grid;
     grid-template-columns: 1fr;
     grid-template-rows: auto auto;
     box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
     justify-content: center;
     transition: transform ease-in-out 0.4s;
 }

 .section:hover {
     transform: scale(1.02);
 }

 .section > div {
     margin-top: 15px;
     text-align: center;
     color: grey;
 }

 .section > img {
     margin: 0 auto;
     display: block;
 }
</style>
