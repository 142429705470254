<template>
    <div class="container offset">
        <div class="custom-form">

            <p>Veja aqui todas as solicitações recebidas. Você pode aceitar ou recusá-las.</p>
            <input 
                type="name"
                class="form-control input-personal"
                placeholder="Digite o nome do remetente..."
                v-model="searchByName"
                id="inlineFormCustomName"
                autocomplete="off"
            />

            <div class="typeTitle">
                Tipos de solicitação:
            </div>
            <div class="filters">
                <select
                    v-model="typeSearch"
                    class="form-control type">
                    <option value="">Todos</option>
                    <option value="GRUPO">Grupos</option>
                    <option value="USUARIO">Usuários</option>
                </select>
            </div>

            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <div class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home-insti" role="tab" aria-controls="nav-home" aria-selected="true">Pendentes</div>
                    <div class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile-insti" role="tab" aria-controls="nav-profile" aria-selected="false">Aceitos</div>
                    <div class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact-insti" role="tab" aria-controls="nav-contact" aria-selected="false">Recusados</div>
                    <div class="nav-item nav-link" id="nav-sent-tab" data-toggle="tab" href="#nav-sent-insti" role="tab" aria-controls="nav-sent" aria-selected="false">Enviados</div>
                </div>
            </nav>

            <!------------ ABAS - GERAL ------------------------------------------------------>
            <div class="tab-content" id="nav-tabContent">

                <!----------------- ABA CONVITES PENDENTES/NULL ---------------------------->
                <div class="tab-pane fade show active" id="nav-home-insti" role="tabpanel" aria-labelledby="nav-home-tab">
                    <transition-group tag="div" v-if='filterSolicitacoes.length' class="listagem-solicitacao" name="listanimate">
                        <div v-for="(info, idx) in filterSolicitacoes" :key="idx">
                            <div v-if="info.aceito === 'PENDENTE'">
                                <div class="institution-row-style">
                                    <div class="infos-text">
                                        <i
                                            class="img-style ml-1 mr-2"
                                            :class="{
                                                'fas fa-user': info.tipoSolicitacao.nome == 'USUARIO_INSTITUICAO',
                                                'fas fa-users': info.tipoSolicitacao.nome == 'GRUPO_INSTITUICAO',
                                            }"
                                        >
                                        </i>
                                        <label class="text-style"> {{  info.tipoSolicitacao.nome == 'GRUPO_INSTITUICAO' ? info.idGrupo?.nome : info.idUsuario?.nome }} </label>
                                    </div>
                                    <div class="inviteType">
                                        {{ info.tipoSolicitacao.nome == 'GRUPO_INSTITUICAO' ? 'GRUPO' : 'USUÁRIO' }}
                                    </div>
                                    <div class="buttons">
                                        <Button 
                                            @click="setStatusInstiRequest(info.idNotificacao, 'RECUSADO')"
                                            section="descartar"
                                            value="Recusar"
                                            :isOutline="true"
                                            :hasIcon="false"
                                            class="button-style"
                                        />  
                                        <Button 
                                            @click="setStatusInstiRequest(info.idNotificacao, 'ACEITO')"
                                            section="salvar"
                                            value="Aceitar"
                                            :isOutline="true"
                                            :hasIcon="false"
                                            class="button-style ml-2"
                                        />  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition-group>
                    <transition-group tag="div" v-else class="listagem-solicitacao" name="listanimate">
                        <label class="text-style noneFound">Sem resultados.</label>
                    </transition-group>             
                </div>
                <!----------------- FIM DA ABA CONVITES PENDENTES/NULL ---------------------------->

                <!----------------- ABA CONVITES ACEITOS/TRUE ------------------------------------->
                <div class="tab-pane fade" id="nav-profile-insti" role="tabpanel" aria-labelledby="nav-profile-tab">
                    <transition-group tag="div" v-if='filterState("ACEITO").length' class="listagem-solicitacao" name="listanimate">
                        <div v-for="(info, idx) in filterState('ACEITO')" :key="idx">
                            <div class="institution-row-style">
                                <div class="infos-text">
                                    <i
                                        class="img-style ml-1 mr-2"
                                        :class="{
                                            'fas fa-user': info.tipoSolicitacao.nome == 'USUARIO_INSTITUICAO',
                                            'fas fa-users': info.tipoSolicitacao.nome == 'GRUPO_INSTITUICAO',
                                        }"
                                    >
                                    </i>
                                    <label class="text-style"> {{  info.tipoSolicitacao.nome == 'GRUPO_INSTITUICAO' ? info.idGrupo?.nome : info.idUsuario?.nome }} </label>
                                </div>
                                <div class="inviteType">
                                    {{ info.tipoSolicitacao.nome == 'GRUPO_INSTITUICAO' ? 'GRUPO' : 'USUÁRIO' }}
                                </div>
                                <label class="text-style aceito-text">ACEITO</label>
                            </div>
                        </div>
                    </transition-group>
                    <transition-group tag="div" v-else class="listagem-solicitacao" name="listanimate">
                        <label class="text-style noneFound">Sem resultados.</label>
                    </transition-group>
                </div>
                <!----------------- FIM DA ABA CONVITES ACEITOS/TRUE ------------------------------------->

                <!----------------- ABA CONVITES RECUSADOS/FALSE ------------------------------------->
                <div class="tab-pane fade" id="nav-contact-insti" role="tabpanel" aria-labelledby="nav-contact-tab">
                    <transition-group tag="div" v-if='filterState("RECUSADO").length' class="listagem-solicitacao" name="listanimate">
                        <div v-for="(info, idx) in filterState('RECUSADO')" :key="idx">
                            <div class="institution-row-style">
                                <div class="infos-text">
                                    <i
                                        class="img-style ml-1 mr-2"
                                        :class="{
                                            'fas fa-user': info.tipoSolicitacao.nome == 'USUARIO_USUARIO',
                                            'fas fa-users': info.tipoSolicitacao.nome == 'GRUPO_USUARIO',
                                            'fas fa-university': info.tipoSolicitacao.nome == 'INSTITUICAO_USUARIO',
                                        }"
                                    >
                                    </i>
                                    <label class="text-style"> {{  info.tipoSolicitacao.nome == 'GRUPO_INSTITUICAO' ? info.idGrupo?.nome : info.idUsuario?.nome }} </label>
                                </div>
                                <div class="inviteType">
                                    {{ info.tipoSolicitacao.nome == 'GRUPO_INSTITUICAO' ? 'INSTITUICAO' : 'USUÁRIO' }}
                                </div>
                                <label class="text-style recusado-text">RECUSADO</label>
                            </div>
                        </div>
                    </transition-group>
                    <transition-group tag="div" v-else class="listagem-solicitacao" name="listanimate">
                        <label class="text-style noneFound">Sem resultados.</label>
                    </transition-group>
                </div>

                <!----------------- ABA CONVITES ENVIADOS ------------------------------------->
                <div class="tab-pane fade" id="nav-sent-insti" role="tabpanel" aria-labelledby="nav-sent-tab">
                    <transition-group tag="div" v-if='sentSolicitacoes.length' class="listagem-solicitacao" name="listanimate">
                        <div v-for="(info, idx) in sentSolicitacoes" :key="idx">
                            <div class="institution-row-style">
                                <div class="infos-text">
                                    <i
                                        class="img-style ml-1 mr-2"
                                        :class="{
                                            'fas fa-users': info.tipoSolicitacao.nome == 'INSTITUICAO_GRUPO',
                                            'fas fa-user': info.tipoSolicitacao.nome == 'INSTITUICAO_USUARIO',
                                        }"
                                    >
                                    </i>
                                    <label class="text-style"> {{  info.tipoSolicitacao.nome == 'INSTITUICAO_USUARIO' ? info.idUsuario?.nome : info.idGrupo?.nome }} </label>
                                </div>
                                <div class="inviteType">
                                    {{ info.tipoSolicitacao.nome == 'INSTITUICAO_GRUPO' ? 'GRUPO' : 'USUÁRIO' }}
                                </div>
                                <label class="text-style recusado-text">{{ info?.aceito }}</label>
                                <div class="buttons" v-if="info?.aceito == 'PENDENTE'">
                                    <Button
                                        @click="cancelNoti(info)"
                                        section="descartar"
                                        value="Cancelar"
                                        :isOutline="true"
                                        :hasIcon="false"
                                        class="button-style"
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-for="(info, idx) in externalSolicitacoes" :key="idx">
                            <div class="institution-row-style">
                                <div class="infos-text">
                                    <i class="img-style ml-1 mr-2 fas fa-user"></i>
                                    <label class="text-style"> {{  info.email }} </label>
                                </div>
                                <div class="inviteType">
                                    EXTERNO
                                </div>
                                <label class="text-style recusado-text">{{ info?.aceito }}</label>
                            </div>
                        </div>
                    </transition-group>
                    <transition-group tag="div" v-else class="listagem-solicitacao" name="listanimate">
                        <label class="text-style noneFound">Sem resultados.</label>
                    </transition-group>
                </div>

            </div>
        </div>   
    </div>
</template>

<script>

 import Button from '@components/Button'
 import { Notyf } from "notyf";
 import Swal from 'sweetalert2';

 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: 'left',
         y: 'bottom',
     }
 })
 export default {
     name: "SearchInstitution",
     data() {
         return {
             searchByName: '',
             typeSearch: "",
             infosData: [
             ]
         }
     },
     components: {
         Button
     },
     async created() {
         const payload = {
             idInstituicao: this.$route.params.idInstituicao,
             notiTypes: [
                 "USUARIO_INSTITUICAO",
                 "GRUPO_INSTITUICAO",
                 "INSTITUICAO_USUARIO",
                 "INSTITUICAO_GRUPO",
             ]
         }
         this.$store.dispatch('setListInstiRequests', payload)
     },
     computed: {
         filterSolicitacoes: function() {
             const mainFilters = [
                 "GRUPO_INSTITUICAO",
                 "USUARIO_INSTITUICAO",
             ];
             //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
             var allRequests = this.$store.getters.getListInstiRequests?.filter(request => mainFilters.includes(request?.tipoSolicitacao?.nome));

             let query = allRequests
             query = query?.sort((a, b) =>
                 (a?.idGrupo?.nome || a?.idUsuario?.nome)?.localeCompare(
                     (b?.idGrupo?.nome || b?.idUsuario?.nome)
                 )
             );

             if (this.searchByName.trim() != '')
                 query = query?.filter(team =>
                     team?.idUsuario?.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()) ||
                     team?.idGrupo?.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase())
                 )

             switch (this.typeSearch) {
                 case "":
                     break;
                 case "GRUPO":
                     query = query?.filter(team => team?.idGrupo)
                     break;
                 case "USUARIO":
                     query = query?.filter(team => team?.idUsuario)
                     break;
                 default:
                     break;
             }

             return query;
         },
         sentSolicitacoes() {
             const sentFilters = [
                 "INSTITUICAO_GRUPO",
                 "INSTITUICAO_USUARIO",
             ];
             let allRequests = this.$store.getters.getListInstiRequests.filter(request => sentFilters.includes(request?.tipoSolicitacao?.nome));

             var query = allRequests
             query = query?.sort((a, b) =>
                 (a?.idGrupo?.nome || a?.idUsuario?.nome)?.localeCompare(
                     (b?.idGrupo?.nome || b?.idUsuario?.nome)
                 )
             );

             if (this.searchByName.trim() != '')
                 query = query?.filter(team =>
                     team?.idGrupo?.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()) ||
                     team?.idUsuario?.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase())
                 )

             switch (this.typeSearch) {
                 case "":
                     break;
                 case "GRUPO":
                     query = query?.filter(team => team?.tipoSolicitacao?.nome == "INSTITUICAO_GRUPO")
                     break;
                 case "USUARIO":
                     query = query?.filter(team => team?.tipoSolicitacao?.nome == "INSTITUICAO_USUARIO")
                     break;
                 default:
                     break;
             }

             return query;
         },
         externalSolicitacoes() {
             if (!["", "USUARIO"].includes(this.typeSearch)) return [];

             let allRequests = this.$store.getters.getInstiExternal;

             var query = allRequests
             query = query?.sort((a, b) => a?.email?.localeCompare(b?.email));

             if (this.searchByName.trim() != '')
                 query = query?.filter(team => team?.email.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))

             return query;
         },
     },
     methods: {
         async setStatusInstiRequest(idSolicitacao, status) {

             const payload = {
                 idInstituicao: this.$route.params.idInstituicao,
                 idSolicitacao: idSolicitacao,
                 isAceito: status
             }
             await this.$store.dispatch('setStatusInstiRequest', payload)


             if (this.$store.getters.getIsRequestUpdated) {
                 const payload2 = {
                     idInstituicao: this.$route.params.idInstituicao,
                     notiTypes: [ "USUARIO_INSTITUICAO", "GRUPO_INSTITUICAO" ]
                 }
                 this.$store.dispatch('setListInstiRequests', payload2)
                 notyf.open({
                     type: 'success',
                     message: 'Solicitação atualizada!'
                 });
             } else {
                 notyf.open({
                     type: 'error',
                     message: 'Não foi possível atualizar a solicitação'
                 });
             }
         },

         async cancelNoti(info) {
             const swalResult = await Swal.fire({
                 title: 'Cancelar convite?',
                 text: `Tem certeza que deseja cancelar convite a ${info?.idUsuario?.nome || info?.idGrupo?.nome}?`,
                 icon: 'warning',
                 showCancelButton: true,
                 confirmButtonColor: '#d33',
                 cancelButtonColor: '#3085d6',
                 cancelButtonText: 'Cancelar',
                 confirmButtonText: 'Sim, tenho certeza'
             })
             if (!swalResult) return;

             await this.$store.dispatch('deleteInstiNoti', {
                 idInsti: info?.idInstituicao?.idInstituicao,
                 idNoti: info?.idNotificacao,
             })

             if (this.$store.getters.getIsCanceledGroupNoti) {
                 Swal.fire(
                     'Convite cancelado!',
                     `O convite feito a ${info?.idUsuario?.nome} foi cancelado com sucesso`,
                     'success'
                 )
                 this.$store.dispatch('setListInstiRequests', {
                     idInstituicao: info?.idInstituicao?.idInstituicao,
                     notiTypes: [
                         "USUARIO_INSTITUICAO",
                         "GRUPO_INSTITUICAO",
                         "INSTITUICAO_USUARIO",
                         "INSTITUICAO_GRUPO",
                     ]
                 })
             } else {
                 Swal.fire(
                     'Erro no cancelamento!',
                     `Não foi possível cancelar o convite`,
                     'error'
                 )
             }
         },
         filterState(state) {
             return this.filterSolicitacoes.filter(info => info.aceito == state);
         },
     },

 }

</script>

<style scoped>

 .container{
     padding: 2%;
     width: 100%;
     background-color: transparent;
 }

 .title-style{
     font-size: 1.5rem;
     font-weight: 600;
     color: #656565;
 }

 .nav-tabs{
     margin: 1rem 0 0 0;
     cursor: pointer;
 }

 .image-style{
     display: block;
     margin-left: auto;
     margin-right: auto;
     width: 50%;
     padding: 1rem;
 }

 .button-div{
     text-align: right;
 }

 .recusado-text {
     color: #d40707;
     font-weight: bold;
 }

 .aceito-text {
     font-weight: bold;
     background-clip: text;
     text-fill-color: transparent;
     background-image: url("~@/assets/img/Backgrounds/verdeEapi.webp");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
 }

 .institution-row-style{
     border-bottom: 1px solid rgba(255, 255, 255, 0.8);
     margin: 0;
     padding: 0.5rem 0rem;
     text-align: center;
     display: flex;
     justify-content: space-between;
     align-items: center;
 }

 .institution-row-style > * {
     flex: 1;
 }

 .img-style{
     font-size: 1.3rem;
     color: #0d9f9f;
     border-radius: 5rem;
 }

 .text-style{
     font-weight: 500;
     margin: 0;
     text-align: center;
 }

 .separator {
     border: none;
     height: 1px;
     margin: 1 0 0 0;
     margin-bottom: 0;
     background-color: rgba(51, 51, 51, 0.178);
 }

 .button-style {
     line-height: 1.3em;
 }

 .input-personal {
     padding: 0.2rem 0.4rem;
     font-size: 15px;
     font-weight: 400;
 }
 /*
    .select-personal, .input-personal {
    border-radius: 8px;
    background: #FCFCFC;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.11);
    font-weight: lighter;
    padding: 0.2rem 0.4rem;
    box-shadow: 1px 2px 6px -3px rgb(0 0 0 / 25%);
    }
  */
 .listagem-solicitacao {
     max-height: 300px;
 }

 #nav-tabContent {
     overflow: auto;
     padding: 0 5px;
 }

 .typeTitle {
     margin-top: 5px;
 }

 .type {
     width: max-content;
     margin: 5px;
 }

 .filters {
     width: 100%;
 }

 .buttons {
     width: auto;
     display: flex;
     flex-direction: row;
     justify-content: flex-end;
     padding: 5px;
     box-sizing: border-box;
     align-items: center;
 }

 .infos-text {
     display: flex;
     justify-content: center;
     align-items: center;
     flex-wrap: wrap;
     padding: 5px;
     box-sizing: border-box;
 }

 .infos-text > label {
     font-weight: bold !important;
 }

 .inviteType {
     padding: 5px;
     box-sizing: border-box;
     font-weight: 600;
 }

 .noneFound {
     margin: 5px 0;
 }

 p {
     font-weight: bold;
 }

 .nav-item.active {
     background-color: rgba(255, 255, 255, 0.3);
     border: 1px solid rgba(255, 255, 255, 0.8);
 }

 .nav-item, .nav-tabs {
     border-bottom: 1px solid rgba(255, 255, 255, 0.8);
 }
</style>
