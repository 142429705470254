<!--
|---------------------------------------------
| SignUp Component 
|---------------------------------------------
| @usage Import the component and use, no params
| 
-->

<template>
  <div>
    <div id="carouselControls" class="carousel slide carousel-fade" data-ride="carousel" data-interval="false">
      <div class="carousel-inner">

        <!-- PASSO 1 CADASTRO -->
        <div class="carousel-item active">
          
          <div class="d-flex justify-content-center align-items-center mt-1 mb-2">
            <div class="crumb crumb-active mr-2"></div>
            <div class="crumb mr-2"></div>
            <div class="crumb"></div>
          </div>


          <form class="formulario" @submit.prevent="" autocomplete="off">
            <div class="form-group">
              <label for="nome">Nome</label>
              <input v-model.trim="signInStepOne.nome" type="text" class="form-control" id="nome" placeholder="Insira seu nome..." required/>
            </div>
    
            <div class="form-group">
              <label for="signInStepOne.email">Email</label>
              <input @input="verifyValidadeEmail()" v-model.trim="signInStepOne.email" type="signInStepOne.email" class="form-control" id="signInStepOne.email" placeholder="Insira seu email..." required/>
              <span v-if="(isValidEmail !== 'true') && (validateEmail(signInStepOne.email))" class="text-alert-match-passwords"><b>O email está indisponível.</b></span>
              <span v-else-if="(isValidEmail === 'true') && (validateEmail(signInStepOne.email))" class="text-alert-valid-email"><b>O email está disponível.</b></span>
            </div>

            <div class="form-group">
              <label for="signInStepOne.dtnasc">Data de Nascimento</label>
              <input v-model="signInStepOne.dtnasc" type="date" class="form-control" id="dtnasc" placeholder="Insira sua data de nascimento..." required/>
            </div>

            <label>Gênero</label>
            <div class="form-inline">
              <div class="form-check mr-3">
                <input v-model="signInStepOne.genero" class="form-check-input" type="radio" name="genero" id="masculino" value="MASCULINO" required>
                <label class="form-check-label">Masculino</label>    
              </div>

              <div class="form-check mr-3">
                <input v-model="signInStepOne.genero" class="form-check-input" type="radio" name="genero" id="feminino" value="FEMININO">
                <label class="form-check-label">Feminino</label>
              </div>

              <div class="form-check">
                <input v-model="signInStepOne.genero" class="form-check-input" type="radio" name="genero" id="outro" value="OUTRO">
                <label class="form-check-label">Outro</label>
              </div>
            </div>

            <div class="d-flex justify-content-center">
              <Button :isDisabled="!stepOneRegistration" href="#carouselControls" data-slide="next" class="btn-full" section="main" value="Próximo" />
            </div>
          </form>

        </div>

        <!-- PASSO 2 CADASTRO -->
        <div class="carousel-item">
          <div class="d-flex justify-content-center align-items-center mt-1 mb-2">
            <div class="crumb mr-2"></div>
            <div class="crumb crumb-active mr-2"></div>
            <div class="crumb"></div>
          </div>

          <form class="formulario" @submit.prevent="" autocomplete="off">
            <div class="form-group">
              <label for="profissao">Profissão</label>
              <input v-model.trim="signInStepTwo.profissao" type="text" class="form-control" id="profissao" placeholder="Insira sua profissão..." required/>
            </div>

            <div class="form-group">
              <label for="organizacao">Organização</label>
              <input v-model.trim="signInStepTwo.organizacao" type="text" class="form-control" id="organizacao" placeholder="Insira a organização que você participa..." required/>
            </div>

            <div class="form-group">
              <label for="atuacao">Atuação</label>
              <input v-model.trim="signInStepTwo.atuacao" type="text" class="form-control" id="atuacao" placeholder="Insira sua área de atuação..." required/>
            </div>

            <div class="d-flex justify-content-start align-items-center">
            <div class="mr-4">
                <label for="signInStepTwo.estado">Estado</label>
                <select @change="getCities()" v-model="signInStepTwo.estado" class="form-control" id="signInStepTwo.estado" required>
                  <option value="AC">Acre</option>
                  <option value="AL">Alagoas</option>
                  <option value="AP">Amapá</option>
                  <option value="AM">Amazonas</option>
                  <option value="BA">Bahia</option>
                  <option value="CE">Ceará</option>
                  <option value="DF">Distrito Federal</option>
                  <option value="ES">Espírito Santo</option>
                  <option value="GO">Goiás</option>
                  <option value="MA">Maranhão</option>
                  <option value="MT">Mato Grosso</option>
                  <option value="MS">Mato Grosso do Sul</option>
                  <option value="MG">Minas Gerais</option>
                  <option value="PA">Pará</option>
                  <option value="PB">Paraíba</option>
                  <option value="PR">Paraná</option>
                  <option value="PE">Pernambuco</option>
                  <option value="PI">Piauí</option>
                  <option value="RJ">Rio de Janeiro</option>
                  <option value="RN">Rio Grande do Norte</option>
                  <option value="RS">Rio Grande do Sul</option>
                  <option value="RO">Rondônia</option>
                  <option value="RR">Roraima</option>
                  <option value="SC">Santa Catarina</option>
                  <option value="SP">São Paulo</option>
                  <option value="SE">Sergipe</option>
                  <option value="TO">Tocantins</option>
                </select>
            </div>

            <div>
                <label class="text-align-left" for="signInStepTwo.cidade">Cidade</label>
                <select v-model="signInStepTwo.cidade" class="form-control" id="signInStepTwo.cidade" required>
                  <option v-for="city in cities" :key="city.idCidade" :value="city.nome">{{city.nome}}</option>
                </select>
            </div>
            </div>

            <div class="d-flex flex-row-reverse justify-content-between mt-3">
              <Button :isDisabled="!stepTwoRegistration" href="#carouselControls" data-slide="next"  section="main" value="Próximo" />
              <Button href="#carouselControls" data-slide="prev"  section="main" value="Voltar" />
            </div>
          </form>


        </div>



         <!-- PASSO 3 CADASTRO -->
        <div class="carousel-item">
           <div class="d-flex justify-content-center align-items-center mt-1 mb-2">
            <div class="crumb mr-2"></div>
            <div class="crumb mr-2"></div>
            <div class="crumb crumb-active"></div>
          </div>

          <form class="formulario" @submit.prevent="" autocomplete="off">
            
            <div class="form-group">
              <label for="signInStepThree.senha">Senha</label>
              <input v-model="signInStepThree.senha" type="password" class="form-control" id="signInStepThree.senha" placeholder="Insira uma senha..." required/>
            </div>

            <div class="form-group">
              <label for="signInStepThree.senha-repeat">Repita a senha</label>
              <input v-model="signInStepThree.senha_repeat" type="password" class="form-control" id="signInStepThree.senha_repeat" placeholder="Repita a senha..." required/>
            </div>

            <span v-if="!isEqualPassword" class="text-alert-match-passwords"><b>As senhas precisam ser iguais.</b></span>
            <span v-if="!validateSenha" class="text-alert-match-passwords">
              <ul>A senha precisa ter: 
                <li :class="{'green-text': thereIsLower}">Pelo menos um caractere minúsculo</li>
                <li :class="{'green-text': thereIsUpper}">Pelo menos um caractere maiúsculo</li>
                <li :class="{'green-text': thereIsSpecial}">Pelo menos um caractere especial</li>
                <li :class="{'green-text': thereIsNumber}">Pelo menos um caractere numérico</li>
                <li :class="{'green-text': minimumLength}">Pelo menos oito caracteres</li>
              </ul>
            </span>

            <div class="d-flex flex-row-reverse justify-content-between mt-3">
              <Button @click="setSignUp()" :isDisabled="!stepThreeRegistration" section="main-confirm" value="Finalizar" />
              <Button href="#carouselControls" data-slide="prev" section="main" value="Voltar" />
            </div>
          </form>

        </div>


        <!-- CONFIRMACAO CADASTRO -->
        <div class="carousel-item">
          <div class="formulario d-flex flex-column justify-content-center align-items-center">
            <img class="img-success mb-3" src="@assets/img/check.png">
            <h1 class="success-msg-h1">Parabéns, sua conta foi criada!</h1>
            <span class="success-msg-span text-justify">Um link para validação da conta foi enviado para seu email. Para poder acessar a plataforma, é <b>obrigatório a validação da conta.</b></span>
            <span @click="resendVerificationMail" class="link-resend-email">Não recebeu o email? Clique aqui para reenviar.</span>
            <span class="feedback-msg-verification-mail" v-if="isResendVerificationMail">O email foi enviado novamente!</span>
            <span class="feedback-msg-verification-mail" v-else-if="isResendVerificationMail == false">Aguarde um pouco para reenviar :)</span>
            <Button @click="newSignUp" class="mt-4" section="main-confirm" value="Novo Cadastro" />
          </div>
        </div>

        <!-- ERRO CADASTRO -->
        <div class="carousel-item">
          <div class="formulario d-flex flex-column justify-content-center align-items-center">
            <img class="img-success mb-3" src="@assets/img/error.png">
            <h1 class="success-msg-h1">Oops, algo deu errado!</h1>
            <span class="success-msg-span text-justify">Por favor, tente novamente.</span>
            <Button @click="newSignUp" class="mt-4" section="main" value="Novo Cadastro" />
          </div>
        </div>

      </div>
      <a ref="inicioForm" class="d-none" data-target="#carouselControls" data-slide-to="0" href="#carouselControls"></a>
      <a ref="successForm" class="d-none" data-target="#carouselControls" data-slide-to="3" href="#carouselControls"></a>
      <a ref="errorForm" class="d-none" data-target="#carouselControls" data-slide-to="4" href="#carouselControls"></a>
    </div>
  </div>
</template>

<script>
import Button from "@components/Button";
import Regex from "@middleware/regex"

export default {
  name: "SignUp",
  data() {
    return {
      signInStepOne:{
      nome: "",
      email: "",
      genero: "",
      dtnasc: "",
      },
      signInStepTwo:{
      profissao: "",
      organizacao: "",
      atuacao: "",
      estado: "",
      cidade: "",
      },
      signInStepThree: {
      senha: "",
      senha_repeat: ""
      }


    };
  },
  components: {
    Button,
  },
  computed: {
    /**
   * Retorna true se todos os campos da primeira parte do cadastro estiverem corretos
   */
    stepOneRegistration() {
      return Object.values(this.signInStepOne).some((isEmpty)=> isEmpty === "") ? false : true;
    },
    /**
   * Retorna true se todos os campos da segunda parte do cadastro estiverem corretos
   */
    stepTwoRegistration() {
      return Object.values(this.signInStepTwo).some((isEmpty)=> isEmpty === "") ? false : true;
    },
    /**
   * Retorna true se a senha cadastrada for válida
   */
    stepThreeRegistration() {
      return this.validateSenha && this.isEqualPassword ? true : false
    },

    /**
     * Retorna true se a senha digitada contemplar as diretrizes da senha 
     * minímo: 1 minúsculo, 1 maiúsculo, 1 caracter especial, 1 caracter numérico, e 8 caracteres.
     */
    validateSenha() {
      return new RegExp(Regex('validatePwd')).exec(this.signInStepThree.senha) ? true : false;
    },

    /**
   * Retorna true se as senhas digitadas forem iguais de acordo com a regra de senha
   */
    isEqualPassword(){
      return this.signInStepThree.senha === this.signInStepThree.senha_repeat ? true : false
    },

    /**
   * Retorna true se o email digitado estiver disponível
   */
    isValidEmail() {
      return this.$store.getters.getIsValidEmail
    },

    /**
   * Retorna true se já tiver sido solicitado um reenvio de confirmação de email
   */
    isResendVerificationMail() {
      return this.$store.getters.getResendVerificationMail
    },

    /**
   * Retorna todas as cidades
   */
    cities(){
     return this.signInStepTwo.estado != '' ? this.$store.getters.getCidades : []
    },

    /**
   * Retorna true se a senha digitada contém pelo menos um caracter minúsculo
   */
    thereIsLower() {
      return new RegExp(Regex('lower')).exec(this.signInStepThree.senha) ? true : false;
    },

    /**
   * Retorna true se a senha digitada contém pelo menos um caracter maiúsculo
   */
    thereIsUpper() {
      return new RegExp(Regex('upper')).exec(this.signInStepThree.senha) ? true : false;
    },

    /**
   * Retorna true se a senha digitada contém pelo menos um caracter numérico
   */
    thereIsNumber() {
      return new RegExp(Regex('number')).exec(this.signInStepThree.senha) ? true : false;
    },

    /**
   * Retorna true se a senha digitada contém pelo menos um caracter especial
   */
    thereIsSpecial() {
      return new RegExp(Regex('special')).exec(this.signInStepThree.senha) ? true : false;
    },

    /**
   * Retorna true se a senha digitada contém pelo menos uma quantidade predefinida de caracteres
   */
    minimumLength() {
      return new RegExp(Regex('length')).exec(this.signInStepThree.senha) ? true : false;
    }

  },
  methods: {

    /**
   * Limpa todos os campos do formulário de cadastro e redireciona para o passo 1
   */
    newSignUp() {
      Object.keys(this.signInStepOne).map((prop)=>this.signInStepOne[prop] = '' )
      Object.keys(this.signInStepTwo).map((prop)=>this.signInStepTwo[prop] = '' )
      Object.keys(this.signInStepThree).map((prop)=>this.signInStepThree[prop] = '' )
      this.$refs.inicioForm.click()
    },

    /**
   * Obtém todas as cidades com base no estado selecionado
   */
    async getCities() {
      await this.$store.dispatch('setCidades', this.signInStepTwo.estado);
      this.signInStepTwo.cidade = ''
    },

    /**
     * Retorna true se o email digitado tiver um template básico de email
     * @param {email} string - email a ser validado
     */
    validateEmail(email) {
      return new RegExp(Regex('validateEmail')).exec(email) ? true : false;
    },


    /**
     * Retorna true caso reenvio de email já foi solicitado
     */
    async resendVerificationMail() {
      await this.$store.dispatch('setResendVerificationMail', this.signInStepOne.email)
    },

    /**
     * Retorna true caso o email esteja disponivel para cadastro e estiver em um template básico
     */
    async verifyValidadeEmail() {
      this.validateEmail(this.signInStepOne.email) ? await this.$store.dispatch('setIsValidEmail', this.signInStepOne.email) : null
    },

    /**
     * Envia os campos preenchidos do formulário para API, solicitando o signUP
     */
    async setSignUp(){
      const payload = {
        ...this.signInStepOne,
        ...this.signInStepTwo,
        ...this.signInStepThree
      }
      await this.$store.dispatch('setSignUp', payload)
      const checkRegistration = await this.$store.getters.getErrorRegisterNewUser
      if (!checkRegistration) {
        this.$store.commit('setResendVerificationMail', null)
        this.$refs.successForm.click()
      }
      else {
        this.$refs.errorForm.click()
      }
    }
  },
};
</script>

<style scoped>
.crumb {
  background-color: rgb(204, 203, 203);
  height: 0.5rem;
  width: 2rem;
  border-radius: 0.5rem;
}

.green-text {
  color: rgb(104, 158, 60);
}

.crumb-active {
  background-color: rgb(137, 197, 87);
  box-shadow: 1px 1px 5px 0px rgb(137 197 87)
}

.formulario {
  padding: 0rem 2rem 2rem 2rem;
}

.btn-full {
  margin-top: 0.8rem;
  width: 100%;
}

.img-success {
  width: 9rem;
  height: 9rem;
}

.success-msg-h1 {
  font-size: 1.4rem;
}

.success-msg-span {
  font-size: 1rem;
  line-height: 1.4rem;
  color: rgb(90, 88, 88);
}

.text-alert-match-passwords {
  color: rgb(224, 53, 53);
  font-weight: 100;
  font-size: 0.9rem;
}

.text-alert-valid-email {
  color: rgb(104, 158, 60);
  font-weight: 100;
  font-size: 0.9rem;
}

.link-resend-email {
  margin-top: 1rem;
  color: #18a0fb;
  text-decoration: underline;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
}

.feedback-msg-verification-mail {
  font-size: 0.8rem;
}
</style>
