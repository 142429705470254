<template>
   <div class="body">
     inclui práticas pedagógicas que têm a brincadeira como base para ampliação do repertório e aprendizagem das crianças em espaços internos e externos,  incentivando seu protagonismo seja na escolha ou na (re)invenção dos brinquedos e brincadeiras e, fomentando o reconhecimento e preservação da natureza por meio do uso de materiais naturais nas brincadeiras e confecção de brinquedos e jogos.  
   </div>
   
</template>

<script>

export default {
    name: "EixoReconhecimento",
    data() {
    },
    components: {
       
    },
}

</script>

<style scoped>

    .container{
        padding: 1rem;
        width: 100%;
        background-color: white;
        margin: 0;
    }

    .title-style{
        font-size: 1.5rem;
        font-weight: 600;
        color: #656565;
    }

    .word-style{
        color:  #656565;
    }

    .custom-form{
        margin: 0 auto;
    }

    .question-style{
        width: 100%;
        font-size: 1rem;
        font-weight: 500;
        color: #656565;
    }

    .button-div{
        text-align: right;
    }

</style>
