<template>
   <div class="body">
       inclui práticas pedagógicas de combate ao preconceito em todas as suas formas, sejam elas de raça, gênero, etnia, deficiências, entre outras, reconhecendo e valorizando a diversidade cultural.
   </div>
   
</template>

<script>

export default {
    name: "EixoReconhecimento",
    data() {
    },
    components: {
       
    },
}

</script>

<style scoped>

    .container{
        padding: 1rem;
        width: 100%;
        background-color: white;
        margin: 0;
    }

    .title-style{
        font-size: 1.5rem;
        font-weight: 600;
        color: #656565;
    }

    .word-style{
        color:  #656565;
    }

    .custom-form{
        margin: 0 auto;
    }

    .question-style{
        width: 100%;
        font-size: 1rem;
        font-weight: 500;
        color: #656565;
    }

    .button-div{
        text-align: right;
    }

</style>
