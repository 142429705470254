<template>
    <form v-if="loaded" class="container offset" @submit.prevent="updateSections">
        <div
            v-for="(section, idx) of sections"
            :key="idx"
            class="form-group">
            <label class="question-style">
                <span>Seção {{ idx + 1 }}: *</span>
                <i class="fas fa-trash" @click="removeSection(idx)" />
            </label>

            <input
                v-model="sections[idx].nome"
                type="text"
                class="form-control"
                placeholder="Título da seção..."
                required
            />
        </div>

        <div
            class="addSection"
            @click="addSection">
            <span>+</span>
        </div>

        <Button
            section="instituicao-campo"
            value="Salvar Seções"
            :isOutline="true"
            :hasIcon="true"
            iconName="save"
        />
    </form>
</template>

<script type="text/javascript">
 import Button from '@components/Button'
 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: "left",
         y: "bottom",
     },
 });


 export default {
     name: "UpdateSectionsPraticas",
     components: {
         Button,
     },
     data() {
         return {
             loaded: false,
             sections: [],
             backup: [],
         };
     },
     async created() {
         this.loadData();
     },
     methods: {
         async loadData() {
             this.loaded = false;
             await this.$store.dispatch('setPraticaSections');
             this.sections = (this.$store.getters.getPraticaSections || [])
                 .sort((a, b) => a.idSecao - b.idSecao)
                 .map(section => ( { nome: section.nome, idSecao: section.idSecao, } ));
             this.backup = this.sections.map(s => ( { ...s, } ));
             this.loaded = true;
         },
         addSection() {
             let newSection = { ...this.sections[this.sections.length - 1] };
             newSection.idSecao = -1; newSection.nome = "";
             this.sections.push(newSection);
         },
         removeSection(idx) {
             if (this.sections.length <= 1) return;
             this.sections.splice(idx, 1);
         },
         async updateSections() {
             const backupIds = this.backup.map(section => section.idSecao);

             let success = true,
                 toUpdate = [],
                 toCreate = [],
                 toDelete = [];

             for (const section of this.sections)  {
                 if (section.idSecao == -1)
                     toCreate.push(section);
                 else if (
                     backupIds.includes(section.idSecao) &&
                     section.nome != this.backup.find(b => b.idSecao == section.idSecao)?.nome
                 )
                     toUpdate.push(section);
             }

             let bIdx = 0, sIdx = 0;
             const _sections = this.sections.filter(s => s.idSecao != -1);
             while (bIdx < backupIds.length) {
                 if (backupIds[bIdx] == _sections[sIdx].idSecao && sIdx < _sections.length - 1)
                     sIdx++;
                 else if (backupIds[bIdx] != _sections[sIdx].idSecao)
                     toDelete.push(backupIds[bIdx]);
                 bIdx++;
             }

             for (const uSection of toUpdate) {
                 await this.$store.dispatch('updatePraticaSection', uSection);
                 if (!this.$store.getters.getPraticaSectionUpdated)
                     notyf.open({
                         type: "error",
                         message: `Erro na atualização da seção '${uSection?.nome}'`,
                     });
                 success &= this.$store.getters.getPraticaSectionUpdated;
             }

             for (const dSection of toDelete) {
                 await this.$store.dispatch('deletePraticaSection', dSection);
                 if (!this.$store.getters.getPraticaSectionDeleted)
                     notyf.open({
                         type: "error",
                         message: `Erro ao deletar sessão!`,
                     });
                 success &= this.$store.getters.getPraticaSectionDeleted;
             }

             for (const cSection of toCreate) {
                 await this.$store.dispatch('createPraticaSection', cSection);
                 if (!this.$store.getters.getPraticaSectionCreated)
                     notyf.open({
                         type: "error",
                         message: `Erro ao criar sessão '${cSection.nome}'!`,
                     });
                 success &= this.$store.getters.getPraticaSectionCreated;
             }

             if (success) {
                 this.loadData();
                 notyf.open({
                     type: "success",
                     message: `Seções atualizadas com sucesso`,
                 });
             }
         },
     },
 };
</script>

<style scoped>
 button {
     float: right;
 }

 .addSection {
     width: 2.5em;
     border-radius: 100%;
     aspect-ratio: 1 / 1;
     margin: 0 auto;
     position: relative;
     cursor: pointer;
     transition: 0.4s;
     transform-origin: center center;
     background: url("~@/assets/img/Backgrounds/verdeEscuro.png");
     background-repeat: repeat;
     background-size: 256px;
 }

 .addSection:hover {
     transform: scale(1.1);
 }

 .addSection > span {
     font-size: 2em;
     color: white;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
 }

 label.question-style {
     display: grid;
     grid-template-columns: 1fr auto;
     align-items: center;
 }

 label.question-style > i {
     display: flex;
     align-items: center;
     justify-content: center;
     transition: 0.4s;
     transform-origin: center center;
     cursor: pointer;
 }

 label.question-style > i:hover {
     transform: scale(1.05);
 }

</style>
