<template>
    
    <div class="input-file">
        <h1>Exportar Devolutiva (CSV):</h1>      
        <div v-cloak @click="openDialog" @drop.prevent="addFile" @dragover.prevent @dragover.capture="isDragOverActions()" @dragleave.capture="isDragLeaveActions()" class="drag-and-drop" :class="{'drag-style': isDragOver}">
            <div v-if="arquivo == null" class="text-center infos">
                <img class="img-fluid img-center-input-file" :src="require('@assets/img/Dados/ExportarDevolutiva/'+name_image+'.png')">
                <p class="label-center-input-file">{{label}}</p>
            </div>
            <div class="file-uploaded" v-else>
                <img src="@assets/img/Dados/ExportarDevolutiva/csv-icon.png" alt="">
                {{ arquivo.name}} {{(arquivo.size/1000).toFixed(1)+ ' KB'}}     
            </div>
        </div>
        <button v-if="arquivo != null" class="remove-file" @click="removeFile()" title="Remove">Remover Arquivo</button>
        <input tabindex="-1" @change="addFileViaInput" type="file" ref="uploadFile" placeholder="Escolha ou arraste um arquivo para gerar a devolutiva" hidden>
        <button @click="geraDevolutiva()" class="mt-4"><i class="fas fa-cog pr-2 pl-2" :class="{'animateCog': isTurningCog}"></i>{{label_button}}</button>
    </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    name: 'InputFile',
    data () {
        return {
            label_button: 'Gerar Devolutiva',
            isTurningCog: false,
            arquivo: null,
            isDragOver: false,
            name_image: "icone-upload",
            label: "Escolha ou arraste um arquivo para gerar a devolutiva"
        }
    },
    methods: {
        isDragOverActions: function() {
            this.isDragOver = true
            this.label = "Solte o arquivo aqui!"
            this.name_image = "icone-upload-happy"
        },
        isDragLeaveActions: function() {
            this.isDragOver = false
            this.label = "Escolha ou arraste um arquivo para gerar a devolutiva"
            this.name_image = "icone-upload"
        },
        geraDevolutiva: function() {
            //método com funções de demonstração
            if (this.arquivo != null){

                this.label_button = 'Gerando Devolutiva...'
                this.isTurningCog = true  
                
                setTimeout( () => { 
                    this.label_button = 'Gerar Devolutiva'
                    this.isTurningCog = false
                    this.arquivo = null
                    this.$refs.uploadFile.value = ''
                    Swal.fire(
                        'Devolutiva Gerada!',
                        'O download começará imediatamente!',
                        'success'
                    )
                }, 3000)
            } 
            else {
                Swal.fire(
                    'Erro!',
                    'Você precisa fazer o upload de um arquivo CSV para gerar a devolutiva.',
                    'error'
                )   
            }
        },
        addFile: function(e) {
            let droppedFiles = e.dataTransfer.files;
            this.isDragLeaveActions()
            for (let f of droppedFiles) {
                if (f.type == "text/csv") {
                    this.arquivo = f;
                }
                else {
                    Swal.fire(
                        'Erro!',
                        'Só é permitido arquivos com extensão ".csv"',
                        'error'
                    )
                }
            }     
        },
        addFileViaInput: function() {
            let droppedFiles = this.$refs.uploadFile.files;
            for (let f of droppedFiles) {
                if (f.type == "text/csv") {
                    this.arquivo = f;
                }
                else {
                    Swal.fire(
                        'Erro!',
                        'Só é permitido arquivos com extensão ".csv"',
                        'error'
                    )
                }
            }  
        },
        removeFile(){
            this.arquivo = null; 
        },
        openDialog: function() {
            this.$refs.uploadFile.value = ''
            this.$refs.uploadFile.click() 
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/_variables.scss";

.input-file {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 40vh;
    position: relative;
}

.input-file input {
    max-width: 30rem;
}

.input-file h1 {
    font-size: 1.6rem;
    font-weight: bold;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    color: $verdeEscuro-eapi;
}

.input-file button {
    border: 1px solid $verdeEscuro-eapi;
    color: $verdeEscuro-eapi;
    background-color: white;
    border-radius: 4px;
    padding: 0.4rem 1.2rem 0.4rem 0.4rem;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    transition: all ease-in-out 0.2s;
    -webkit-box-shadow: 10px 10px 5px -1px rgba(0,0,0,0.51);
    -moz-box-shadow: 10px 10px 5px -1px rgba(0,0,0,0.51);
    box-shadow: 6px 6px 5px -1px rgba(0,0,0,0.2);
}

.input-file button:hover {
    color: white; 
    background-color: $verdeEscuro-eapi;
}

.drag-and-drop {
    height: 14rem;
    width: 38rem;
    border: 4px dashed $verdeEscuro-eapi;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    padding: 0 1.5rem;
}

.drag-and-drop img {
    max-height: 7rem;
}

.drag-and-drop p {
    color: #656565;
    font-weight: 400;
    user-select: none;
}

.drag-style {
    filter: blur(0.7px);
}

.file-uploaded {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -2.5rem;
}

.animateCog {
    animation: cogAnimation linear infinite 1s;
}

.infos {
    pointer-events: none;
}

.input-file .remove-file {
    border: 1px solid #fd5454;
    color: #fd5454;
    padding: 0.4rem;
    margin-top: 0.2rem;
    font-size: 0.9rem;
    position: absolute;
    bottom: 30%;
}

.input-file .remove-file:hover {
    color: white; 
    background-color: #fd5454;
}

@keyframes cogAnimation {
    to {
        transform: rotate(360deg);
    }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media screen and (max-width: 728px) {
    .drag-and-drop{
        width: auto;
        margin: 0 1rem;
    }
  }

</style>