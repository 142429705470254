<!--
|---------------------------------------------
| ModalLateral Component 
|---------------------------------------------
| @usage Import the component and use, no params
| Modal and Form for login
-->

<template>
  <div>
    <div @click="closeModalLateral()" class="overlay-invisible" :class="{'overlay-visible': !isActiveModal}"></div>
      <div class="box-modal" :class="{boxModalVisibility: isActiveModal}">
        <div class="itens-modal">
          <div class="titulo-modal">
              <span>{{title}}</span>
              <div @click="closeModalLateral()" class="close-container">
                <div class="leftright"></div>
                <div class="rightleft"></div>
              </div>
          </div>
        <div class="container-fluid">
          <component :is="component"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Login from '@components/Login'
import SignUp from '@components/SignUp'

export default {
  name: 'ModalLateral',
  components: {
    Login,
    SignUp
  },
  props: {
    component: String,
    title: String		
  },
  methods: {
    showModalLateral: function() {
        if (this.component == "Login"){
          this.$store.commit('setIsActiveModalLateralLogin', false)
        }
        else {
          this.$store.commit('setIsActiveModalLateralSignUp', false)
        }
    },
    closeModalLateral: function() {
        if (this.component == "Login"){
          this.$store.commit('setIsActiveModalLateralLogin', true)
        }
        else {
          this.$store.commit('setIsActiveModalLateralSignUp', true)
        }
    }
  },
  computed: {
    isActiveModal() {
      if (this.component == "Login"){
        return this.$store.getters.getIsActiveModalLateralLogin
      }
      else {
        return this.$store.getters.getIsActiveModalLateralSignUp
      }
    }
  } 
}
</script>

<style scoped>
.overlay-invisible {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: rgba(22, 22, 22, 0.363);
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    transition: all ease-in-out 0.3s;
}

.overlay-visible {
    opacity: 1;
    pointer-events: all;
}

.box-modal {
    background: #fcfcfc;
    height: 100%;
    width: 33%;
    position: fixed;
    right: 0rem;
    z-index: 3;
    -webkit-box-shadow: -9px 0px 50px -18px rgba(0,0,0,0.6);
    -moz-box-shadow: -9px 0px 50px -18px rgba(0,0,0,0.6);
    box-shadow: -9px 0px 50px -18px rgba(0,0,0,0.4);
    transition: right ease-in-out 0.25s 0.1s;
}

.boxModalVisibility {
    right: -35%;
}

.close-container{
  position: relative;
  margin-right: -5px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.leftright{
  height: 2.5px;
  width: 30px;
  position: absolute;
  margin-top: 24px;
  background-color: #414141;
  border-radius: 2px;
  transform: rotate(45deg);
  transition: all .3s ease-in;
}

.rightleft{
  height: 2.5px;
  width: 30px;
  position: absolute;
  margin-top: 24px;
  background-color: #414141;
  border-radius: 2px;
  transform: rotate(-45deg);
  transition: all .3s ease-in;
}

.close-container:hover .leftright{
  transform: rotate(-45deg);
  background-color: #F25C66;
}
.close-container:hover .rightleft{
  transform: rotate(45deg);
  background-color: #F25C66;
}



/*
 |-----------------------------------------------------
 |--MEDIA QUERIES PARA RESPONSIVIZAR O MODAL
 |-----------------------------------------------------
 */

/*
 -> TELA PEQUENA
*/
 @media screen and (min-width: 992px) and (max-width: 1250px) {
    .box-modal {
        width: 40%;
    }

    .boxModalVisibility {
        right: -42%;
    }
 } 

/*
 -> CELULAR 
*/
 @media screen and (max-width: 991px) {
    .box-modal {
        width: 100%;
    }

    .boxModalVisibility {
        right: -102%;
    }
 } 

</style>
