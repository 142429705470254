<template>
    <form @submit.prevent>
    <div class="container offset">
        <div class="custom-form">

            <div class="form-group">
                <label class="question-style">Título do PDF:</label>
                <input
                    v-model.trim="titulo"
                    type="name"
                    class="form-control"
                    placeholder="Insira aqui o novo título do PDF"
                    required
                />
                <br />
				<label class="question-style">Novo Link do PDF:</label>
				<input
					v-model.trim="link"
					type="name"
					class="form-control"
					placeholder="Insira aqui o novo link do PDF"
                    required
				/>
            </div>
        </div> 
        <div class="button-div pt-3">
            <Button 
                @click="editPDF()"
                section="instituicao-campo"
                value="Salvar alterações"
                :isOutline="true"
                :hasIcon="false"
                iconName="edit"
            /> 
        </div>
    </div>
    </form>
   
</template>

<script>

import Button from '@components/Button'
// import Swal from 'sweetalert2';
// import { Notyf } from "notyf";
// const notyf = new Notyf({
//     duration: 5000,
//     position: {
//         x: "left",
//         y: "bottom",
//     }
// });

export default {
    name: "EditPDF",
    props: ['data'],
    components: {
        Button
    },
    watch: {
         data(updated) {
             const foundModal = this.modals.find(modal => modal.idModal == updated?.idModal);
             this.idModal = foundModal?.idModal || updated.idTipo;
             this.titulo = foundModal?.titulo || "";
             this.link = foundModal?.link || "";
         },
     },
     data() {
        return {
            idModal: null,
            titulo: "",
            link: "",
        }
    },
    methods: {
		async editPDF() {
			if(this.titulo !== "" && this.link !== ""){
                const payload = {
				idModal: this.data.idModal,
				titulo: this.titulo,
				link: this.link,
                }

                await this.$store.dispatch('updateModal', payload)
                await this.$store.dispatch('setListModais', {
                    categoria: window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1),
                })

                //this.clearForm()
                this.$emit('closeModal')
            }
        },

        /*clearForm() {
			this.titulo = ''
			this.link = ''
		}, */
	},
    computed: {
         modals() { return this.$store.getters.getListModais; },
     },
}

</script>

<style scoped>

    .container{
        padding: 1rem;
        width: 100%;
        background-color: white;
        margin: 0;
    }

    .title-style{
        font-size: 1.5rem;
        font-weight: 600;
        color: #656565;
    }

    .word-style{
        color:  #656565;
    }

    .custom-form{
        margin: 0 auto;
    }

    .question-style{
        width: 100%;
        font-size: 1rem;
        color: #656565;
    }

    .button-div{
        text-align: right;
    }

</style>
