//---------------------------------------------
// Instituicao Module 
//---------------------------------------------
// Módulo para gerenciar as ações da instituição

// import axios from 'axios';
// axios.defaults.baseURL = process.env.VUE_APP_API_ROOT ? process.env.VUE_APP_API_ROOT : 'http://localhost:3000'
import axios from '@middleware/api.js'
import Cookies from 'js-cookie'
// var qs = require('qs');
var JWT = () => {
    return (Cookies.get("accessToken") ? Cookies.get("accessToken") : "JWT VAZIO")
}

// STATES
const state = () => ({
    listTeams: [],
    listAllTeams: [],
    isInstiUpdated: null,
})

// MUTATIONS
const mutations = {
    //-- MUTATION REQUISICAO DO LISTTEAMS
    setListTeams(state, payload) {
        state.listTeams = payload
    },

    //-- MUTATION REQUISICAO DE TODAS AS INSTITUICOES
    setListAllTeams(state, payload) {
        state.listAllTeams = payload
    },

    setIsInstiUpdated(state, payload) {
        state.isInstiUpdated = payload;
    },
}

// ACTIONS
const actions = {
    //-- ACTION REQUISICAO DE CRIAR INSTITUIÇÃO
    async setCreateInstitution(_, payload) {
        var formData = new FormData();
        formData.append("nome", payload.nome);
        formData.append("descricao", payload.descricao);
        formData.append("orgao", payload.orgao);
        formData.append("id_cidade", payload.id_cidade);
        if (payload.id_grupos.length > 0) {
            formData.append("id_grupos", payload.id_grupos);
        }
        if (payload.imagem) {
            formData.append("imagem", payload.imagem)
        }
        await axios
            .post(`insti/`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${JWT()}`
                    }
                })
            .catch((error) => console.log(error.message))
    },

    async setUpdateInstitution({ commit }, payload) {
        commit('setIsInstiUpdated', null);

        var formData = new FormData();
        formData.append("nome", payload.nome);
        if (payload.imagem) {
            formData.append("imagem", payload.imagem)
        }

        await axios
            .put(`insti/${payload.id}`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${JWT()}`
                    }
                })
            .then(() => commit('setIsInstiUpdated', true))
            .catch((error) => {
                console.log(error.message);
                commit('setIsInstiUpdated', false);
            });
    },

    //-- ACTION REQUISICAO DO LISTTEAMS
    async setListTeams({ commit }) {
        await axios
            .get(`insti/listUserInstis`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListTeams', response.data)
            })
            .catch((error) => console.log(error.message))
    },


    

    //-- ACTION REQUISICAO DE TODAS AS INSTITUICOES
    async setListAllTeams({ commit }) {
        await axios
            .get(`insti`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListAllTeams', response.data)
            })
            .catch((error) => console.log(error.message))
    },

    //-- ACTION DELETE INSTITUIÇÃO
    async deleteInstitution(_, payload) {
        await axios
            .delete(`insti/${payload.idInstituicao}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .catch((error) => console.log(error.message))
    },
}

// GETTERS
const getters = {
    getListTeams(state) {
        try {
            return state.listTeams.data
        } catch (e) {
            return []
        }
    },

    getListAllTeams(state) {
        try {
            return state.listAllTeams.data
        } catch (e) {
            return []
        }
    },

    getIsInstiUpdated(state) {
        return state.isInstiUpdated;
    },
}

export default {
    state,
    mutations,
    actions,
    getters
}
