<template>
    
    <div class="list-data bg">

        <div class="title-tree">
            <Breadcrumb :crumbs="crumbs" section="instituicao-csv"/>
        </div>  

        <InstitutionInfo class="title-style" :title="nomeInstituicao" section="instituicao-cadastrar"/>

        <p class="text-info-personal text-img-clipper bg-color">Nesta seção, você encontrará todos os arquivos em formato CSV da Instituição. Caso necessário, utilize o filtro abaixo para localizar o arquivo desejado.</p>

        <div class="itens-box">
        <div class="filter-box">
            <!-- INICIO DO FORM DE FILTRAGEM -->
            <form @submit.prevent="" class="form-inline">
                <div class="form-group">
                
                <!-- DROPDOWN DE SELECAO DE FILTRO -->
                <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomSelect">Filtrar por: </label>
                    <Filter ref="filtersearchname" @click="setSelectedValue()" :optionsSelect="options" nameSelect="filtro_instituicoes" id="inlineFormCustomSelect"/>
                </div>

                <!-- SE A SELECAO FOR NOME (2) ENTAO EXIBIRA OS SUBFILTROS DE NOME -->
                <div class="form-group" v-if="selectedValue == 2">
                    <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomName">Digite o nome: </label>
                    <input class="input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_instituicoes" id="inlineFormCustomName" autocomplete="off">
                
                    <input class="radio-personal mr-sm-2" v-model="searchWithOrder" value="cres" type="radio" name="filtro_nome_ordenacao" id="inlineFormCustomRadioAZ" checked>
                    <label class="mr-sm-2 text-nowrap label-filtro mr-sm-4" for="inlineFormCustomRadioAZ">A - Z</label>
                    
                    <input class="radio-personal mr-sm-2" v-model="searchWithOrder" value="desc" type="radio" name="filtro_nome_ordenacao" id="inlineFormCustomRadioZA">
                    <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomRadioZA">Z - A</label>
                </div>

                <!-- SE A SELECAO FOR POR GRUPOS (3) ENTAO EXIBIRA OS SUBFILTROS DOS GRUPOS -->
                <div class="form-group" v-if="selectedValue == 3">
                    <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomCodigo">Selecione o grupo: </label>
                    <Filter ref="filtersearchgroup" @click="setSelectedGroup()" :optionsSelect="group_options" nameSelect="filtro_grupo_instituicoes" id="inlineFormCustomCodigo"/>
                </div>

            </form>
        </div>
        </div>
        <hr class="separator">
        
        <div class="list-box">
            <ListagemElement :infos="csv" listaType="listarCSV"/>
        </div>

    </div>
</template>

<script>
import Filter from '@components/Filter'
import ListagemElement from '@dados/VisualizarInstituicao/ListagemElement'
import Breadcrumb from '@components/Breadcrumb'
import Swal from 'sweetalert2';
import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'

export default {
    name: 'CampoListaFormularios',
    data (){
        return {
            nomeInstituicao: '',
            descricao: '',
            selectedValue: '',
            searchByName: '',
            searchByCode: '',
            searchWithOrder: '',
            csv: [
                // {
                //     id: 1,
                //     cidade: 'Ribeirão Preto',
                //     inicio: '29/01/2020',
                //     encerrado: '10/03/2021'
                // },
                // {
                //     id: 2,
                //     cidade: 'Ribeirão Preto',
                //     inicio: '29/01/2020',
                //     encerrado: '10/03/2021'
                // },
                // {
                //     id: 3,
                //     cidade: 'Ribeirão Preto',
                //     inicio: '29/01/2020',
                //     encerrado: '10/03/2021'
                // },
            ],
            options: [
                {
                id:1,
                value: 'Todos'
                },
                {
                id:2,
                value: 'Nome'
                },
                {
                id:3,
                value: 'Grupo de Pesquisa'
                }
            ],
            crumbs: [
                {
                    id:1,
                    value: '',
                    iconName: 'th',
                    name_route: 'GerenciarInstituicoes'
                },
                {
                    id:2,
                    value: 'Instituição',
                    iconName: 'university',
                    name_route: 'InstituicaoMenu'
                },
                {
                    id:4,
                    value: 'CSV',
                    iconName: 'file-csv',
                    name_route: ''
                }
                
            ]
        }
    },
    components: {
        Filter,
        ListagemElement,
        Breadcrumb,
        InstitutionInfo
    },
    methods: {
        applyChanges: function() {
            Swal.fire(
                'Alterações aplicadas!',
                'As seleções foram aplicadas com sucesso.',
                'success'
            )
        },
        setSelectedValue: function() {
            //! TODO: só pra parar de dar erro no console
        }
    },
    created() {
        this.teams = this.$store.getters.getListTeams
    },
    mounted: function() {
        window.scrollTo(0, 0);
        let idTeamSelected = this.$route.params.idInstituicao

        if (typeof idTeamSelected == "undefined"){
            this.nomeInstituicao = "Nome da Instituição"
        }
        else {
            let teamsSelected
            let teamSelected

            teamsSelected = this.teams.filter(team => 
                team.idinstituicao == idTeamSelected
            )

            teamSelected = [...teamsSelected].shift()

            this.nomeInstituicao = teamSelected.name
            this.descricao = teamSelected.descricao
        }
    }
}
</script>

<style scoped>
 .bg-color {
     background-image: url("~@/assets/img/Backgrounds/verdeClaroMateriais.webp");
 }

.text-info-personal {
  color: #656565;
  font-size: 1.1rem;
  text-align: justify;
  padding: 0rem;
  margin: 0 0 0 3rem;
}

.list-data {
    min-height: 90vh;
    padding-top: 2rem;
}
.label-filtro {
    font-weight: 600;
}

.filter-box {
    margin: 1rem 3rem;
}

.list-box {
    margin: 2rem;
}

.itens-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-tree {
    margin: 1rem 3rem;
    font-weight: bold;
    display: flex;
}

.title-style{
    margin: 0 0 0 3rem;
}

.lista-data-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 2rem 3rem;
}

.title-tree h1 {
    font-size: 1.2rem;
    font-weight: 700;
}

.main-link {
    outline:none;
    text-decoration: none;
    cursor: pointer;
    margin-right: .3rem;
}

.sub-link {
    margin-left: .3rem;
}

.separator {
    border: none;
    height: 1px;
    margin: 0.8rem 3rem;
    background-color: rgba(51, 51, 51, 0.178);
}

a {
    color: #212529;
    text-decoration: none;
    transition: all ease-in-out 0.2s
}

a:hover {
    color: #4d4f50;
}

@media screen and (max-width: 1010px) {
    .lista-data-info {
        justify-content: center;
    }
}


@media screen and (max-width: 1012px) {
    .filter-box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 680px) {
    .itens-box{
        flex-direction: column;
        align-items: flex-start;
    }

    .filter-box{
        margin: 0 3rem;
    }

    .lista-data-info{
        margin: 0 3rem;
    }
}
    
</style>
