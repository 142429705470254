<template>
    <div class="container offset">
        <div class="custom-form ">
            <p>{{dataLocal?.descricao}}</p>
        </div>
    </div>
</template>

<script>


export default {
    props: {
        data: Object
    },
    computed: {
        dataLocal() {
            return this.data
        }
    }
}

</script>

<style scoped>

    .container{
        width: 100%;
        background-color: white;
    }

</style>
