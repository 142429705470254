<template>
    <div class="container">
        <span>
            <i :class="`${icon}`"></i>
            {{ filteredQnt }}
        </span>
        <span>Total de {{ term }}: {{ totalQnt }}</span>
    </div>
</template>

<script type="text/javascript">
 export default {
     name: "MemberQnt",
     props: {
         totalQnt: {
             type: Number,
             required: true,
         },
         filteredQnt: {
             type: Number,
             required: true,
         },
         icon: {
             type: String,
             default: 'fas fa-user',
         },
         term: {
             type: String,
             required: false,
             default: "membros",
         },
     }
 };
</script>

<style scoped>
 .container {
     width: 100%;
     display: flex;
     justify-content: space-between;
     flex-wrap: wrap;
 }

 span {
     font-size: 1.1em;
     font-weight: bold;
 }
</style>
