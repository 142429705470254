<template>
    <div class="bg">
        <div class="list-data">
        <ModalCentral
            componentName="InviteUserGroup"
            :data="{ id: idGrupo }"
            title="Adicionar Membro"
            idName="idInvite"
        />

        <div class="title-tree">
            <BreadCrumb :crumbs="crumbs" section="instituicao-cadastrar" />
        </div>

        <div class="title-info">
            <h1 class="title mt-4 mb-4">Membros</h1>
            <p class="text-info-personal imgText">
                Nesta seção, você encontrará todos os membros do Grupo. Caso necessário, utilize o filtro abaixo para localizar o membro desejado.
            </p>
        </div>

        <div class="itens-box">
            <div class="filter-box">
                <!-- INICIO DO FORM DE FILTRAGEM -->
                <form @submit.prevent="" class="form-inline">
                    <div class="form-group">
                        <input class="input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_instituicoes" id="inlineFormCustomName" placeholder="Nome..." autocomplete="off">
                    </div>

                    <!-- SE A SELECAO FOR POR GRUPOS (3) ENTAO EXIBIRA OS SUBFILTROS DOS GRUPOS -->
                    <div class="form-group" v-if="selectedValue == 3">
                        <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomCodigo">Selecione o grupo: </label>
                        <Filter ref="filtersearchgroup" @click="setSelectedGroup()" :optionsSelect="group_options" nameSelect="filtro_grupo_instituicoes" id="inlineFormCustomCodigo"/>
                    </div>

                </form>
            </div>
            <div class="lista-data-info">
                <Button
                    v-if="groupPerm?.idPermissao == 3"
                    class="btn mr-2 menuButton"
                    section="instituicao-cadastrar"
                    value="Convidar membro"
                    :isOutline="true"
                    :hasIcon="true"
                    data-target="#idInvite"
                    data-toggle="modal"
                    iconName="envelope" />
            </div>
        </div>
        <hr class="separator">

        <div class="list-box">
            <MemberQnts :totalQnt="membros.length" :filteredQnt="membros_grupo?.length" />
            <!--<ListagemElements :infos="membros_grupo" listaType="listarMembrosGrupo"/>-->
            <transition-group tag="div" v-if='membros_grupo' name="slide-fade">
                    <div class="box-header">
                        <span></span>
                        <span>Nome (Email)</span>
                        <span>Permissão</span>
                    </div>

                    <div class="box-lista" v-for="membro in membros_grupo" :key="membro.idusuario">
                        <div>
                            <img v-if="membro.permissao == 'ADMINISTRADOR'" class="img-info-box-lista" src="@assets/img/Cards/Coroa.svg" alt="">
                            <img v-else-if="membro.permissao == 'COORDENADOR'" class="img-info-box-lista" src="@assets/img/Cards/Membro.svg" alt="">
                            <img v-else class="img-info-box-lista" src="@assets/img/Cards/Lapis.svg" alt="">
                        </div>
                        <span>{{membro.nome}} ({{membro.email}})</span>
                        <span>{{membro.permissao}}</span>
                    </div>
                </transition-group>

                <transition-group v-else>
                    <h2 class="title-style-box-lista">Nenhum resultado encontrado.</h2>
                </transition-group>
        </div>
        </div>
    </div>
</template>


<script>
 import Filter from '@components/Filter';
 import Button from '@components/Button';
 //import ListagemElements from '@dados/VisualizarInstituicao/ListagemElement';
 import ModalCentral from '@components/ModalCentral';
 import MemberQnts from '@dados/VisualizarInstituicao/MemberQnt';
 import BreadCrumb from '@components/Breadcrumb'

 export default {
     name: "GrupoMembros",
     data () {
         return {
             idGrupo: null,
             isActiveMiniDropdown: false,
             selectedValue: '',
             searchByName: '',
             searchByCode: '',
             searchWithOrder: '',
             options: [
                 {
                     id:1,
                     value: 'Todos'
                 },
                 {
                     id:2,
                     value: 'Nome'
                 },
                 {
                     id:3,
                     value: 'Grupo de Pesquisa'
                 }
             ],
             crumbs: [
                 {
                     id:1,
                     value: '',
                     iconName: 'th',
                     name_route: 'GerenciarGrupos'
                 },
                 {
                     id:2,
                     value: 'Grupo',
                     iconName: 'graduation-cap',
                     name_route: 'GrupoMenu'
                 },
                 {
                     id:3,
                     value: 'Membros',
                     iconName: 'user-friends',
                     name_route: ''
                 }
             ],
         }
     },
     methods: {
         showMiniDropdown: function() {
             this.isActiveMiniDropdown = true
         },
         closeMiniDropdown: function() {
             this.isActiveMiniDropdown = false
         },
         setSelectedValue: function() {
             //! TODO: só pra parar de dar erro no console
         },
         gotoSolicitacoes() {
             this.$router.push({name: 'GrupoSolicitacoes'})
         },
     },
     async created() {
         window.scrollTo(0, 0);
         this.idGrupo = this.$route.params.idGrupo;
         await this.$store.dispatch('setListMembrosGrupo', this.idGrupo)
         this.$store.dispatch("groupPerm", { idGrupo: this.idGrupo })
     },
     totalMembros() {
            return this.$store.getters.getListMembros?.length;
        },
        membros(){
            var membros = this.$store.getters.getListMembros
            membros?.forEach((value) => {
                if (value.permissao == 'ADMINISTRADOR') {
                    value.idPermissao = 3
                }
                if (value.permissao == 'COORDENADOR') {
                    value.idPermissao = 2
                }
                if (value.permissao == 'APLICADOR') {
                    value.idPermissao = 1
                }
            })

            // VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
            var query = membros;

            if (this.searchByName.trim() != '') {
                var filtered = membros.filter(membro =>membro.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))

                if(filtered?.length != 0){
                    query = filtered
                } else{
                    query = false
                }
            }

            return query;
        },
        
        userPerm() {
            return this.$store.getters.getUserPerm
        },
        userInfo() {
            return this.$store.getters.getUserInfo
        },
     computed: {
         membros_grupo() {
             let query = this.membros;

             if (this.searchByName.trim() != '')
                 query = query.filter(membro => membro.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))

             return query;
         },
         membros() {
             return this.$store.getters.getListMembrosGrupo;
         },
         groupPerm() {
             return this.$store.getters.getGroupPerm(this.idGrupo) || {}
         },
     },
     components: {
         Filter,
         Button,
         //ListagemElements,
         ModalCentral,
         MemberQnts,
         BreadCrumb,
     }
 }
</script>


<style scoped lang="scss">
 @import "../../assets/scss/_variables.scss";

 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;
 }

 .list-data {
    min-height: 90vh;
    padding-top: 2rem;
}


 .imgText {
     background-image: url("~@/assets/img/Backgrounds/azulEapi.png");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

.separator {
    border: none;
    height: 1px;
    margin: 0.8rem 3rem;
    background-image: url("~@/assets/img/Backgrounds/azulEapi.png");
}

 .overlay-invisible {
     position: fixed;
     top: 0; right: 0; bottom: 0; left: 0;
     background-color: rgba(22, 22, 22, 0.0);
     z-index: 2;
     transition: all ease-in-out 0.3s;
     opacity: 0;
     pointer-events: none;
 }

 .overlay-visible {
     opacity: 1;
     pointer-events: all;
     cursor: default;
 }

 .banner-sup {
     display: flex;
     width: 80%;
     max-width: 1200px;
     min-width: 300px;
 }
 .img-banner {
     height: 100%;
     width: 100%;
     border-radius: 0.8rem  0rem  0rem 0.8rem;
 }
 .left-side {
     flex: 30%;
 }
 .right-side {
     display: flex;
     flex-direction: column;
     justify-content: center;
     padding: 2rem;
     background-color: white;
     flex: 70%;
     border-radius: 0 0.8rem 0.8rem 0;
     position: relative;
 }
 .right-side h1 {
     font-size: 1rem;
     font-weight: bold;
 }
 .right-side p {
     font-size: 0.9rem;
     font-weight: lighter;
     text-align: justify;
     margin: 0;
 }

 .fa-cog {
     cursor: pointer;
     font-size: 1.4rem;
     position: absolute;
     top: 8%;
     right: 4%;
 }

 .item-minidropdown {
     color: #656565;
 }

 .item-minidropdown:hover {
     color: rgb(58, 58, 58);
 }

 .mini-triangle{
     margin: 0rem;
     display: center;
     align-items: center;
     justify-items: center;
     position: absolute;
     top: -16%;
     left: 80%;

 }
 .dropdown-box {
     position: absolute;
     top: 188%;
     left: -480%;
     z-index: 2;
     opacity: 0;
     pointer-events: none;
     cursor: default;
     transition: all 0.4s ease;
 }

 .dropdown-box-visible{
     opacity: 1;
     cursor: pointer;
     pointer-events: all;
 }

 .mini-dropdown {
     position: relative;
 }

 .mini-pop-up{
     height: 5.5rem;
     width: 8rem;
     background-color:white;
     color:grey;
     border-radius: 1rem;
     transition: color ease-in-out 0.2s;
     font-size: 1rem;
     font-weight: 300;
     font-family: 'Hind', sans-serif;
 }

 .item-menu-dropdown {
     margin: 1rem 0;
 }

 .label-filtro {
     font-weight: 600;
 }

 .filter-box {
     margin: 1rem 3rem;
 }

 .list-box {
     margin: 2rem;
 }


 .itens-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.title-tree {
    margin: 1rem 3rem;
    font-weight: bold;
    display: flex;
}

 .lista-data-info {
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;
     margin: 2rem 3rem;
 }

 .title-tree h1 {
    font-size: 1.2rem;
    font-weight: 700;
}

 .main-link {
     outline:none;
     text-decoration: none;
     cursor: pointer;
     margin-right: .3rem;
 }

 .sub-link {
     margin-left: .3rem;
 }

 a {
     color: #212529;
     text-decoration: none;
     transition: all ease-in-out 0.2s
 }

 a:hover {
     color: #4d4f50;
 }

 @media screen and (max-width: 1010px) {
     .lista-data-info {
         justify-content: center;
     }
 }


 @media screen and (max-width: 1012px) {
     .filter-box {
         display: flex;
         align-items: center;
         justify-content: center;
     }
 }

 @media screen and (max-width: 680px) {
     .itens-box{
         flex-direction: column;
     }

     .img-banner{
         border-radius: 1rem 1rem 0 0;
     }

     .banner-sup{
         flex-direction: column;
     }

     .right-side{
         border-radius: 0 0 1rem 1rem;
     }

     .box-lista span, .box-lista a{
        width: 100%;
        text-align: center;
        margin-top: .5rem;
    }
    .box-lista{
        place-content: center;
    }
}

 .img-info-box-lista {
     padding: 10px;
 }

 .menuButton {
     margin: 5px;
 }

 .title-info {
     padding: 0 3rem;
     box-sizing: border-box;
     margin: 20px auto 0 auto;
 }

 .title-style{
    margin: 0 0 0 3rem;
}

 .title-info > p {
     margin: 0;
 }

 .title-info > .title {
    font-weight: 600;
    font-size: 1.6rem;
    color: #535353;
    margin: 0;
 }

 .text-info-personal {
  color: #656565;
  font-size: 1.1rem;
  text-align: justify;
  padding: 0rem;
  margin: 0 3rem 0 3rem;
}

 
 .box-lista {
     background-repeat: repeat;
     background-size: 256px;
     background-image: url("~@/assets/img/Backgrounds/azulMaisClaro.webp");
     border: 1px solid #952713;
     box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
 }

 .box-lista > * {
  margin: 0 auto;
  flex: 1;
  text-align: center;
  word-break: break-all;
}

:deep(.box-lista span) {
     font-size: 1.1em;
 }

 .box-lista span {
     background-image: url("~@/assets/img/Backgrounds/marromEscuro.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

</style>
