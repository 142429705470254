<template>
    <div>
        <ListGroups/>
    </div>
</template>

<script>
import ListGroups from '@dados/GerenciarGrupos/ListGroups';

export default {
    name: 'GerenciarInstituicoes',
    components: {
        ListGroups
    }
}
</script>

<style scoped>

</style>