<template>
    <form @submit.prevent class="container offset">
        <div class="form-group">
            <label class="question-style">Email para convite:</label>
            <input
                v-model.trim="email"
                type="email"
                autocomplete="off"
                class="form-control"
                placeholder="Insira o email do usuário a convidar..."
                required />
        </div>

        <div class="pt-4">
            <Button
                @click="sendInvite"
                section="dados-outline"
                value="Adicionar membro"
                :isOutline="true"
                :hasIcon="true"
                iconName="paper-plane"
            />
        </div>
    </form>
</template>

<script>
 import Button from '@components/Button';
 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: "left",
         y: "bottom",
     },
 });

 export default {
     name: "InviteUserGroup",
     components: {
         Button,
     },
     props: {
         data: {
             type: Object,
             required: true
         },
     },
     data() {
         return {
             email: "",
         };
     },
     methods: {
         async sendInvite() {
             await this.$store.dispatch('setNotifyGroupInviteUser', {
                 idGrupo: this.data.id,
                 userMails: [ this.email ],
             });

             if (this.$store.getters.getIsSentRequestGroupUser) {
                 notyf.open({
                     type: "success",
                     // message: this.$store.getters.getMemberCreationMsg,
                     message: "Membro criado com sucesso",
                 });
                 this.email = "";
                 this.$store.dispatch('setListMembrosGrupo', this.data.id);
             } else {
                 notyf.open({
                     type: "error",
                     message: "Erro no convite do membro",
                 });
             }
         },
     },
 };
</script>

<style scoped>
 .container{
     padding: 1rem;
     width: 100%;
     background-color: white;
     margin: 0;
 }

 .question-style{
     width: 100%;
     font-size: 1rem;
     color: #656565;
 }

</style>
