<template>
    <div class="popup">
        <h2>Quase lá...</h2>
        <p>Gostou do conteúdo? Por favor, ajude-nos preenchendo as informações abaixo!</p>

        <form class="customForm" @submit.prevent="downloadPdf">
            <div class="atuacaoInput">
                <label for="area">Área de atuação</label>
                <input
                  v-model.trim="atuacao"
                  type="text"
                  name="atuacao"
                  class="form-control"
                  placeholder="Insira sua área de atuação..."
                  required />
                <!-- list="areasAtuacao" -->
                <!-- <datalist id="areasAtuacao"> -->
                <!--     <option -->
                <!--         v-for="(atuacaoOpt, idx) in atuacoes" -->
                <!--         :key="idx" -->
                <!--         :value="atuacaoOpt" /> -->
                <!-- </datalist> -->
            </div>

            <br />

            <input
                v-model.trim="email"
                name="email"
                type="email"
                class="form-control"
                placeholder="Insira aqui seu email..."
                required
            />

            <br />

            <Button
                class="popupButton"
                value="Baixar"
                :isOutline="false" />
        </form>
    </div>
</template>

<script type="text/javascript">
 import Button from "@components/Button";
 import axios from '@middleware/api.js';
 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: "left",
         y: "bottom",
     }
 });

 export default {
     name: "PopupDownload",
     props: {
         data: {
             type: Object,
             required: true,
         },
     },
     data() {
         return {
             id: this.data.id,
             pdf: this.data.pdf,
             name: this.data.name,
             email: "",
             atuacao: "",
             baseUrl: "",
         };
     },
     components: {
         Button,
     },
     async created() {
         this.baseUrl = axios.defaults.baseURL;
       await this.$store.dispatch('setUserInfo');
       if (this.userInfo) {
         this.email = this.userInfo.data.email;
         this.atuacao = this.userInfo.data.profissao;
       }
     },
     methods: {
         registerDownload() {
             if (this.atuacao == '') return false;
             this.$store.dispatch('registerDownloadPratica', {
                 idPratica: this.id,
                 atuacao: this.atuacao,
                 email: this.email,
             });
             return true;
         },
         downloadPdf() {
             if (!this.registerDownload()) {
                 notyf.open({
                     type: "error",
                     message: "Por favor descreva sua área de atuação!",
                 });
                 return;
             }

             let but = document.createElement('a');
             but.setAttribute('href', `${this.baseUrl}/boas_praticas/downloadPdf/?path=${this.pdf}`);
             but.setAttribute('download', `${this.name}.pdf`);
             but.setAttribute('target', `_blank`);
             but.click();
             notyf.open({
                 type: "success",
                 message: "Seu download iniciará em instantes",
             });
             this.$emit("closeModal");
         },
     },
     computed: {
         atuacoes() {
             return [
                 "Atuação 1",
                 "Atuação 2",
             ];
         },
        userInfo() {
            return this.$store.getters.getUserInfo;
        },
     },
 };
</script>

<style type="text/scss" media="screen" scoped>
 h2 {
     color: #414141;
     width: 100%;
     padding: 5px;
     text-align: center;
 }

 .popupButton {
     display: block;
     background-color: #41B3A3;
     border-radius: 30px;
     margin: 0 auto;
     min-width: 150px;
 }

 .atuacaoInput {
     display: flex;
     flex-direction: row;
     justify-content: space-around;
     align-items: center;
     gap: 10px;
 }

 .atuacaoInput label {
     flex: 0 1 max-content;
     padding: 0;
     margin: 0;
 }

 .atuacaoInput input {
     flex: 1;
 }

</style>
