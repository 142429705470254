<template>
	<div class="container">
		<!---------- MODAL CENTRAL PARA ADICIONAR VÍDEO E PDF -------------------->
		<ModalCentral componentName="AddPDF" title="ADICIONAR PDF" idName="AddPDF" />
		<ModalCentral componentName="AddVideo" title="ADICIONAR VÍDEO" idName="AddVideo" />

		<!------------------- A VIEW COMEÇA AQUI ---------------------------------------------------------->
		<div>
			<h1 class="materiais-documentos title-info">Vídeos para Treino</h1>
			<p class="text-info-personal">
				Para conhecer um pouco mais sobre o monitoramento da qualidade da Educação Infantil no Brasil realizado pelo
				LEPES-USP, acesse os materiais abaixo:
			</p>
		</div>

		<div v-show="userPerm == true" class="buttons">
			<Button
				class="ml-4"
				section="materiais-documentos-outline"
				value=" Adicionar Vídeo"
				:isOutline="true"
				:hasIcon="true"
				:hasShadow="true"
				iconName="play"
				:isReverse="false"
				data-toggle="modal"
				data-target="#AddVideo"
			/>
		</div>

		<!------------------- LISTAGEM DOS MODAIS ---------------------------------------------------------->
		<div class="boxes-style">
			<transition-group v-if="modais?.length > 0" name="slide-fade">
				<MaterialBox
					class="box-style"
					v-for="modal in modais"
					:key="modal.idModal"
					:id="modal.idModal"
					:isVideo="modal.isVideo"
					:title="modal.titulo"
					:videoLink="modal.link"
					:pdfLink="modal.link"
				/>
			</transition-group>
			<transition-group v-else>
				<h2 class="title-style-box-lista">Nenhum material encontrado.</h2>
			</transition-group>
		</div>

		<!--TODO: OS BOTÕES DAS PÁGINAS AINDA NÃO FUNCIONAM-->
		<!-------------------- PAGINATION ------------------------>

		<div class="pagination-style pt-5">
			<nav>
				<ul class="pagination">
					<li class="page-item">
						<a class="page-link" href="#" aria-label="Previous">
							<span aria-hidden="true">&laquo;</span>
							<span class="sr-only">Previous</span>
						</a>
					</li>
					<li class="page-item"><a class="page-link" href="#">1</a></li>
					<li class="page-item"><a class="page-link" href="#">2</a></li>
					<li class="page-item"><a class="page-link" href="#">3</a></li>
					<li class="page-item">
						<a class="page-link" href="#" aria-label="Next">
							<span aria-hidden="true">&raquo;</span>
							<span class="sr-only">Next</span>
						</a>
					</li>
				</ul>
			</nav>
		</div>

		<!-------------------- FIM DO PAGINATION ------------------------>
	</div>
</template>

<script>
import MaterialBox from '@components/MateriaiseDocumentos/MaterialBox'
import Button from '@components/Button'
import ModalCentral from '@components/ModalCentral'

export default {
	name: 'VideosTreino',
	data() {
		return {}
	},
	async created() {
		this.isLoadedInfo = false
		await this.$store.dispatch('setListModais', {
			categoria: window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1),
		})
		this.isLoadedInfo = true
	},
	computed: {
		userPerm() {
			try{
            	return this.$store.getters.getUserInfo.data.isAdmin
			} catch(e) {
				return false
			}
        },
		modais() {
			return this.$store.getters.getListModais
		},
	},
	components: {
		MaterialBox,
		Button,
		ModalCentral,
	},
}
</script>

<style scoped>
.container {
	margin: 2.5rem auto;
}

.title-info {
	font-weight: 600;
	font-size: 1.6rem;
	color: #535353;
	margin: 0;
}

.buttons {
	margin: 1rem 1rem 1rem 0;
}

p {
	display: block;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}

.materiais-documentos {
	color: #eb7071;
}

.text-info-personal {
	color: #656565;
	font-size: 1.1rem;
	text-align: justify;
	padding: 0rem;
}

.box-style {
	margin: 1rem;
}

.boxes-style {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}

.pagination-style {
	justify-content: center;
	display: flex;
}

.page-link {
	color: #eb7071;
}

.page-link:focus {
	box-shadow: none;
}

.page-link:hover {
	color: white;
	background-color: #eb7071;
}
</style>
