<!--
|---------------------------------------------
| InfoGraphs Component 
|---------------------------------------------
| @usage Import the component and use, no params
| Graphs with some info of the project
-->

<template>
   <div class='container-fluid'>
       <h2 class='info-title text-center'>
        Entenda a nossa plataforma!
      </h2>
     
    <div class='row rowContainer'>
        <div class="row-item">
            <img class="img-fluid" src='@assets/img/Home/InfoGraphs/aplicacoes.svg'/> <br />
            <div> Número de aplicações ao longo dos anos </div>
        </div>

        <div class="row-item">
            <img class="img-fluid" src='@assets/img/Home/InfoGraphs/timeline.svg'/> <br />
            <div> Porcentagem de aplicações nos principais estados </div>
        </div>

        <div class="row-item">
            <img class="img-fluid" src='@assets/img/Home/InfoGraphs/conquistas.svg'/> <br />
            <div> Nossas conquistas </div>
        </div>
   </div>
  </div>
</template>

<script>

export default {
    name: 'InfoGraphs'
    
}
</script>

<style scoped>
 @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.container-fluid{
    background-image: url("~@/assets/img/Backgrounds/bege.webp");
    background-origin: border-box;
    background-repeat: repeat;
    background-size: 256px;
    box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.2);
    padding: 1em;
}

 .info-title{
     padding-top: 50px;
     color: #a66957;
     font-size: 39px;
     font-weight: 600;
     text-align: left;
     font-family: 'Montserrat', sans-serif;
 }

.row{
    padding: 25px;
 }

 .rowContainer {
     text-align: center;
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
 }

 .row-item {
     display: block;
     margin: 15px auto;
     text-align: center;
     font-weight: 100;
     font-size: 1.3em;
     max-width: 200px;
     width: 100%;
 }

 .row-item img {
     max-height: 250px;
     width: 100%;
     margin: 0 auto;
 }

 .row-item div {
     font-size: 22px;
     font-weight: 400;
     font-family: 'Montserrat', sans-serif;
 }

.graph{
    margin-right: 0px;
}


 @media screen and (max-width: 728px) {
     .container-fluid {
         padding: 0;
     }
 }
</style>
