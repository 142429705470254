//---------------------------------------------
// Cidades Module 
//---------------------------------------------
// Módulo para gerenciar as cidades

// import axios from 'axios';
import axios from '@middleware/api.js'
// axios.defaults.baseURL = process.env.VUE_APP_API_ROOT ? process.env.VUE_APP_API_ROOT : 'http://localhost:3000'

// STATES
const state = () => ({
    cidades: []
})

// MUTATIONS
const mutations = {
    //-- MUTATION PARA ALTERAR ESTADO DAS CIDADES
    setCidades(state, payload) {
        state.cidades = payload
    },
}

// ACTIONS
const actions = {
    //-- ACTION REQUISICAO DO LISTFORMS
    async setCidades({ commit }, payload) {
        await axios
            .get(`cidades?estado=${payload}`)
            .then(response => {
                commit('setCidades', response.data)
            })
            .catch((error) => console.log(error.message))
    },
}

// GETTERS
const getters = {
    getCidades(state) {
        try {
            return state.cidades.data
        } catch (e) {
            return []
        }
    },

}

export default {
    state,
    mutations,
    actions,
    getters
}
