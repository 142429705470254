<template>
    <div class="bg">
        <div class="list-data">

            <ModalCentral
                componentName="ShowCampoInfo"
                title="DESCRIÇÃO DO CAMPO"
                idName="showCampoInformacaoTwo"
                :data="dataToShowCampoInfo"
            />

            <div class="title-tree">
                <Breadcrumb :crumbs="crumbs" section="instituicao-aplicacoes"/>
            </div>

            <InstitutionInfo class="title-style" :title="nomeInstituicao" section="instituicao-cadastrar"/>

            <p class="text-info-personal text-img-clipper imgText">Nesta seção, você encontrará todos os Campos relacionados à Instituição.
                Caso necessário, utilize o filtro abaixo para localizar o Campo desejado.
                Apenas um Campo pode apresentar o status "Ativo" por vez. Para acessar as Aplicações realizadas em
                cada Campo, clique no botão "Ver Aplicações" do Campo desejado.</p>

            <div class="itens-box">
                <div class="filter-box">
                    <!-- INICIO DO FORM DE FILTRAGEM -->
                    <form @submit.prevent="" class="form-inline">
                        <!-- {{}} -->
                        <div class="form-group">

                            <!-- DROPDOWN DE SELECAO DE FILTRO -->
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomSelect">Filtrar por: </label>
                            <Filter ref="filtersearchname" @change="setSelectedValue()" :optionsSelect="options" nameSelect="filtro_instituicoes" id="inlineFormCustomSelect"/>
                        </div>

                        <!-- SE A SELECAO FOR NOME (2) ENTAO EXIBIRA OS SUBFILTROS DE NOME -->
                        <div class="form-group" v-if="selectedValue == 2">
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomName">Digite o nome: </label>
                            <input class="input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_instituicoes" id="inlineFormCustomName" autocomplete="off">
                        </div>
                    </form>
                </div>
            </div>
            <hr class="separator">

            <!-------------------------------- LISTANDO AS APLICAÇÕES DO CAMPO ----------------------------------------------->

            <div v-if="isLoadedInfo">
                <MemberQnt term="campos" :totalQnt="totalCampos" :filteredQnt="campos?.length || 0" icon="fad fa-binoculars" />

                <div class="list-box">
                    <transition-group name="slide-fade" v-if="campos?.length > 0">
                        <div class="row box-header">
                            <span class="col-1"></span>
                            <span class="col-3">Título</span>
                            <span class="col-2">Ano</span>
                            <span class="col-3">Estado</span>
                            <span class="col-3">Controle</span>
                        </div>

                        <div class="row box-lista text-center" v-for="campo in campos" :key="campo.counter" :class="{'list-item-selected-orange': campo.isativo == 'ON'}">
                            <div class="col-1">
                                <img class="img-info" src="@assets/img/Cards/Lapis.svg" alt="">
                            </div>
                            <span class="col-4">{{campo.titulo}}<i @click="sendInfosToShowCampoInfo(campo.descricao)" data-toggle="modal" data-target="#showCampoInformacaoTwo" class="fa fa-info-circle ml-2"></i></span>
                            <span class="col-2">Iniciada em {{campo.ano}}</span>
                            <span v-if="campo.isativo == 'INATIVO'" class="col-3">Campo Inativo</span>
                            <span v-else-if="campo.isativo == 'OFF'" class="col-3">Campo Encerrado</span>
                            <span v-else-if="campo.isativo == 'ON'" class="col-3 orange-text-no-hover">Campo Ativo</span>
                            <div class="col-6 d-flex">
                                <router-link :to="{name: 'ListarAplicacoes', params: { idCampoAplicacoes: campo.idamostra }}" class="btn-router-link">
                                    <Button
                                        class="orange-text btnLista mr-2"
                                        value="Ver Aplicações"
                                        section="instituicao-aplicacoes"
                                        :isOutline="true"
                                    />
                                </router-link>

                                <Button
                                  class="orange-text btnLista"
                                  value="Baixar dados"
                                  @click="downloadData(campo.idamostra)"
                                  section="instituicao-aplicacoes"
                                  :isOutline="true"
                                  />
                            </div>
                        </div>
                    </transition-group>
                    <transition-group v-else>
                        <h2 class="title-style-box-lista">Nenhum Campo encontrado.</h2>
                    </transition-group>
                </div>

            </div>

            <div v-else>
                <SkeletonList/>
            </div>

        </div>
    </div>

</template>

<script>
 import Filter from '@components/Filter'
 import Button from '@components/Button'
 import ModalCentral from '@components/ModalCentral'
 import Breadcrumb from '@components/Breadcrumb'
 import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
 import SkeletonList from '@components/SkeletonUI/SkeletonList'
 import MemberQnt from '@dados/VisualizarInstituicao/MemberQnt'

 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: "left",
         y: "bottom",
     }
 });

 export default {
     name: 'CampoListaFormularios',
     data (){
         return {
             baixando: false,
             nomeInstituicao: '',
             nomeCampo: '',
             descricao: '',
             selectedValue: '',
             searchByName: '',
             searchByCode: '',
             searchWithOrder: '',
             isLoadedInfo: false,
             dataToShowCampoInfo: {},
             options: [
                 {
                     id:1,
                     value: 'Todos'
                 },
                 {
                     id:2,
                     value: 'Nome'
                 }
             ],
             crumbs: [
                 {
                     id:1,
                     value: '',
                     iconName: 'th',
                     name_route: 'GerenciarInstituicoes'
                 },
                 {
                     id:2,
                     value: 'Instituição',
                     iconName: 'university',
                     name_route: 'InstituicaoMenu'
                 },
                 {
                     id:3,
                     value: 'Campos',
                     iconName: 'th-large',
                     name_route: ''
                 }
             ]
         }
     },
     components: {
         Filter,
         Button,
         Breadcrumb,
         ModalCentral,
         InstitutionInfo,
         SkeletonList,
         MemberQnt,
     },
     async created() {
         this.teams = this.$store.getters.getListTeams

         this.isLoadedInfo = false
         await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)
         this.isLoadedInfo = true
     },
     computed: {
         totalCampos() {
             return this.$store.getters?.getListCampos.length || 0;
         },
         campos() {
             var campos = this.$store.getters.getListCampos

             var query = campos;
             //CASO SEJA SELECIONADO TODOS, EXIBE TODOS OS ITENS DO ARRAY
             if (this.selectedValue == 1) {
                 query = campos;
             }
             //CASO SEJA SELECIONADO NOME, FILTRA POR NOME
             if (this.selectedValue == 2) {
                 //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
                 if (this.searchByName.trim() == '') {
                     query = campos;
                 }
                 //SENAO ELE FILTRA PELO O QUE FOR DIGITADO
                 else {
                     var filtered = campos?.filter(campo =>campo.titulo.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
                     if(filtered?.length != 0){
                         query = filtered
                     }

                     else{
                        
                         query = false
                     }
                 }
             }
             return query;
         }
     },
     methods: {
         downloadData(idAmostra) {
             if (this.baixando) return;
             this.baixando = true;

             const notiLoad = notyf.open({
                 type: "info",
                 background: "#40c9a2",
                 message: "Carregando dados...",
                 duration: 0,
             });

             this.$store
                 .dispatch("loadAmostraRespostas", {
                     idInstituicao: this.$route.params.idInstituicao,
                     idAmostra: idAmostra,
                 })
                 .then(response => {
                     const url = window.URL.createObjectURL(new Blob([typeof(response.data) === 'string' ? response.data : JSON.stringify(response.data)]));
                     const link = document.createElement('a');
                     link.href = url;
                     link.setAttribute('download', 'amostras.json');
                     document.body.appendChild(link);
                     link.click();
                 })
                 .catch(err => {
                     notyf.open({
                         type: "info",
                         ...(err?.status != 500 ? { background: "#40c9a2" } : {}),
                         message: err?.status != 500 ? err?.response?.data?.message?.message : "Erro ao baixar o arquivo!",
                     });
                 })
                 .finally(() => {
                     notyf.dismiss(notiLoad);
                     this.baixando = false;
                 });
         },
         setSelectedValue: function() {
             this.selectedValue = this.$refs.filtersearchname.value
         },

         sendInfosToShowCampoInfo(descricao) {
             this.dataToShowCampoInfo = {
                 descricao: descricao
             }
         },
         async copyLinkFamilia(idAmostra) {
             await this.$store.dispatch('setLinkFamilia', {
                 idInstituicao: this.$route.params.idInstituicao,
                 idAmostra: idAmostra,
             })

             if (this.$store.getters.getLinkFamilia) {
                 navigator.clipboard.writeText(this.$getters.getLinkFamilia)
                 notyf.open({
                     type: "success",
                     message: "Link copiado para a área de transferência",
                 });
             } else
                 notyf.open({
                     type: "error",
                     message: "Não foi possível gerar o link",
                 });
         },
     },
     mounted: function() {
         window.scrollTo(0, 0);
         let idTeamSelected = this.$route.params.idInstituicao

         if (typeof idTeamSelected == "undefined"){
             this.nomeInstituicao = "Nome da Instituição"
         }
         else {
             let teamsSelected
             let teamSelected

             teamsSelected = this.teams.filter(team =>
                 team.idinstituicao == idTeamSelected
             )

             teamSelected = [...teamsSelected].shift()

             this.nomeInstituicao = teamSelected.name
             this.descricao = teamSelected.descricao
         }
     }
 }
</script>

<style scoped>
 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;
 }

 .imgText {
     background-image: url("~@/assets/img/Backgrounds/laranjaIntenso.webp");
 }

 .box-lista {
     background-repeat: repeat;
     background-size: 256px;
     background-image: url("~@/assets/img/Backgrounds/laranjaMaisClaro.webp");

     border: 1px solid #958db1;
 }

 .box-lista span {
     background-image: url("~@/assets/img/Backgrounds/laranjaIntenso.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .text-info-personal {
     color: #656565;
     font-size: 1.1rem;
     text-align: justify;
     padding: 0rem;
     margin: 0 3rem 0 3rem;
 }

 .list-data {
     min-height: 90vh;
     padding-top: 2rem;
 }

 .label-filtro {
     font-weight: 600;
 }

 .filter-box {
     margin: 1rem 3rem;
 }

 .list-box {
     margin: 2rem;
 }

 .itens-box {
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .title-tree {
     margin: 1rem 3rem;
     font-weight: bold;
     display: flex;
 }

 .title-tree h1 {
     font-size: 1.2rem;
     font-weight: 700;
 }

 .title-style{
     margin: 0 0 0 3rem;
 }

 .separator {
     border: none;
     height: 1px;
     margin: 0.8rem 3rem;
     background: url("~@/assets/img/Backgrounds/laranja.webp");
 }

 a {
     color: #212529;
     text-decoration: none;
     transition: all ease-in-out 0.2s
 }

 a:hover {
     color: #4d4f50;
 }

 @media screen and (max-width: 1012px) {
     .filter-box {
         display: flex;
         align-items: center;
         justify-content: center;
     }
 }

 @media screen and (max-width: 680px) {
     .itens-box{
         flex-direction: column;
     }
 }

 :deep(.box-lista span) {
     font-size: 1.1em;
 }

 .list-item-selected-orange {
     background-image: url("~@/assets/img/Backgrounds/laranjaClaroEAPI.webp");
     border: 1.8px solid #FFAC30;
     transition: all ease-in-out 0.3s;
 }

 .second-info {
     width: 20%;
     text-align: center;
 }

 .info-hide {
     opacity: 0;
     pointer-events: none;
 }

 .box-img {
     flex-basis: 5%;
 }

 .img-info {
     width: auto;
     max-height: 72px;
     margin: 0.5rem;
 }

 @media screen and (min-width: 901px) {
     .first-info {
         flex-basis: 35%;
         text-align: left;
     }
     .first-info-short {
         flex-basis: 35%;
         text-align: left;
     }
 }

 @media screen and (max-width: 680px) {
     .first-info {
         text-align: center;
         width: -webkit-fill-available;
     }
     .first-info-short {
         text-align: center;
         width: -webkit-fill-available;
     }
     .second-info, .third-info{
         width: 100%;
         text-align: center;
         margin-top: .5rem;
     }
     .info-hide{
         display: none;
     }
     .box-lista{
         place-content: center;
     }
 }

 .slide-fade-enter-active {
     transition: all .2s ease;
 }
 .slide-fade-leave-active {
     transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
 }
 .slide-fade-enter, .slide-fade-leave-to {
     transform: translateX(-40px);
     opacity: 0;
 }

 .fa-info-circle {
     color: #FFAC30;
     font-size: 1.1rem;
     cursor: pointer;
 }

</style>
