<template>
    <form v-if="loaded" class="container offset" @submit.prevent="updateCategorias">
        <div class="form-group" v-for="(categoria, idx) in categorias" :key="categoria.idTipo">
            <label class="question-style">Seção {{ idx + 1 }}: *</label>
            <input
                v-model="categorias[idx].nome"
                type="text"
                class="form-control"
                placeholder="Título da primeira seção..."
                required
            />
        </div>

        <Button
            section="instituicao-campo"
            value="Salvar categorias"
            :isOutline="true"
            :hasIcon="true"
            iconName="save"
        />
    </form>
</template>

<script type="text/javascript">
 import Button from '@components/Button'
 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: "left",
         y: "bottom",
     },
 });

 export default {
     name: "UpdateCategoriesPraticas",
     components: {
         Button,
     },
     data() {
         return {
             categorias: [],
             loaded: false,
         };
     },
     async created() {
         await this.$store.dispatch('setPraticaCategories');
         this.categorias = [ ...this.$store.getters.getPraticaCategories ];
         this.categorias.sort((a, b) => a.codigo - b.codigo);
         this.loaded = true;
     },
     methods: {
         async updateCategorias() {
             let success = true;
             for (const categoria of this.categorias)  {
                 await this.$store.dispatch('updatePraticaCategory', {
                     idCategoria: categoria?.idTipo,
                     nome: categoria?.nome,
                 });
                 if (!this.$store.getters.getPraticaCategoryUpdated)
                     notyf.open({
                         type: "error",
                         message: `Erro na atualização da categoria ${categoria?.nome}`,
                     });
                 success &= this.$store.getters.getPraticaCategoryUpdated;
             }

             if (success) {
                 notyf.open({
                     type: "success",
                     message: `Categorias atualizadas com sucesso`,
                 });
                 this.$store.dispatch('loadListBoasPraticas');
                 this.$store.dispatch('setPraticaCategories');
             }
         },
     },
     computed: {
     },
 };
</script>

<style scoped>
 button {
     float: right;
 }
</style>
