<template>
    <form @submit.prevent="upsertCard" class="container offset">
        <div class="form-group">
            <label for="title" class="question-style pt-1">Título: *</label>
            <input
                v-model.trim="titulo"
                type="text"
                class="form-control"
                placeholder="Insira aqui o título do card"
                required />
        </div>

        <div class="form-group">
            <label class="question-style pt-1">Corpo: *</label>
            <MarkdownEditor :text="corpoTexto" @update="updateContent" />
        </div>

        <div class="form-group">
            <label class="question-style">Imagem:</label>
            <ImageInput :img="imagem" ref="imgInput" />
        </div>

        <div class="form-group">
            <label class="question-style pt-1">Botões:</label>

            <div
                v-for="(button, idx) in botoes"
                :key="idx"
                class="cardButton">
                <input
                    style="grid-area: text;"
                    v-model.trim="botoes[idx].texto"
                    type="text"
                    class="form-control"
                    placeholder="Texto do botão"
                    required />
                <input
                    style="grid-area: link;"
                    v-model.trim="botoes[idx].link"
                    type="url"
                    class="form-control"
                    placeholder="Link do botão"
                    required />
                <i
                    style="grid-area: trash;"
                    @click="removeButton(idx)"
                    class="fas fa-trash-alt icon"></i>
            </div>

            <Button
                type="button"
                @click="addButton"
                class="addButton"
                section="dados-outline"
                value="Adicionar botão"
                :isOutline="true"
                :hasIcon="true"
                iconName="plus"
            />
        </div>

        <div class="button-div pt-4">
            <Button
                section="dados-outline"
                :value="data.idCard ? 'Atualizar card' : 'Criar card'"
                :isOutline="true"
                :hasIcon="true"
                iconName="save" />
        </div>
    </form>
</template>

<script type="text/javascript">
 import Button from '@components/Button';
 import ImageInput from '@components/ImageInput';
 import MarkdownEditor from '@components/MateriaiseDocumentos/MarkdownEditor';
 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: 'left',
         y: 'bottom',
     }
 });

 export default {
     name: "EditCard",
     components: {
         Button,
         MarkdownEditor,
         ImageInput,
     },
     props: {
         data: {
             type: Object,
             required: true,
         },
     },
     watch: {
         data(updated) {
             const foundCard = this.cards.find(card => card.idCard == updated?.idCard);
             this.idTipo = foundCard?.idTipo || updated.idTipo;
             this.titulo = foundCard?.titulo || "";
             this.corpoTexto = foundCard?.corpoTexto || "";
             this.imagem = foundCard?.imagem ? foundCard?.imagem[0] : null;
             this.ordem = foundCard?.ordem || updated.ordem;
             this.botoes = foundCard?.botao || [];
         },
     },
     created() {
         this.$store.dispatch("setListCards");
     },
     data() {
         return {
             idTipo: null,
             titulo: "",
             corpoTexto: "",
             imagem: null,
             ordem: null,
             botoes: [],
         };
     },
     methods: {
         updateContent(updated) {
             this.corpoTexto = updated;
         },
         removeButton(idx) {
             this.botoes?.splice(idx, 1);
         },
         addButton() {
             this.botoes.push({
                 texto: "",
                 link: "",
             });
         },
         async updateCard() {
             if (this.botoes != [])
                 await this.$store.dispatch(
                     "deleteCardItem", {
                         idCard: this.data.idCard,
                         data: {
                             deleteBotoes: true,
                         }
                     }
                 );


             await this.$store.dispatch(
                 "updateCard", {
                     idCard: this.data.idCard,
                     idTipo: this.idTipo,
                     titulo: this.titulo,
                     corpoTexto: this.corpoTexto.replace(/[\t\n\r]/gm, ''),
                     ...( this.$refs.imgInput.getAltered() && { imagem: this.$refs.imgInput.getFile() || "" }),
                     botoes: this.botoes,
                 }
             );

             if (this.$store.getters.getCardUpdated) {
                 notyf.open({
                     type: "success",
                     message: "Card editado com sucesso",
                 });
                 this.$store.dispatch("setListCards");
                 this.$emit('closeModal');
             } else {
                 notyf.open({
                     type: "error",
                     message: "Não foi possível editar o card",
                 });
             }
         },
         async insertCard() {
             await this.$store.dispatch(
                 "insertCard", {
                     idCard: this.data.idCard,
                     idTipo: this.idTipo,
                     titulo: this.titulo,
                     ordem: this.ordem,
                     corpoTexto: this.corpoTexto.replace(/[\t\n\r]/gm, ''),
                     imagem: this.$refs.imgInput.getFile() || null,
                     botoes: this.botoes,
                 }
             );

             if (this.$store.getters.getCardInserted) {
                 notyf.open({
                     type: "success",
                     message: "Card criado com sucesso",
                 });
                 this.$store.dispatch("setListCards");
                 this.$emit('closeModal');
             } else {
                 notyf.open({
                     type: "error",
                     message: "Não foi possível criar o card",
                 });
             }
         },
         async upsertCard() {
             if (this.data.idCard) this.updateCard();
             else this.insertCard();
         },
     },
     computed: {
         cards() { return this.$store.getters.getListCards; },
     },
 };
</script>

<style type="text/css" media="screen" scoped>
 .addButton {
     display: block;
     margin: 0 auto;
 }

 .cardButton {
     width: 100%;
     display: grid;
     row-gap: 5px;
     column-gap: 5px;
     grid-template-columns: 1fr min-content;
     grid-template-rows: auto;
     grid-template-areas: 'text trash'
     'link trash';
     align-items: center;
     margin-bottom: 1em;
 }

 .cardButton > i {
     cursor: pointer;
 }

 .icon {
     transition: 0.4s;
 }

 .icon:hover {
     transform: scale(1.1);
     transform-origin: center center;
 }
</style>
