<template>
    <div class="container offset">
        <form @submit.prevent>
        <div class="custom-form ">  
            <div class="form-group">
                <label class="question-style pt-1">Nome:</label>
                <input
                    v-model="userInfo.nome"
                    type="name"
                    class="form-control input-personal"
                    required
                />
            </div>

            <div class="form-group">
                <label class="question-style pt-1">Email:</label>
                <input
                    v-model="userInfo.email"
                    type="name"
                    class="form-control input-personal disabled-style"
                    required
                />
            </div>
            <label>Gênero</label>
            <div class="form-group">
                    <div class="form-inline">
                    <div class="form-check mr-3">
                        <input v-model="userInfo.genero" class="form-check-input" type="radio" name="genero" id="masculino_profile" value="MASCULINO" required>
                        <label class="form-check-label">Masculino</label>    
                    </div>

                    <div class="form-check mr-3">
                        <input v-model="userInfo.genero" class="form-check-input" type="radio" name="genero" id="feminino_profile" value="FEMININO">
                        <label class="form-check-label">Feminino</label>
                    </div>

                    <div class="form-check">
                        <input v-model="userInfo.genero" class="form-check-input" type="radio" name="genero" id="outro_profile" value="OUTRO">
                        <label class="form-check-label">Outro</label>
                    </div>
            </div>
            </div>
            <div class="form-group">
                <label class="question-style pt-1">Data de Nascimento:</label>
                <input
                    v-model="userInfo.dataNascimento"
                    type="date"
                    class="form-control input-personal"
                    required
                />
            </div>
            <div class="form-group">
                <label class="question-style pt-1">Profissão:</label>
                <input
                    v-model="userInfo.profissao"
                    type="name"
                    class="form-control input-personal"
                    required
                />
            </div>
            <div class="form-group">
                <label class="question-style pt-1">Organização:</label>
                <input
                    v-model="userInfo.organizacao"
                    type="name"
                    class="form-control input-personal"
                    required
                />
            </div>
            <div class="form-group">
                <label class="question-style pt-1">Atuação:</label>
                <input
                    v-model="userInfo.atuacao"
                    type="name"
                    class="form-control input-personal"
                    required
                />
            </div>

            <div class="button-div d-flex flex-row-reverse">
                <Button 
                    @click="updateUser"
                    section="salvar"
                    value="Salvar"
                    :isOutline="true"
                    :hasIcon="true"
                    iconName="save"
                /> 
                <Button 
                    @click="close"
                    section="descartar"
                    value="Descartar"
                    :isOutline="true"
                    :hasIcon="true"
                    iconName="times"
                /> 
                
            </div>
        </div>
        </form>
    </div>
</template>

<script>

import Button from '@components/Button'
import Swal from "sweetalert2";

export default {
    name: "Profile",

    components: {
        Button
    },

    data (){
        return {
            userInfo: {}
        }
    },
    mounted() {
        this.emitter.on('loadDataProfile', () => {
            this.loadData()
        });
    },
    methods: {
        async loadData(){
            await this.$store.dispatch('setUserInfo')
            try {
                this.userInfo = this.$store.getters.getUserInfo.data
            } catch(e) {
                this.userInfo = {}
            }
        },
        async updateUser() {
            await this.$store.dispatch('setUpdateUser', this.userInfo)
            await this.$store.dispatch('setUserInfo')
            Swal.fire(
                'Informações Atualizadas!',
                'As informações da suas conta foram atualizadas',
                'success'
            )      
            this.$emit('closeModal')
        },
        close() {
            this.$emit('closeModal')
        }
    },
}

</script>

<style scoped>

    .container{
        width: 100%;
        background-color: white;
    }

    .form-group{
        display: flex;
    }

    .title-style{
        font-size: 1.5rem;
        font-weight: 600;
        color: #656565;        
    }

    .question-style{
        width: 100%;
        font-size: 1rem;
        color: #656565;
    }

    .image-style{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        padding: 1rem;
    }

    .button-div{
        justify-content: space-between;
    }

    .input-personal {
        padding: 0.2rem 0.4rem;
        font-size: 15px;
        font-weight: 400;
        border-radius: 8px;
        background: #FCFCFC;
        outline: none;
        border: 1px solid rgba(0, 0, 0, 0.11);
        font-weight: lighter;
        padding: 0.2rem 0.4rem;
        box-shadow: 1px 2px 6px -3px rgb(0 0 0 / 25%);
    }

        .disabled-style{
        background-color: #D0D0D0 !important;
        cursor: default;
        pointer-events: none;
    }

</style>
