<!--
|---------------------------------------------
| ColorfulCard Component 
|---------------------------------------------
| @usage Import the component and use <ColorfulCard crumb='Array()' section='Section Name'/>
| 
-->

<template>
    <div class='colorful-card-container grow'>

        <img :src="require('@assets/img/Dados/BaixarDadosNacionais/' + thumbnail)" class='image-style'>

		<h1 class='title-style'>{{title}}</h1>
		    		    
	</div>
</template>

<script>

    export default ({
        name: 'ColorfulCard',
        props: {
            thumbnail: String,
            title: String,
        }
    })
    
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";

    .colorful-card-container {
        height: 12rem;  
        width: 14rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: $verdeEscuro-eapi;
        border-radius: 0.6rem;
        margin: 1rem 1rem 1rem 1rem;
        cursor: pointer;
        transition: 0.3s;
        -webkit-box-shadow: 4px 8px 12px -3px rgb(0 0 0 / 25%);
        -moz-box-shadow: 4px 8px 12px -3px rgb(0 0 0 / 25%);
        box-shadow: 4px 8px 12px -3px rgb(0 0 0 / 25%);
    }

    .title-style{
       text-align: center;
       font-size: 1.07rem;
       color: #fafafa;
       padding: 1rem 0rem 0rem 0rem;
    }

    .image-style{
       height: 6.5rem;
       width: 5.5rem;
       padding: 1rem 0rem 0rem 0rem; 
       filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.3));
    }

</style>
