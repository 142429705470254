<!--
|---------------------------------------------
| Section Component 
|---------------------------------------------
| @usage Import the component and use, no external params
| 
|   Receive info from the parameters below, the infos are: 
|	title: Section Title,
    text: Section Text,
    imageName: Section Image,
    hasButton: Defines if the section has a button,
    section: Defines the general colors of the Section,
    buttonIcon: Defines the icon that will be shown inside the button,
    buttonText: Defines the value that will be shown inside the button,
    direction: Defines the position of the button (on the left or right side of the Section).
-->

<template>
    <div class='container-fluid'>
	    <div :class="'section-component row '+directionSection+' '+sectionSection">
		<div class='col-md-6'>
		    <img class="img-info-section img-fluid" :src="require('@/assets/img/'+imageNameSection)" alt="">
		</div>
		<div class="infos col-md-6">
		    <h1>{{titleSection}}</h1>
		    <p>{{textSection}}</p>
		    <div v-if="hasButtonSection" class="button">
                <Button
                    :value="buttonTextSection"
                    :iconName="buttonIconSection"
                    :section="sectionSection+'-outline'"
                    :isOutline="true"
                    :hasIcon="true"
                    :hasShadow="true"
                />
            </div>
		</div>
	    </div>
	</div>
</template> 

<script>
import Button from '@components/Button'
    
export default {
    name: 'SectionElement',
    data () {
        return {
            titleSection: this.title,
            textSection: this.text,
            imageNameSection: this.imageName,
            hasButtonSection: this.hasButton,
            sectionSection: this.section,
            directionSection: this.direction,
            buttonTextSection: this.buttonText,
            buttonIconSection: this.buttonIcon
        }
    },
    props: {
        title: String,
        text: String,
        imageName: String,
        hasButton: Boolean,
        section: String,
        buttonIcon: String,
        buttonText: String,
        direction: String
    },
    components: {
        Button
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";
/*
 |-----------------------------------------------------
 |--ESTILIZANDO OS ELEMENTOS BÁSICOS DO COMPONENTE
 |-----------------------------------------------------
 */

.section-component {
    align-items: center;
    padding: 2rem 5rem;
    background-color: transparent;
}

.infos {
    font-size: 0.9rem;
    text-align: justify;
} 

.infos h1 {
    font-size: 1.6rem;
    font-weight: bold;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.infos p {
    margin-bottom: 2rem;
}

.info-section img {
    border-radius: 0.6rem;
    -webkit-box-shadow: 10px 10px 34px -22px rgba(0,0,0,0.50);
    -moz-box-shadow: 10px 10px 34px -22px rgba(0,0,0,0.50);
    box-shadow: 10px 10px 34px -22px rgba(0,0,0,0.50);
    transition: transform ease-in-out 0.4s;
}

.info-section img:hover {
    transform: scale(1.02);
}

.section-component  p {
    color: #656565;
    font-size: 1.2rem;
}

/*-----------------------------------------------------*/

/*
 |-----------------------------------------------------
 |--PADRONIZAÇÃO DE CORES   
 |-----------------------------------------------------
 */

.boas-praticas h1 {
    color: #41B3A3;
}

.materiais-documentos h1 {
    color: #EB7071;
}

.certificacao h1 {
    color: #F498C0;
}

.dados h1 {
    color: $verdeEscuro-eapi;
}

/*-----------------------------------------------------*/

/*
 |-----------------------------------------------------
 |--REUTILIZANDO PARAMETROS PARA ESPECIFICACOES
 |-----------------------------------------------------
 */


.default {
    flex-direction: row;
}

.reverse {
    flex-direction: row-reverse;
}

.default .button {
    text-align: right;
}

.reverse .button {
    text-align: left;
}

@media only screen and (min-width: 800px){
    .default img {
	    margin-right: 5rem;
    }

    .reverse img {
	    margin-left: 5rem;   
    }
}

@media screen and (max-width: 728px) {
    .info-section {
	    padding: 1.2rem;
    }
    .section-component{
        padding: 2rem 0;
    }
    .section-component > div{
        margin-bottom: 1rem;
    }
  }

</style>
