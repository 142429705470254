//---------------------------------------------
// Amostras Module 
//---------------------------------------------
// Módulo para gerenciar as ações da amostra

// import axios from 'axios';
// axios.defaults.baseURL = process.env.VUE_APP_API_ROOT ? process.env.VUE_APP_API_ROOT : 'http://localhost:3000'
import axios from '@middleware/api.js'
import Cookies from 'js-cookie'
// var qs = require('qs');
var JWT = () => {
    return (Cookies.get("accessToken") ? Cookies.get("accessToken") : "JWT VAZIO")
}

// STATES
const state = () => ({
    listCampos: [],
    listCamposGrupo: [],
    listCampoMembros: [],
    listCampoFormularios: [],
    listCampoUEs: [],
    listCampoTurmas: [],
    amostraStatusAtiva: false,
    linkFamilia: null,
    amostraCreated: null,
    amostraUpdated: null,

    //ESTADOS PARA ARMAZENAR OS ARRAYS DE PAYLOAD DAS REQUISICOES MANAGE
    payloadManageAplicadores: [],
    payloadManageFormularios: [],
    payloadManageUnidades: [],
    payloadManageTurmas: [],
    isCampoInit: null,
    campoReqMsg: null,
})

// MUTATIONS
const mutations = {
    //-- MUTATION REQUISICAO DO LISTCAMPOS/AMOSTRAS
    setListCampos(state, payload) {
        state.listCampos = payload
    },

    //-- MUTATION REQUISICAO DO LISTCAMPOS/AMOSTRAS (GRUPO)
    setListCamposGrupo(state, payload) {
        state.listCamposGrupo = payload
    },

    //-- MUTATION REQUISICAO DO LISTCAMPOMEMBROS/APLICADORES
    setListCampoMembros(state, payload) {
        state.listCampoMembros = payload
    },

    //-- MUTATION REQUISICAO DO LISTCAMPOFORMULARIOS
    setListCampoFormularios(state, payload) {
        state.listCampoFormularios = payload
    },

    //-- MUTATION REQUISICAO DO LISTCAMPOUNIDADESEDUCACIONAIS
    setListCampoUEs(state, payload) {
        state.listCampoUEs = payload
    },

    //-- MUTATION REQUISICAO DO LISTCAMPOUNIDADESEDUCACIONAIS
    setListCampoTurmas(state, payload) {
        state.listCampoTurmas = payload
    },

    //-- MUTATION ESTADO STATUS AMOSTRA
    setAmostraStatusAtiva(state, payload) {
        state.amostraStatusAtiva = payload
    },

    //-- MUTATION ESTADO DE PAYLOAD MANAGE APLICADORES
    setPayloadManageAplicadores(state, payload) {
        state.payloadManageAplicadores = payload
    },

    //-- MUTATION ESTADO DE PAYLOAD MANAGE FORMULARIOS
    setPayloadManageFormularios(state, payload) {
        state.payloadManageFormularios = payload
    },

    //-- MUTATION ESTADO DE PAYLOAD MANAGE UNIDADES
    setPayloadManageUnidades(state, payload) {
        state.payloadManageUnidades = payload
    },

    //-- MUTATION ESTADO DE PAYLOAD MANAGE TURMAS
    setPayloadManageTurmas(state, payload) {
        state.payloadManageTurmas = payload
    },

    setLinkFamilia(state, payload) {
        state.linkFamilia = payload
    },

    setAmostraCreated(state, payload) {
        state.amostraCreated = payload;
    },

    setAmostraUpdated(state, payload) {
        state.amostraUpdated = payload;
    },

    setIsCampoInit(state, init) {
        state.isCampoInit = init;
    },

    setCampoReqMsg(state, msg) {
        state.campoReqMsg = msg;
    },
}

// ACTIONS
const actions = {
    //-- ACTION REQUISICAO CRIAR AMOSTRA/CAMPO
    async setCreateAmostra({ commit }, payload)  {
        commit('setAmostraCreated', null);

        await axios
            .post(
                `amostras/${payload.idInstituicao}`,
                payload, {
                    headers: {
                        'Authorization': `Bearer ${JWT()}`
                    }
                })
            .then(() => commit('setAmostraCreated', true))
            .catch((error) => { commit('setAmostraCreated', false); console.log(error.message); })
    },

    //-- ACTION REQUISICAO ATUALIZAR AMOSTRA/CAMPO
    async setUpdateAmostra({ commit }, payload)  {
        commit('setAmostraUpdated', null);

        let formData = new FormData();
        formData.append("titulo", payload?.titulo);
        formData.append("ano", payload?.ano);
        formData.append("descricao", payload?.descricao);
        formData.append("pdf", payload?.pdf);

        await axios
            .put(
                `amostras/${payload.idInstituicao}/${payload.idAmostra}`,
                formData, {
                    headers: {
                        'Authorization': `Bearer ${JWT()}`
                    }
                })
            .then(() => commit('setAmostraUpdated', true))
            .catch((error) => { commit('setAmostraUpdated', false); console.log(error.message); })
    },

    async loadPdfDownload(_, payload) {
        return axios
            .get(
                `amostras/getDevolutiva/${payload.idInstituicao}/${payload.idamostra}/?path=${payload.devolutiva}`, {
                    responseType: 'arraybuffer',
                    headers: {
                        'Authorization': `Bearer ${JWT()}`
                    }
                }
            );
    },

    //-- ACTION DELETE AMOSTRA
    async setDeleteAmostra(_, payload) {
        await axios
            .delete(`amostras/${payload.idInstituicao}/${payload.idAmostra}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                console.log(response)
            })
            .catch((error) => console.log(error.message))
    },

    //-- ACTION REQUISICAO DO LISTCAMPOS (AMOSTRAS)
    async setListCampos({ commit }, idInstituicao) {
        await axios
            .get(`amostras/${idInstituicao}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListCampos', response.data)
            })
            .catch((error) => console.log(error.message))
    },

    //-- ACTION REQUISICAO DO LISTCAMPOS DE GRUPO (AMOSTRAS)
    async setListCamposGrupo({ commit }, payload) {
        await axios
            .get(`amostras/${payload?.idInstituicao}/${payload?.idGrupo}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListCamposGrupo', response.data)
            })
            .catch((error) => console.log(error.message))

    },

    async setEncerraCampo( _, payload)  {
        await axios
            .put(`amostras/${payload.idInstituicao}/${payload.idAmostra}`, {
                estaAtiva: "OFF"
            },
                {headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .catch((error) => console.log(error.message))
    },

    async setIniciaCampo({ commit }, payload)  {
        commit("setIsCampoInit", null);
        commit("setCampoReqMsg", null);
        await axios
            .put(`amostras/${payload.idInstituicao}/${payload.idAmostra}`, {
                estaAtiva: "ON"
            },
                {headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
                })
            .then(() => commit("setIsCampoInit", true))
            .catch((error) => {
                commit("setIsCampoInit", false);
                if (error.response.status == 401)
                    commit("setCampoReqMsg", error.response.data.message.message);
            });
    },

    //-- ACTION DELETE CAMPO/AMOSTRA
    async deleteCampo(_, payload) {
        await axios
            .delete(`amostras/${payload.idInstituicao}/${payload.idAmostra}`, {

            },{
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                console.log(response)
            })
            .catch((error) => console.log(error.message))
    },


    //-- ACTION REQUISICAO DO LISTCAMPOMEMBROS (APLICADORES)
    async setListCampoMembros({ commit }, payload) {
        await axios
            .get(`amostras/aplicadores/${payload.idInstituicao}/${payload.idAmostra}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListCampoMembros', response.data)
            })
            .catch((error) => console.log(error.message))

    },

    //-- ACTION REQUISICAO DO LISTCAMPOFORMULARIOS
    async setListCampoFormularios({ commit }, payload) {
        await axios
            .get(`amostras/formularios/${payload.idInstituicao}/${payload.idAmostra}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListCampoFormularios', response.data)
            })
            .catch((error) => console.log(error.message))

    },

    //-- ACTION REQUISICAO DO LISTCAMPOUNIDADESEDUCACIONAIS
    async setListCampoUEs({ commit }, payload) {
        await axios
            .get(`amostras/unidades/${payload.idInstituicao}/${payload.idAmostra}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListCampoUEs', response.data)
            })
            .catch((error) => console.log(error.message))

    },

    //-- ACTION REQUISICAO DO LISTCAMPOTURMAS
    async setListCampoTurmas({ commit }, payload) {
        await axios
            .get(`amostras/turmas/${payload.idInstituicao}/${payload.idAmostra}/${payload.idUnidade}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListCampoTurmas', response.data)
            })
            .catch((error) => console.log(error.message))

    },

    //-- ACTION REQUISICAO DE MANAGE APLICADORES
    async setManageAplicadores(_, payload) {
        await axios
            .post(`amostras/manageAplicadores/${payload.idInstituicao}/${payload.idAmostra}`, {
                    ids: payload.ids
            },{
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                console.log(response)
            })
            .catch((error) => console.log(error.message))

    },

    //-- ACTION REQUISICAO DE MANAGE FORMULARIOS
    async setManageFormularios(_, payload) {
        await axios
            .post(`amostras/manageFormularios/${payload.idInstituicao}/${payload.idAmostra}`, {
                    ids: payload.ids
            },{
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                console.log(response)
            })
            .catch((error) => console.log(error.message))

    },

    //-- ACTION REQUISICAO DE MANAGE UNIDADES
    async setManageUnidades(_, payload) {
        await axios
            .post(`amostras/manageUnidades/${payload.idInstituicao}/${payload.idAmostra}`, {
                    ids: payload.ids
            },{
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                console.log(response)
            })
            .catch((error) => console.log(error.message))

    },

    //-- ACTION REQUISICAO DE ADICIONAR TODAS AS TURMAS DA UNIDADE EDUCACIONAL
    async setSelectAllTurmas(_, payload) {
        await axios
            .post(`amostras/selectAllTurmas/${payload.idInstituicao}/${payload.idAmostra}`, {
                    ids: payload.ids
            },{
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                console.log(response)
            })
            .catch((error) => console.log(error.message))

    },

    //-- ACTION REQUISICAO DE MANAGE TURMAS
    async setManageTurmas(_, payload) {
        await axios
            .post(`amostras/manageTurmas/${payload.idInstituicao}/${payload.idAmostra}/${payload.idUnidade}`, {
                    ids: payload.ids
            },{
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                console.log(response)
            })
            .catch((error) => console.log(error.message))

    },

    async setLinkFamilia({ commit }, payload) {
        commit('setLinkFamilia', null)
        await axios
            .get(`amostras/linkFamilia/${payload.idInstituicao}/${payload.idAmostra}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`,
                },
            })
            .then(response => {
                commit('setLinkFamilia', response.data)
            })
            .catch((error) => {
                console.log(error.message)
                commit('setLinkFamilia', null)
            })
    },
    async loadAmostraRespostas(_, payload) {
        return axios
            .get(`amostras/amostraRespostas/${payload.idInstituicao}/${payload.idAmostra}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`,
                },
            });
    },
}

// GETTERS
const getters = {
    getListCampos(state) {
        try {
            return state.listCampos.data
        } catch (e) {
            return []
        }
    },

    getListCamposGrupo(state) {
        try {
            return state.listCamposGrupo.data
        } catch (e) {
            return []
        }
    },

    getListCampoMembros(state) {
        try {
            return state.listCampoMembros.data
        } catch (e) {
            return []
        }
    },

    getListCampoFormularios(state) {
        try {
            return state.listCampoFormularios.data
        } catch (e) {
            return []
        }
    },

    getListCampoUEs(state) {
        try {
            return state.listCampoUEs.data
        } catch (e) {
            return []
        }
    },

    getListCampoTurmas(state) {
        try {
            return state.listCampoTurmas.data
        } catch (e) {
            return []
        }
    },

    getPayloadManageAplicadores(state) {
        return state.payloadManageAplicadores
    },

    getPayloadManageFormularios(state) {
        return state.payloadManageFormularios
    },

    getPayloadManageUnidades(state) {
        return state.payloadManageUnidades
    },

    getPayloadManageTurmas(state) {
        return state.payloadManageTurmas
    },

    getAmostraStatusAtiva(state) {
        return state.amostraStatusAtiva
    },

    getLinkFamilia(state) {
        return state.linkFamilia
    },

    getIsAmostraCreated(state) {
        return state.amostraCreated;
    },

    getIsAmostraUpdated(state) {
        return state.amostraUpdated;
    },

    getIsCampoInit(state) {
        return state.isCampoInit;
    },

    getCampoReqMsg(state) { return state.campoReqMsg; },
}

export default {
    state,
    mutations,
    actions,
    getters
}
