const path = require('path');

module.exports = {
	pluginOptions: {
		compression:{
		  modes: ['development', 'production'],
		  gzip: {
			filename: '[file].gz[query]',
			algorithm: 'gzip',
			include: /\.(js|css|html|svg|json)(\?.*)?$/i,
			minRatio: 0.8,
		  }
		}
	},
	chainWebpack: config => {
		config.plugins.delete('prefetch');
	},
	configureWebpack:{
		devServer: {
		public: 'http://localhost:8080/',
		headers: { 'Access-Control-Allow-Origin': '*' },
		watchOptions: {
			ignored: /node_modules/,
			aggregateTimeout: 300,
			poll: 1000,
		  },
		},
		module: {
			rules: [
			  {
				test: /\.(csv|xlsx)$/,
				loader: 'file-loader',
				options: {
				  name: `examples/[name].[ext]`
				}
			  }
			],
		},
		resolve: {
			alias: {
				'@source': path.resolve(__dirname, 'src/'),
				'@components': path.resolve(__dirname, 'src/components/'),
				'@views': path.resolve(__dirname, 'src/views/'),
				'@middleware': path.resolve(__dirname, 'src/middleware/'),
				'@dados': path.resolve(__dirname, 'src/components/Dados/'),
				'@assets': path.resolve(__dirname, 'src/assets/'),
				'@forms': path.resolve(__dirname, 'src/components/Forms/'),
			}
		}
	}
	// },
	// dev: {
    //     useEslint: false
    // }
}
