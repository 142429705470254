<template>
  <div>
      <div class="barraPainel">
          <button class="btn btn-sm btn-icon" title="Funções" @click="changeState(1)"><i class="fas fa-th"></i></button>
          <button class="btn btn-sm btn-icon" title="Perguntas" @click="changeState(2)"><i class="far fa-list-alt"></i></button>
          <button class="btn btn-sm btn-icon" title="Categorias" @click="changeState(3)"><i class="fas fa-clipboard-list"></i></button>
      </div>
      <div>
        <PainelLateralFuncoes v-if="state == 1" />
        <PainelLateralPerguntas v-if="state == 2" />
        <PainelLateralCategorias v-if="state == 3" />
      </div>
  </div>
</template>

<script>
import PainelLateralFuncoes from './PainelLateralFuncoes.vue'
import PainelLateralPerguntas from './PainelLateralPerguntas.vue'
import PainelLateralCategorias from './PainelLateralCategorias.vue'
export default {
  name: 'PainelLateralPrincipal',
  components: {
    PainelLateralFuncoes,
    PainelLateralPerguntas,
    PainelLateralCategorias
  },
  data(){
    return{
      state: 2,
    }
  },
  methods: {
    changeState: function(newState) {
      this.state = newState;
    }
  }
}
</script>

<style scoped>
.barraPainel{
  background: var(--cinza-20);
  padding: 1rem 1rem;
  display: flex;
  justify-content: space-between;
}

.btn-icon{
  display: flex;
  flex-wrap: wrap;
    height: 2rem;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
}

.btn-icon i{
  font-size: 1.2rem;
}

</style>