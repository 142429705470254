<template>
<div class="bg">
  <div class="list-data">
    <ModalCentral
      componentName="AddField"
      title="ADICIONAR CAMPO"
      idName="AddCampo"
      />

    <ModalCentral
      componentName="UpdateField"
      title="EDITAR CAMPO"
      idName="updateCampo"
      :data="{ 'campo': campoEdit }"
      />

    <ModalCentral
      componentName="ShowCampoInfo"
      title="DESCRIÇÃO DO CAMPO"
      idName="showCampoInformacao"
      :data="dataToShowCampoInfo"
      />

    <div class="title-tree">
      <Breadcrumb :crumbs="crumbs" section="instituicao-campo"/>
    </div>

    <InstitutionInfo class="title-style" :title="nomeInstituicao" section="instituicao-cadastrar"/>

    <p class="text-info-personal">Nesta seção, você encontrará todos os Campos relacionados à Instituição. Caso necessário, utilize o filtro abaixo para localizar o Campo desejado. Apenas um Campo pode apresentar o status "Ativo" por vez. Para acessar e/ou gerenciar os Membros, Formulários, Unidades Educacionais e Turmas que participam deste campo, clique no botão "Ver Campo" do Campo desejado.</p>

    <div class="itens-box">
      <div class="filter-box">
        <!-- INICIO DO FORM DE FILTRAGEM -->
        <form @submit.prevent="" class="form-inline">
          <div class="form-group">

            <!-- DROPDOWN DE SELECAO DE FILTRO -->
            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomSelect">Filtrar por: </label>
            <Filter ref="filtersearchname" @change="setSelectedValue()" :optionsSelect="options" nameSelect="filtro_instituicoes" id="inlineFormCustomSelect"/>
          </div>

          <!-- SE A SELECAO FOR NOME (2) ENTAO EXIBIRA OS SUBFILTROS DE NOME -->
          <div class="form-group" v-if="selectedValue == 2">
            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomName">Digite o nome: </label>
            <input class="input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_instituicoes" id="inlineFormCustomName" autocomplete="off">
          </div>

        </form>
      </div>
      <div class="lista-data-info">
        <Button
          v-if="userPerm.permissao != 'APLICADOR'"
          class="btn"
          section="instituicao-campo"
          value="Adicionar Campo"
          :isOutline="true"
          :hasIcon="true"
          iconName="chart-pie"
          data-toggle="modal"
          data-target="#AddCampo"
          />
      </div>
    </div>
    <hr class="separator">

    <!-------------------------------- LISTANDO OS CAMPOS ----------------------------------------------->

    <div v-if="isLoadedInfo">
      <MemberQnt term="amostras" :totalQnt="totalCampos" :filteredQnt="campos?.length || 0" icon="fad fa-binoculars" />

      <div class="list-box">
        <transition-group name="slide-fade" v-if="campos?.length > 0">
          <div class="box-header">
            <span></span>
            <span>Nome</span>
            <span>Status</span>
            <span>Controle</span>
          </div>

          <div class="box-lista" v-for="campo in campos" :key="campo.counter" :class="{'list-item-selected': campo.isativo == 'ON'}">
            <div class="box-img-box-lista" >
              <img
                class="img-info-box-lista"
                :class="{ 'turning-grey': campo.isativo == 'ON' }"
                src="@assets/img/Cards/Campo.svg"
                alt="Ícone de campo" />
            </div>
            <span class="first-info-short d-flex align-items-center nomeSpan">
              {{campo.titulo}}
              <i @click="sendInfosToShowCampoInfo(campo.descricao)" data-toggle="modal" data-target="#showCampoInformacao" class="fa fa-info-circle ml-2"></i>
              <i @click="campoEdit = campo" data-toggle="modal" data-target="#updateCampo" class="fa fa-pen ml-2"></i>
            </span>
            <span v-if="campo.isativo != 'ON'" class="second-info">Criado em {{campo.ano}}</span>
            <span v-else class="second-info">Campo Ativo</span>

            <div class="controlGroup">
              <Button
                v-if="campo.isativo == 'ON' && userPerm.permissao != 'APLICADOR'"
                @click="encerraCampo(campo.idamostra)"
                class="btn"
                value="Encerrar Campo"
                section="instituicao-cadastrar"
                :isOutline="true"
                />

              <Button
                v-if="campo.isativo == 'INATIVO' && userPerm.permissao != 'APLICADOR' && hasCampoAtivo() == false"
                @click="iniciaCampo(campo.idamostra)"
                class="btn"
                value="Iniciar Campo"
                section="salvar"
                :isOutline="true"
                />

              <Button
                v-if="campo.isativo == 'INATIVO' && userPerm.permissao != 'APLICADOR'"
                @click="excluirCampo(campo.idamostra)"
                class="btn"
                value="Excluir Campo"
                section="descartar"
                :isOutline="true"
                />

              <span v-if="campo.isativo == 'INATIVO' && userPerm.permissao != 'APLICADOR' && hasCampoAtivo() == true" class="third-info-non-clickable">Campo Inativo</span>

              <span v-if="campo.isativo == 'OFF'" class="third-info-non-clickable">Campo Encerrado</span>
              <router-link :to="{name: 'InstituicaoMenuCampo', params: { idCampo: campo.idamostra }}" >
                <Button
                  value="Ver Campo"
                  class="btn"
                  section="instituicao-campo"
                  :isOutline="true"
                  />
              </router-link>
            </div>
          </div>
        </transition-group>
        <transition-group v-else>
          <h2 class="title-style-box-lista">Nenhum campo encontrado.</h2>
        </transition-group>
      </div>

    </div>

    <div v-else>
      <SkeletonList/>
    </div>
  </div>
</div>
</template>

<script>
import Filter from '@components/Filter'
import Button from '@components/Button'
import ModalCentral from '@components/ModalCentral'
import Breadcrumb from '@components/Breadcrumb'
import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
import Swal from 'sweetalert2';
import SkeletonList from '@components/SkeletonUI/SkeletonList'
import MemberQnt from '@dados/VisualizarInstituicao/MemberQnt'
import { Notyf } from "notyf";
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: "left",
    y: "bottom",
  }
});

export default {
  name: 'ListarFormularios',
  data (){
    return {
      campoEdit: {},
      dataToShowCampoInfo: {},
      nomeInstituicao: '',
      descricao: '',
      selectedValue: '',
      searchByName: '',
      searchByCode: '',
      searchWithOrder: '',
      isLoadedInfo: false,
      options: [
        {
          id:1,
          value: 'Todos'
        },
        {
          id:2,
          value: 'Nome'
        }
      ],
      crumbs: [
        {
          id:1,
          value: '',
          iconName: 'th',
          name_route: 'GerenciarInstituicoes'
        },
        {
          id:2,
          value: 'Instituição',
          iconName: 'university',
          name_route: 'InstituicaoMenu'
        },
        {
          id:3,
          value: 'Campos',
          iconName: 'th-large',
          name_route: ''
        }
      ]
    }
  },
  async created() {
    this.teams = this.$store.getters.getListTeams

    window.scrollTo(0, 0);
    const payload = {
      idInstituicao: this.$route.params.idInstituicao
    }
    await this.$store.dispatch('setUserPerm', payload)

    this.isLoadedInfo = false
    await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)
    this.isLoadedInfo = true
  },
  computed: {
    userPerm() {
      return this.$store.getters.getUserPerm
    },
    totalCampos() {
      return this.$store.getters?.getListCampos.length || 0;
    },
    campos() {
      var campos = this.$store.getters.getListCampos
      // VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
      var query = campos;
      //CASO SEJA SELECIONADO TODOS, EXIBE TODOS OS ITENS DO ARRAY
      if (this.selectedValue == 1) {
        query = campos;
      }
      //CASO SEJA SELECIONADO NOME, FILTRA POR NOME
      if (this.selectedValue == 2) {
        //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
        if (this.searchByName.trim() == '') {
          query = campos;
        }
        //SENAO ELE FILTRA PELO O QUE FOR DIGITADO
        else {
          var filtered = campos?.filter(campo =>campo.titulo.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
          if(filtered?.length != 0){
            query = filtered
          }

          else{
            query = false
          }
        }
      }
      return query;
    }
  },
  components: {
    Filter,
    Button,
    ModalCentral,
    Breadcrumb,
    InstitutionInfo,
    SkeletonList,
    MemberQnt,
  },
  methods: {
    setSelectedValue: function() {
      this.selectedValue = this.$refs.filtersearchname.value
    },

    sendInfosToShowCampoInfo: function(descricao) {
      this.dataToShowCampoInfo = {
        descricao: descricao
      }
    },
    hasCampoAtivo() {
      var hasAtivo = false
      this.campos.forEach((value) => {
        if (value.isativo == 'ON') {
          hasAtivo =  true
        }
      })
      return hasAtivo
    },
    async encerraCampo(idAmostra){
      const result = await Swal.fire({
        title: 'Deseja mesmo encerrar o campo?',
        text: 'Uma vez encerrado, ele não poderá ser reaberto.',
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Encerrar Campo",
      })

      if (result.isConfirmed) {
        const payload = {
          idInstituicao: this.$route.params.idInstituicao,
          idAmostra: idAmostra
        }
        await this.$store.dispatch('setEncerraCampo', payload)
        await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)
      }
    },

    async iniciaCampo(idAmostra){
      const result = await Swal.fire({
        title: 'Deseja mesmo inicar o campo?',
        text: 'Uma vez inicado, você não poderá remover nenhuma entidade (membro, formulário, unidades e turmas) do campo.',
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Iniciar Campo",
      })

      if (!result.isConfirmed) return;

      const payload = {
        idInstituicao: this.$route.params.idInstituicao,
        idAmostra: idAmostra
      }
      await this.$store.dispatch('setIniciaCampo', payload);
      if (!this.$store.getters.getIsCampoInit) {
        notyf.open({
          type: "error",
          message: this.$store.getters.getCampoReqMsg ?? "Erro ao iniciar campo",
        });
        return;
      }

      notyf.open({
        type: "success",
        message: "Campo iniciado com sucesso",
      });

      await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)
    },

    async excluirCampo(idAmostra){
      const result = await Swal.fire({
        title: 'Deseja mesmo excluir o campo?',
        text: 'Ao excluir, você não terá mais acesso às entidades (membro, formulário, unidades e turmas) do campo.',
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Excluir Campo",
      })

      if (result.isConfirmed) {
        const payload = {
          idInstituicao: this.$route.params.idInstituicao,
          idAmostra: idAmostra
        }
        await this.$store.dispatch('setDeleteAmostra', payload)
        await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)
      }
    },
  },
  mounted: function() {
    window.scrollTo(0, 0);
    let idTeamSelected = this.$route.params.idInstituicao

    if (typeof idTeamSelected == "undefined"){
             this.nomeInstituicao = "Nome da Instituição"
         }
         else {
             let teamsSelected
             let teamSelected

             teamsSelected = this.teams.filter(team =>
                 team.idinstituicao == idTeamSelected
             )

             teamSelected = [...teamsSelected].shift()

             this.nomeInstituicao = teamSelected.name
             this.descricao = teamSelected.descricao
         }
     }
 }
</script>

<style scoped>
.bg {
    background-image: url("~@/assets/img/Backgrounds/branco.webp");
    background-repeat: repeat;
    background-size: 256px;
    overflow: auto;
    box-sizing: border-box;
}

.imgText {
    background-image: url("~@/assets/img/Backgrounds/roxoEapi.webp");
    background-clip: text;
    text-fill-color: transparent;
    background-repeat: repeat;
    background-size: 256px;
    color: transparent !important;
}

.box-lista {
    background-repeat: repeat;
    background-size: 256px;
    background-image: url("~@/assets/img/Backgrounds/roxoMaisClaro.webp");

    border: 1px solid #958db1;
}

:deep(.box-lista span) {
    font-size: 1.1em;
}

.box-lista span {
    color: #7647ac;
}

.text-info-personal {
    color: #7647ac;
    font-size: 1.1rem;
    text-align: justify;
    padding: 0rem;
    margin: 0 3rem 0 3rem;
}

.list-data {
    min-height: 90vh;
    padding-top: 2rem;
}
.label-filtro {
    font-weight: 600;
}

.filter-box {
    margin: 1rem 3rem;

}

.list-box {
    margin: 2rem;
}

.itens-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-tree {
    margin: 1rem 3rem;
    font-weight: bold;
    display: flex;
}

.title-style{
    margin: 0 0 0 3rem;
}

.lista-data-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 2rem 3rem;
}

.title-tree h1 {
    font-size: 1.2rem;
    font-weight: 700;
}

.main-link {
    outline:none;
    text-decoration: none;
    cursor: pointer;
    margin-right: .3rem;
}

.sub-link {
    margin-left: .3rem;
}

.separator {
    border: none;
    height: 1px;
    margin: 0.8rem 3rem;
    background: url("~@/assets/img/Backgrounds/roxoEapi.webp");
}

a {
    color: #212529;
    text-decoration: none;
    transition: all ease-in-out 0.2s
}

a:hover {
    color: #4d4f50;
}

@media screen and (max-width: 1010px) {
    .lista-data-info {
        justify-content: center;
    }
}


@media screen and (max-width: 1012px) {
    .filter-box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 680px) {
    .itens-box{
        flex-direction: column;
        align-items: flex-start;
    }

    .filter-box{
        margin: 0 3rem;
    }

    .lista-data-info{
        margin: 0 3rem;
    }
}


.turning-grey{
    filter: grayscale(100%);
}

.list-item-selected {
    background-color: #e4ccfe;
    border: 1.8px solid #7647ac;
}

.second-info {
    width: 20%;
    text-align: center;
}

.third-info {
    color: #5c2597;
    cursor: pointer;
    user-select: none;
    font-weight: 500;
    transition: all ease 0.2s;
}

.third-info:hover {
    color: #341258;
}

.third-info-non-clickable {
    color: #212529;
    cursor: default;
    user-select: none;
    font-weight: normal;
    transition: all ease 0.2s;
}

.last-info {
    color: #EB3E3E;
    cursor: pointer;
    user-select: none;
    transition: all ease 0.3s;
    font-weight: 500;
    text-align: right;
}

.last-info:hover {
    color: #8f1c1c;
}

.info-hide {
    opacity: 0;
    pointer-events: none;
}

.box-img {
    flex-basis: 5%;
}

.img-info {
    width: auto;
    max-height: 72px;
    margin: 0.2rem;
}

@media screen and (min-width: 901px) {
    .first-info {
        /* flex-basis: 35%; */
        text-align: left;
    }
    .first-info-short {
        /* flex-basis: 35%; */
        text-align: left;
    }
}

@media screen and (max-width: 680px) {
    .first-info {
        text-align: center;
        width: -webkit-fill-available;
    }
    .first-info-short {
        text-align: center;
        width: -webkit-fill-available;
    }
    .second-info, .third-info{
        width: 100%;
        text-align: center;
        margin-top: .5rem;
    }
    .info-hide{
        display: none;
    }
    .box-lista{
        place-content: center;
     }
 }

 .slide-fade-enter-active {
     transition: all .2s ease;
 }
 .slide-fade-leave-active {
     transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
 }
 .slide-fade-enter, .slide-fade-leave-to {
     transform: translateX(-40px);
     opacity: 0;
 }

 .fa-info-circle {
     color: #5c2597;
     font-size: 1.1rem;
     cursor: pointer;
 }

 .btn {
     border-color: #a09ac0;
     color: #7647ac;
 }

span.nomeSpan > i {
    cursor: pointer;
}
</style>
