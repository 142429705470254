<template>
    <div>
        <div class="list-load"></div>
        <div class="list-load"></div>
        <div class="list-load"></div>
    </div>
</template>

<script>

export default {
    name: "SkeletonList",
}

</script>

<style lang="scss" scoped>
.list-load {
  height: 15vh;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  background-color: #eeedee; 
  margin: 1rem 3rem;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.15);
  padding: 0.4rem;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 1.4s infinite;
    content: '';
  }
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style> 