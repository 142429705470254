<!--
     |---------------------------------------------
     | ListTeams Component
     |---------------------------------------------
     | @usage Import the component and use, no params
     | List all teams and show its filters
-->
<template> 
    <div class="list-teams">

        <div>
            <Panel 
                :infos="infosPanel"
                :teams="teams"
            />
            
            <div class="container mt-5">    
                <Button
                    class="grow entendaButton"
                    value="Entenda"
                    iconName="question-circle"
                    :isOutline="true"
                    :hasIcon="true"
                    data-target="#idBotaoInstituicoes"
                    data-toggle="modal"
                />
            </div>
        </div >
        <!-- MODAL CENTRAL DO 'ENTENDA' -->
        <ModalCentral
            componentName="Carousel"
            :slides="infosCarousel"
            title="Instituições"
            idName="idBotaoInstituicoes"
        />

        <div class="container mt-5" v-if="Object?.keys(teams ?? []).length != 0">
            <!-- INICIO DO FORM DE FILTRAGEM -->
            <form @submit.prevent="" class="form-inline">
                <div class="form-group">

                    <!-- DROPDOWN DE SELECAO DE FILTRO -->
                    <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomSelect">Filtrar por: </label>
                    <Filter ref="filtersearchname" @click="setSelectedValue()" :optionsSelect="options" nameSelect="filtro_instituicoes" id="inlineFormCustomSelect"/>
                </div>

                <!-- SE A SELECAO FOR NOME (2) ENTAO EXIBIRA OS SUBFILTROS DE NOME -->
                <div class="form-group" v-if="selectedValue == 2">
                    <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomName">Digite o nome: </label>
                    <input class="input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_instituicoes" id="inlineFormCustomName" autocomplete="off">

                    <input class="radio-personal mr-sm-2" v-model="searchWithOrder" value="cres" type="radio" name="filtro_nome_ordenacao" id="inlineFormCustomRadioAZ" checked>
                    <label class="mr-sm-2 text-nowrap label-filtro mr-sm-4" for="inlineFormCustomRadioAZ">A - Z</label>
                    
                    <input class="radio-personal mr-sm-2" v-model="searchWithOrder" value="desc" type="radio" name="filtro_nome_ordenacao" id="inlineFormCustomRadioZA">
                    <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomRadioZA">Z - A</label>
                </div>

                <!-- SE A SELECAO FOR POR GRUPOS (3) ENTAO EXIBIRA OS SUBFILTROS DOS GRUPOS -->
                <div class="form-group" v-if="selectedValue == 3">
                    <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomCodigo">Selecione o grupo: </label>
                    <Filter ref="filtersearchgroup" @click="setSelectedGroup()" :optionsSelect="group_options" nameSelect="filtro_grupo_instituicoes" id="inlineFormCustomCodigo"/>
                </div>

            </form>
        </div>

        <transition-group tag="div" v-if='filterInstituicao' class="cards mt-5" name="listanimate">

            <div class="card-personal mb-4"  v-for="(team) in filterInstituicao" :key="team.idinstituicao">

                <CardsBG
                    :id="parseInt(team.idinstituicao)"
                    :name="team.name"
                    :nr_members="team.nr_members"
                    :nr_sample="team.nr_sample"
                    :thumbnail="team.thumbnail"
                />
            </div>

        </transition-group>

        <transition-group v-else>
            <h2 class="title-style">Nenhum resultado encontrado.</h2>
        </transition-group>
    </div>
    
</template>

<!--Linguagem marcação: JavaScript-->
<script>

 import CardsBG from '@dados/GerenciarInstituicoes/CardsBG';
 import Filter from '@components/Filter';
 import Button from '@components/Button';
 import ModalCentral from '@components/ModalCentral';
 import Panel from '@dados/GerenciarInstituicoes/Panel';

 export default {
     name: 'ListTeams',
     data() {
         return {
             qtdTeams: 0,
             selectedValue: '',
             searchByName: '',
             searchByCode: '',
             searchWithOrder: '',
             selectedGroupFilter: 0,
             teste: [],
             options: [
                 {
                     id:1,
                     value: 'Todos'
                 },
                 {
                     id:2,
                     value: 'Nome'
                 },
                 {
                     id:3,
                     value: 'Grupo de Pesquisa'
                 }
             ],
             group_options: [
                 {
                     id:1,
                     value: 'Todos'
                 },
                 {
                     id:2,
                     value: 'LEPES X'
                 },
                 {
                     id:3,
                     value: 'Secretaria X'
                 }
             ],

             infosPanel: {
                 title: 'Instituições',
                 firstButtonTitle: 'Buscar Instituição',
                 firstButtonText: 'Faz parte de alguma instituição? Encontre-a e solicite sua entrada',
                 secondButtonTitle: 'Criar Instituição',
                 secondButtonText: 'Cadastre uma nova instituição na plataforma',
                 thumbnail: 'grupos-pesquisa-image2.png',
                 type: 'Institution'
             },
             infosCarousel: [
                 {
                     id:0,
                     title: 'O que são Instituições?',
                     text: 'Instituições são estruturas ou mecanismos de ordem social, que regulam o comportamento de um conjunto de indivíduos dentro de uma determinada comunidade. Instituições são identificadas com uma função social, que transcende os indivíduos e as intenções mediando as regras que governam o comportamento vivo.',

                 },
                 {
                     id:1,
                     title: 'Como buscar Instituições?',
                     text: 'Clique no Botão "Buscar Instituição", busque a instituição desejada e clique em "Enviar" para enviar uma solicitação de entrada ao Administrador da Instituição.'

                 },
                 {
                     id:2,
                     title: 'Como criar Instituições?',
                     text: 'Clique no Botão "Criar Instituição", preencha os campos solicitados e siga os passos.'

                 },

             ]
         }
     },
     components: {
         CardsBG,
         Filter,
         Panel,
         Button,
         ModalCentral
     },
     methods: {
         setSelectedValue () {
             this.selectedValue = this.$refs.filtersearchname.value
         },
         setSelectedGroup () {
             this.selectedGroupFilter = this.$refs.filtersearchgroup.value
         }

     },

     async created(){
         await this.$store.dispatch('setListTeams')
     },

     mounted: function() {
         this.qtdTeams = Object?.keys(this.teams ?? []).length;
     },
     computed: {
         //TODO: José - Carregamento automático de retornos de busca está bugando
         teams() {
             return this.$store.getters.getListTeams
         },
         filterInstituicao: function() {

             //VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
             var query = this.teams;
             query = query?.sort((a, b) => a?.name.localeCompare(b?.name));

             //CASO SEJA SELECIONADO TODOS, EXIBE TODOS OS ITENS DO ARRAY
             if (this.selectedValue == 1) {
                 query = this.teams;
                 query = query?.sort((a, b) => a?.name.localeCompare(b?.name));
             }

             //CASO SEJA SELECIONADO NOME, FILTRA POR NOME
             else if (this.selectedValue == 2) {

                 //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
                 if (this.searchByName.trim() == '') {
                     query = this.teams;
                 }
                 //SENAO ELE FILTRA PELO O QUE FOR DIGITADO
                 else {

                     var filtered = this.teams?.filter(team =>team.name.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))

                     if(filtered.length != 0){
                         query = filtered
                     }

                     else{
                         query = false
                     }

                 }

                 //ORDERNA CRESCENTEMENTE
                 if (this.searchWithOrder == 'cres') {
                     query?.sort((a, b) => a?.name.localeCompare(b.name));
                 }

                 //ORDERNA CRESCENTEMENTE
                 if (this.searchWithOrder == 'desc') {
                     query?.reverse((a, b) => a?.name.localeCompare(b.name));
                 }

             }

             //CASO SEJA SELECIONADO GRUPO DE PESQUISA, FILTRA POR GRUPO
             else if (this.selectedValue == 3) {

                 if (this.selectedGroupFilter == 0){
                     query = this.teams;
                 }

                 else if (this.selectedGroupFilter == 1){
                     query = this.teams;
                 }

                 else if (this.selectedGroupFilter == 2) {
                     query = this.teams?.filter(team => team.gp_research == 'LEPES X')
                 }

                 else if (this.selectedGroupFilter == 3) {
                     query = this.teams?.filter(team => team.gp_research == 'Secretaria X')
                 }
             }

             return query;
         }
     }
 }

</script>

<style scoped>

 .list-teams {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     min-height: 90vh;
     padding-top: 2rem;
     width: 100%;
 }

 .title-style{
     font-size: 25px;
     color: #656565;
     margin: 0;
     text-align: center;
     font-weight: 500;
 }

 .about-team {
     background-color: rgb(255, 255, 255);
     display: flex;
     align-items: center;
     justify-content: space-around;
     border-radius: 12px;
     padding: 1rem 4rem;
 }

 .img-about-team {
     max-height: 16rem;
 }

 .box-btn-about-team button {
     background-color: transparent;
     outline: none;
     cursor: pointer;
     padding: .3rem .8rem;
     border-radius: 6px;
     color: #105B9F;
     border: 3px solid #105B9F;
     font-size: 1.1rem;
     display: flex;
     justify-content: flex-end;
     align-items: center;
 }

 .box-btn-about-team i {
     font-size: 1.3rem;
 }

 .label-filtro {
     font-weight: 600;
 }

 /* Código para centralizar os cards como se fosse
    (flex-start + div centralizada) */
 .cards {
     display: grid;
     grid-template-columns: repeat(3, minmax(21rem, max-content));
     grid-gap: 1.8rem;
     justify-content: center;
 }

 /* Responsoivizando alinhamento dos cards */
 @media screen and (max-width: 1160px) {
     .cards {
         grid-template-columns: repeat(auto-fit, minmax(21rem, max-content));
     }
 }

 .card-personal {
     width: 21rem;
 }

 .card.custom-rounded {
     border-radius: 20px;
     box-shadow: 12px 12px 23px -7px rgba(158,146,158,0.49);
 }

 .filter-box {
     margin: 2rem 4rem 2rem 0;
     margin-left: 6.5vw;
 }

 .card-body {
     text-align: center;
     font-weight: bold;
     min-height: 116px;
 }

 .card-text {
     padding-top: 1rem;
     color: #212529;
     font-size: 1rem;
 }

 .card-img-top {
     cursor: pointer;
 }

 @media screen and (max-width: 1012px) {
     .filter-box {
         display: flex;
         align-items: center;
         justify-content: center;
     }
 }

 .listanimate-enter-active,
 .listanimate-leave-active {
     transition: all 0.4s;
 }

 .listanimate-enter,
 .listanimate-leave-to  {
     transform: scale(0);
     opacity: 0;
 }

 .entendaButton {
     background-image: url("~@/assets/img/Backgrounds/verdeEscuroEAPI.webp");
     background-repeat: repeat;
     background-size: 256px;
     border: 0;
     box-shadow: inset -2px 2px 2px rgba(0, 0, 0, 0.25);
 }

 :deep(.entendaButton div) {
     background-clip: text;
     text-fill-color: transparent;
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
 }
</style>
