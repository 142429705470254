<template>
    <div>
        <ListTeams/>
    </div>
</template>

<script>
import ListTeams from '@dados/GerenciarInstituicoes/ListTeams';

export default {
    name: 'GerenciarInstituicoes',
    components: {
        ListTeams
    }
}
</script>

<style scoped>

</style>