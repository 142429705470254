<template>
    <div class="bg">

        <h2 class="pageTitle text-img-clipper">
            O que é a plataforma?
        </h2>

        <div class="infoCard cardLeft">
            <div class="text">
                <span>
                    <h2 class="titlePraticas titleRight text-img-clipper">
                        QUAL O CONTEXTO DA PLATAFORMA?
                    </h2>
                    <p>A iniciativa de criar essa plataforma parte-se da importância de avaliar a qualidade da Educação Infantil, para além dos aspectos de infraestrutura das unidades educacionais. Para isso, a plataforma, “Observatório da Qualidade na Educação Infantil” tem três objetivos:</p>
                    <div>
                        <ul>
                            <li>Disponibilizar ferramentas para mensurar esta qualidade.</li>
                            <li>Democratizar o acesso aos dados coletados com estas ferramentas.</li>
                            <li>Disseminar conhecimento para que familiares, professores, gestores, dentre outros atores da sociedade, se inspirem e atuem por uma Educação Infantil de qualidade.</li>
                        </ul>
                    </div>
                </span>
            </div>
        </div>

        <br />

        <div class="infoCard cardRight">
            <div class="text">
                <span>
                    <h2 class="titlePraticas text-img-clipper">
                        QUAL A IMPORTÂNCIA DE  AVALIAR A QUALIDADE DA EDUCAÇÃO INFANTIL?
                    </h2>

                    <h3 class="titlePraticas text-img-clipper">Dados e contexto histórico:</h3>
                    <p>
                        A Educação Infantil pública e gratuita é um direito das crianças brasileiras desde a promulgação da Constituição de 1988. Posteriormente, em 1996, a Lei de Diretrizes e Bases (LDB) atribuiu a responsabilidade por esta etapa da Educação Básica aos municípios e cabe aos demais entes federados colaborarem com recursos e estratégias que proporcionem a garantia deste direito às crianças.
                    </p>
                    <p>
                        No âmbito federal, em meados da década de 2010,  a incidência e articulação de diferentes atores sobre a importância da primeira infância impulsionaram a promulgação do Marco Legal da Primeira Infância (Lei 13.257/2016). Ainda, mais recentemente, em 2021, alterações no Fundo de Manutenção e Desenvolvimento da Educação Básica (Fundeb) abriram caminhos para um maior aporte de recursos para a Educação Infantil.
                    </p>
                    <p>
                        Segundo dados do Censo Escolar de 2021, esta etapa possui 6.403.866 crianças matriculadas na rede pública. Sendo 2.399.766 de matrículas até 3 anos de idade, correspondente à creche, modalidade não obrigatória, e 4.004.100 na pré-escola, modalidade obrigatória que atende crianças de 4 e 5 anos. Cabe um destaque de que esses números de matrícula que vinham em uma crescente, sofreram uma redução entre 2019 e 2021 por conta da pandemia de Covid-19.
                    </p>
                    <p>
                        Em relação à porcentagem da população atendida, os últimos dados disponíveis são de 2019 e indicam que na creche este índice é de 37%, enquanto que na pré-escola é de 94,1%. Com o agravante de que esta proporção de atendimento não se distribui de maneira igualitária, uma vez que há diferenças marcantes deste acesso a depender do nível socioeconômico da família dessa criança e da região em que ela reside.
                    </p>

                    <h3 class="titlePraticas text-img-clipper">Não devemos garantir apenas o acesso:</h3>
                    <p>Dessa forma, quando olhamos para o que foi estabelecido como meta no Plano Nacional de Educação (PNE) – 50% das crianças, no mínimo, matriculadas em creches até 2024 e universalização do acesso à pré-escola até 2016 – é evidente que o Brasil ainda necessita melhorar o acesso à Educação Infantil. Porém, restringir as discussões e políticas públicas sobre Educação Infantil ao acesso – sendo esta uma etapa cuja função social passa por complementar o que as famílias podem ofertar de cuidados e educação para as crianças – inviabiliza a promoção de igualdade de direitos e de oportunidades para as crianças inseridas em diferentes contextos sociais.</p>

                    <h3 class="titlePraticas text-img-clipper">Introdução sobre a qualidade:</h3>
                    <p>Evidências científicas de distintos campos de conhecimento, como por exemplo, as neurociências, a pedagogia, a psicologia e a economia, escancaram a centralidade da primeira infância, faixa etária que compreende dos 0 aos 6 anos de idade e que condiz com o período em que as crianças frequentam a Educação Infantil. Diversas destas evidências demonstram o quanto a qualidade das interações com o meio e com os adultos de referência, bem como a garantia de afeto, estímulos adequados e cuidados são cruciais para as aprendizagens e desenvolvimento integral. O que demonstra a urgência em se garantir a qualidade do atendimento oferecido nas unidades educacionais existentes e nas que venham a ser criadas.</p>

                    <h3 class="titlePraticas text-img-clipper">Mas o que viria a ser essa qualidade no atendimento nos espaços de Educação Infantil?</h3>
                    <p>
                        Em se tratando da caracterização do papel de creches e pré-escolas, em 2010, o Ministério da Educação por meio da publicação das Diretrizes Curriculares Nacionais para a Educação Infantil (DCNEI) estabelece que as unidades de Educação Infantil são espaços de educação e cuidado, tendo como eixo curricular as interações e as brincadeiras. Também é dentro desta concepção de cuidado vinculado ao processo educativo que, a partir de 2017, com a homologação da Base Nacional Comum Curricular (BNCC), os estados e municípios brasileiros passaram a revisar ou construir seus currículos e propostas pedagógicas para a Educação Infantil.
                    </p>
                    <p>
                        De modo que utilizando a BNCC como norteadora, temos bem estabelecidos quais são os direitos e objetivos de aprendizagens a serem garantidos para crianças que frequentam a Educação Infantil. Ainda, outros documentos oficiais, como os Indicadores de Qualidade na Educação Infantil e os Parâmetros Nacionais de Qualidade para Educação Infantil, além de uma vasta literatura internacional, estabelecem critérios que devem ser atendidos.
                    </p>
                    <p>
                        Resumidamente, além dos eixos estruturantes “interações e brincadeiras”, citados anteriormente, temos estabelecidos pela BNCC seis direitos de aprendizagem e desenvolvimento a serem garantidos para as crianças: Conviver, Brincar, Participar, Explorar, Expressar e Conhecer-se.
                    </p>

                    <h3 class="titlePraticas text-img-clipper">Agora que foi estabelecido o que é qualidade, como garantir?</h3>
                    <p>
                        Resta saber como de fato isso se dá na prática. <b>O quanto estes parâmetros e critérios de qualidade estabelecidos estão sendo implementados e chegam até as crianças no Brasil?</b>
                    </p>
                    <p>
                        Neste contexto, realizado em 2021 em 12 municípios brasileiros, temos o estudo denominado “Avaliação da Qualidade da Educação Infantil”, cujo principal propósito foi obter um retrato de como está a qualidade da Educação Infantil, a partir de um entendimento de em qual estágio de implementação a BNCC se encontra. Paralelamente, sendo capaz de fomentar uma cultura de avaliação na Educação Infantil ao subsidiar gestores municipais na tomada de decisão inspirada em evidências.
                    </p>
                    <p>
                        O estudo teve execução técnica da equipe do Laboratório de  Estudos e Pesquisas em Economia Social da Universidade de São Paulo (LEPES – USP/ Ribeirão Preto), em parceria com a Fundação Maria Cecilia Souto Vidigal (FMCSV), e com financiamento da referida fundação, além do Itaú Social e do Movimento Bem Maior.
                    </p>

                </span>
            </div>
        </div>

        <br />

        <div class="infoCard cardLeft">
            <div class="text">
                <span>
                    <h2 class="titlePraticas titleRight text-img-clipper">
                        O QUE É A PLATAFORMA?
                    </h2>
                    <p>
                        Diante desse contexto, a plataforma é o local em que estão os resultados do estudo nacional, e que, a partir de agora, poderá contribuir com esses municípios que participaram do estudo, e novos municípios  a realizarem  um diagnóstico em relação à qualidade. Desta forma, poderão criar planos de ação que avancem em prol de melhorias na qualidade das escolas, e assim possam garantir os direitos das crianças.
                    </p>
                    <p>
                        Então para além do objetivo de ser um suporte para os municípios realizarem suas avaliações, a plataforma está estruturada para instrumentalizar todo o processo: desde a relevância da avaliação, até como usar os dados em prol de melhorias
                    </p>
                    <p>
                        Nesse intuito a plataforma está organizada da seguinte forma:
                    </p>

                    <div>
                        <ol>
                            <li><b>QUALIDADE NA EI:</b> Essa seção trata da relevância da qualidade na Educação Infantil, quais são os documentos oficiais brasileiros que sustentam o que é  qualidade, e boas práticas.</li>
                            <li><b>AVALIAÇÃO DA QUALIDADE:</b>  A seção trata de todos materiais e procedimentos, e exemplos de avaliações já feitas.</li>
                            <li><b>CURSO PARA GESTORES:</b> A seção trata do curso para gestores, que pretende formar (compreender e instrumentalizar) gestores públicos municipais no planejamento e execução de uma avaliação da qualidade dos ambientes de aprendizagem da Educação Infantil.</li>
                            <li><b>IMPLEMENTAÇÃO DA AVALIAÇÃO:</b>  A seção trata de ferramentas para fazer a avaliação.</li>
                            <li><b>GESTÃO DA AVALIAÇÃO:</b> A seção trata de todos os processos para o armazenamento dos dados.</li>
                        </ol>
                    </div>
                </span>
            </div>
        </div>

    </div>

</template>

<script>
 export default {
     name: 'SobreOQueE',
 }
</script>

<style scoped>
 .bg {
     padding: 2rem 2rem;
     width: 100%;
     background: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
 }

 .infoCard {
     padding: 2rem;
     background-repeat: repeat;
     background-size: 256px;
     border-radius: 2rem;
     box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
 }

 .cardRight {
     background: url("~@/assets/img/Backgrounds/verdeEscuroMateriais.webp");
 }

 .cardLeft {
     background: url("~@/assets/img/Backgrounds/verdeEscuro.webp");
 }

 .infoCard > .text {
     width: 100%;
     display: block;
     text-align: justify;
     font-size: 1.15em;
     box-sizing: border-box;
     padding: 10px;
 }

 .infoCard > .text p {
     text-indent: 1em;
 }

 .titlePraticas {
     width: 100%;
     padding: 5px;
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
 }

 .cardRight h2.titlePraticas {
     text-align: right;
 }

 .cardLeft h2.titlePraticas {
     text-align: left;
 }

 .text p, .text div {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .pageTitle {
     background-image: url("~@/assets/img/Backgrounds/marsala.webp");
     width: 100%;
     text-align: center;
 }

 ol, ul {
     list-style-position: inside;
 }

 ol {
     list-style-type: upper-roman;
 }

 li::marker {
     color: #fcfaf2;
     font-weight: bold;
 }
</style>
