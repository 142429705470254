
<template>
    <div class="bg">
        <div class="list-data">
            <ModalCentral
                componentName="ShowCampoInfo"
                title="DESCRIÇÃO DO CAMPO"
                idName="showCampoInformacaoTwo"
                :data="dataToShowCampoInfo"
            />

            <div class="title-tree">
                <Breadcrumb :crumbs="crumbs" section="instituicao-aplicacoes"/>
            </div>

            <InstitutionInfo class="title-style" :title="nomeInstituicao" section="instituicao-cadastrar"/>

            <p class="text-info-personal imgText">
                Nesta seção, você encontrará todos os arquivos de aplicações relacionados aos campos da Instituição.
            </p>

            <div class="itens-box">
                <div class="filter-box">
                    <!-- INICIO DO FORM DE FILTRAGEM -->
                    <form @submit.prevent="" class="form-inline">
                        <div class="form-group">

                            <!-- DROPDOWN DE SELECAO DE FILTRO -->
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomSelect">Filtrar por: </label>
                            <Filter ref="filtersearchname" @change="setSelectedValue()" :optionsSelect="options" nameSelect="filtro_instituicoes" id="inlineFormCustomSelect"/>
                        </div>

                        <!-- SE A SELECAO FOR NOME (2) ENTAO EXIBIRA OS SUBFILTROS DE NOME -->
                        <div class="form-group" v-if="selectedValue == 2">
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomName">Digite o nome: </label>
                            <input class="input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_instituicoes" id="inlineFormCustomName" autocomplete="off">
                        </div>
                    </form>
                </div>
            </div>
            <hr class="separator">

            <!-------------------------------- LISTANDO AS APLICAÇÕES DO CAMPO ----------------------------------------------->
            <div v-if="isLoadedInfo">
                <div class="list-box">
                    <transition-group name="slide-fade" v-if="campos.length">
                        <div class="row data-box" v-for="campo in campos" :key="campo.counter" :class="{'list-item-selected-orange': campo.isativo == 'ON'}">
                            <div class="d-flex col-7 align-items-center  justify-content-between ">
                                <img  class="img-info mr-3" src="@assets/img/Dados/ListarCampos/field-icon-orange.png" alt="">
                                <span class="first-info">{{campo.titulo}}<i @click="sendInfosToShowCampoInfo(campo.descricao)" data-toggle="modal" data-target="#showCampoInformacaoTwo" class="fa fa-info-circle ml-2"></i></span>
                                <span class="second-info">Iniciada em {{campo.ano}}</span>
                                <span class="second-info">{{ campo.isativo === 'OFF' ? `Encerrado em ${formatedDate(campo?.hora_off)}` : "Campo ativo" }}</span>
                            </div>

                            <div class="d-flex col-5">
                                <router-link :to="{ name: 'ListarAplicacoes', params: { idCampoAplicacoes: campo.idamostra }}" class="btn-router-link mr-2">
                                    <Button
                                        class="col orange-text downloadButton"
                                        value="Ver Aplicações"
                                        section="instituicao-aplicacoes"
                                        :isOutline="true"
                                        :hasIcon="true"
                                        iconName="file-pdf"
                                    />
                                 </router-link>

                                 <Button
                                    :disabled="false"
                                    @click="downloadData(campo.idamostra)"
                                    class="col orange-text downloadButton mr-2"
                                    value="Baixar dados"
                                    section="instituicao-aplicacoes"
                                    :isOutline="true"
                                    :hasIcon="true"
                                    iconName="file"
                                />
                            
                                <Button
                                    :disabled="campo.isativo !== 'OFF'"
                                    :class="{ 'buttonDisabled': campo.isativo !== 'OFF' }"
                                    @click="downloadPdf(campo)"
                                    class="col orange-text downloadButton"
                                    value="PDF"
                                    section="instituicao-aplicacoes"
                                    :isOutline="true"
                                    :hasIcon="true"
                                    iconName="file-pdf"
                                />

                            </div>

                        </div>
                    </transition-group>
                    <transition-group v-else>
                        <h2 class="title-style-box-lista">Nenhum campo encerrado na instituição.</h2>
                    </transition-group>
                </div>

            </div>

            <div v-else>
                <SkeletonList/>
            </div>

        </div>
    </div>

</template>

<script>
 import Filter from '@components/Filter'
 import Button from '@components/Button'
 import ModalCentral from '@components/ModalCentral'
 import Breadcrumb from '@components/Breadcrumb'
 import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
 import SkeletonList from '@components/SkeletonUI/SkeletonList'
 import parsers from '@middleware/parsers'

 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: "left",
         y: "bottom",
     },
 });

 export default {
     name: 'CampoListaFormularios',
     data (){
         return {
             nomeInstituicao: '',
             nomeCampo: '',
             descricao: '',
             selectedValue: '',
             searchByName: '',
             searchByCode: '',
             searchWithOrder: '',
             isLoadedInfo: false,
             dataToShowCampoInfo: {},
             options: [
                 {
                     id:1,
                     value: 'Todos'
                 },
                 {
                     id:2,
                     value: 'Nome'
                 }
             ],
             crumbs: [
                 {
                     id:1,
                     value: '',
                     iconName: 'th',
                     name_route: 'GerenciarGrupos'
                 },
                 {
                     id:2,
                     value: 'Grupo',
                     iconName: 'graduation-cap',
                     name_route: 'GrupoMenu'
                 },
                 {
                     id:3,
                     value: 'Instituição',
                     iconName: 'th-large',
                     name_route: 'GrupoInstituicoes'
                 },
                 {
                     id:4,
                     value: 'Arquivos de aplicações',
                     iconName: 'th-large',
                     name_route: ''
                 }
             ]
         }
     },
     components: {
         Filter,
         Button,
         Breadcrumb,
         ModalCentral,
         InstitutionInfo,
         SkeletonList
     },
     async created() {
         this.teams = this.$store.getters.getListTeams

         await this.$store.dispatch('setListCamposGrupo', {
             idInstituicao: this.$route.params.idInstituicao,
             idGrupo: this.$route.params.idGrupo,
         });
         this.isLoadedInfo = true
     },
     computed: {
         campos() {
             let query = this.$store.getters.getListCamposGrupo;
             // query = campos.filter(campo => campo.isativo == 'OFF');

             //CASO SEJA SELECIONADO NOME, FILTRA POR NOME
             if (this.selectedValue == 2) {
                 //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
                 if (this.searchByName.trim() != '') {
                     var filtered = query.filter(campo =>campo.titulo.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
                     if(filtered?.length != 0){
                         query = filtered
                     } else {
                         query = false
                     }
                 }
             }

             return query;
         }
     },
     methods: {
         setSelectedValue: function() {
             this.selectedValue = this.$refs.filtersearchname.value
         },

         sendInfosToShowCampoInfo(descricao) {
             this.dataToShowCampoInfo = {
                 descricao: descricao
             }
         },

         formatedDate(date) {
             if (!date) return "Encerrado";

             const formatter = new Intl.DateTimeFormat("pt-BR", {
                 year:  'numeric',
                 month: 'long',
                 day:   'numeric',
             });
             return formatter.format(new Date(date));
         },
         addWorkDays(date, numDays) {
            const dow = date.getDay();
            if (dow == 0) numDays++;
            else if (dow == 6) numDays += 2;

            if (dow + numDays >= 6) {
                let remaining = numDays - (5 - dow);
                numDays += 2;
                if (numDays > 5) {
                    numDays += 2*Math.floor(remaining/5);
                    if (remaining % 5 == 0)
                        numDays -= 2;
                }
            }

            date.setDate(date.getDate() + numDays); return date;
        },
        downloadPdf(campo) {
            if (campo.isativo !== 'OFF'){
                notyf.open({
                    type: "error",
                    message: "Campo aberto, não é possível acessar suas devolutivas",
                });
                return;
            }

            if (!campo?.devolutiva || campo?.devolutiva == 'null' || campo?.devolutiva == 'undefined') {
                notyf.open({
                type: "error",
                message: `Os dados estarão disponíveis no dia ${parsers.convertUTC(this.addWorkDays(new Date(campo.hora_off), 4))}`,
                });
                return;
            }
        
            console.log("AAAAAAAAAAAAAAAAAAA")
            console.log(campo.devolutiva)
        this.$store
            .dispatch("loadPdfDownload", {
                idInstituicao: this.$route.params.idInstituicao,
                idamostra: campo.idamostra,
                devolutiva: campo.devolutiva,
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]), { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'devolutiva.pdf');
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                console.log(err);
                notyf.open({
                type: "error",
                message: "Erro ao obter arquivo!",
                });
            });

        },

         downloadData(idAmostra) {
             if (this.baixando) return;
             this.baixando = true;

             const notiLoad = notyf.open({
                 type: "info",
                 background: "#40c9a2",
                 message: "Carregando dados...",
                 duration: 0,
             });

             this.$store
                 .dispatch("loadAmostraRespostas", {
                     idInstituicao: this.$route.params.idInstituicao,
                     idAmostra: idAmostra,
                 })
                 .then(response => {
                     const url = window.URL.createObjectURL(new Blob([typeof(response.data) === 'string' ? response.data : JSON.stringify(response.data)]));
                     const link = document.createElement('a');
                     link.href = url;
                     link.setAttribute('download', 'amostras.json');
                     document.body.appendChild(link);
                     link.click();
                 })
                 .catch(err => {
                     notyf.open({
                         type: "info",
                         ...(err?.status != 500 ? { background: "#40c9a2" } : {}),
                         message: err?.status != 500 ? err?.response?.data?.message?.message : "Erro ao baixar o arquivo!",
                     });
                 })
                 .finally(() => {
                     notyf.dismiss(notiLoad);
                     this.baixando = false;
                 });
         },
     },
     mounted: function() {
         window.scrollTo(0, 0);
         let idTeamSelected = this.$route.params.idInstituicao

         if (typeof idTeamSelected == "undefined"){
             this.nomeInstituicao = "Nome da Instituição"
         }
         else {
             let teamsSelected
             let teamSelected

             teamsSelected = this.teams.filter(team =>
                 team.idinstituicao == idTeamSelected
             )

             teamSelected = [...teamsSelected].shift()

             this.nomeInstituicao = teamSelected.name
             this.descricao = teamSelected.descricao
         }
     }
 }
</script>

<style scoped>
 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.png");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;
 }

 .imgText {
     background-image: url("~@/assets/img/Backgrounds/laranja.png");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .data-box {
     background-repeat: repeat;
     background-size: 256px;
     background-image: url("~@/assets/img/Backgrounds/laranjaClaroEAPI.png");

     border: 1px solid #958db1;
 }

 .data-box span {
     background-image: url("~@/assets/img/Backgrounds/laranja.png");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: rgb(255, 119, 0);
 }

 .text-info-personal {
     color: #656565;
     font-size: 1.1rem;
     text-align: justify;
     padding: 0rem;
     margin: 0 3rem 0 3rem;
 }

 .list-data {
     min-height: 90vh;
     padding-top: 2rem;
 }

 .label-filtro {
     font-weight: 600;
 }

 .filter-box {
     margin: 1rem 3rem;
 }

 .list-box {
     margin: 2rem;
 }

 .itens-box {
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .title-tree {
     margin: 1rem 3rem;
     font-weight: bold;
     display: flex;
 }

 .title-tree h1 {
     font-size: 1.2rem;
     font-weight: 700;
 }

 .title-style{
     margin: 0 0 0 3rem;
 }

 .separator {
     border: none;
     height: 1px;
     margin: 0.8rem 3rem;
     background: url("~@/assets/img/Backgrounds/laranja.png");
 }

 a {
     color: #212529;
     text-decoration: none;
     transition: all ease-in-out 0.2s
 }

 a:hover {
     color: #4d4f50;
 }

 @media screen and (max-width: 1012px) {
     .filter-box {
         display: flex;
         align-items: center;
         justify-content: center;
     }
 }

 @media screen and (max-width: 680px) {
     .itens-box{
         flex-direction: column;
     }
 }

 .data-box {
     background-color: #FCFCFC;
     border-radius: 8px;
     border: 1px solid rgba(0, 0, 0, 0.11);
     box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
     display: flex;
     justify-content: space-between;
     align-items: center;
     flex-wrap: wrap;
     min-height: 15vh;
     margin: 1rem;
     padding: 0.4rem 2.5rem;
 }

 :deep(.data-box span) {
     font-size: 1.1em;
 }

 .list-item-selected-orange {
     background-color: #FFECD0;
     border: 1.8px solid #FFAC30;
     transition: all ease-in-out 0.3s;
 }

 .second-info {
     width: 20%;
     text-align: center;
 }

 .info-hide {
     opacity: 0;
     pointer-events: none;
 }

 .box-img {
     flex-basis: 5%;
 }

 .img-info {
     width: auto;
     max-width: 12vh;
     margin: 0.2rem;
     padding: 10px;
 }

 @media screen and (min-width: 901px) {
     .first-info {
         text-align: left;
     }
     .first-info-short {
         text-align: left;
     }
 }

 @media screen and (max-width: 680px) {
     .first-info {
         text-align: center;
         width: -webkit-fill-available;
     }
     .first-info-short {
         text-align: center;
         width: -webkit-fill-available;
     }
     .second-info, .third-info{
         width: 100%;
         text-align: center;
         margin-top: .5rem;
     }
     .info-hide{
         display: none;
     }
     .data-box{
         place-content: center;
     }
 }

 .slide-fade-enter-active {
     transition: all .2s ease;
 }
 .slide-fade-leave-active {
     transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
 }
 .slide-fade-enter, .slide-fade-leave-to {
     transform: translateX(-40px);
     opacity: 0;
 }

 .fa-info-circle {
     color: #FFAC30;
     font-size: 1.1rem;
     cursor: pointer;
 }
</style>
