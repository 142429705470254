<template>
  <div>
    <div class="painelFuncoesWrapper">
      <h1 class="painelTitulo">Perguntas</h1>

      <div class="perguntasMenu">
        <div class="adicionarPergunta">
          <button class="btn btn-adicionarPergunta">
            <i class="fas fa-plus"></i>
          </button>
        </div>
        <input
          type="text"
          class="form-control ml-2"
          placeholder="Pesquisar Pergunta..."
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PainelLateralPerguntas',
};
</script>

<style scoped>
.painelFuncoesWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.adicionarPergunta{
  width: 37px;
  height: 32px;
}

.btn-adicionarPergunta {
  position: relative;
  background: var(--azul) !important;
  padding: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
}

.btn-adicionarPergunta i {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(55%, 45%);
  color: var(--branco);
}

.painelTitulo {
  padding: 1rem;
  font-size: 26.56px;
  border-bottom: 1px solid var(--cinza-40);
  margin: 0;
  color: var(--cinza);
}

.perguntasMenu {
  display: flex;
  padding: 1rem;
}
</style>