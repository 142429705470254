<template>
    <div class="container offset">
        <form @submit.prevent="createTurma" ref="formClass">
        <div class="custom-form">

            <div class="form-group">
                <label class="question-style pt-1">Nome da turma: *</label>
                <input
                    v-model.trim="classData.nome"
                    type="name"
                    class="form-control"
                    placeholder="Insira aqui o nome da turma..."
                    required
                />
            </div>
            <div class="form-group">
                <label class="question-style">Código/Número da turma: *</label>
                <input
                    v-model.trim="classData.codigo"
                    type="name"
                    class="form-control"
                    placeholder="Insira aqui o código da turma..."
                    required
                />
            </div>

             <div class="form-group">
            <label class="question-style">Período da turma: *</label>
            <select v-model.trim="classData.periodo" class="form-control" required>
                <option hidden class="question-style"> Selecione aqui o período</option>
                <option class="question-style" value="19">Manhã</option>
                <option class="question-style" value="20">Tarde</option>
                <option class="question-style" value="21">Noturno</option>
                <option class="question-style" value="22">Integral</option>
                <option class="question-style" value="23">Intermediário</option>
                <option class="question-style" value="24">Vespertino</option>
            </select>
            </div>

            <div class="form-group">
            <label class="question-style pt-1">Tipo da turma: *</label>
            <select v-model.trim="classData.tipo" class="form-control" required>
                <option hidden class="question-style"> Selecione aqui o tipo da turma</option>
                <option class="question-style" value="25">Regular</option>
                <option class="question-style" value="26">Multisseriada/Mista</option>
            </select>
            </div>

            <div class="form-group">
            <label class="question-style pt-1">Idade da turma: *</label>
            <select v-model.trim="classData.idade" class="form-control" required>
                <option hidden class="question-style"> Selecione aqui o tipo da idade da turma</option>
                <option class="question-style" value="5">Creche (2A - 2A E 11M)</option>
                <option class="question-style" value="6">Creche (3A - 3A E 11M)</option>
                <option class="question-style" value="7">Pré-escola (4A - 4A E 11M)</option>
                <option class="question-style" value="8">Pré-escola (5A - 5A E 11M)</option>
            </select>
            </div>

        </div>
        <div class="formMsg"
             :class="{'formMsgSuccess': lastAdd.length, 'formMsgError': !lastAdd.length}">
            Última Turma adicionada: '{{ lastAdd }}'
        </div>

        <div class="formButtons">
            <div class="button-div pt-4">
                <Button
                    value="Limpar"
                    section="descartar"
                    :isOutline="true"
                    :hasIcon="true"
                    iconName="trash"
                    @click="clearForm" />
            </div>
            <div class="button-div pt-4">
                <Button 
                    section="instituicao-cadastrar"
                    value="Salvar Turma"
                    :isOutline="true"
                    :hasIcon="true"
                    iconName="save"
                /> 
            </div>
        </div>

    </form> 
    </div>
</template>

<script>

import Button from '@components/Button'
import { Notyf } from "notyf";
const notyf = new Notyf({
    duration: 5000,
    position: {
        x: "left",
        y: "bottom",
    }
});

export default {
    name: "AddUser",
    data() {
        return {
          classData: {  
          nome: '',
          codigo: '',
          periodo: '',
          idade: '',
          tipo: '',
          },
          lastAdd: ''

        }
    },
    methods: {
        async createTurma() {
            const payload = {
                idInstituicao: this.$route.params.idInstituicao,
                idUnidade: this.$route.params.idUnidade,
                nome: this.classData.nome,
                codigoTurma: this.classData.codigo,
                tipoPeriodo: this.classData.periodo,
                tipoIdade: this.classData.idade,
                tipoTurma: this.classData.tipo
            }

            const payload2 = {
                idInstituicao: this.$route.params.idInstituicao,
                idUnidade: this.$route.params.idUnidade
            }

            await this.$store.dispatch('setCreateTurma', payload)
            await this.$store.dispatch('setListTurmas', payload2)
            if (this.$store.getters.getIsTurmaCreated){
                this.lastAdd = this.classData.nome;
                notyf.open({
                    type: "success",
                    message: "Turma adicionada com sucesso!",
                });
                this.clearForm();
            } else {
                notyf.open({
                    type: "error",
                    message: "Erro na criação da turma!",
                });
            }
        },
        clearForm() {
            Object.keys(this.classData).map((prop)=>this.classData[prop] = '' )
        }
    },
    components: {
        Button
    }
    
}

</script>

<style scoped>

    .container{
        width: 100%;
        background-color: white;
    }

    .title-style{
        font-size: 1.5rem;
        font-weight: 600;
        color: #656565;        
    }

    .question-style{
        width: 100%;
        font-size: 1rem;
        color: #656565;
    }

    .image-style{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        padding: 1rem;
    }

    .button-div{
        text-align: center;
    }
    
    .formMsg {
        margin-top: 5px;
    }
    .formMsgSuccess {
        color: green;
    }
    .formMsgError {
        display: none;
    }
    .formButtons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        align-content: space-around;
    }
</style>
