<template>
    <form @submit.prevent class="container offset">
        <label class="question-style">Instituições a adicionar:</label>
        <div class="searchBox">
            <input
                v-model="filterName"
                type="text"
                name="search"
                placeholder="Nome para busca..." />
            <i class="fas fa-search"></i>
        </div>

        <transition-group tag="div" v-if='filteredInstitutions.length' class="institutions" name="listanimate">
            <div v-for="insti in filteredInstitutions" :key="insti?.idinstituicao">
                <div class="institution-row-style">
                    <div>
                        <img class="img-style ml-1 mr-2" :src="thumbnailPath(insti)" :alt="`Instituição '${insti.name}'`" />
                        <label class="text-style name"> {{ insti?.name }} </label>
                    </div>
                    <Button
                        @click="sendSolicitacao(insti)"
                        :disabled="sentInstis.includes(insti?.idinstituicao)"
                        section="painel-outline"
                        value="Enviar"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="paper-plane"
                        class="button-style"
                    />
                </div>
            </div>
        </transition-group>

        <transition-group tag="div" v-else class="emptyList">
            Nenhuma instituição a ser adicionada!
        </transition-group>
    </form>
</template>

<script>
 import Button from '@components/Button';
 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: "left",
         y: "bottom",
     },
 });

 export default {
     name: "AddInstitutionGroup",
     components: {
         Button,
     },
     async created() {
         this.$store.dispatch('loadListInstis', { idGrupo: this.data.id });
         this.$store.dispatch('setListTeams');
         this.$store.dispatch('setListGroupRequests', {
             idGrupo: this.data.id,
             notiTypes: [ "GRUPO_INSTITUICAO" ],
         });
     },
     props: {
         data: {
             type: Object,
             required: true
         },
     },
     data() {
         return {
             selected: [],
             filterName: ""
         };
     },
     computed: {
         institutions() {
             return this.$store.getters.getListInstis;
         },
         sentInstis() {
             let invited = this.$store.getters.getListGroupRequests;
             return invited
                 .filter(invite => !["REMOVIDO", "RECUSADO"].includes(invite.aceito))
                 .map(invite => invite?.idInstituicao?.idInstituicao);
         },
         filteredInstitutions() {
             let unordered = this.$store.getters.getListTeams,
                 currentIds = this.institutions?.map(insti => insti?.idinstituicao);
             unordered = unordered?.filter(insti => !currentIds.includes(insti?.idinstituicao));

             if (this.filterName.trim() !== "") {
                 let lowerFilter = this.filterName.trim().toLowerCase();
                 unordered = unordered?.filter(insti => insti?.name?.toLowerCase().includes(lowerFilter));
             }

             return unordered?.sort((a, b) => a?.name.localeCompare(b?.name));
         },
     },
     methods: {
         thumbnailPath(institution) {
             try {
                 return require('../../../' + institution.thumbnail);
             } catch (e) {
                 return require('@assets/img/Dados/ListarInstituicao/Instituicoes/instituicao1.jpg');
             }
         },
         async sendSolicitacao(insti) {
             await this.$store.dispatch('setNotifyGroupInviteInsti', {
                 idGrupo: this.data.id,
                 instiId: insti?.idinstituicao,
             });

             if (this.$store.getters.getIsSentRequestGroupInsti) {
                 this.$store.dispatch('setListGroupRequests', {
                     idGrupo: this.data.id,
                     notiTypes: [ "GRUPO_INSTITUICAO" ],
                 });

                 notyf.open({
                     type: "success",
                     message: "Convite enviado com sucesso",
                 });
             } else {
                 notyf.open({
                     type: "error",
                     message: "Erro ao enviar convite",
                 });
             }
         },
     },
 };
</script>

<style scoped>
 .container{
     padding: 1rem;
     width: 100%;
     background-color: white;
     margin: 0;
 }

 .question-style{
     width: 100%;
     font-size: 1rem;
     color: #656565;
 }

 .institutions {
     width: 100%;
 }

 .institution {
     background-color: #f1f6f9;
     box-shadow: 12px 12px 23px -7px rgba(0, 0, 0, 0.671);
     width: 100%;
     max-width: 150px;
     margin: 5px auto;
     cursor: pointer;
     text-align: center;
     overflow: hidden;
     transition: transform 0.4s;
     border: 0;
     border-radius: 8px;
 }

 .institution > img {
     width: 100%;
     filter: grayscale(1);
 }

 .searchBox {
     box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
     border-radius: 5px;
     padding: 5px;
     width: 100%;
     display: grid;
     grid-template-columns: 1fr auto;
     grid-template-rows: auto;
     font-size: 1.2em;
     margin-bottom: 10px;
 }

 .searchBox > input {
     border: 0;
     cursor: pointer;
     outline: none !important;
 }

 .searchBox > i {
     padding: 5px;
 }

 .emptyList {
     font-weight: bold;
     font-size: 1.1em;
     text-align: center;
     width: 100%;
 }

 .addBut {
     margin: 5px 0;
 }

 .institution-row-style{
     border-bottom: 1px solid #ddd;
     margin: 0;
     padding: 0.5rem 0rem;
     text-align: left;
     display: flex;
     justify-content: space-between;
     align-items: center;
 }

 .img-style{
     border-radius: 100%;
     width: 2em;
     height: 2em;
     object-fit: cover;
 }

 .text-style{
     font-weight: 500;
     margin: 0;
     text-align: center;
 }

</style>
