<template>

    <div class="bg">
        <div class="container">
            <InstitutionInfo class="imgText" :title="nomeInstituicao" :crumbs="crumbs" section="instituicao-campo" :text="descricao"/>
            <div class="cardsful-bg">

            <router-link :to="{name: 'CampoListaMembros'}">
            <ColorfulCardsBG
                            :hasIcon="true"
                            class="cardful-single"
                            thumbnail="user-friends"
                            title="Membros"
                            palette="pink-section"
            />
            </router-link>

            <router-link :to="{name: 'CampoListaFormularios'}">
            <ColorfulCardsBG
                            :hasIcon="true"
                            class="cardful-single"
                            thumbnail="file-alt"
                            title="Formulários"
                            palette="pink-section"

            />
            </router-link>

            <router-link :to="{name: 'CampoListaUnidadesEducacionais'}">
            <ColorfulCardsBG
                            :hasIcon="true"
                            class="cardful-single"
                            thumbnail="graduation-cap"
                            title="Unidades Educacionais e Turmas"
                            palette="pink-section"
            />
            </router-link>

            <router-link v-if="powerBi.length" :to="{name: 'CampoDashboard'}">
            <ColorfulCardsBG
                            :hasIcon="true"
                            class="cardful-single"
                            thumbnail="project-diagram"
                            title="Dashboard"
                            palette="pink-section"
            />
            </router-link>

            </div>
        </div>
    </div>

</template>

<script>
import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo';
import ColorfulCardsBG from '@components/ColorfulCardsBG'

export default {
  name: 'Instituicao',
  data () {
    return {
      crumbs: [
        {
          id:1,
          value: '',
          iconName: 'th',
          name_route: 'GerenciarInstituicoes'
        },
        {
          id:2,
          value: 'Instituição',
          iconName: 'university',
          name_route: 'InstituicaoMenu'
        },
        {
          id:3,
          value: 'Campos',
          iconName: 'th-large',
          name_route: 'ListarCampos'
        },
        {
          id:4,
          value: 'Gerenciar Campo',
          iconName: 'chart-pie',
          name_route: ''
        }
      ]
    }
  },
  components: {
    InstitutionInfo,
    ColorfulCardsBG
    },
    created(){
        this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)
    },
    mounted() {
        window.scrollTo(0, 0);
    },
  computed: {
    campos() {
      return this.$store.getters.getListCampos || [];
    },
    campo() { return this.campos.find(c => c.idamostra == this.$route.params.idCampo) || {}; },
    nomeInstituicao() { return this.campo?.titulo; },
    descricao() { return this.campo?.descricao; },
    powerBi() { return this.campo?.power_bi || []; },
  },
}

</script>

<style scoped>
 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;
 }

.container {
    margin: 3rem auto;
}
.cardsful-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.cardful-single {
    margin: 1rem;
}
</style>
