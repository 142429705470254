<template>
    <div class="pratica-destaque d-flex ">
            
            <img class="" src="../../assets/img/BoasPraticas/aspraticas.png" alt="" />
            
            <div class="content mx-5">
                <h2>Título: {{title}}</h2>

                <div class="pratica-info d-flex mb-5">

                    <div class="autor-name mr-5 d-flex align-items-center">
                        <i class="fas fa-id-badge fa-2x mr-3"></i>
                        {{autor}}
                    </div>

                    <div class="pratica-age d-flex align-items-center">
                        <i class="fas fa-child fa-2x mr-3"></i>
                        {{idade}} anos
                    </div>

                </div>

                <h4>Sumário da Prática</h4>
                <p>
                    {{summary}}
                </p>

                <button>Acesse o material completo</button>
            </div>
            

        </div>
</template>
<script>
export default ({
        name: 'PraticaDestaque',
        props: {
            thumbnail: String,
            title: String,
            summary: String,
            idade: String,
            autor: String,
        }
    })
</script>
<style scoped>
.pratica-destaque{
    padding: 2rem 5rem;
}
h2{
    font-size: 1.6rem;
    font-weight: bold;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    color: #41B3A3;
    text-align: left;
}

i{
    color: #41B3A3;
}

button{
    border-radius: 20px;
    height: 30px;
    padding: 1% 2%;
    color: white;
    background-color: #41B3A3;
    border: 1px solid #41B3A3;
}
</style>