<template>
    <ModalCentral componentName="AddPDF" title="ADICIONAR PDF" idName="AddPDF" />
    <ModalCentral componentName="AddVideo" title="ADICIONAR VÍDEO" idName="AddVideo" />
    
    <div class="bg">

        <h2>Documentos oficiais</h2>

        <div v-show="userPerm == true" class="buttons">
            <Button
                value="Adicionar PDF"
                :isOutline="true"
                :hasIcon="true"
                :hasShadow="true"
                iconName="file-pdf"
                :isReverse="false"
                data-toggle="modal"
                data-target="#AddPDF"
                class="adminButton"
            />
            <Button
                value="Adicionar Vídeo"
                :isOutline="true"
                :hasIcon="true"
                iconName="play"
                :isReverse="false"
                data-toggle="modal"
                data-target="#AddVideo"
                class="adminButton"
            />
        </div>

        <div v-if="materials.length">
            <MaterialListItem
                v-for="(material, idx) in materials"
                :key="idx"
                :listIdx="idx + 1"
                :index="material.idModal"
                :title="material.titulo"
                :isVideo="material.isVideo"
                :link="material.link"
            />
        </div>

        <div class="none" v-else>
            <h2>Nenhum documento oficial cadastrado!</h2>
        </div>
    </div>
</template>

<script type="text/javascript">
 import MaterialListItem from '@components/MateriaiseDocumentos/MaterialListItem';
 import ModalCentral from '@components/ModalCentral';
 import Button from '@components/Button';

 export default {
     name: "DocsOficiais",
     data() {
         return {};
     },
     async created() {
		this.isLoadedInfo = false
		await this.$store.dispatch('setListModais', {
			categoria: window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1),
		})
		this.isLoadedInfo = true
	},
    computed: {
         materials() {
             return this.$store.getters.getListModais;
         },
         userPerm() {
             try{
                 return this.$store.getters.getUserInfo.data.isAdmin;
             } catch(e) {
                 return false;
             }
         },
     },
     components: {
        MaterialListItem,
        ModalCentral,
        Button,
     },
 };
</script>

<style type="text/css" media="screen" scoped>
 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.png");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;

     padding: 1em;
     box-sizing: border-box;
 }

 h2 {
     background-image: url("~@/assets/img/Backgrounds/roxoEapi.png");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .buttons {
     width: 100%;
     display: flex;
     flex-wrap: wrap;
     justify-content: flex-end;
     margin-bottom: 1em;
 }

 .adminButton {
     background-image: url("~@/assets/img/Backgrounds/roxoEapi.png");
     background-repeat: repeat;
     background-size: 256px;
     border: 0;
     box-shadow: inset -2px 2px 2px rgba(0, 0, 0, 0.25);
     color: #fcfcfc;
     margin: 5px 10px;
 }

 .adminButton span {
     background-clip: text;
     text-fill-color: transparent;
     background-image: url("~@/assets/img/Backgrounds/branco.png");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
 }

 .none {
     width: 100%;
     text-align: center;
 }

 button.grow:hover {
     transform: scale(1.01);
 }
</style>
