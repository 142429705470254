<template>
  <div class="menuWrapper">
    <div>
      <button class="btn btn-sm btn-exit"><i class="fas fa-sign-out-alt"></i>  Sair do Editor</button>
    </div>
    <div>
      <input class="form-control" type="text" name="" id="" placeholder="Formulario da Família" />
      <button class="btn btn-sm"><i class="fas fa-save"></i>  Salvar Formulário</button>
    </div>
  </div>
</template>

<script>
</script>

<style scoped>
.menuWrapper {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.menuWrapper div:first-child{
  padding: 1rem;
}

.menuWrapper div{
  padding: 1rem 1rem 1rem 0;
  display: flex;
  justify-content: space-between;
}

.btn-exit{
  background: var(--cinza);
  color: var(--branco);
}

.btn-saveForm{
  background: var(--branco);
  color: var(--cinza);
}

.form-control{
  width: auto;
}

</style>