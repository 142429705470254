<template>
    <form @submit.prevent="updateGroup" class="container offset">
        <div class="form-group">
            <label class="question-style pt-1">Nome: *</label>
            <input
                v-model.trim="nome"
                type="name"
                class="form-control"
                placeholder="Insira aqui o nome do grupo"
                required />
        </div>
        <div class="form-group">
            <label class="question-style">Descrição: *</label>
            <textarea
                v-model.trim="descricao"
                row=5
                type="name"
                class="form-control"
                placeholder="Insira aqui a descrição do grupo"
                required></textarea>
        </div>

        <div class="input-file">
            <label class="question-style">Imagem do Grupo:</label>
            <div v-cloak @click="openDialog" @drop.prevent="addFile" @dragover.prevent @dragover.capture="isDragOverActions()" @dragleave.capture="isDragLeaveActions()" class="drag-and-drop" :class="{'drag-style': isDragOver}">
                <div v-if="arquivo == null" class="text-center infos">
                    <img class="img-fluid img-center-input-file" :src="require('@assets/img/Dados/ExportarDevolutiva/'+name_image+'.png')">
                    <p class="label-center-input-file">{{label}}</p>
                </div>
                <div class="file-uploaded" v-else>
                    <img class="img-icon-uploaded" src="@assets/img/Dados/ExportarDevolutiva/img-icon.png" alt="">
                    {{ arquivo.name.substr(0, 7)+'...'}} {{(arquivo.size/1000).toFixed(1)+ ' KB'}}
                </div>
            </div>
            <button v-if="arquivo != null" class="remove-file" @click="removeFile()" title="Remove">Remover Arquivo</button>
            <input tabindex="-1" @change="addFileViaInput" type="file" ref="uploadFile" placeholder="Escolha ou arraste um arquivo para gerar a devolutiva" hidden>
        </div>

        <div class="button-div pt-4">
            <Button
                section="dados-outline"
                value="Atualizar informações"
                :isOutline="true"
                :hasIcon="true"
                iconName="save" />
        </div>
    </form>
</template>

<script>
 import Button from '@components/Button';
 import Swal from "sweetalert2";
 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: 'left',
         y: 'bottom',
     }
 });

 export default {
     name: "UpdateGrupo",
     components: {
         Button,
     },
     props: {
         data: {
             type: Object,
             required: true
         },
     },
     data() {
         return {
             loaded: false,
             groupData: {},
             id: this.data.idGrupo,
             nome: '',
             descricao: '',

             label_button: 'Fazer Upload',
             isTurningCog: false,
             arquivo: null,
             isDragOver: false,
             name_image: "icone-upload",
             label: "Escolha ou arraste uma imagem"
         }
     },
     async created() {
         await this.$store.dispatch('setListGroups');
         this.groupData = this.$store.getters.getListGroups?.find(group => group.idgrupo == this.id);
         this.nome = this.groupData?.name;
         this.descricao = this.groupData?.descricao;
     },
     methods: {
         async updateGroup() {
             await this.$store.dispatch("updateGroup", {
                 idGrupo: this.id,
                 nome: this.nome,
                 descricao: this.descricao,
                 imagem: this.arquivo,
             })

             if (this.$store.getters.getGroupUpdated) {
                 notyf.open({
                     type: "success",
                     message: "Grupo atualizado com sucesso!"
                 });
             } else {
                 notyf.open({
                     type: "error",
                     message: "Erro ao atualizar o grupo"
                 });
             }
         },
         // METODOS DO FILE INPUT IMAGE
         isDragOverActions: function() {
             this.isDragOver = true
             this.label = "Solte o arquivo aqui!"
             this.name_image = "icone-upload-happy"
         },
         isDragLeaveActions: function() {
             this.isDragOver = false
             this.label = "Clique ou arraste um arquivo para gerar a devolutiva"
             this.name_image = "icone-upload"
         },
         addFile: function(e) {
             let droppedFiles = e.dataTransfer.files;
             this.isDragLeaveActions()
             for (let f of droppedFiles) {
                 if (f.type.split("/")[0] == "image") {
                     this.arquivo = f;
                     console.log(this.arquivo)
                 }
                 else {
                     Swal.fire(
                         'Erro!',
                         'Só é permitido arquivos de imagem.',
                         'error'
                     )
                 }
             }
         },
         addFileViaInput: function() {
             let droppedFiles = this.$refs.uploadFile.files;
             for (let f of droppedFiles) {
                 if (f.type.split("/")[0] == "image") {
                     this.arquivo = f;
                 }
                 else {
                     Swal.fire(
                         'Erro!',
                         'Só é permitido arquivos de imagem.',
                         'error'
                     )
                 }
             }
         },
         removeFile(){
             this.arquivo = null;
         },
         openDialog: function() {
             this.$refs.uploadFile.value = ''
             this.$refs.uploadFile.click()
         },
     }
 };
</script>

<style scoped lang="scss">
 @import "../../assets/scss/_variables.scss";

 .container{
     width: 100%;
     background-color: white;
 }

 .title-style{
     font-size: 1.5rem;
     font-weight: 600;
     color: #656565;
 }

 .question-style{
     width: 100%;
     font-size: 1rem;
     color: #656565;
 }

 .image-style{
     display: block;
     margin-left: auto;
     margin-right: auto;
     width: 50%;
     padding: 1rem;
 }

 .button-div{
     text-align: right;
 }

 /* INPUT FILE STYLES */

 .input-file {
     margin-top: 1rem;
     display: flex;
     align-items: center;
     flex-direction: column;
     justify-content: center;
     min-height: 40vh;
     position: relative;
 }

 .input-file input {
     max-width: 30rem;
 }

 .input-file h1 {
     font-size: 1.6rem;
     font-weight: bold;
     text-align: left;
     margin-top: 1rem;
     margin-bottom: 1.5rem;
     color: #0d9f9f;
 }

 .input-file button {
     border: 1px solid $verdeEscuro-eapi;
     color: #0d9f9f;
     background-color: white;
     border-radius: 4px;
     padding: 0.4rem 1.2rem 0.4rem 0.4rem;
     font-weight: bold;
     cursor: pointer;
     outline: none;
     transition: all ease-in-out 0.2s;
     -webkit-box-shadow: 10px 10px 5px -1px rgba(0,0,0,0.51);
     -moz-box-shadow: 10px 10px 5px -1px rgba(0,0,0,0.51);
     box-shadow: 6px 6px 5px -1px rgba(0,0,0,0.2);
 }

 .input-file button:hover {
     color: white;
     background-color: $verdeEscuro-eapi;
 }

 .drag-and-drop {
     height: 14rem;
     width: 27rem;
     border: 4px dashed $verdeEscuro-eapi;
     background-color: white;
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     cursor: pointer;
     padding: 0 1.5rem;
     margin: 0 2rem;
 }

 .drag-and-drop img {
     max-height: 5rem;
 }

 .drag-and-drop p {
     color: #656565;
     font-weight: 400;
     user-select: none;
 }

 .drag-style {
     filter: blur(0.7px);
 }

 .file-uploaded {
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     margin-top: -2.5rem;
 }

 .animateCog {
     animation: cogAnimation linear infinite 1s;
 }

 .infos {
     pointer-events: none;
 }

 .input-file .remove-file {
     border: 1px solid #fd5454;
     color: #fd5454;
     padding: 0.4rem;
     margin-top: 0.2rem;
     font-size: 0.9rem;
     position: absolute;
     bottom: 24%;
 }

 .input-file .remove-file:hover {
     color: white;
     background-color: #fd5454;
 }

 @keyframes cogAnimation {
     to {
         transform: rotate(360deg);
     }
 }

 .fade-enter-active, .fade-leave-active {
     transition: opacity .5s;
 }
 .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
     opacity: 0;
 }

 @media screen and (max-width: 728px) {
     .drag-and-drop{
         width: auto;
         margin: 0 1rem;
     }
 }

 .img-icon-uploaded{
     max-height: 17% !important;
     width: auto;
 }
</style>
