//---------------------------------------------
// Formulários Module 
//---------------------------------------------
// Módulo para gerenciar as ações dos formulários

// import axios from 'axios';
// axios.defaults.baseURL = process.env.VUE_APP_API_ROOT ? process.env.VUE_APP_API_ROOT : 'http://localhost:3000'
import axios from '@middleware/api.js'
import Cookies from 'js-cookie'
var JWT = () => {
    return (Cookies.get("accessToken") ? Cookies.get("accessToken") : "JWT VAZIO")
}

// STATES
const state = () => ({
    listFormularios: []
})

// MUTATIONS
const mutations = {
     //-- MUTATION REQUISICAO DO LISTFORMS
     setListFormularios(state, payload) {
        state.listFormularios = payload
    },
}

// ACTIONS
const actions = {
      //-- ACTION REQUISICAO DO LISTFORMS
      async setListFormularios({ commit }, idInstituicao) {
        await axios
            .get(`formularios/${idInstituicao}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListFormularios', response.data)
            })
            .catch((error) => console.log(error.message))
    },

    //-- ACTION DELETE FORMULARIO 
    async deleteFormulario(_, payload) {
        await axios
            .delete(`formularios/${payload.idInstituicao}/${payload.idFormulario}`, {
                idFormulario: payload.idFormulario
            },{
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .catch((error) => console.log(error.message))
    },
}

// GETTERS
const getters = {
    getListFormularios(state) {
        try {
            return state.listFormularios.data
        } catch (e) {
            return []
        }
    },
}

export default {
    state,
    mutations,
    actions,
    getters
}
