<!--
     |---------------------------------------------
     | ListGroups Component
     |---------------------------------------------
     | @usage Import the component and use, no params
     | List all teams and show its filters
-->
<template> 
    <div class="list-teams bg">

        <div>
            <Panel 
                :infos="infosPanel"
                :teams="groups"
            />

            <div class="container mt-5">
                <Button
                    class="grow entendaButton"
                    value="Entenda"
                    iconName="question-circle"
                    section="painel-outline"
                    :isOutline="true"
                    :hasIcon="true"
                    :hasShadow="true"
                    data-target="#idBotaoGrupos"
                    data-toggle="modal"
                />
            </div>
        </div>
        <!-- MODAL CENTRAL DO 'ENTENDA' -->
        <ModalCentral
            componentName="Carousel"
            :slides="infosCarousel"
            title="Grupos de Pesquisa"
            idName="idBotaoGrupos"
        />

        <div v-if="groups.length">
            <div class="container mt-5">
                <!-- {{teste}} -->
                <!-- INICIO DO FORM DE FILTRAGEM -->
                <form @submit.prevent="" class="form-inline">
                    <div class="form-group">

                        <!-- DROPDOWN DE SELECAO DE FILTRO -->
                        <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomSelect">Filtrar por: </label>
                        <Filter ref="filtersearchname" @click="setSelectedValue()" :optionsSelect="options" nameSelect="filtro_instituicoes" id="inlineFormCustomSelect"/>
                    </div>

                    <!-- SE A SELECAO FOR NOME (2) ENTAO EXIBIRA OS SUBFILTROS DE NOME -->
                    <div class="form-group" v-if="selectedValue == 2">
                        <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomName">Digite o nome: </label>
                        <input class="input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_instituicoes" id="inlineFormCustomName" autocomplete="off">

                        <input class="radio-personal mr-sm-2" v-model="searchWithOrder" value="cres" type="radio" name="filtro_nome_ordenacao" id="inlineFormCustomRadioAZ" checked>
                        <label class="mr-sm-2 text-nowrap label-filtro mr-sm-4" for="inlineFormCustomRadioAZ">A - Z</label>

                        <input class="radio-personal mr-sm-2" v-model="searchWithOrder" value="desc" type="radio" name="filtro_nome_ordenacao" id="inlineFormCustomRadioZA">
                        <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomRadioZA">Z - A</label>
                    </div>

                    <!-- SE A SELECAO FOR POR GRUPOS (3) ENTAO EXIBIRA OS SUBFILTROS DOS GRUPOS -->
                    <div class="form-group" v-if="selectedValue == 3">
                        <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomCodigo">Selecione o grupo: </label>
                        <Filter ref="filtersearchgroup" @click="setSelectedGroup()" :optionsSelect="group_options" nameSelect="filtro_grupo_instituicoes" id="inlineFormCustomCodigo"/>
                    </div>

                </form>
            </div>

            <transition-group tag="div" class="cards mt-5" name="listanimate" v-if="filterInstituicao">
                <div class="card-personal mb-4" v-for="group in filterInstituicao" :key="group.idgrupo">
                    <GroupCardsBG
                        :id="parseInt(group.idgrupo)"
                        :name="group.name"
                        :nr_members="group.nr_members"
                        :nr_institutions="group.nr_institutions"
                        :thumbnail="group.thumbnail"/>
                </div>
            </transition-group>
            <transition-group tag="div" class="empty" v-else>
                <h2 class="title-style">Nenhum resultado encontrado.</h2>
            </transition-group>
        </div>

    </div>
    
</template>

<script>

 import GroupCardsBG from '@dados/GerenciarGrupos/GroupCardsBG';
 import Filter from '@components/Filter';
 import Panel from '@dados/GerenciarInstituicoes/Panel';
 import Button from '@components/Button';
 import ModalCentral from '@components/ModalCentral';

 export default {
     name: 'ListGroups',
     data() {
         return {
             qtdTeams: 0,
             selectedValue: '',
             searchByName: '',
             searchByCode: '',
             searchWithOrder: '',
             selectedGroupFilter: 0,
             teste: [],
             options: [
                 {
                     id:1,
                     value: 'Todos'
                 },
                 {
                     id:2,
                     value: 'Nome'
                 },
                 {
                     id:3,
                     value: 'Grupo de Pesquisa'
                 }
             ],
             infosPanel: {
                 title: 'Grupos de Pesquisa',
                 firstButtonTitle: 'Buscar Grupos',
                 firstButtonText: 'Faz parte de algum grupo? Encontre-o e solicite sua entrada',
                 secondButtonTitle: 'Criar Grupo',
                 secondButtonText: 'Crie um novo grupo na plataforma',
                 thumbnail: 'ilustra1-section.png',
                 type: 'Group'
             },
             infosCarousel: [
                 {
                     id:0,
                     title: 'O que são Grupos de Pesquisa?',
                     text: 'Grupos de pesquisa são estruturas ou mecanismos de ordem social, que regulam o comportamento de um conjunto de indivíduos dentro de uma determinada comunidade. Grupos de pesquisa são identificadas com uma função social, que transcende os indivíduos e as intenções mediando as regras que governam o comportamento vivo.',

                 },
                 {
                     id:1,
                     title: 'Como buscar Grupos de Pesquisa?',
                     text: 'Clique no Botão "Buscar Grupo de Pesquisa", busque a instituição desejada e clique em "Enviar" para enviar uma solicitação de entrada ao Administrador da Instituição.'

                 },
                 {
                     id:2,
                     title: 'Como criar Grupos de Pesquisa?',
                     text: 'Clique no Botão "Criar Grupo de Pesquisa", preencha os campos solicitados e siga os passos.'

                 },

             ]
         }
     },
     components: {
         GroupCardsBG,
         Filter,
         Panel,
         Button,
         ModalCentral
     },
     methods: {
         setSelectedValue () {
             this.selectedValue = this.$refs.filtersearchname.value
         },
         setSelectedGroup () {
             this.selectedGroupFilter = this.$refs.filtersearchgroup.value
         },
     },
     async created() {
         this.$store.dispatch('setListGroups')
     },
     mounted: function() {
         this.qtdTeams = Object.keys(this.groups).length;
     },
     computed: {
         groups() {
             return this.$store.getters.getListGroups
         },
         filterInstituicao: function() {
             //VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
             var query = this.groups;
             query = query?.sort((a, b) => a?.name.localeCompare(b?.name));

             //CASO SEJA SELECIONADO TODOS, EXIBE TODOS OS ITENS DO ARRAY
             if (this.selectedValue == 1) {
                 query = this.groups;
                 query = query?.sort((a, b) => a?.name.localeCompare(b?.name));
             }

             //CASO SEJA SELECIONADO NOME, FILTRA POR NOME
             else if (this.selectedValue == 2) {

                 //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
                 if (this.searchByName.trim() != '')
                     query = query?.filter(group => group.name.trim().toLowerCase().startsWith(this.searchByName.trim().toLowerCase()))

                 //ORDERNA CRESCENTEMENTE
                 if (this.searchWithOrder == 'cres') {
                     query?.sort((a, b) => a?.name.localeCompare(b?.name));
                 }

                 //ORDERNA CRESCENTEMENTE
                 if (this.searchWithOrder == 'desc') {
                     query?.reverse((a, b) => a?.name.localeCompare(b?.name));
                 }

             }

             //CASO SEJA SELECIONADO GRUPO DE PESQUISA, FILTRA POR GRUPO
             else if (this.selectedValue == 3) {
                 if (this.selectedGroupFilter == 2) {
                     query = query.filter(group => group?.gp_research == 'LEPES X')
                 } else if (this.selectedGroupFilter == 3) {
                     query = query.filter(group => group?.gp_research == 'Secretaria X')
                 }
             }

             return query;
         }
     }
 }

</script>

<style scoped>
 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;
 }


 .list-teams {
     min-height: 90vh;
     padding-top: 2rem;
     width: 100%;
     padding-bottom: 1em;
 }

 .title-style{
     font-size: 25px;
     color: #656565;
     margin: 0;
     text-align: center;
     font-weight: 500;
 }

 .about-team {
     background-color: rgb(255, 255, 255);
     display: flex;
     align-items: center;
     justify-content: space-around;
     border-radius: 12px;
     padding: 1rem 4rem;
 }

 .img-about-team {
     max-height: 16rem;
 }

 .box-btn-about-team button {
     background-color: transparent;
     outline: none;
     cursor: pointer;
     padding: .3rem .8rem;
     border-radius: 6px;
     color: #105B9F;
     border: 3px solid #105B9F;
     font-size: 1.1rem;
     display: flex;
     justify-content: flex-end;
     align-items: center;
 }

 .box-btn-about-team i {
     font-size: 1.3rem;
 }

 .label-filtro {
     font-weight: 600;
 }

 /* Código para centralizar os cards como se fosse
    (flex-start + div centralizada) */
 .cards {
     display: grid;
     grid-template-columns: repeat(3, minmax(21rem, max-content));
     grid-gap: 1.8rem;
     justify-content: center;
 }

 /* Responsoivizando alinhamento dos cards */
 @media screen and (max-width: 1160px) {
     .cards {
         grid-template-columns: repeat(auto-fit, minmax(21rem, max-content));
     }
 }

 .card-personal {
     width: 21rem;
 }

 .card.custom-rounded {
     border-radius: 20px;
     box-shadow: 12px 12px 23px -7px rgba(158,146,158,0.49);
 }

 .filter-box {
     margin: 2rem 4rem 2rem 0;
     margin-left: 6.5vw;
 }

 .card-body {
     text-align: center;
     font-weight: bold;
     min-height: 116px;
 }

 .card-text {
     padding-top: 1rem;
     color: #212529;
     font-size: 1rem;
 }

 .card-img-top {
     cursor: pointer;
 }

 @media screen and (max-width: 1012px) {
     .filter-box {
         display: flex;
         align-items: center;
         justify-content: center;
     }
 }

 .listanimate-enter-active,
 .listanimate-leave-active {
     transition: all 0.4s;
 }

 .listanimate-enter,
 .listanimate-leave-to  {
     transform: scale(0);
     opacity: 0;
 }

 .entendaButton {
     background-image: url("~@/assets/img/Backgrounds/verdeEscuroEAPI.webp");
     background-repeat: repeat;
     background-size: 256px;
     border: 0;
     box-shadow: inset -2px 2px 2px rgba(0, 0, 0, 0.25);
 }

 :deep(.entendaButton div) {
     background-clip: text;
     text-fill-color: transparent;
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
 }

 .empty {
     margin-top: 1.8rem;
 }
</style>
