<template>
    <div class="container offset">
        <!-- <form @submit.prevent="createUnidade" ref="formUE" @focus="lastAdd = ''"> -->
        <form @submit.prevent="() => {}" ref="formUE" @focus="lastAdd = ''">
        <div class="custom-form">

            <div class="form-group">
                <label class="question-style pt-1">Nome da Unidade Educacional: *</label>
                <input
                    v-model.trim="UEData.nome"
                    type="name"
                    class="form-control"
                    placeholder="Insira aqui o nome da unidade educacional"
                    required
                />
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label class="question-style">Município: *</label>
                    <input
                        v-model.trim="UEData.municipio"
                        type="name"
                        class="form-control"
                        placeholder="Insira o município da unidade"
                        required
                    />
                </div>

                <div class="form-group col-md-6">
                    <label class="question-style">Bairro: *</label>
                    <input
                        v-model.trim="UEData.bairro"
                        type="name"
                        class="form-control"
                        placeholder="Insira o bairro da unidade"
                        required
                    />
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-8">
                    <label class="question-style">Rua: *</label>
                    <input
                        v-model.trim="UEData.rua"
                        type="name"
                        class="form-control"
                        placeholder="Insira a rua da unidade"
                        required
                    />
                </div>

                <div class="form-group col-md-4">
                    <label class="question-style">Número: *</label>
                    <input
                        v-model.trim="UEData.numero"
                        type="number"
                        min="0"
                        class="form-control"
                        placeholder="Insira o número da unidade"
                        required
                    />
                </div>
            </div>


            <div class="form-row">
                <div class="form-group col-md-4">
                    <label class="question-style">Complemento:</label>
                    <input
                        v-model.trim="UEData.complemento"
                        type="name"
                        class="form-control"
                        placeholder="Insira o complemento da unidade"
                    />
                </div>

                <div class="form-group col-md-4">
                    <label class="question-style">Ano de início:</label>
                    <input
                        v-model.trim="UEData.anoInicio"
                        type="number"
                        min="1900"
                        max="2021"
                        class="form-control"
                        placeholder="Insira o ano de início das atividades"
                    />
                </div>

                <div class="form-group col-md-4">
                <label class="question-style">Código INEP:</label>
                <input
                    v-model.trim="UEData.inep"
                    type="number"
                    min="0"
                    class="form-control"
                    placeholder="Insira o códgio INEP da unidade educacional"
                />
                </div>

            </div>

            <div class="d-flex">
                <div class="d-flex flex-column align-items-start macroarea-wrapper">
                    <label class="question-style pt-1">Macroárea: *</label>
                    <input class="form-control" type="text" v-model.trim="UEData.macroarea" list="macroareas" placeholder="Selecione ou digite uma macroárea...">
                    <datalist id="macroareas"  required>
                        <!-- <option hidden class="question-style">Selecione aqui a Macroárea:</option> -->
                        <option v-for="mac in macroareas" :key="mac.counter" :value="mac.macroarea">{{mac.macroarea}}</option>
                    </datalist>
                </div>
                <!-- <div class="button-add-macroarea-wrapper">
                    <i class="add-macroarea fas fa-plus"></i>
                </div> -->
            </div>
          
            <label class="question-style pt-2">Modalidade: *</label>
            <select class="form-control" v-model.trim="UEData.tipoModalidade" required>
                <option hidden class="question-style">Selecione aqui o tipo da unidade educacional:</option>
                <option class="question-style" value="15">Pública</option>
                <option class="question-style" value="16">Privada</option>
                <option class="question-style" value="17">Filantrópica</option>
                <option class="question-style" value="18">Parceira/Conveniada</option>
            </select>


            <label class="question-style pt-2">Localização: *</label>
            <select class="form-control" v-model.trim="UEData.tipoLocalizacao" required>
                <option hidden class="question-style">Selecione aqui a localização da unidade educacional:</option>
                <option class="question-style" value="9">Rural</option>
                <option class="question-style" value="10">Indígena</option>
                <option class="question-style" value="11">Urbana</option>
                <option class="question-style" value="12">Assentamento</option>
                <option class="question-style" value="13">Área remanescente de quilombos</option>
                <option class="question-style" value="14">Unidade de uso sustentável</option>
            </select>
        </div>

        <div class="formMsg"
             :class="{'formSuccess': lastAdd.length, 'formError': !lastAdd.length}">
            Última Unidade Educacional adicionada: '{{ lastAdd }}'
        </div>


        <div class="formButtons">
            <div class="button-div pt-4">
                <Button
                    value="Limpar"
                    section="descartar"
                    :isOutline="true"
                    :hasIcon="true"
                    iconName="trash"
                    @click="clearForm" />
            </div>

            <div class="button-div pt-4">
                <Button
                    section="instituicao-cadastrar"
                    value="Salvar UE"
                    :isOutline="true"
                    :hasIcon="true"
                    iconName="save"
                    @click="createUnidade" />
            </div>
        </div>

        </form>
    </div>
</template>

<script>

import Button from '@components/Button'
// import Swal from "sweetalert2";
import { Notyf } from "notyf";
const notyf = new Notyf({
    duration: 5000,
    position: {
        x: "left",
        y: "bottom",
    }
});

export default {
    name: "AddEducationalUnit",
    data() {
        return {
            UEData: {
            nome: '',
            bairro: '',
            macroarea: '',
            inep: '',
            tipoLocalizacao: '',
            tipoModalidade: '',
            anoInicio: '',
            municipio: '',
            rua: '',
            numero: '',
            complemento: '',
            },
            lastAdd: ''
        }
    },
    computed: {
        macroareas(){
            return this.$store.getters.getListMacroareas;
        },
    },
    async created() {
        this.$store.dispatch('setListMacroareas', this.$route.params.idInstituicao)
    },
    methods: {
        async openModalMacroarea() {
            

            // const { value: macroarea_input } = await Swal.fire({
            // title: "Insira aqui a macroárea",
            // text: "Iremos reenviar o email de validação para você.",
            // icon: "info",
            // input: "text",
            // inputPlaceholder: 'Digite aqui..',
            // inputAttributes: {
            //     autocapitalize: "off",
            // },
            // showCancelButton: true,
            // confirmButtonColor: "#3085d6",
            // cancelButtonColor: "#d33",
            // cancelButtonText: "Cancelar",
            // confirmButtonText: "Adicionar",
            // inputValidator: (value) => {
            //     if (!value) {
            //     return 'Você precisa digitar a macroárea.'
            //     }
            // }
            // })

            // if (macroarea_input) {
            //  await this.$store.commit('setAddMacroarea', macroarea_input)
            //  this.macroarea = macroarea_input
            // }
        },
        async createUnidade() {
            // eslint-disable-next-line no-unused-vars
            const sendData = Object.fromEntries(Object.entries(this.UEData).filter(([_, prop]) => prop != ""));
            const payload = {
                idInstituicao: this.$route.params.idInstituicao,
                ...sendData
            }


            const payload2 = {
                idInstituicao: this.$route.params.idInstituicao
            } 

            await this.$store.dispatch('setCreateUE', payload)
            await this.$store.dispatch('setListUE', payload2)
            await this.$store.dispatch('setListMacroareas', this.$route.params.idInstituicao)

            if (this.$store.getters.getIsUECreated){
                this.lastAdd = this.UEData.nome;
                notyf.open({
                    type: "success",
                    message: "Unidade adicionada com sucesso!",
                });
                this.clearForm()
            } else {
                notyf.open({
                    type: "error",
                    message: "Erro na criação da unidade!",
                });
            }
        },
        clearForm(){
                Object.keys(this.UEData).map((prop)=>this.UEData[prop] = '' )
        }
    },
    components: {
        Button
    }
    
}

</script>

<style scoped>

    .container{
        width: 100%;
        background-color: white;
    }

    .title-style{
        font-size: 1.5rem;
        font-weight: 600;
        color: #656565;        
    }

    .question-style{
        width: 100%;
        font-size: 1rem;
        color: #656565;
    }

    .macroarea-wrapper {
        width: 100%;
    }

    .button-add-macroarea-wrapper {
        position: relative;
        width: 10%;
    }

    .add-macroarea {
        position: absolute;
        right: 20%;
        bottom: 15%;
        font-size: 1.4rem;
        color: #676bd6;
        cursor: pointer;
        transition: all ease 0.3s;
    }

    .add-macroarea:hover {
        color: #3e4194;
    }

    .image-style{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        padding: 1rem;
    }

    .button-div{
        text-align: center;
    }

 .formMsg {
     margin-top: 5px;
 }

 .formSuccess {
     color: green;
 }

 .formError {
     display: none;
 }

 .formButtons {
     display: grid;
     grid-template-columns: 1fr 1fr;
     grid-template-rows: auto;
     align-content: space-around;
 }

</style>
