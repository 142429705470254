<template>

    <div class="bg">
        <div class="list-data">

            <div class="title-tree">
                <Breadcrumb :crumbs="crumbs" section="instituicao-campo"/>
            </div>

            <InstitutionInfo class="title-style" :title="nomeCampo" section="instituicao-cadastrar"/>

            <p class="text-info-personal">Nesta seção, você encontrará todos os Membros pertencentes ao Campo. Caso necessário, utilize o filtro abaixo para localizar o Membro desejado.</p>

            <div class="itens-box">
                <div class="filter-box">
                    <!-- INICIO DO FORM DE FILTRAGEM -->
                    <form @submit.prevent="" class="form-inline">
                        <div class="form-group">

                            <!-- DROPDOWN DE SELECAO DE FILTRO -->
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomSelect">Filtrar por: </label>
                            <Filter ref="filtersearchname" @change="setSelectedValue()" :optionsSelect="options" nameSelect="filtro_instituicoes" id="inlineFormCustomSelect"/>
                        </div>

                        <!-- SE A SELECAO FOR NOME (2) ENTAO EXIBIRA OS SUBFILTROS DE NOME -->
                        <div class="form-group" v-if="selectedValue == 2">
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomName">Digite o nome: </label>
                            <input class="input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_instituicoes" id="inlineFormCustomName" autocomplete="off">
                        </div>

                    </form>
                </div>
                <div v-if="userPerm.permissao != 'APLICADOR'" class="lista-data-info">
                    <Button
                        v-if="campoStatus != 'ENCERRADO'"
                        @click="setSelectAllItems"
                        class="btn"
                        section="instituicao-campo"
                        value="Marcar Todos"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="check-square"
                    />
                    <Button
                        v-if="campoStatus != 'ENCERRADO'"
                        @click="setUnselectAllItems"
                        class="btn ml-2"
                        section="instituicao-campo"
                        value="Desmarcar Todos"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="square"
                    />
                    <Button
                        v-if="campoStatus != 'OFF'"
                        @click="applyChanges"
                        class="btn ml-2"
                        section="instituicao-campo"
                        value="Aplicar Alterações"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="check"
                    />
                </div>
            </div>
            <hr class="separator">

            <!-------------------------------- LISTANDO OS MEMBROS ----------------------------------------------->

            <div v-if="isLoadedInfo">
                <MemberQnt membros="membros" :totalQnt="totalMembros" :filteredQnt="membros_filtered?.length || 0" />

                <transition-group name="slide-fade" v-if="membros_filtered?.length > 0">
                    <div class="box-header">
                        <span></span>
                        <span>Nome (Email)</span>
                        <span>Função</span>
                        <span>Controle</span>
                    </div>

                    <div class="box-lista" v-for="membro in membros_filtered" :key="membro.counter" :class="{'list-item-selected-pink': membro.isSelect.isSelected}">
                        <div>
                            <img v-if="membro.permissao == 'ADMINISTRADOR'" class="img-info-box-lista" :class="{'pink-img': membro.isSelect.isSelected, 'black-img': !membro.isSelect.isSelected}" src="@assets/img/Dados/ListarMembros/admin-user-blue.png" alt="">
                            <img v-else-if="membro.permissao == 'COORDENADOR'" class="img-info-box-lista" :class="{'pink-img': membro.isSelect.isSelected, 'black-img': !membro.isSelect.isSelected}" src="@assets/img/Dados/ListarMembros/coordenador-user-blue.png" alt="">
                            <img v-else class="img-info-box-lista" :class="{'pink-img-multiple': membro.isSelect.isSelected, 'black-img': !membro.isSelect.isSelected}" src="@assets/img/Dados/ListarMembros/aplicador-user-blue.png" alt="">
                        </div>
                        <span>{{membro.nome}} ({{membro.email}})</span>
                        <span>{{membro.permissao}}</span>

                        <div v-if="userPerm.idPermissao != '1'">
                            <div v-if="membro.isLocked">Selecionado</div>
                            <div v-else>
                                <div v-if="['ON', 'INATIVO'].includes(campoStatus)" @click="setIsSelected(membro.idusuario)" class="select-info">
                                    <div :class="['box-select-info mr-2 ',{'selected-info': membro.isSelect.isSelected}]"></div>
                                    <span class="span-label-selected" v-if="membro.isSelect.isSelected">Selecionado</span>
                                    <span class="span-label-selected" v-else>Selecionar</span>
                                </div>
                                <div v-else class="select-info-blocked">
                                    <span class="span-label-selected" v-if="membro.isSelect.isSelected">Selecionado</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition-group>
                <transition-group v-else>
                    <h2 class="title-style-box-lista">Nenhum aplicador encontrado.</h2>
                </transition-group>

            </div>

            <div v-else>
                <SkeletonList/>
            </div>

        </div>
    </div>

</template>

<script>
 import Filter from '@components/Filter'
 import Button from '@components/Button'
 import Breadcrumb from '@components/Breadcrumb'
 import Swal from 'sweetalert2';
 import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
 import Amostra from '@middleware/amostra.js'
 import SkeletonList from '@components/SkeletonUI/SkeletonList'
 import MemberQnt from '@dados/VisualizarInstituicao/MemberQnt'

 export default {
     name: 'CampoListaMembros',
     data (){
         return {
             nomeCampo: '',
             descricao: '',
             selectedValue: '',
             searchByName: '',
             searchByCode: '',
             searchWithOrder: '',
             isLoadedInfo: false,
             teste: [],
             membros: [],
             membros_amostra: [],
             options: [
                 {
                     id:1,
                     value: 'Todos'
                 },
                 {
                     id:2,
                     value: 'Nome'
                 },
                 {
                     id:3,
                     value: 'Selecionados',
                 },
             ],
             crumbs: [
                 {
                     id:1,
                     value: '',
                     iconName: 'th',
                     name_route: 'GerenciarInstituicoes'
                 },
                 {
                     id:2,
                     value: 'Instituição',
                     iconName: 'university',
                     name_route: 'InstituicaoMenu'
                 },
                 {
                     id:3,
                     value: 'Campos',
                     iconName: 'th-large',
                     name_route: 'ListarCampos'
                 },
                 {
                     id:4,
                     value: 'Gerenciar Campo',
                     iconName: 'chart-pie',
                     name_route: 'InstituicaoMenuCampo'
                 },
                 {
                     id:5,
                     value: 'Membros',
                     iconName: 'user-friends',
                     name_route: ''
                 }
             ]
         }
     },
     watch: {
         rawListMembros() { this.setData() },
         rawListCampoMembros(){ this.setData() },
     },
     computed: {
         campoStatus() {
             return this.$store.getters.getAmostraStatusAtiva
         },
         userPerm() {
             return this.$store.getters.getUserPerm
         },
         totalMembros() {
             return this.membros?.length || 0;
         },
         membros_filtered() {
             // VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
             var query = this.membros;

             //CASO SEJA SELECIONADO TODOS, EXIBE TODOS OS ITENS DO ARRAY
             if (this.selectedValue == 1) {
                 query = this.membros;
             }

             //CASO SEJA SELECIONADO NOME, FILTRA POR NOME
             if (this.selectedValue == 2) {
                 //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
                 if (this.searchByName.trim() == '') {
                     query = this.membros;
                 }
                 //SENAO ELE FILTRA PELO O QUE FOR DIGITADO
                 else {
                     var filtered = this.membros.filter(membro =>membro.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
                     // console.log(filtered);
                     if(filtered?.length != 0){
                         query = filtered
                     }

                     else{
                         query = false
                     }
                 }
             } else if (this.selectedValue === 3) {
                 query = this.membros.filter(membro => membro.isSelect.isSelected);
             }
             return query;

         },
         rawListMembros() { return this.$store.getters.getListMembros || [] },
         rawListCampoMembros() { return this.$store.getters.getListCampoMembros || [] },
     },
     async created() {
         this.isLoadedInfo = false

         this.campos = this.$store.getters.getListCampos

         this.loadData()

         const payload = {
             idInstituicao: this.$route.params.idInstituicao
         }
         await this.$store.dispatch('setUserPerm', payload)
         await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)

         const all_campos = this.$store.getters.getListCampos
         const campo = all_campos.filter((value) => {
             return value.idamostra == this.$route.params.idCampo
         }).map((value) => {return value.isativo})

         const campoStatus = campo[0]

         this.$store.commit('setAmostraStatusAtiva', campoStatus)
     },
     components: {
         Filter,
         Button,
         Breadcrumb,
         InstitutionInfo,
         SkeletonList,
         MemberQnt,
     },
     methods: {
         setIsSelected(id) {
             this.membros = Amostra.setIsSelectedItem(this.membros, 'usuario', id)
         },
         setSelectAllItems() {
             this.membros = Amostra.setSelectAllItems(this.membros, 'selectMembros')

             Amostra.setAllSelectedItems(this.membros, 'aplicador')
         },
         setUnselectAllItems() {
             this.membros = Amostra.setUnselectAllItems(this.membros, 'selectMembros')

             Amostra.setAllSelectedItems(this.membros, 'aplicador')
         },
         setData() {
             this.membros = this.$store.getters.getListMembros
             this.membros_amostra = this.$store.getters.getListCampoMembros

             this.membros.forEach(membro => {
                 membro.isLocked = false
                 this.membros_amostra.forEach(membro_amostra => {
                     if (membro_amostra.idusuario == membro.idusuario) {
                         membro.isLocked = membro_amostra.isLocked
                     }
                 })
                 membro.isSelect = {
                     isSelected: this.membros_amostra.some(membro_amostra => membro_amostra.idusuario == membro.idusuario),
                     isSaved: true
                 }
             })

             this.isLoadedInfo = true
         },
         async loadData() {
             const payload = {
                 idInstituicao: this.$route.params.idInstituicao,
                 idAmostra: this.$route.params.idCampo
             }
             await this.$store.dispatch('setListCampoMembros', payload)
             await this.$store.dispatch('setListMembros', this.$route.params.idInstituicao)
         },
         async applyChanges() {
             if (this.$store.getters.getAmostraStatusAtiva == 'ON') {
                 const result = await Swal.fire({
                     title: "Tem certeza que deseja aplicar as alterações?",
                     text: "Você está em um campo ativo, os elementos inseridos não poderão ser modificados.",
                     icon: "question",
                     showCancelButton: true,
                     confirmButtonColor: "#3085d6",
                     cancelButtonColor: "#d33",
                     cancelButtonText: "Cancelar",
                     confirmButtonText: "Sim, tenho certeza",
                 })

                 if (result.isConfirmed) {

                     Amostra.setAllSelectedItems(this.membros, 'aplicador')

                     const payloadManageAplicadores = this.$store.getters.getPayloadManageAplicadores
                     const payload1 = {
                         idInstituicao: this.$route.params.idInstituicao,
                         idAmostra: this.$route.params.idCampo,
                         ids: JSON.parse(JSON.stringify(payloadManageAplicadores))
                     }

                     await this.$store.dispatch('setManageAplicadores', payload1)

                     this.loadData()
                     Swal.fire(
                         'Alterações aplicadas!',
                         'As seleções foram aplicadas com sucesso.',
                         'success'
                     )
                 }

             } else {
                 Amostra.setAllSelectedItems(this.membros, 'aplicador')

                 const payloadManageAplicadores = this.$store.getters.getPayloadManageAplicadores
                 const payload1 = {
                     idInstituicao: this.$route.params.idInstituicao,
                     idAmostra: this.$route.params.idCampo,
                     ids: JSON.parse(JSON.stringify(payloadManageAplicadores))
                 }
                 await this.$store.dispatch('setManageAplicadores', payload1)

                 this.loadData()
                 Swal.fire(
                     'Alterações aplicadas!',
                     'As seleções foram aplicadas com sucesso.',
                     'success'
                 )
             }
         },
         setSelectedValue() {
             this.selectedValue = this.$refs.filtersearchname.value
         }
     },
     mounted: function() {
         window.scrollTo(0, 0);
         let idTeamSelected = this.$route.params.idCampo

         if (typeof idTeamSelected == "undefined"){
             this.nomeInstituicao = "Nome do Campo"
         }
         else {
             let teamsSelected
             let teamSelected

             teamsSelected = this.campos.filter(team =>
                 team.idamostra == idTeamSelected
             )

             teamSelected = [...teamsSelected].shift()

             this.nomeCampo = teamSelected.titulo
             this.descricao = teamSelected.descricao
         }
     }
 }
</script>

<style scoped>
 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.png");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;
 }

 .imgText {
     background-image: url("~@/assets/img/Backgrounds/roxoEapi.png");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .box-lista {
     background-repeat: repeat;
     background-size: 256px;
     background-image: url("~@/assets/img/Backgrounds/roxoMaisClaro.png");

     border: 1px solid #958db1;
 }

 :deep(.box-lista span) {
     font-size: 1.1em;
 }

 .box-lista span {
     color: #7647ac;
 }

 .text-info-personal {
     color: #7647ac;
     font-size: 1.1rem;
     text-align: justify;
     padding: 0rem;
     margin: 0 3rem 0 3rem;
 }

 .list-data {
     min-height: 90vh;
     padding-top: 2rem;
 }
 .label-filtro {
     font-weight: 600;
 }

 .filter-box {
     margin: 1rem 3rem;
 }

 .itens-box {
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .title-tree {
     margin: 1rem 3rem;
     font-weight: bold;
     display: flex;
 }

 .lista-data-info {
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;
     margin: 2rem 3rem;
 }

 .title-tree h1 {
     font-size: 1.2rem;
     font-weight: 700;
 }

 .title-style{
     margin: 0 0 0 3rem;
 }

 .separator {
     border: none;
     height: 1px;
     margin: 0.8rem 3rem;
     background: url("~@/assets/img/Backgrounds/roxoEapi.png");
 }

 a {
     color: #212529;
     text-decoration: none;
     transition: all ease-in-out 0.2s
 }

 a:hover {
     color: #4d4f50;
 }

 @media screen and (max-width: 1010px) {
     .lista-data-info {
         justify-content: center;
     }
 }


 @media screen and (max-width: 1012px) {
     .filter-box {
         display: flex;
         align-items: center;
         justify-content: center;
     }
 }


 @media screen and (max-width: 680px) {
     .itens-box{
         flex-direction: column;
     }
 }

</style>
