<template>
  <div class="card">
    <div class="card-body">
      <div class="perguntaHeader">
        <div class="perguntaTitulo">
          <h1>Pergunta 001</h1>
        </div>
        <div class="perguntaMenu">
          <button class="btn"><i class="fas fa-info-circle"></i></button>

          <button class="btn"><i class="fas fa-copy"></i></button>

          <button class="btn"><i class="fas fa-edit"></i></button>

          <button class="btn"><i class="fas fa-trash"></i></button>

          <button class="btn"><i class="fas fa-window-minimize"></i></button>
        </div>
      </div>
      <div class="perguntaBody">
        <p>
          Oportunidades de aprendizagens que promovem os movimentos amplos e
          gestos das crianças em jogos e brincadeiras Correr; pular; arrastar;
          engatinhar; jogar com bola, peteca e outros objetos; esconder;
          escalar; rolar; percursos de obstáculos etc. OBS: Considere neste item
          somente situações em que a oportunidade de ampliação dos movimentos se
          dê em áreas externas da Unidade Educacional.
        </p>
        <div class="custom-control custom-radio">
          <input
            type="radio"
            name="customRadio"
            class="custom-control-input"
            disabled
          />
          <label class="custom-control-label" for="customRadio1"
            >Não foram observados momentos de organização de rotina.</label
          >
        </div>
        <div class="custom-control custom-radio">
          <input
            type="radio"
            name="customRadio"
            class="custom-control-input"
            disabled
          />
          <label class="custom-control-label" for="customRadio2"
            >Momentos de organização de rotina foram realizados de maneira
            mecânica e padronizada.</label
          >
        </div>
        <div class="custom-control custom-radio">
          <input
            type="radio"
            name="customRadio"
            class="custom-control-input"
            disabled
          />
          <label class="custom-control-label" for="customRadio1"
            >Professora(or) realizou momentos de organização da rotina, sendo
            que: ● Envolve as crianças na conversa, usando perguntas abertas,
            MAS ● Não realiza modificações no planejamento do dia ou na
            atividade com base na fala e nos interesses apresentados pelas
            crianças.</label
          >
        </div>
        <div class="custom-control custom-radio">
          <input
            type="radio"
            name="customRadio"
            class="custom-control-input"
            disabled
          />
          <label class="custom-control-label" for="customRadio2"
            >Professora(or) realizou momentos de organização da rotina, sendo
            que: ● Envolve as crianças na conversa, usando perguntas abertas,
            MAS ● Não realiza modificações no planejamento do dia ou na
            atividade com base na fala e nos interesses apresentados pelas
            crianças.</label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.card {
  border-radius: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
    margin-bottom: 2rem;
}

.perguntaMenu .btn {
  background-color: var(--branco);
}

.perguntaHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>