<template>
    <ModalCentral
        componentName="InstiRequests"
        title="Solicitações da instituição"
        idName="InstiRequests"
        :textured="true"
    />

    <div class="bg">
        <div class="container">
            <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" section="dados-section"/>
            <br />
            <div class="title-row">
                <h1 class="title-info">{{ nomeInstituicao || "..." }}</h1>
                <div>
                    <Button
                        v-if="userPerm.permissao != 'APLICADOR'"
                        class="btn mr-2 menuButton"
                        section="dados-outline"
                        value="Solicitações"
                        :isOutline="true"
                        :hasIcon="true"
                        data-target="#InstiRequests"
                        data-toggle="modal"
                        iconName="envelope" />
                </div>
            </div>

            <div class="cardsful-bg">
            <router-link :to="{name: 'InstituicaoMenuCadastrar'}">
            <ColorfulCardsBG
                                :hasIcon="true"
                                class="cardful-single"
                                thumbnail="search-plus"
                                title="Cadastrar informações"
                                palette="blue-section"
            />
            </router-link>

            <router-link :to="{name: 'ListarCampos'}">
            <ColorfulCardsBG
                                :hasIcon="true"
                                class="cardful-single"
                                thumbnail="chart-pie"
                                title="Gerenciar campo"
                                palette="pink-section"

            />
            </router-link>

            <router-link :to="{name: 'AplicacoesListaCampos'}">
            <ColorfulCardsBG
                                :hasIcon="true"
                                class="cardful-single"
                                thumbnail="pencil-alt"
                                title="Aplicações"
                                palette="orange-section"
            />
            </router-link>

            </div>
            <div class="cardsful-bg">

            <router-link :to="{name: 'ListarDevolutivas'}">
            <ColorfulCardsBG
                                :hasIcon="true"
                                class="cardful-single"
                                thumbnail="chart-bar"
                                title="Dados"
                                palette="red-section"
            />
            </router-link>


<!--             <router-link :to="{name: 'ListarCSV'}">
            <ColorfulCardsBG
                                :hasIcon="true"
                                class="cardful-single"
                                thumbnail="file-csv"
                                title="CSV"
                                palette="green-section"
            />
            </router-link> -->

            </div>
        </div>
    </div>

</template>

<script>
import ColorfulCardsBG from '@components/ColorfulCardsBG'
import Button from '@components/Button';
import ModalCentral from '@components/ModalCentral';
import Breadcrumb from "@components/Breadcrumb";

export default {
    data () {
        return {
            nomeInstituicao: '',
            descricao: '',
            idInstituicao: Number,
            teams: [],
            crumbs: [
                {
                    id:1,
                    value: '',
                    iconName: 'th',
                    name_route: 'GerenciarInstituicoes'
                },
                {
                    id:2,
                    value: 'Instituição',
                    iconName: 'university',
                    name_route: 'InstituicaoMenu'
                }
            ]
        }
    },
    components: {
        ColorfulCardsBG,
        Button,
        ModalCentral,
        Breadcrumb,
    },

    created(){
        this.teams = this.$store.getters.getListTeams
    },
    mounted: function() {
        window.scrollTo(0, 0);
        let idTeamSelected = this.$route.params.idInstituicao

        if (typeof idTeamSelected == "undefined"){
            this.nomeInstituicao = "Nome da Instituição"
        }
        else {
            let teamsSelected
            let teamSelected

            teamsSelected = this.teams.filter(team => 
                team.idinstituicao == idTeamSelected
            )

            teamSelected = [...teamsSelected].shift()

            this.nomeInstituicao = teamSelected.name
            this.descricao = teamSelected.descricao
            this.idInstituicao = this.$route.params.id
        }
    },
     computed: {
         userPerm() {
             return this.$store.getters.getUserPerm
         },
     }
}

</script>

<style scoped>

 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;
 }

.container {
    margin: 3rem auto;
}
.cardsful-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.cardsful-bg a:hover {
    text-decoration: none;
}

.cardful-single {
    margin: 1rem;
}

 .title-row {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
 }

 .title-info {
     font-weight: 600;
     font-size: 1.6rem;
     color: #535353;
     margin: 0;
 }

 .menuButton {
     background-image: url("~@/assets/img/Backgrounds/verdeEscuroEAPI.webp");
     background-repeat: repeat;
     background-size: 256px;
     box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
 }

 :deep(.menuButton div) {
     background-clip: text;
     text-fill-color: transparent;
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
 }
</style>
