<template>
<ModalCentral
  v-if="userPerm"
  componentName="EditCard"
  title="Editar card"
  idName="editCard"
  :data="{ idCard: idCard, ordem: order, idTipo: idTipo }"
  />

<div class="bg">
  <h2 class="pageTitle">Avaliações realizadas</h2>
  <template v-for="card in cardsRealizadas" :key="card.idCard">
    <div class="card">
      <h2 class="text">
        <i class="fas fa-trash editIcon"
           v-if="userPerm && cardsRealizadas.length > 1"
           @click="deleteCard(card.idCard)" />
        <i class="fas fa-pen editIcon"
           v-if="userPerm"
           @click="setIdCard(card.idCard)"
           data-toggle="modal"
           data-target="#editCard" />
        {{ card?.titulo }}
      </h2>

      <div class="resource">
        <img
          v-if="getImage((card?.imagem || [])[0])"
          :src="getImage((card?.imagem || [])[0])"
          alt="Avaliações" />
        <div class="info">
          <div class="text" v-html="card?.corpoTexto" />

          <br />

          <a
            v-for="(button, idx) in card?.botao"
            target="_blank"
            :key="idx"
            :href="button?.link">
            <Button
              :value="button?.texto"
              class="cardBut resourceBut"
              :isOutline="false" />
          </a>
        </div>
      </div>
    </div>

    <div
      v-if="userPerm"
      @click="setIdCard(parseInt(card?.ordem) + 1, true, card?.idTipo)"
      data-toggle="modal"
      data-target="#editCard"
      class="addCard">
      <i class="fas fa-plus"></i>
    </div>
  </template>
</div>
</template>

<script type="text/javascript">
import Button from '@components/Button';
import ModalCentral from '@components/ModalCentral';
import axios from '@middleware/api.js';
import Swal from 'sweetalert2';
import { Notyf } from "notyf";
const notyf = new Notyf({
    duration: 5000,
    position: {
        x: 'left',
        y: 'bottom',
    }
});

export default {
    name: "AvaliacoesRealizadas",
    components: {
        Button,
        ModalCentral,
    },
    data() {
        return {
            idCard: null,
            order: null,
            idTipo: null,
        }
    },
    methods: {
        async deleteCard(idCard) {
          if (this.cardsRealizadas.length == 1) return;
            const result = await Swal.fire({
                title: 'Deseja realmente deletar esse?',
                text: 'Tem certeza que deseja deletar esse card? Essa operaçao nao pode ser desfeita.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sim, tenho certeza'
            });
            if (!result.isConfirmed) return;

            await this.$store.dispatch("deleteCard", idCard);

            if (this.$store.getters.getCardDeleted) {
                notyf.open({
                    type: "success",
                    message: "Card deletado com sucesso",
                });
                this.$store.dispatch("setListCards");
            } else {
                notyf.open({
                    type: "error",
                    message: "Não foi possível deletar o card",
                });
            }
        },
        setIdCard(id, newCard, idTipo) {
            this.idCard = newCard ? null : id;
            this.order = newCard ? id : null;
             this.idTipo = newCard ? idTipo : this.cards.find(card => card.idCard == id)?.idTipo;
         },
         getImage(path) {
             if (!path || path === 'null' || path === 'undefined') return null;
             return `${axios.defaults.baseURL}/materiais_documentos/cards/getImage?path=${path}`;
         },
     },
     computed: {
         userPerm() {
             try{
                 return this.$store.getters.getUserInfo.data.isAdmin;
             } catch(e) {
                 return false;
             }
         },
         cards() { return this.$store.getters.getListCards; },
         cardsRealizadas() { return (this.cards || []).filter(card => card.idTipo == 44); },
     },
 };
</script>

<style type="text/css" media="screen" scoped>
 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.png");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;

     padding: 1em;
     box-sizing: border-box;
 }

 .pageTitle {
     background-clip: text;
     background-image: url("~@/assets/img/Backgrounds/marromEscuro.png");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
     width: 100%;
     text-align: center;
 }

 .card {
     background-repeat: repeat;
     background-size: 256px;
     width: 100%;
     box-shadow: inset 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
     border-radius: 1em;
     padding: 1em;
     box-sizing: border-box;
     margin-bottom: 10px;
     background-image: url("~@/assets/img/Backgrounds/marromEscuro.png");
 }

 .card > h2 {
     width: 100%;
     text-align: right;
 }

 .resource {
     display: flex;
     flex-flow: row wrap;
     align-items: center;
 }

 .resource > img {
     flex: 1 0 30%;
     min-width: 250px;
     object-fit: cover;
     border-radius: 1em;
     box-sizing: border-box;
 }

 .resource > .info {
     flex: 1 0 70%;
     padding: 10px;
     box-sizing: border-box;
 }

 .cardBut {
     border-radius: 50px;
     background: #B1514E;
     width: max-content;
     margin: 5px;
     float: right;
     box-sizing: border-box;
     height: max-content;
     padding-left: 3em;
     padding-right: 3em;
 }

 .text {
     background-clip: text;
     background-image: url("~@/assets/img/Backgrounds/branco.png");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
 }

 .editIcon {
     float: right;
     color: #fcfaf2;
     font-size: 1.3rem;
     cursor: pointer;
     transition: 0.4s;
     box-sizing: border-box;
     margin: 5px;
 }

 .editIcon:hover {
     transform: scale(1.1);
     transform-origin: center center;
 }

 .addCard {
     background: url("~@/assets/img/Backgrounds/verdeEscuroMateriais.webp");
     height: 2em;
     aspect-ratio: 1 / 1;
     position: relative;
     margin: 0 auto 10px auto;
     border-radius: 100%;
     transition: 0.4s;
     cursor: pointer;
 }

 .addCard:hover {
     transform: scale(1.1);
     transform-origin: center center;
 }

 .addCard > * {
     font-size: 1em;
     margin: 0;
     padding: 0;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
 }
</style>
