<template>
    <div id="home-carousel" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#home-carousel" data-slide-to="0" class="active"></li>
            <li data-target="#home-carousel" data-slide-to="1"></li>
            <li data-target="#home-carousel" data-slide-to="2"></li>
            <li data-target="#home-carousel" data-slide-to="3"></li>
            <li data-target="#home-carousel" data-slide-to="4"></li>
            <li data-target="#home-carousel" data-slide-to="5"></li>
            <li data-target="#home-carousel" data-slide-to="6"></li>
        </ol>

        <div class="carousel-inner">
            <div class="carousel-item active">
                <img
                    class="d-block w-100"
                    :src="require('../../assets/img/Home/Carousel/1.webp')"
                    alt="1" />
            </div>
            <div class="carousel-item">
                <img
                    class="d-block w-100"
                    :src="require('../../assets/img/Home/Carousel/2.webp')"
                    alt="1" />
            </div>
            <div class="carousel-item">
                <img
                    class="d-block w-100"
                    :src="require('../../assets/img/Home/Carousel/3.webp')"
                    alt="1" />
            </div>
            <div class="carousel-item">
                <img
                    class="d-block w-100"
                    :src="require('../../assets/img/Home/Carousel/4.webp')"
                    alt="1" />
            </div>
            <div class="carousel-item">
                <img
                    class="d-block w-100"
                    :src="require('../../assets/img/Home/Carousel/5.webp')"
                    alt="1" />
            </div>
            <div class="carousel-item">
                <img
                    class="d-block w-100"
                    :src="require('../../assets/img/Home/Carousel/6.webp')"
                    alt="1" />
            </div>
        </div>

        <a class="carousel-control-prev" href="#home-carousel" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Anterior</span>
        </a>
        <a class="carousel-control-next" href="#home-carousel" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Próximo</span>
        </a>
    </div>
</template>

<script>
</script>

<style scoped>
 #home-carousel {
     width: 100%;
     border: 0 !important;
 }
</style>
