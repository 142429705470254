<template>
    <CategoryDescription idName="description-modal" :data="currentDescription" />

    <div class="apresentacao">

        <router-link to="/boaspraticas/acervo" class="bancoContainer">
            <Button
                class="butBanco"
                :isOutline="false"
                value="Acesse o banco de práticas" />
        </router-link>

        <div class="infoCard">
            <img :src="require('../../assets/img/BoasPraticas/oquee.webp')" />
            <div class="text">
                <span>
                    <h2 class="titlePraticas">O que são BOAS PRÁTICAS?</h2>
                    <p>O banco de práticas pedagógicas da Educação Infantil compõe a Plataforma Nacional com a função de defender o que acreditamos como parte da qualidade. Por meio de práticas pedagógicas inspiradoras, disponibilizadas em formatos de relatos de experiências e vídeos, acreditamos ser possível elevar a qualidade das práticas que são desenvolvidas no cotidiano da Educação Infantil.</p>
                    <p>Acreditamos, que ao redigir sua vivência, relembrando e refletindo acerca dela, o(a) professor(a) a transforma em experiência. Nesse processo ele se constitui como autor e um dos agentes de sua própria formação. A partir do compartilhamento dessa experiência, o(a) professor(a) tem a oportunidade de colaborar com a formação de seus pares, oferecendo e recebendo novas perspectivas.</p>
                </span>
            </div>
        </div>

        <h2 class="titleAxis">Entenda as categorias mobilizadas por essas práticas</h2>
        <div class="categories">
            <div
                v-for="(category, idx) in categories"
                @click="setCurrent(idx)"
                :key="idx"
                class="category"
                data-toggle="modal"
                data-target="#description-modal"
                :style="{
                    'background': `url(${category.background})` || '#8fbce5',
                    'color': category.fontColor || 'white'
                }">
                <span>{{ category.title }}</span>
            </div>
        </div>

    </div>

</template>

<script>
 import Button from '@components/Button';
 import CategoryDescription from '@components/BoasPraticas/CategoryDescription';

 export default {
     name: 'BoasPraticas_OQueE',
     components: {
         Button,
         CategoryDescription,
     },
     async created() {
         await this.$store.dispatch('setPraticaCategories');
         let categoryNames = this.$store.getters
                                 .getPraticaCategories?.sort((a, b) => a.codigo - b.codigo)
                                                       .map(cat => cat.nome);

         for (let idx = 0; idx < categoryNames.length; ++idx)
             this.categories[idx].title = categoryNames[idx];
     },
     data() {
         return {
             currentCategory: 0,
             categories: [
                 {
                     title: 'Ampliação do repertório por meio do brincar',
                     text: '<b>Inclui práticas que destacam bem o trabalho com o protagonismo das crianças.</b> Na Educação Infantil os conhecimentos devem ser desenvolvidos de forma não episódica nem fragmentada. As brincadeiras e interações devem ser eixos de todas as propostas pedagógicas. O <b>protagonismo das crianças</b> deve também estar presente, considerando suas vozes e ações para elaboração de práticas pedagógicas com, e não apenas para as crianças. ',
                     background: require('../../assets/img/Backgrounds/azulEscuro.webp'),
                 },
                 {
                     title: 'Parcerias com as famílias e comunidade escolar',
                     text: 'Inclui práticas pedagógicas que reconhecem as famílias e a comunidade escolar como parceiras para a elaboração e/ou desenvolvimento de projetos e de práticas pedagógicas, como participantes ativos nos processos e interações que ocorrem na instituição.',
                     background: require('../../assets/img/Backgrounds/roxoEscuro.webp'),
                 },
                 {
                     title: 'Escuta Infantil, observações, investigações',
                     text: 'Inclui práticas pedagógicas que incentivam a escuta e  investigações infantis por meio de pesquisas,  experimentações,  descobertas,  invenções  e  teorizações a partir da observação do meio físico e sociocultural.',
                     background: require('../../assets/img/Backgrounds/azulClaro.webp'),
                 },
                 {
                     title: 'Valorização e reconhecimento da importância do ecossistema e do meio ambiente',
                     text: 'Práticas que promovam o desemparedamento das crianças e proporcionem interações com  a natureza e a utilização de seus recursos como brinquedos não estruturados, incluindo atividades que abordam de maneira reflexiva a  conscientização, valorização e preservação do meio ambiente. ',
                     background: require('../../assets/img/Backgrounds/marrom.webp'),
                 },
                 {
                     title: 'Cuidado, autonomia e atenção às crianças',
                     text: 'Inclui práticas pedagógicas que compreendem os cuidados diários, como a troca, o banho, a alimentação e outros como atividades pedagógicas que tem intencionalidade e que incentivam as crianças para seu autoconhecimento e autonomia.',
                     background: require('../../assets/img/Backgrounds/cianoEscuro.webp'),
                 },
                 {
                     title: 'Reconhecimento e valorização da diversidade cultural',
                     text: 'Inclui práticas pedagógicas de combate ao preconceito em todas as suas formas, sejam elas de raça, gênero, etnia, deficiências, entre outras, reconhecendo e valorizando a diversidade cultural.',
                     background: require('../../assets/img/Backgrounds/marsala.webp'),
                 },
                 {
                     title: 'Acolhimento e gestão de sentimentos',
                     text: 'Inclui práticas pedagógicas de acolhimento das crianças seja em termos de adaptação ou de sentimentos e gestão de conflitos por meio de diálogo, auxiliando as crianças a reconhecer, nomear seus sentimentos e praticar a empatia.',
                     background: require('../../assets/img/Backgrounds/verdeEscuro.webp'),
                 },
                 {
                     title: 'Práticas de formação e desenvolvimento da equipe de profissionais',
                     text: 'Inclui trabalhos cujo foco são ideias inovadoras ou reflexões sobre os processos de acompanhamento, formação e apoio aos profissionais da escola, visando sua qualificação e bem-estar no ambiente de trabalho.',
                     background: require('../../assets/img/Backgrounds/laranja.webp'),
                 },
             ],
         };
     },
     methods: {
         setCurrent(idx) { this.currentCategory = idx; },
     },
     computed: {
         currentDescription() {
             return this.categories[this.currentCategory];
         }
     }
 }
</script>

<style scoped>
 .apresentacao {
     padding: 0 2rem;
     width: 100%;
     background: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
 }

 .infoCard {
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     padding: 2rem;
     justify-content: space-between;
     align-items: center;
     background: url("~@/assets/img/Backgrounds/verdeMaisClaro.webp");
     background-repeat: repeat;
     background-size: 256px;
     border-radius: 2rem;
     box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
 }

 .infoCard > img {
     margin: 0 auto;
     flex: 1 0 30%;
     display: block;
     width: 100%;
     max-width: 400px;
     min-width: 300px;
     border-radius: 5px;
     box-sizing: border-box;
     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
 }

 .infoCard > .text {
     flex: 1 0 70%;
     /* width: 100%; */
     display: block;
     text-align: justify;
     font-size: 1.15em;
     box-sizing: border-box;
     padding: 10px;
 }

 .infoCard > .text p {
     text-indent: 1em;
 }

 .apresentacao > .imgs {
     display: flex;
     flex-wrap: wrap;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
     text-align: center;
 }

 .imgs > img {
     margin: 0 auto;
     padding: 10px;
     width: 100%;
 }

 .bancoContainer {
     width: 100%;
     padding: 2rem;
     display: flex;
     flex-direction: row-reverse;
 }

 .butBanco {
     display: block;
     background: url("~@/assets/img/Backgrounds/verdeEapi.webp");
     background-repeat: repeat;
     background-size: 256px;
     border-radius: 20px;
 }

 :deep(.butBanco span) {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .categories {
     display: grid;
     grid-template-columns: repeat(2, 1fr);
     /* grid-template-rows: auto; */
     grid-auto-rows: 1fr;
     row-gap: 10px;
     column-gap: 10px;
     text-align: center;
     padding: 15px;
 }

 .category {
     margin: 0 auto;
     border: 0;
     border-radius: 2rem;
     padding: 0.5rem;
     width: 70%;
     display: flex;
     justify-content: center;
     align-items: center;
     cursor: pointer;
     font-weight: 600;
     box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
     background-repeat: repeat;
     background-size: 256px;
 }

 .titlePraticas {
     width: 100%;
     padding: 5px;
     text-align: right;
     color: rgba(0, 0, 0, 0.6);
     text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
 }

 .titleAxis {
     width: 100%;
     padding: 20px;
     text-align: center;
     color: rgba(25, 89, 83, 0.8);
     text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
 }

 @media screen and (max-width: 800px) {
     .categories {
         grid-template-columns: 1fr;
     }

     .imgs > img {
         width: 40% !important;
         max-width: 350px;
     }
 }
</style>
