<!--
|---------------------------------------------
| Login Component 
|---------------------------------------------
| @usage Import the component and use, no params
| 
-->
<template>
  <div>
    <form @submit.prevent="login" class="formulario">
      <div class="form-group">
        <label for="exampleInputEmail1">Email</label>
        <input
          v-model.trim="email"
          type="email"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="Insira o email..."
          spellcheck="false"
          required
        />
      </div>
      <div class="form-group container-password">
        <label for="exampleInputPassword1">Senha</label>
        <input
          v-model="senha"
          :type="type_input_password"
          class="form-control input-password"
          id="exampleInputPassword1"
          placeholder="Senha..."
          spellcheck="false"
          required
        />
        <i @click="changeVisibility" class="button-eye" :class="{'fa fa-eye': type_input_password == 'password', 'fa fa-eye-slash': type_input_password == 'text' }"></i>
      </div>

      <div><Button class="btn-full mb-3" section="main" value="Entrar" /></div>
      <!-- <a class="mt-4" href="">Esqueceu a senha?</a> -->
      
      <div class="d-flex flex-column">
        <a class="mt-4 link mb-2" @submit.prevent @click="sendResetPasswordMail()">Esqueceu a senha?</a>
        <a class="mt-1 link" @submit.prevent @click="resendVerificationMail()">Não consegue ativar sua conta?</a>
      </div>

    </form>
  </div>
</template>

<script>
import Button from "@components/Button";
import Swal from "sweetalert2";
import { Notyf } from 'notyf';
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: 'left',
    y: 'bottom',
  },
  types: [
    {
      type: 'info',
      background: '#18a0fb',
      icon: {
        className: 'fa fa-info-circle',
        tagName: 'i',
        color: 'white'
      }
    }
  ]
});

export default {
  name: "Login",
 
  data() {
    return {
      email: '',
      senha: '',
      type_input_password: 'password'
    }
  },
  components: {
    Button
  },

  methods: {
      async login() {
        const payload = {
          email: this.email,
          senha: this.senha  
        }
        await this.$store.dispatch('setLogin', payload)
        await this.$store.dispatch('setUserInfo')
        
        //se logado fecha o menu lateral
        if (this.$store.getters.getIsLogged) {
          notyf.open({
            type: 'success',
            message: 'Login feito com sucesso!'
          });
          this.$store.commit('setIsActiveModalLateralLogin', true)
        }
        //se nao exibe notificacao de erro
        else {
          notyf.open({
            type: 'error',
            message: 'Usuário ou senha incorretos.'
          });
        }
      },

      changeVisibility() {
        if (this.type_input_password == 'password') {
          this.type_input_password = 'text'
        }
        else {
          this.type_input_password = 'password'
        }
      },

      async resendVerificationMail(){
        const { value: emailResend } = await Swal.fire({
          title: "Ativar conta",
          text: "Iremos reenviar o email de validação para você.",
          icon: "info",
          input: "text",
          inputPlaceholder: 'Digite seu email aqui..',
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Enviar",
          inputValidator: (value) => {
            if (!value) {
              return 'Você precisa digitar o email.'
            }
          }
        })

        if (emailResend) {
          await this.$store.dispatch('setResendVerificationMail', emailResend)
          Swal.fire(
            'Email enviado!',
            'O email de confirmação da conta foi enviado para a sua caixa de entrada. Caso ainda não tenha chego, por favor, verifique a caixa de promoções e a caixa de spam, ou tente novamente mais tarde.',
            'success'
          )
      }
      },

      async sendResetPasswordMail(){
        const { value: emailSend } = await Swal.fire({
          title: "Redefinir senha",
          text: "Iremos enviar o email de redefinição de senha para você.",
          icon: "info",
          input: "text",
          inputPlaceholder: 'Digite seu email aqui..',
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Enviar",
          inputValidator: (value) => {
            if (!value) {
              return 'Você precisa digitar o email.'
            }
          }
        })

        if (emailSend) {
          await this.$store.dispatch('setSendResetPasswordMail', emailSend)
          Swal.fire(
            'Email enviado!',
            'O email de redefinição de senha foi enviado para a sua caixa de entrada. Caso ainda não tenha chego, por favor, verifique a caixa de promoções e a caixa de spam, ou tente novamente mais tarde.',
            'success'
          )
      }
      }
  },
};
</script>

<style scoped>
.formulario {
  padding: 0rem 2rem 2rem 2rem;
}

.btn-full {
  margin-top: 0.8rem;
  width: 100%;
}

.container-password {
  position: relative;
}

.button-eye {
  position: absolute;
  right: 4%;
  top: 56%;
  color: #6b6b6b;
  font-size: 1.2rem;
  cursor: pointer;
}

.input-password {
  padding-right: 2.6rem;
}

.link {
  color: #18a0fb !important;
  cursor: pointer;
}
</style>
