<template>
    <div class="feedback">
        <div class="rating">
            <img
                v-for="idx in [...Array(5).keys()]"
                :key="idx"
                @click="setRating(idx)"
                :src="idx + 1 <= score ? fullImg : emptyImg"
                alt="Estrela" />
            <p v-if="text.trim() != ''">{{ text }}</p>
        </div>
    </div>
</template>

<script type="text/javascript">
 export default {
     name: "Avaliacao",
     props: {
         score: {
             type: Number,
             required: true,
         },
         text: {
             type: Number,
             required: false,
         }
     },
     data() {
         return {
             fullImg: require('../../assets/img/BoasPraticas/FullStar.svg'),
             emptyImg: require('../../assets/img/BoasPraticas/EmptyStar.svg'),
         };
     },
 };
</script>

<style type="text/css" media="screen" scoped>
 .feedback {
     width: 100%;
     background: url("~@/assets/img/Backgrounds/azulClaro.png");
     background-repeat: repeat;
     background-size: 256px;
     box-shadow: inset -2px 2px 2px rgba(0, 0, 0, 0.25);
     border-radius: 8px;
     margin-bottom: 10px;
     padding: 5px;
 }

 .rating {
     width: 100%;
     padding: 3px;
 }

 .rating > img {
     max-width: 1.5em;
     margin: 2px;
 }

 .rating > p {
     width: 100%;
     margin: 5px;
 }
</style>
