<template>
    <div class="divEdicao">
        <div class="headerCategoria">
            <p class="categoria">Categoria 001</p>
            <h5 class="dadosInstituicao">Dados da Instituição</h5>
        </div>

        <div class="divPergunta">
            <h2 class="tituloPergunta">Pergunta 001</h2>

            <textarea class="areaPergunta"></textarea>

            <p class="tituloMenor">Notas (Opcional)</p>
            <textarea class="areaNotas"></textarea>

            <p class="tituloMenor tituloMedio">Intervalo de Hora</p>
            <div class="divCheckBox">
                <label>
                    <input type="checkbox" value="hora"><span>Hora</span>
                </label>
                <br>

                <label>
                    <input type="checkbox" value="minuto"><span>Minuto</span>
                </label>
            </div>
            
            <div class="divPadrao">
            <p class="tituloMenor tituloMedio">Resposta Padrão</p>
            <input type="time" class="inputRespPadrao">
            </div>

        </div>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
    .divEdicao{
        width: 100%;
        height: 100%;
    }

    .headerCategoria{
        margin-bottom: 3%;
    }

    .categoria{
        margin-bottom: 0;
        font-size: 18px;
    }

    .dadosInstituicao{
        font-weight: bold;
        color: #4C545F;
    }

    .divPergunta{
        height: 100%;
    }
    
    .tituloPergunta{
       color: #1F2937;
       font-weight: 100;
    }

    textarea{
        width: 100%;
        padding: 10px;
    }

    input[type=time] {
        border: none;
        color: #2a2c2d;
        font-size: 14px;
        font-family: helvetica;
        width: 180px;
    }

    input[type=time]:focus {
        outline: none;
    }

    /* Wrapper around the hour, minute, second, and am/pm fields as well as 
    the up and down buttons and the 'X' button */
    input[type=time]::-webkit-datetime-edit-fields-wrapper {
        display: flex;
    }

    /* The space between the fields - between hour and minute, the minute and 
    second, second and am/pm */
    input[type=time]::-webkit-datetime-edit-text {
    padding: 19px 4px;
    }

    /* The naming convention for the hour, minute, second, and am/pm field is
    `-webkit-datetime-edit-{field}-field` */

    /* Hour */
    input[type=time]::-webkit-datetime-edit-hour-field {
        background-color: #ffffff;
        border-radius: 15%;
        padding: 19px 13px;
    }

    input[type=time]::-webkit-datetime-edit-hour-field:focus{
        border: 2px solid #1F2937;
    }

    /* Minute */
    input[type=time]::-webkit-datetime-edit-minute-field {
        background-color: #ffffff;
        border-radius: 15%;
        padding: 19px 13px;
    }

    input[type=time]::-webkit-datetime-edit-minute-field:focus{
        border: 2px solid #1F2937;
    }

    /* AM/PM */
    input[type=time]::-webkit-datetime-edit-ampm-field {
        background-color: #7155d3;
        border-radius: 15%;
        color: #fff;
        padding: 19px 13px;
    }

    /* 'X' button for resetting/clearing time */
    input[type=time]::-webkit-clear-button {
        display: none;
    }

    /* Up/Down arrows for incrementing/decrementing the value */
    input[type=time]::-webkit-inner-spin-button {
        display: none;
    }

    input[type="time"]::-webkit-calendar-picker-indicator {
        background: none;
    }

    .inputRespPadrao{
        height: 12%;
        background:none;
    }

    .areaPergunta{
        height: 40%;
        margin-bottom: 15px;
    }

    .areaNotas{
        height: 12%;
        margin-bottom: 15px;
    }

    .areaRespPadrao{
        height: 12%;
    }

    .tituloMenor{
        margin-bottom: 5px;
    }

    .tituloMedio{
        font-size: 18px;
    }

    label > input[type="checkbox"] {
        display: inline-block;
        vertical-align: middle;
        width: 1rem;
        height: 1rem;
        margin-right: 5px;
    }

    .divCheckBox{
        margin-bottom: 15px;;
    }

</style>
