<template>
<div class="container offset">
  <form @submit.prevent="updateUE" ref="formClass" class="customForm">

    <div class="form-group">
      <label class="question-style pt-1">Nome da unidade:</label>
      <input
        v-model.trim="ue.nome"
        type="name"
        class="form-control"
        placeholder="Insira aqui o nome da unidade..."
        required
        />
    </div>

    <div class="form-group">
      <label class="question-style pt-1">Cidade: *</label>
      <select
        v-model.trim="ue.cidade"
        class="form-control"
        required
        >
        <option
          v-for="(cidade, idx) of cidades"
          :key="idx"
          :value="cidade">
          {{ cidade }}
        </option>
      </select>
    </div>

    <div class="form-group">
      <label class="question-style pt-1">Macroárea: *</label>
      <select
        v-model.trim="ue.macroarea"
        class="form-control"
        name="macroarea"
        required
        >
        <option
          v-for="(macro, idx) in macroareas"
          :key="idx"
          :value="macro">
          {{ macro }}
        </option>
      </select>
    </div>

    <div class="form-group">
      <label class="question-style pt-1">Ano de início:</label>
      <input
        class="form-control"
        min="1990"
        :max="new Date().getFullYear()"
        type="number"
        v-model.trim="ue.inicioatividades"
        list="macroareas"
        placeholder="Digite o ano de início das atividades..."
        />
    </div>

    <div class="form-group">
      <label class="question-style pt-1">Tipo de modalidade: *</label>
      <select
        class="form-control"
        v-model.trim="ue.tipomodalidade">
        <option
          v-for="modalidadeId of Object.keys(modalidades)"
          :key="modalidadeId"
          :value="modalidadeId" >
          {{ modalidades[modalidadeId] }}
        </option>
      </select>
    </div>

    <div class="formButtons">
      <div class="button-div pt-4">
        <Button
          section="instituicao-cadastrar"
          value="Atualizar UE"
          :isOutline="true"
          :hasIcon="true"
          iconName="save"
          />
      </div>
    </div>

  </form>
</div>
</template>

<script>
import Button from '@components/Button'
import { Notyf } from "notyf";
const notyf = new Notyf({
    duration: 5000,
    position: {
        x: "left",
        y: "bottom",
    },
});

export default {
  name: "UpdateUE",
  data() {
    return {
      id: null,
      ue: {},
      modalidades: {
        "15": "Pública",
        "16": "Privada",
        "17": "Filantrópica",
        "18": "Parceira/Conveniada",
      },
    };
  },
  components: {
    Button,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  watch: {
    data(updated) {
      this.id = updated?.idUE;
      this.loadUE();
    },
  },
  async created() {
    this.$store.dispatch('setListMacroareas', this.$route.params.idInstituicao)
    await this.$store.dispatch('setListTeams')
    this.$store.dispatch('setCidades', this.estado);
  },
  computed: {
    macroareas(){
      return (this.$store.getters.getListMacroareas || [])?.map(o => o?.macroarea);
    },
    cidades() {
      return (this.$store.getters.getCidades || [])?.map(o => (o?.nome || '')?.toUpperCase());
    },
    estado() {
      return ((this.$store.getters.getListTeams || []).find(i => i.idinstituicao == this.$route.params.idInstituicao) || {})?.estado;
    },
  },
  methods: {
    loadUE() {
      this.ue = { ...((this.$store.getters.getListUE || []).find(ue => ue.idue == this.id) || {}) };
    },
    async updateUE() {
      await this.$store.dispatch("setUpdateUE", {
        idUE: this.id,
        idInstituicao: this.$route.params.idInstituicao,
        ...this.ue,
      });

      if (!this.$store.getters.getIsUEUpdated) {
        notyf.open({
          type: "error",
          message: "Erro ao atualizar UE!"
        });
        return;
      }

      this.$store.dispatch('setListCampoUEs', {
        idInstituicao: this.$route.params.idInstituicao,
        idAmostra: this.$route.params.idCampo,
      });
      notyf.open({
        type: "success",
        message: "UE atualizada com sucesso"
      });
      this.$emit('closeModal');
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
}

.formButtons {
    display: flex;
    flex-direction: row-reverse;
}
</style>
