<template>
    <div class="bg">
        <div class="list-data">

            <div class="title-tree">
                <Breadcrumb :crumbs="crumbs" section="instituicao-aplicacoes"/>
            </div>

            <InstitutionInfo class="title-style" :title="nomeCampo" section="instituicao-cadastrar"/>

            <p class="text-info-personal text-img-clipper imgText">Nesta seção, você encontrará todas as Aplicações relacionadas ao Campo. Caso necessário, utilize o filtro abaixo para localizar a Aplicação desejada.</p>

            <!-- <pre>{{aplicacoes}}</pre> -->
            <div class="itens-box">
                <div class="filter-box">
                    <!-- INICIO DO FORM DE FILTRAGEM -->
                    <form @submit.prevent="" class="form-inline">
                        <div class="form-group">

                            <!-- DROPDOWN DE SELECAO DE FILTRO -->
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomSelect">Filtrar por: </label>
                            <Filter ref="filtersearchname" @change="setSelectedValue()" :optionsSelect="options" nameSelect="filtro_instituicoes" id="inlineFormCustomSelect"/>
                        </div>

                        <!-- SE A SELECAO FOR NOME (2) ENTAO EXIBIRA OS SUBFILTROS DE NOME -->
                        <div class="form-group" v-if="selectedValue == 2">
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomName">Digite o nome do aplicador: </label>
                            <input class="input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_instituicoes" id="inlineFormCustomName" autocomplete="off">
                        </div>

                        <!-- SE A SELECAO FOR NOME (3) -->
                        <div class="form-group" v-if="selectedValue == 3">
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomName">Digite o nome do formulário: </label>
                            <input class="input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_instituicoes" id="inlineFormCustomName" autocomplete="off">
                        </div>

                        <!-- SE A SELECAO FOR NOME (4) -->
                        <div class="form-group" v-if="selectedValue == 4">
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomName">Digite o nome da UE: </label>
                            <input class="input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_instituicoes" id="inlineFormCustomName" autocomplete="off">
                        </div>

                        <!-- SE A SELECAO FOR NOME (5) -->
                        <div class="form-group" v-if="selectedValue == 5">
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomName">Digite o nome da turma: </label>
                            <input class="input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_instituicoes" id="inlineFormCustomName" autocomplete="off">
                        </div>

                        <!-- SE A SELECAO FOR DATA (6) -->
                        <div class="form-group" v-if="selectedValue == 6">
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomName">Data de início: </label>
                            <input class="input-personal mr-sm-4" v-model="searchDate.begin" type="date" id="inlineFormCustomName" autocomplete="off">

                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomName">Data de fim: </label>
                            <input class="input-personal mr-sm-4" v-model="searchDate.end" type="date" id="inlineFormCustomName" autocomplete="off">
                        </div>
                    </form>
                </div>
            </div>
            <hr class="separator">

            <!-------------------------------- LISTANDO AS APLICAÇÕES ----------------------------------------------->

            <div v-if="isLoadedInfo">
                <MemberQnt term="aplicações" :totalQnt="totalAplicacoes" :filteredQnt="aplicacoes?.length || 0" icon="fas fa-pen" />

                <transition-group name="slide-fade" v-if="aplicacoes?.length > 0">
                    <div class="box-header">
                        <span></span>
                        <span>Aplicador</span>
                        <span>Nome</span>
                        <span>Unidade</span>
                        <span>Turma</span>
                        <span>Status</span>
                        <span>Envio</span>
                    </div>

                    <div class="box-lista" v-for="aplicacao in aplicacoes" :key="aplicacao.counter" :class="{'list-item-selected-orange': aplicacao.isAtivo}">
                        <div>
                            <img  class="img-info-box-lista" src="@assets/img/Dados/ListarCampos/icon-aplicacoes.png" alt="">
                        </div>
                        <span>{{aplicacao.aplicador}}</span>
                        <span>{{aplicacao.form_name}}</span>
                        <span>{{aplicacao.unidade}}</span>
                        <span>{{aplicacao.turma}}</span>
                        <span>{{aplicacao.status}}</span>
                        <span class="orange-text-no-hover ">Enviado em {{convertUTC(aplicacao.data_upload)}}</span>
                    </div>
                </transition-group>
                <transition-group v-else>
                    <h2 class="title-style-box-lista">Nenhuma Aplicação encontrada.</h2>
                </transition-group>

            </div>

            <div v-else>
                <SkeletonList/>
            </div>

        </div>
    </div>

</template>

<script>
 import parsers from '@middleware/parsers'
 import Filter from '@components/Filter'
 import Breadcrumb from '@components/Breadcrumb'
 import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
 import SkeletonList from '@components/SkeletonUI/SkeletonList'
 import MemberQnt from '@dados/VisualizarInstituicao/MemberQnt'

 export default {
     name: 'CampoListaFormularios',
     data (){
         return {
             nomeCampo: '',
             descricao: '',
             selectedValue: '',
             searchByName: '',
             searchByCode: '',
             searchWithOrder: '',
             searchDate: {
                 begin: null,
                 end: null,
             },
             isLoadedInfo: false,
             options: [
                 {
                     id:1,
                     value: 'Todos'
                 },
                 {
                     id:2,
                     value: 'Nome do aplicador'
                 },
                 {
                     id:3,
                     value: 'Nome do formulário'
                 },
                 {
                     id:4,
                     value: 'Nome da UE'
                 },
                 {
                     id:5,
                     value: 'Nome da turma'
                 },
                 {
                     id: 6,
                     value: "Data",
                 }
             ],
             crumbs: [
                 {
                     id:1,
                     value: '',
                     iconName: 'th',
                     name_route: 'GerenciarInstituicoes'
                 },
                 {
                     id:2,
                     value: 'Instituição',
                     iconName: 'university',
                     name_route: 'InstituicaoMenu'
                 },
                 {
                     id:3,
                     value: 'Campos',
                     iconName: 'th-large',
                     name_route: 'AplicacoesListaCampos'
                 },
                 {
                     id:4,
                     value: 'Aplicações',
                     iconName: 'pencil-alt',
                     name_route: ''
                 }

             ]
         }
     },
     async created(){
         this.campos = this.$store.getters.getListCampos

         const payload = {
             idInstituicao: this.$route.params.idInstituicao,
             idCampo: this.$route.params.idCampoAplicacoes,
         }

         this.isLoadedInfo = false
         await this.$store.dispatch('setListarAplicacao', payload)
         this.isLoadedInfo = true
     },
     computed: {
         totalAplicacoes() {
             return this.$store.getters?.getListarAplicacao.length || 0;
         },
         aplicacoes() {
             var aplicacoes =  this.$store.getters.getListarAplicacao

             var query = aplicacoes;
             //CASO SEJA SELECIONADO TODOS, EXIBE TODOS OS ITENS DO ARRAY
             if (this.selectedValue == 1) {
                 query = aplicacoes;
             }
             //CASO SEJA SELECIONADO NOME, FILTRA POR NOME
             else if (this.selectedValue == 2) {
                 //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
                 if (this.searchByName.trim() == '') {
                     query = aplicacoes;
                 }
                 //SENAO ELE FILTRA PELO O QUE FOR DIGITADO
                 else {
                     let filtered = aplicacoes?.filter(aplicacao => aplicacao.aplicador.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
                     if(filtered?.length != 0){
                         query = filtered
                     }

                     else{
                         query = false
                     }
                 }
             }

             else if (this.selectedValue == 3) {
                 //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
                 if (this.searchByName.trim() == '') {
                     query = aplicacoes;
                 }
                 //SENAO ELE FILTRA PELO O QUE FOR DIGITADO
                 else {
                     let filtered = aplicacoes?.filter(aplicacao => aplicacao.form_name.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
                     if(filtered?.length != 0){
                         query = filtered
                     }

                     else{
                         query = false
                     }
                 }
             }

             else if (this.selectedValue == 4) {
                 //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
                 if (this.searchByName.trim() == '') {
                     query = aplicacoes;
                 }
                 //SENAO ELE FILTRA PELO O QUE FOR DIGITADO
                 else {
                     let filtered = aplicacoes?.filter(aplicacao => aplicacao.unidade.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
                     if(filtered?.length != 0){
                         query = filtered
                     }

                     else{
                         query = false
                     }
                 }
             }

             else if (this.selectedValue == 5) {
                 //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
                 if (this.searchByName.trim() == '') {
                     query = aplicacoes;
                 }
                 //SENAO ELE FILTRA PELO O QUE FOR DIGITADO
                 else {
                     let filtered = aplicacoes?.filter(aplicacao => aplicacao.turma.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
                     if(filtered?.length != 0){
                         query = filtered
                     }

                     else{
                         query = false
                     }
                 }
             }

             else if (this.selectedValue == 6) {
                 //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
                 if (!this.searchDate.begin && !this.searchDate.end) {
                     query = aplicacoes;
                 }
                 //SENAO ELE FILTRA PELO O QUE FOR DIGITADO
                 else {
                     const dateBegin = new Date(this.searchDate.begin || 0),
                           dateEnd = this.searchDate.end ? new Date(this.searchDate.end) : new Date();
                     let filtered = aplicacoes?.filter(aplicacao => new Date(aplicacao.data_aplicacao) > dateBegin && new Date(aplicacao.data_aplicacao) < dateEnd);
                     if(filtered?.length != 0){
                         query = filtered
                     }

                     else{
                         query = false
                     }
                 }
             }

             return query;
         }
     },
     components: {
         Filter,
         Breadcrumb,
         InstitutionInfo,
         SkeletonList,
         MemberQnt,
     },
     methods: {
         setSelectedValue: function() {
             this.selectedValue = this.$refs.filtersearchname.value
         },

         convertUTC(data){
             return parsers.convertUTC(data)
         },
     },
     mounted: function() {
         window.scrollTo(0, 0);
         let idCampoSelected = this.$route.params.idAmostra

         if (typeof idCampoSelected == "undefined"){
             this.nomeCampo = "Nome do Campo"
         }
         else {
             let CamposSelected
             let CampoSelected

             CamposSelected = this.teams.filter(team =>
                 team.idAmostra == idCampoSelected
             )

             CampoSelected = [...CamposSelected].shift()

             this.nomeCampo = CampoSelected.name
             this.descricao = CampoSelected.descricao
         }
     }
 }
</script>

<style scoped>
 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;
 }

 .imgText {
     background-image: url("~@/assets/img/Backgrounds/laranjaIntenso.webp");
 }

 .box-lista {
     background-repeat: repeat;
     background-size: 256px;
     background-image: url("~@/assets/img/Backgrounds/laranjaClaroEAPI.webp");

     border: 1px solid #f9ac5d;
 }

 :deep(.box-lista span) {
     font-size: 1.1em;
 }

 .box-lista span {
     background-image: url("~@/assets/img/Backgrounds/laranjaIntenso.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .text-info-personal {
     color: #d78100;
     font-size: 1.1rem;
     text-align: justify;
     padding: 0rem;
     margin: 0 3rem 0 3rem;
 }

 .list-data {
     min-height: 90vh;
     padding-top: 2rem;
 }
 .label-filtro {
     font-weight: 600;
 }

 .filter-box {
     margin: 1rem 3rem;
 }

 .list-box {
     margin: 2rem;
 }

 .itens-box {
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .title-tree {
     margin: 1rem 3rem;
     font-weight: bold;
     display: flex;
 }


 .lista-data-info {
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;
     margin: 2rem 3rem;
 }

 .title-tree h1 {
     font-size: 1.2rem;
     font-weight: 700;
 }

 .main-link {
     outline:none;
     text-decoration: none;
     cursor: pointer;
     margin-right: .3rem;
 }

 .sub-link {
     margin-left: .3rem;
 }

 .title-style{
     margin: 0 0 0 3rem;
 }

 .separator {
     border: none;
     height: 1px;
     margin: 0.8rem 3rem;
     background: url("~@/assets/img/Backgrounds/laranja.webp");
 }

 a {
     color: #212529;
     text-decoration: none;
     transition: all ease-in-out 0.2s
 }

 a:hover {
     color: #4d4f50;
 }

 @media screen and (max-width: 1010px) {
     .lista-data-info {
         justify-content: center;
     }
 }


 @media screen and (max-width: 1012px) {
     .filter-box {
         display: flex;
         align-items: center;
         justify-content: center;
     }
 }

 @media screen and (max-width: 680px) {
     .itens-box{
         flex-direction: column;
         align-items: flex-start;
     }

     .filter-box{
         margin: 0 3rem;
     }

     .lista-data-info{
         margin: 0 3rem;
     }
 }

</style>
