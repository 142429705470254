<template>
<VideoModal
  v-if="pratica?.link && pratica?.link != 'null' && pratica?.link != 'undefined'"
  :videoLink="clipVideoId(pratica.link)"
  id="videoModal"
  />

<ModalCentral
  v-if="pratica?.pdf && pratica?.pdf != 'null' && pratica?.pdf != 'undefined'"
  componentName="PopupDownload"
  title="Baixar PDF"
  :data="{
         pdf: pratica.pdf,
         name: pratica.titulo,
         id: pratica.idPratica,
         }"
  idName="pdfPopup" />

<ModalCentral
  componentName="PopupRating"
  title="Avaliar Prática"
  :data="{ idPratica: idPratica }"
  idName="ratingPopup" />

<ModalCentral
  componentName="UpdatePratica"
  :data="{ idPratica: idPratica }"
  title="Editar Prática"
  ref="edit"
  idName="editPopup" />

<div class="outter" v-if="loaded">
  <div v-if="!pratica">
    Não foi possível encontrar a prática solicitada!
  </div>
  <div v-else>
    <div v-if="isAdmin" class="adminMenu">
      <Button
        @click="deletePratica"
        class="menuBut"
        value="Excluir prática"  />

      <Button
        @click="setEdit"
        data-target="#editPopup"
        data-toggle="modal"
        class="menuBut"
        value="Editar prática" />
    </div>

    <div class="intro">
      <div class="info">
        <div class="title">{{ pratica.titulo }}</div>

        <div
          class="videoContainer interactive"
          data-target="#videoModal"
          data-toggle="modal"
          >
          <img
            v-if="useThumb"
            class="thumb"
            :src="`https://i.ytimg.com/vi/${clipVideoId(pratica.link)}/maxresdefault.jpg`"
            alt="Capa do vídeo" />
          <img
            v-else
            class="thumb"
            :src="linkImg"
            :alt="`Prática ${pratica.titulo}`" />
          <!-- <i class="fas fa-play playThumb playBorder" /> -->
          <i v-if="useThumb" class="fas fa-play playThumb" />
        </div>

        <div class="innerData">
          <div class="innerInfos">
            <h6>Publicado em {{ horaCriacao }}</h6>
            <div>
              <i class="fas fa-id-badge"></i>
              &nbsp;
              {{ pratica.autor }}
            </div>
            <div>
              <i class="fas fa-shapes"></i>
              &nbsp;
              {{ pratica.idade }}
            </div>
            <Button
              v-if="pratica?.pdf && pratica?.pdf != 'null' && pratica?.pdf != 'undefined'"
              data-target="#pdfPopup"
              data-toggle="modal"
              class="innerButton"
              value="BAIXAR ARQUIVO PDF"
              :isOutline="true"
              :hasIcon="true"
              iconName="file-download" />
          </div>
          <div class="themes">
            <h6>Categorias</h6>
            <div class="tags">
              <div v-for="tematica in pratica?.boasPraticasCategorias"
                   :style="{ 'background': `url(${colorImgs[tematica.idTipo.idTipo % colorImgs.length].background})` }"
                   :key="tematica"
                   :title="tematica.idTipo.nome"
                   class="tag">
                {{ tematica.idTipo.nome }}
              </div>
            </div>
            <Button
              data-target="#ratingPopup"
              data-toggle="modal"
              class="innerButton"
              value="AVALIAR"
              :isOutline="true"
              :hasIcon="true"
              iconName="star" />
          </div>
        </div>
      </div>

      <div class="summary">
        <div class="title">{{ pratica.boasPraticasSecoes[0]?.idSecao.nome }}</div>
        <p> {{ pratica.boasPraticasSecoes[0]?.conteudo }} </p>
      </div>
    </div>

    <div class="bottom">
      <div class="bottomText">
        <template
          v-for="(secao, idx) of pratica.boasPraticasSecoes.slice(1)"
          :key="idx">
          <div class="title">{{ secao.idSecao.nome }}</div>
          <p> {{ secao.conteudo }} </p>
        </template>

        <div v-if="isAdmin && avaliacoes.length">
          <div class="title">Avaliações</div>
          <Avaliacao
            v-for="(rating, idx) in avaliacoes"
            :key="idx"
            :score="rating?.score"
            :text="rating?.msg" />
        </div>
      </div>
      <div class="related">
        <div class="title">Práticas Relacionadas</div>
        <div
          v-for="(related, idx) in relatedPraticas"
          :style="{
                  'backgroundImage': `linear-gradient(white, white), linear-gradient(to bottom right, ${praticaGradient(related)})`
                  }"
          :key="idx"
          class="relatedPratica">
          <a :href="`/boaspraticas/${related.idPratica}`" class="relatedInner">
            <img
              v-if="getUseThumb(related)"
              :src="`https://i.ytimg.com/vi/${clipVideoId(related.link)}/maxresdefault.jpg`"
              alt="Capa do vídeo" />
            <img
              v-else
              :src="getPraticaImg(related)"
              :alt="`Prática ${pratica.titulo}`" />
            <div class="titulo"> {{ related.titulo }} </div>
            <div class="autor">
              <i class="fas fa-id-badge"></i>
              &nbsp;
              {{ related.autor }}
            </div>
            <div class="idade">
              <i class="fas fa-shapes"></i>
              &nbsp;
              {{ related.idade }}
            </div>
          </a>
        </div>
        <div class="noneFound" v-if="!relatedPraticas.length">
          Nenhuma prática relacionada encontrada!
        </div>
      </div>
    </div>
  </div>
</div>

<div hidden>
  <img
    v-for="(i, idx) of imgs"
    :ref="`img${idx}`"
    :src="i"
    :key="idx"
    @load="loadedImgs[idx] = true"
    />
</div>
</template>

<script>
import Button from "@components/Button";
import Avaliacao from "@components/BoasPraticas/Avaliacao";
import ModalCentral from "@components/ModalCentral";
import VideoModal from "@components/Forms/VideoModal";
import ColorThief from "colorthief";
import axios from '@middleware/api.js';

import Swal from 'sweetalert2';
import { Notyf } from "notyf";
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: "left",
    y: "bottom",
  },
});

export default {
  name: "Pratica",
  components: {
    Button,
    ModalCentral,
    Avaliacao,
    VideoModal,
  },
  data() {
    return {
      loaded: false,
      imgs: [
        require('../../assets/img/Backgrounds/azulEscuro.webp'),
        require('../../assets/img/Backgrounds/roxoEscuro.webp'),
        require('../../assets/img/Backgrounds/azulClaro.webp'),
        require('../../assets/img/Backgrounds/marrom.webp'),
        require('../../assets/img/Backgrounds/cianoEscuro.webp'),
        require('../../assets/img/Backgrounds/marsala.webp'),
        require('../../assets/img/Backgrounds/verdeEscuro.webp'),
        require('../../assets/img/Backgrounds/laranja.webp'),
        require('../../assets/img/Backgrounds/cinza.webp'),
        require('../../assets/img/Backgrounds/verde.webp'),
      ],
      loadedImgs: [],
    };
  },
  async created() {
    this.$store.dispatch('loadListBoasPraticas');
    this.loaded = true;
    this.loadedImgs = new Array(this.imgs.length).fill(false);
  },
  computed: {
    colorImgs() {
      const cf = new ColorThief();
      return (this.imgs || []).map((c, idx) => {
        if (!this.loadedImgs[idx]) return { color: '', background: '', };
        const color = cf.getColor(this.$refs[`img${idx}`][0] || this.$refs[`img${idx}`]);
        return { color: `rgb(${color[0]}, ${color[1]}, ${color[2]})`, background: c };
      });
    },
    categoryNames() {
      let original = this.$store.getters.getPraticaCategories;
      return original?.sort((a, b) => a.codigo - b.codigo).map(cat => cat.nome) || [];
    },
    listBoasPraticas() {
      return this.$store.getters.getListBoasPraticas;
    },
    pratica() {
      return this.listBoasPraticas.find(p => p.idPratica == this.idPratica);
    },
    avaliacoes() {
      return this.pratica?.avaliacao?.map((v, idx) => {
        return { score: v, msg: this.pratica?.avaliacaoText[idx] || "", };
      }) || [];
    },
    idPratica() {
      return this.$route.params.idPratica;
    },
    horaCriacao() {
      return new Date(this.pratica.horaCriacao).toLocaleDateString('pt-BR');
    },
    relatedPraticas() {
      return this.listBoasPraticas.filter(praticaItem => {
        if (praticaItem.idPratica == this.pratica.idPratica) return false;
        for (const tematica of this.praticaCategories(praticaItem)) {
          if (this.praticaCategories(this.pratica).includes(tematica))
            return true;
        }
        return false;
      }).slice(0, 3);
    },
    isAdmin() {
      return this.$store.getters.getUserInfo?.data?.isAdmin;
    },
    linkImg() { return this.getPraticaImg(this.pratica); },
      useThumb() { return this.getUseThumb(this.pratica); },
      videoPresent() { return (this.pratica?.link && this.pratica?.link != 'null' && this.pratica.link !== 'undefined'); },
  },
  methods: {
      getUseThumb(_pratica) {
          return (!(_pratica?.imagem && _pratica?.imagem != 'null' && _pratica.imagem !== 'undefined') && (_pratica?.link && _pratica?.link != 'null' && _pratica.link !== 'undefined'));
      },
    getPraticaImg(_pratica) {
      return (_pratica?.imagem && _pratica?.imagem != 'null' && _pratica.imagem !== 'undefined') ? `${axios.defaults.baseURL}/boas_praticas/getImage?path=${_pratica?.imagem}` : require('../../assets/img/BoasPraticas/oquee.jpeg');
    },
    setEdit() {
      this.$refs.edit.dispatch("setData");
    },
    praticaCategories(pratica) {
      return pratica?.boasPraticasCategorias.map(t => t.idTipo.idTipo);
    },
    clipVideoId(raw) {
      if (!raw) return "";
      let url = raw.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
    },
    praticaGradient(pratica) {
      if ((pratica?.boasPraticasCategorias?.length || 0) > 1)
        return pratica?.boasPraticasCategorias.map(tematica => this.colorImgs[tematica.idTipo.idTipo % this.colorImgs?.length]?.color);
      return [
        this.colorImgs[pratica?.boasPraticasCategorias[0].idTipo.idTipo % this.colorImgs?.length]?.color,
        this.colorImgs[pratica?.boasPraticasCategorias[0].idTipo.idTipo % this.colorImgs?.length]?.color,
      ];
    },
    async deletePratica() {
      const result = await Swal.fire({
        title: 'Deseja mesmo remover essa prática?',
        text: `Tem certeza que deseja excluir a prática ${this.pratica.titulo}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, tenho certeza',
      });
      if (!result.isConfirmed) return;

      await this.$store.dispatch("deletePratica", {
        idPratica: this.idPratica,
      });

             if (this.$store.getters.getPraticaDeleted) {
                 notyf.open({
                     type: "success",
                     message: "Prática excluída com sucesso"
                 });
                 this.$router.push("/boaspraticas/acervo");
             } else {
                 notyf.open({
                     type: "error",
                     message: "Erro ao excluir a prática!"
                 });
             }
         },
     },
 };
</script>

<style scoped>
 .outter {
     width: 100%;
     padding: 1em;
     background: url("~@/assets/img/Backgrounds/branco.png");
     background-repeat: repeat;
     background-size: 256px;
 }

 .intro {
     background: url("~@/assets/img/Backgrounds/azul.png");
     background-repeat: repeat;
     background-size: 256px;
     padding: 10px;
     width: 100%;
     display: grid;
     grid-template-columns: 1fr 2fr;
     grid-template-rows: auto;
     row-gap: 15px;
     column-gap: 15px;
     box-shadow: inset 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
     border-radius: 1rem;
 }

 .intro > .info {
     display: flex;
     flex-direction: column;
     justify-content: center;
 }

 .interactive {
     cursor: pointer;
     transition: 0.4s;
 }

 .interactive:hover {
     transform: scale(1.02);
     transform-origin: center center;
 }

 .info .videoContainer {
     width: 100%;
     position: relative;
     border-radius: 8px;
     box-shadow: 4px 6px 6px -2px rgb(0 0 0 / 25%);
     margin-bottom: 5px;
     overflow: hidden;
 }

 .videoContainer > img.thumb {
     width: 100%;
 }

 .playThumb {
     font-size: 3em;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     filter: drop-shadow(0 0 10px black);
     color: white;
 }

 .playBorder {
     color: white;
     font-size: 3.5em;
     filter: blur(1px);
 }

 .innerInfos > div {
     color: #444343;
 }

 .innerData {
     width: 100%;
     display: grid;
     grid-template-columns: 50% 50%;
     grid-template-rows: auto;
     column-gap: 5px;
     padding: 2px;
     font-size: 0.8em;
 }

 .innerData h6 {
     width: 100%;
     font-weight: 700;
 }

 .innerInfo {
     text-align: justify;
 }

 .themes {
     text-align: center;
 }

 .tags {
     width: 100%;
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     justify-content: center;
 }

 .tags > .tag {
     width: min-content;
     /* max-width: 100%; */
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     color: white;
     border: none;
     border-radius: 50px;
     margin: 5px;
     box-sizing: border-box;
     font-size: 0.9em;
     font-weight: bold;
     padding: 5px 8px;
     background-repeat: repeat;
     background-size: 256px;
 }

 .innerButton {
     display: block;
     margin: 5px auto;
     border: none;
     border-radius: 2em;
     color: white;
     filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
     background: url("~@/assets/img/Backgrounds/verdeMedio.png");
     box-sizing: border-box;
     width: calc(100% - 30px);
 }

 .title {
     width: 100%;
     text-align: center;
     font-weight: bold;
     font-size: 1.7em;
     filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

     background-clip: text;
     text-fill-color: transparent;
     background-image: url("~@/assets/img/Backgrounds/branco.png");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
 }

 .bottom .title {
     background-image: url("~@/assets/img/Backgrounds/cianoEscuro.png");
 }

 .bottom {
     padding: 15px;
     display: grid;
     grid-template-columns: 4fr 1fr;
     grid-template-rows: auto;
     column-gap: 5px;
     row-gap: 5px;
     width: 100%;
 }

 .bottomText {
     width: auto;
 }

 .related {
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     width: 100%;
     max-width: 220px;
     margin: 0 auto;
 }

 .noneFound {
     width: 100%;
     text-align: center;
     font-style: italic;
 }

 .relatedPratica {
     cursor: pointer;
     width: 100%;
     max-width: 220px;
     margin: 5px auto;
     box-sizing: border-box;
     border: double 3px transparent;
     border-radius: 1rem;
     background-origin: border-box;
     background-clip: content-box, border-box;
 }

 .relatedInner {
     color: currentColor !important;
     width: 100%;
     height: 100%;
     padding: 10px;
     display: flex;
     flex-direction: column;
     background: url("~@/assets/img/Backgrounds/laranjaMaisClaro.png");
     background-repeat: repeat;
     background-size: 256px;
     box-shadow: inset -2px 2px 2px rgba(0, 0, 0, 0.25);
     border-radius: 1rem;
 }

 .relatedInner > img {
     border-radius: 5px;
 }

 .relatedInner > .titulo {
     font-weight: bold;
     width: 100%;
     text-align: center;
 }

 .relatedInner > .autor {
     width: 100%;
     text-align: left;
     font-size: 0.8em;
 }

 .relatedInner > .idade {
     width: 100%;
     text-align: left;
     font-size: 0.8em;
 }
 p {
     text-indent: 1em;
 }

 @media screen and (max-width: 900px) {
     .intro {
         grid-template-columns: auto;
     }

     .bottom {
         grid-template-columns: 1fr;
     }

     .related {
         flex-direction: row;
         max-width: none;
     }
 }

 .adminMenu {
     width: 100%;
     display: flex;
     flex-direction: row-reverse;
 }

 .menuBut {
     margin: 5px;
     display: block;
     background: url("~@/assets/img/Backgrounds/verdeEscuro.png");
     background-repeat: repeat;
     background-size: 256px;
     box-shadow: inset -2px 2px 2px rgba(0, 0, 0, 0.25);
     border-radius: 8px;
     border: 0;
     color: #fcfcfc;
 }
</style>
