<template>
<div class="apresentacao">
  <ModalCentral
    v-if="userPerm"
    componentName="EditCard"
    title="Editar card"
    idName="editCard"
    :data="{ idCard: idCard }"
    />

  <h2 class="pageTitle text-img-clipper">Entenda sobre a importância da qualidade na Educação Infantil</h2>

  <div
    v-for="(card, idx) of cardsOquee"
    class="infoCard"
    :class="{ 'cardRight': !(idx % 2), 'cardLeft': (idx % 2) }"
    :key="card?.idCard">
    <div class="text">
      <span>
        <h2
          class="titlePraticas text-img-clipper titleRight">
          <i class="fas fa-pen editIcon"
             v-if="userPerm"
             @click="idCard = card.idCard"
             data-toggle="modal"
             data-target="#editCard" />
          {{ card?.titulo }}
        </h2>
        <span v-html="card?.corpoTexto" />
      </span>
    </div>
  </div>
</div>
</template>

<script>
import ModalCentral from '@components/ModalCentral';

export default {
    name: 'BoasPraticas_Qualidade',
    components: {
        ModalCentral,
    },
    data() {
        return {
            idCard: null,
        };
    },
    created() {
        this.$store.dispatch("setListCards");
    },
    computed: {
        userPerm() {
            try {
                return this.$store.getters.getUserInfo.data.isAdmin;
            } catch(e) {
                return false;
            }
        },
        cards() { return (this.$store.getters.getListCards || []).sort((a, b) => parseInt(a?.ordem || 0) - parseInt(b?.ordem || 0)); },
        cardsOquee() { return this.cards.filter(card => card?.idTipo == 45); },
    },
}
</script>

<style scoped>
 .apresentacao {
     padding: 2rem 2rem;
     width: 100%;
     background: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
 }

 .infoCard {
     /* display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center; */
     padding: 2rem;
     background-repeat: repeat;
     background-size: 256px;
     border-radius: 2rem;
     box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
     margin-bottom: 1.5em;
 }

 .cardRight {
     background: url("~@/assets/img/Backgrounds/cianoEscuro.webp");
 }

 .cardLeft {
     background: url("~@/assets/img/Backgrounds/marsala.webp");
 }

 /* .infoCard > img {
    margin: 0 auto;
    flex: 1 0 30%;
    display: block;
    width: 100%;
    max-width: 400px;
    min-width: 300px;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    } */

 .infoCard > :deep(.text) {
     /* flex: 1 0 70%; */
     width: 100%;
     display: block;
     text-align: justify;
     font-size: 1.15em;
     box-sizing: border-box;
     padding: 10px;
 }

 .infoCard > :deep(.text p) {
     text-indent: 1em;
 }

 .apresentacao > .imgs {
     display: flex;
     flex-wrap: wrap;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
     text-align: center;
 }

 .imgs > img {
     margin: 0 auto;
     padding: 10px;
     width: 100%;
 }

 .bancoContainer {
     width: 100%;
     display: flex;
     flex-direction: row;
     justify-content: flex-end;
 }

 .butBanco {
     display: block;
     background: url("~@/assets/img/Backgrounds/verdeEapi.webp");
     background-repeat: repeat;
     background-size: 256px;
     border-radius: 20px;
 }

 :deep(.butBanco span) {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .categories {
     display: grid;
     grid-template-columns: repeat(2, 1fr);
     grid-auto-rows: 1fr;
     row-gap: 10px;
     column-gap: 10px;
     text-align: center;
     padding: 15px;
 }

 .category {
     margin: 0 auto;
     border: 0;
     border-radius: 2rem;
     padding: 0.5rem;
     width: 70%;
     display: flex;
     justify-content: center;
     align-items: center;
     cursor: pointer;
     font-weight: 600;
     box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
     background-repeat: repeat;
     background-size: 256px;
 }

 .titlePraticas {
     width: 100%;
     padding: 5px;
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     display: flex;
     align-items: center;
     flex-direction: row-reverse;
 }

 /* .cardRight .titlePraticas { */
 /*     flex-direction: row-reverse; */
 /* } */

 /* .cardLeft .titlePraticas { */
 /*     flex-direction: row; */
 /* } */

 .titleAxis {
     width: 100%;
     padding: 20px;
     text-align: center;
     color: rgba(25, 89, 83, 0.8);
     text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
 }

 @media screen and (max-width: 800px) {
     .categories {
         grid-template-columns: 1fr;
     }

     .imgs > img {
         width: 40% !important;
         max-width: 350px;
     }
 }

 .text :deep(p) {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .pageTitle {
     background-image: url("~@/assets/img/Backgrounds/marsala.webp");
     width: 100%;
     text-align: center;
 }

 .editIcon {
     color: #fcfaf2;
     font-size: 1.3rem;
     cursor: pointer;
     transition: 0.4s;
     box-sizing: border-box;
     margin: 10px;
 }

 .editIcon:hover {
     transform: scale(1.1);
     transform-origin: center center;
 }
</style>
