<template>
  
    <div class="container">
        
        <div class="question-style" data-toggle="collapse" :data-target="`#idFAQ${idFAQ}`" aria-expanded="false" aria-controls="collapseExample">
            {{question}}
            <i class="fas fa-chevron-down"></i>
        </div>
        
        <div class="collapse" :id="`idFAQ${idFAQ}`">
            <div class="card custom-rounded card-body">
                {{answer}}
            </div>
        </div>
    </div>


</template>

<script>
export default {
    name: 'FAQCard',
    props: {
        idFAQ: Number,
        question: String,
        answer: String
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.container{
    width: inherit;
    -webkit-box-shadow: 12px 12px 23px -7px rgb(158 146 158 / 67%);
    -moz-box-shadow: 12px 12px 23px -7px rgb(158 146 158 / 67%);
    box-shadow: 12px 12px 23px -7px rgb(158 146 158 / 67%);
    text-align: justify;
    padding: 0 !important;
    border-radius: 0.4rem !important;
}

.question-style{
    font-size: 1.1rem;
    padding: 0;
    background-color: $marrom-escuro-eapi;
    color: white;
    border-radius: 0.4rem !important;
    padding: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.answer-style{
    font-weight: 400;
    font-size: 1rem;
    color: #535353;
    margin: 0;
}

.card .card-body{
    border-radius: 0.4rem !important;
}

</style>
