<template>
    <ModalCentral
        v-if="userPerm"
        componentName="EditCard"
        :title="idCard ? 'Editar card' : 'Adicionar card'"
        idName="editCard"
        :data="{ idCard: idCard, ordem: order, idTipo: idTipo }"
    />

    <div class="bg">
        <h2 class="pageTitle">Instrumento - EAPI</h2>

        <template v-for="resource in instrumentoTop" :key="resource.idCard">
            <div class="card topCard">
                <h3 class="text">
                    <i class="fas fa-pen editIcon"
                       v-if="userPerm"
                       @click="setIdCard(resource.idCard)"
                       data-toggle="modal"
                       data-target="#editCard" />
                    <i class="fas fa-trash editIcon"
                       v-if="userPerm"
                       @click="deleteCard(resource.idCard)" />
                    <i class="fas fa-chevron-down editIcon"
                       v-if="userPerm"
                       @click="cardSendAfter(resource.idCard)" />
                    <i class="fas fa-chevron-up editIcon"
                       v-if="userPerm"
                       @click="cardSendBefore(resource.idCard)" />
                    {{ resource?.titulo }}
                </h3>

                <div class="text">
                    <img
                        v-if="resource?.imagem?.length > 0"
                        class="textImg"
                        :src="getImage((resource?.imagem || [])[0])"
                        alt="Prancheta de observador" />

                    <div class="content" v-html="resource.corpoTexto"></div>

                    <a
                        v-for="(button, idx) in resource?.botao"
                        :key="idx"
                        :href="button?.link">
                        <Button
                            class="cardBut"
                            :value="button?.texto"
                            :isOutline="false" />
                    </a>
                </div>
            </div>

            <div
                v-if="userPerm"
                @click="setIdCard(parseInt(resource.ordem) + 1, true, resource.idTipo)"
                data-toggle="modal"
                data-target="#editCard"
                class="addCard">
                <i class="fas fa-plus"></i>
            </div>
        </template>

        <template v-for="resource in instrumentoText" :key="resource.idCard">
            <div class="card bottomCard">
                <div class="text">
                    <h3>
                        <i class="fas fa-pen editIcon"
                           v-if="userPerm"
                           @click="setIdCard(resource.idCard)"
                           data-toggle="modal"
                           data-target="#editCard" />
                        <i class="fas fa-trash editIcon"
                           v-if="userPerm"
                           @click="deleteCard(resource.idCard)" />
                        <i class="fas fa-chevron-down editIcon"
                           v-if="userPerm"
                           @click="cardSendAfter(resource.idCard)" />
                        <i class="fas fa-chevron-up editIcon"
                           v-if="userPerm"
                           @click="cardSendBefore(resource.idCard)" />
                        {{ resource?.titulo }}
                    </h3>

                    <div class="content" v-html="resource.corpoTexto" />
                </div>

                <div class="gridButtons">
                    <a
                        v-for="(button, idx) in resource?.botao"
                        :key="idx"
                        :class="{ 'gabaritoArea': (idx + 1) % 3 == 0 }"
                        target="_blank"
                        :href="button?.link">
                        <Button
                            :value="button?.texto"
                            class="cardBut"
                            :isOutline="false" />
                    </a>
                </div>
            </div>

            <div
                v-if="userPerm"
                @click="setIdCard(parseInt(resource.ordem) + 1, true, resource.idTipo)"
                data-toggle="modal"
                data-target="#editCard"
                class="addCard">
                <i class="fas fa-plus"></i>
            </div>
        </template>

        <template v-for="resource in instrumentoMisc" :key="resource.idCard">
            <div class="card bottomCard">
                <h3 class="text">
                    <i class="fas fa-pen editIcon"
                       v-if="userPerm"
                       @click="setIdCard(resource.idCard)"
                       data-toggle="modal"
                       data-target="#editCard" />
                    <i class="fas fa-trash editIcon"
                       v-if="userPerm"
                       @click="deleteCard(resource.idCard)" />
                    <i class="fas fa-chevron-down editIcon"
                       v-if="userPerm"
                       @click="cardSendAfter(resource.idCard)" />
                    <i class="fas fa-chevron-up editIcon"
                       v-if="userPerm"
                       @click="cardSendBefore(resource.idCard)" />
                    {{ resource?.titulo }}
                </h3>

                <div class="resource">
                    <img
                        v-if="resource?.imagem?.length > 0"
                        :src="getImage((resource?.imagem || [])[0])"
                        :alt="resource?.titulo" />
                    <div class="info">
                        <div class="text" v-html="resource?.corpoTexto"></div>
                        <a
                            v-for="(button, bIdx) in resource?.botao"
                            target="_blank"
                            :key="bIdx"
                            :href="button.link">
                            <Button
                                :value="button.texto"
                                class="cardBut resourceBut"
                                :isOutline="false" />
                        </a>
                    </div>
                </div>
            </div>

            <div
                v-if="userPerm"
                @click="setIdCard(parseInt(resource.ordem) + 1, true, resource.idTipo)"
                data-toggle="modal"
                data-target="#editCard"
                class="addCard">
                <i class="fas fa-plus"></i>
            </div>
        </template>

        <div class="card bottomCard extra">
            <div class="text">
                Nas páginas abaixo, estão materiais complementares.
            </div>
            <div class="extraButtons">
                <router-link :to="{ name: 'MaterialTreinamento' }">
                    <Button
                        value="Material de Treinamento"
                        class="cardBut resourceBut"
                        :isOutline="false" />
                </router-link>

                <router-link :to="{ name: 'MaterialLeitura' }">
                    <Button
                        value="Material de leitura"
                        class="cardBut resourceBut"
                        :isOutline="false" />
                </router-link>

                <router-link :to="{ name: 'MaterialCampo' }">
                    <Button
                        value="Material de Campo"
                        class="cardBut resourceBut"
                        :isOutline="false" />
                </router-link>

            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
 import Button from '@components/Button';
 import ModalCentral from '@components/ModalCentral';
 import axios from '@middleware/api.js';
 import Swal from 'sweetalert2';
 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: 'left',
         y: 'bottom',
     }
 });

 export default {
     name: "Instrumentos",
     components: {
         Button,
         ModalCentral,
     },
     async created() {
         this.$store.dispatch("setListCards");
     },
     data() {
         return {
             idCard: null,
             order: null,
             idTipo: null,
         };
     },
     methods: {
         setIdCard(id, newCard, idTipo) {
             this.idCard = newCard ? null : id;
             this.order = newCard ? id : null;
             this.idTipo = newCard ? idTipo : this.cards.find(card => card.idCard == id)?.idTipo;
         },
         getImage(path) {
             if (!path || path === 'null' || path === 'undefined') return null;
             return `${axios.defaults.baseURL}/materiais_documentos/cards/getImage?path=${path}`;
         },
         async cardSendBefore(idCard) {
             await this.$store.dispatch(
                 "updateCard", {
                     idCard: idCard,
                     ordem: 'up',
                 }
             );
             this.$store.dispatch("setListCards");
         },
         async cardSendAfter(idCard) {
             await this.$store.dispatch(
                 "updateCard", {
                     idCard: idCard,
                     ordem: 'down',
                 }
             );
             this.$store.dispatch("setListCards");
         },
         async deleteCard(idCard) {
             const result = await Swal.fire({
                 title: 'Deseja realmente deletar esse?',
                 text: 'Tem certeza que deseja deletar esse card? Essa operaçao nao pode ser desfeita.',
                 icon: 'warning',
                 showCancelButton: true,
                 confirmButtonColor: '#d33',
                 cancelButtonColor: '#3085d6',
                 cancelButtonText: 'Cancelar',
                 confirmButtonText: 'Sim, tenho certeza'
             });
             if (!result.isConfirmed) return;

             await this.$store.dispatch("deleteCard", idCard);

             if (this.$store.getters.getCardDeleted) {
                 notyf.open({
                     type: "success",
                     message: "Card deletado com sucesso",
                 });
                 this.$store.dispatch("setListCards");
             } else {
                 notyf.open({
                     type: "error",
                     message: "Não foi possível deletar o card",
                 });
             }
         },
     },
     computed: {
         userPerm() {
             try{
                 return this.$store.getters.getUserInfo.data.isAdmin;
             } catch(e) {
                 return false;
             }
         },
         cards() { return this.$store.getters.getListCards; },
         instrumentoTop() { return this.cards.filter(card => card.idTipo == 40); },
         instrumentoText() { return this.cards.filter(card => card.idTipo == 41); },
         instrumentoMisc() { return this.cards.filter(card => card.idTipo == 42); },
     },
 };
</script>

<style type="text/css" media="screen" scoped>
 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;

     padding: 1em;
     box-sizing: border-box;
 }

 .pageTitle {
     background-clip: text;
     background-image: url("~@/assets/img/Backgrounds/verdeEscuroMateriais.webp");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
     width: 100%;
     text-align: center;
     padding: 0 2em;
 }

 .card {
     background-repeat: repeat;
     background-size: 256px;
     width: 100%;
     box-shadow: inset 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
     border-radius: 1em;
     padding: 1em;
     box-sizing: border-box;
     margin-bottom: 10px;
 }

 .topCard {
     background: url("~@/assets/img/Backgrounds/verdeEscuroMateriais.webp");
 }

 .bottomCard {
     background: url("~@/assets/img/Backgrounds/verdeClaroMateriais.webp");
 }

 .text {
     background-clip: text;
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
 }

 h3 {
     font-weight: bold;
     width: 100%;
 }

 .topCard h3 {
     text-align: center;
 }

 .editIcon {
     float: right;
     color: #fcfaf2;
     font-size: 1.3rem;
     cursor: pointer;
     transition: 0.4s;
     box-sizing: border-box;
     margin: 5px;
 }

 .editIcon:hover {
     transform: scale(1.1);
     transform-origin: center center;
 }

 .topCard > div {
     text-align: justify;
 }

 .textImg {
     float: left;
     margin: 1em;
     box-sizing: border-box;
     width: 100%;
     max-width: 300px;
 }

 @media screen and (max-width: 680px) {
     .textImg {
         float: none;
         display: block;
         margin: 1em auto;
     }
 }

 .cardBut {
     border-radius: 50px;
     background-color: #195955;
 }

 .topCard .cardBut {
     background-color: #7E8D6B;
     display: block;
     margin: 0 auto;
     float: none;
 }

 .gridButtons {
     display: grid;
     grid-template-columns: 1fr 1fr;
     grid-template-rows: auto auto;
     grid-template-areas: 'roteiro manual'
     'gabarito gabarito';
     column-gap: 10px;
     row-gap: 10px;
 }

 .gridButtons .cardBut {
     width: 100%;
 }

 .resource {
     display: flex;
     flex-flow: row wrap;
     align-items: center;
 }

 .resource > img {
     flex: 1 0 30%;
     min-width: 250px;
     object-fit: cover;
     border-radius: 1em;
     box-sizing: border-box;
 }

 .resource > .info {
     flex: 1 0 70%;
     padding: 10px;
     box-sizing: border-box;
 }

 .resourceBut {
     width: 100%;
     margin: 5px 0;
     box-sizing: border-box;
     height: max-content;
 }

 .extra > div.text {
     width: 100%;
     text-align: center;
 }

 .extraButtons {
     display: flex;
     flex-wrap: wrap;
     flex-direction: row;
     gap: 10px;
 }

 .extraButtons > a,
 .extraButtons > .cardBut {
     flex: 1 0 40%;
     height: max-content;
 }

 .content :deep(ol) {
     list-style-type: decimal;
     list-style-position: inside;
 }

 .content :deep(li::marker) {
     color: #fcfaf2;
 }

 button.grow:hover {
     transform: scale(1.01);
     transform-origin: center center;
 }

 .gabaritoArea {
     grid-area: gabarito;
 }

 .addCard {
     background: url("~@/assets/img/Backgrounds/verdeEscuroMateriais.webp");
     height: 2em;
     aspect-ratio: 1 / 1;
     position: relative;
     margin: 0 auto 10px auto;
     border-radius: 100%;
     transition: 0.4s;
     cursor: pointer;
 }

 .addCard:hover {
     transform: scale(1.1);
     transform-origin: center center;
 }

 .addCard > * {
     font-size: 1em;
     margin: 0;
     padding: 0;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
 }
</style>
