/**
 * Retorna o template da regex com base no tipo solicitado
 */
 export default function handlerRegex(type){
    switch(type)
    {
    case 'upper':
        return '^(?=.*[A-Z])'
    case 'lower':
        return '^(?=.*[a-z])'
    case 'number':
        return '^(?=.*[0-9])'
    case 'special':
        return '^(?=.*[!@#$&*_?<>/%-])'
    case 'length':
        return '^(?=.{8,})'
    case 'validatePwd':
        return '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$&*_?<>/%-])(?=.{8,})'
    case 'validateEmail':
        return /\S+@\w+\.\S+/

    }
}