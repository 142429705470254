<!--
|---------------------------------------------
| External Content Cards Component
|---------------------------------------------
| @usage Import the component and use, no external params
| 
| Receive info from the JSON below, the infos are:
|	id: 'Id of the Card, is the key of v-for'  
|	title: 'Title of the card'
|	desc: 'A larger text for the description of the card'
|	direction: 'Indicates the direction of the img, the options are default or reverse'
|	section: 'Bases on the section the color of the elements in the card changes'
    img: 'Name of the img on the /assets/img/Home/InfoCards folder'
    titleColor: 'CSS color for the title's text'
    button: 'Object containing the properties for the card's button or null if not present'
    button.text: 'Text for the card's button'
    button.icon: 'Icon for the card's button'
    button.link: 'Link for the related content'
    bgImg: 'Texture image for the card'
-->

<template>
    <div class='container-fluid'>
	    <div
            :class="`info-card row ${info.direction} ${info.id == infos.length ? 'last-card' : ''}`"
            :style="{ 'background-image': `url('${info.bgImg}')` }"
            v-for="info in infos"
            :key="info.id" >
            <div class='col-md-6'>
                <img class="img-info-card img-fluid" :src="require('../../assets/img/Home/InfoCards/'+info.img)" alt="">
            </div>
            <div class="infos col-md-6">
                <h1 :style="{ 'color': info.titleColor }">{{info.title}}</h1>
                <p>{{info.desc}}</p>
                <Button
                    v-if="info.button != null"
                    class="external-button"
                    :section="info.section"
                    :isOutline="true"
                    :value="info.button.text"
                    :hasIcon="info.button.icon != null"
                    :iconName="info.button.icon || ''" />
            </div>
	    </div>
	</div>
</template>

<script>
 import Button from '@components/Button';

 export default {
     name: 'InfoCards',
     data() {
	     return {
		     infos: [
                 {
		             id:1,
 		             title: 'Entenda o instrumento EAPI',
 		             desc: 'As Escalas de Avaliação dos Ambientes de Aprendizagem dedicados à Primeira Infância / EAPIs (uma para crianças de 2 a 3 anos, outra para pré-escola) são uma adaptação à realidade brasileira do instrumento MELE (”Avaliação dos Ambientes de Aprendizagem”, tradução livre), módulo do instrumento MELQO (”Medindo Qualidade e Resultados na Educação Infantil”, tradução livre), publicado pela Unesco, em parceria com Unicef, Banco Mundial e Brookings Institution, no ano de 2017.',
		             direction: 'reverse',
                     section: '',
                     titleColor: '#eb7071',
		             img: 'banner-boas-praticas.jpg',
                     bgImg: require('../../assets/img/Backgrounds/papel.png'),
                     button: {
                         text: " Baixar instrumento EAPI",
                         icon: "file-download",
                         link: ""
                     },
		         }, {
                  	 id:2,
		             title: 'Como estudar a certificação?',
		             desc: 'É importante estabelecer um cronograma diário de estudos e organizar sua rotina conforme a necessidade. Entenda que para estudar de forma eficaz, é ncessário ter foco e disciplina. Reserve um tmepo apra a leitura no seu cronograma de estudos. Dedique meia hora a essa tarefa, já que leitura demanda concentração. ',
 		             direction: 'default',
                     section: '',
                     titleColor: '#eb7071',
 		             img: 'banner-materiais-documentos.jpg',
                     bgImg: require('../../assets/img/Backgrounds/azul.png'),
                     button: {
                         text: " Baixar instrumento EAPI",
                         icon: "copy",
                         link: ""
                     },
 		         }, {
 		             id:3,
 		             title: 'Sobre os materiais',
 		             desc: 'Os materiais apresentam referências e dados pertinentes ao estudo da Qualidade da Educação Infantil no Brasil, com o objetivo de auxiliar e impulsionar a pesquisa sobre a área. Os materiais apresentados são livres para consulta e estudo.',
 		             direction: 'reverse',
                     section: '',
                     titleColor: '#ab7031',
 		             img: 'banner-certificacao.jpg',
                     bgImg: require('../../assets/img/Backgrounds/bege.png'),
                     button: null
 		         },
		     ]
	     }
     },
     props: {
		 title: String,
		 desc: String,
		 direction: String,
		 img: String
     },
     components: {
	     Button
     }
 }
</script>

<style scoped>
/*
 |-----------------------------------------------------
 |--ESTILIZANDO OS ELEMENTOS BÁSICOS DO COMPONENTE
 |-----------------------------------------------------
 */
.container-fluid {
    box-shadow: 0px -10px 38px -12px rgb(96 133 236 / 43%);
}

.info-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem;
    position: relative;
    box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.4);
    background-repeat: repeat;
}

.infos {
    /* display: flex;
    justify-content: space-between;
    flex-direction: column; */
    font-size: 0.9rem;
    text-align: justify;
} 

.infos h1 {
    font-size: 1.6rem;
    font-weight: bold;
    text-align: left;
    margin-top: 1rem;
}

.info-card img {
    transition: transform ease-in-out 0.4s;
}

.info-card img:hover {
    transform: scale(1.02);
}

 .external-button {
     background-color: #eb7071;
     text-transform: none;
     font-size: 1.5em;
     color: white;
     box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
     border-radius: 10px;
     border: 0;
     font-weight: 600;
 }

 .last-card {
     box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.4);
     /* inset 0 -5px 5px rgba(0, 0, 0, 0.4); */
 }

/*-----------------------------------------------------*/

/*
 |-----------------------------------------------------
 |--REUTILIZANDO PARAMETROS PARA ESPECIFICACOES
 |-----------------------------------------------------
 */
.default {
    flex-direction: row;
    /* grid-template-columns: 2fr 1fr; */
}

.reverse {
    flex-direction: row-reverse;
    /* grid-template-columns: 1fr 2fr; */
}

 /* .button button {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    } */

.default .button {
    text-align: right;
}

.reverse .button {
    text-align: left;
}

 .reverse img {
     display: block;
     margin: 0 auto;
 }

 .default img {
     display: block;
     margin: 0 auto;
 }

@media only screen and (min-width: 800px){
    /* .default img {
	   margin-right: 5rem;
       } */
    /*
       .reverse img {
	   margin-left: 5rem;
       } */
}

@media screen and (max-width: 728px) {
    .info-card {
	padding: 1.2rem;
    }
  }

/*-----------------------------------------------------*/
</style>
