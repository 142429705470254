<template>

    <div>
        <div>
            <SectionElement title="O que é?"
                text=" O Banco de Práticas Pedagógicas Qualificadas é um acervo de práticas pedagógicas, que foram e são desenvolvidas por diferentes professores(as), divulgadas na nossa plataforma digital. Este projeto complementa os demais desta plataforma, que tem como objetivo amplo definir e propor parâmetros processuais de qualidade da educação infantil, reunir fatos importantes da realidade nacional sobre este tema, e aqui apresentar um conjunto de práticas condizentes com os princípios e parâmetros que defendemos como importantes para o atendimento. "
                imageName="BoasPraticas/oquee.png"
                :hasButton="false"
                section="boas-praticas"
                buttonIcon=""
                buttonText=""
                direction="default"
            />

            <SectionElement title="As Práticas"
                text="Além da apresentação das práticas e o que elas podem representar na vida das crianças, pode-se encontrar a justificativa conceitual das razões para que estas práticas tenham sido selecionadas para estar aqui, e vídeos mostrando como essas podem ser inspiradoras em diferentes contextos escolares sem que sua essência se perca. O banco contempla a diversidade cultural das diferentes regiões brasileiras, selecionando práticas alinhadas aos indicadores de qualidade do Projeto EAPI - Escala de Avaliação de Ambientes de Aprendizagens dedicados à Primeira Infância que apresenta consonância com os eixos da Base Nacional Comum Curricular (BNCC). "
                imageName="BoasPraticas/aspraticas.png"
                :hasButton="false"
                section="boas-praticas"
                buttonIcon=""
                buttonText=""
                direction="default"
            />

            <SectionElement title="De onde vem estas ráticas?"
                text="Os materiais apresentam referências e dados pertinentes ao estudo da Qualidade da Educação
                    Infantil no Brasil, com o objetivo de auxiliar e impulsionar a pesquisa sobre a área. 
                    Os materiais apresentados são livres para consulta e estudo. "
                imageName="BoasPraticas/deondevem.png"
                :hasButton="false"
                section="boas-praticas"
                buttonIcon=""
                buttonText=""
                direction="default"
            />

        </div>

        <div>
            <h1>Entenda os eixos temáticos abordados nessas práticas</h1>
        </div>

        <div class="divModais container">
            <button class="buttonModal px-4 mb-4 reconhecimento" data-target="#idPopUpReconhecimento"
                    data-toggle="modal">
                Reconhecimento e valorização da diversidade cultural
            </button>

            <button class="buttonModal px-4 mb-4 acolhimento" data-target="#idPopUpAcolhimento"
                    data-toggle="modal">
                Acolhimento e gestão de conflitos
            </button>

            <button class="buttonModal px-4 mb-4 ampliacao" data-target="#idPopUpAmpliacao"
                    data-toggle="modal">
                Ampliação do repertório por meio de brincadeiras e jogos propostos pelas crianças
            </button>

            <button class="buttonModal px-4 mb-4 cuidado" data-target="#idPopUpCuidado"
                    data-toggle="modal">
                Cuidado, autonomia e atenção às crianças
            </button>
        </div>

        <ModalCentral
            componentName="EixoReconhecimento"
            title="Reconhecimento e valorização da diversidade cultural"
            idName="idPopUpReconhecimento"
        />

        <ModalCentral
            componentName="EixoInclusao"
            title="Acolhimento e gestão de conflitos"
            idName="idPopUpAcolhimento"
        />

        <ModalCentral
            componentName="EixoAmpliacao"
            title="Ampliação do repertório por meio de brincadeiras e jogos propostos pelas crianças"
            idName="idPopUpAmpliacao"
        />

        <ModalCentral
            componentName="EixoCuidado"
            title="Acolhimento e gestão de conflitos"
            idName="idPopUpCuidado"
        />

    </div>
</template>

<script>
import SectionElement from '@components/SectionElement';
import ModalCentral from '@components/ModalCentral';

export default {
    name: 'OQueE',
    components: {
        SectionElement,
        ModalCentral
    }
}
</script>

<style scoped>
    h1{
        font-size: 1.6rem;
    font-weight: bold;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    color: #41B3A3;
    text-align: center;
    }
    .divModais{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    
    .buttonModal{
        border: none;
        border-radius: 20px;
        width: 45%;
        height: 20vh;
        color: white;
        font-weight: lighter;
        font-size: 1rem;
        cursor: pointer;
    }

    .buttonModal:focus {
  outline: none;
  box-shadow: none;
}

    .reconhecimento{
        background-color: #b14ab4;
    }

    .acolhimento{
        background-color: #b55238;
    }

    .ampliacao{
        background-color: #3f3fb8;
    }

    .cuidado{
        background-color: #44b893;
    }
</style>