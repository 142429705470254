<template>
  <div>
    <Navbar />
    <!-- COMENTADO PARA TESTES -->
    <!-- {{tempo_restante}}   -->
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Navbar from "@components/App/Navbar";
import Footer from "@components/App/Footer";
import Swal from "sweetalert2";
export default {
  name: "App",
  data() {
    return {
      tempo_restante: "",
      // queryParams: null
    };
  },
  methods: {
    async verifyExpirationToken() {
      // SO VAI ACONTECER SE O USUARIO TIVER TEMPO RESTANTE
      let result;
      if (this.$store.getters.getExpireCookieDate) {
        var today = new Date();
        var expirationToken = new Date(this.$store.getters.getExpireCookieDate);
        var diff = expirationToken - today;
        this.tempo_restante = (diff / 1000).toFixed(0);
        //SE O TEMPO RESTANTE FOR MENOS QUE 60 SEG
        if (this.tempo_restante < 60 && this.tempo_restante > 0) {
          // E SE A NOTIFICACAO AINDA N FOI EXIBIDA, ELE A EXIBE
          if (!this.$store.getters.getIsAlreadyShowedExpNotyf) {
            result = await Swal.fire({
              title: `Em ${this.tempo_restante} segundos sua sessão irá expirar!`,
              text:
                'Se você ignorar ou fechar a mensagem, sua sessão irá encerrar e você perderá as modificações não salvas. Caso deseje continuar logado na plataforma, clique em "Manter Sessão".',
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              cancelButtonText: "Fechar mensagem",
              timer: (this.tempo_restante - 1) * 1000,
              timerProgressBar: true,
              confirmButtonText: "Manter Sessão",
            });
            //SE A PESSOA ESCOLHEU MANTER A SESSAO, CHAMA A ACTION REFRESHTOKEN
            if (result.isConfirmed) {
              await this.$store.dispatch("setRefreshToken");
            }
            //AO FINAL DA EXIBICAO DA NOTIFICACAO, INDICO QUE ELA JA FOI EXIBIDA
            this.$store.commit("setIsAlreadyShowedExpNotyf", true);
          }
        }
        //SE O TEMPO RESTANTE FOR MENOR OU IGUAL A 0 SEG ELE VAI DESLOGAR E REDIRECIONAR PRA HOME
        else if (this.tempo_restante <= 0) {
          result.close();
          this.$cookies
            .keys()
            .forEach((cookie) => this.$cookies.remove(cookie));
          this.$store.commit("setIsLogged", false);
          this.$store.commit("setUserInfo", null);
          this.$store.commit("setExpireCookieDate", null);
          this.$store.commit("setIsAlreadyShowedExpNotyf", false);
          this.$router.push("/");
        }
      }
      //EXECUTANDO ESTE METODO RECURSIVAMENTE (FAZ ELE EXECUTAR A CADA 1 SEG)
      var self = this;
      setTimeout(self.verifyExpirationToken, 1000);
    },
  },
  async created() {
    this.$store.commit("setIsActiveModalLateralLogin", true);
    this.$store.commit("setIsActiveModalLateralSignUp", true);
    if (this.$cookies.get("accessToken")) {
      await this.$store.dispatch("setVerifyToken");
      await this.$store.dispatch("setUserInfo");
      this.$store.commit("setIsAlreadyShowedExpNotyf", false);
    } else {
      this.$cookies.keys().forEach((cookie) => this.$cookies.remove(cookie));
      this.$store.commit("setIsLogged", false);
      this.$store.commit("setUserInfo", null);
      this.$store.commit("setExpireCookieDate", null);
      this.$store.commit("setIsAlreadyShowedExpNotyf", false);
      //! clear vuex
    }
  },
  mounted() {
    this.verifyExpirationToken(); //INICIA A VERIFICACAO A CADA SEGUNDO DO TEMPO RESTANTE DA SESSAO
  },
  watch: {
    $route(to) {
      if (to.query.emailconfirmed == "true") {
        Swal.fire(
          "Conta confirmada!",
          "Sua conta foi confirmada com sucesso, você já pode fazer login.",
          "success"
        );
      } else if (to.query.resendverificationemail == "true") {
        Swal.fire(
          "Email de verificação reenviado!",
          "O email de verificação foi reenviado, verifique sua caixa de entrada!",
          "success"
        );
      } else if (to.query.resendregistrationemail == "true") {
        Swal.fire(
          "Email para finalizar cadastro reenviado!",
          "O email para finalizar seu cadastro foi reenviado, verifique sua caixa de entrada!",
          "success"
        );
      } else if (to.query.invalidemailtoken == "true") {
        Swal.fire(
          "Email de validação inválido!",
          "Este email de validação está inválido, reenvie o email de validação.",
          "error"
        );
      } else if (to.query.invalidinvitetoken == "true") {
        Swal.fire(
          "Convite expirado!",
          "O convite para participar da plataforma expirou. Por-favor peça um novo convite ou crie uma conta na plataforma e utilize a função de solicitar para participar da entidade.",
          "error"
        );
      } else if (to.query.invalidpasswordtoken == "true") {
        Swal.fire(
          "Email de recuperação de senha inválido!",
          "Este email de recuperação de senha está inválido, refaça o pedido de recuperação.",
          "error"
        );
      } else if (to.query.invaliduser == "true") {
        Swal.fire("Usuário inválido!", "Por favor, tente novamente.", "error");
      } else if (to.query.toomanyrequests == "true") {
        Swal.fire(
          "Aguarde um tempo!",
          "Aguarde um minuto antes de solicitar um novo email.",
          "error"
        );
      } else if (to.query.resetedpassword == "true") {
        Swal.fire(
          "Senha redefinida!",
          "Parabéns, sua senha foi redefinida. Você já pode fazer login.",
          "success"
        );
      } else if (to.query.sendresetpassword == "true") {
        Swal.fire(
          "Email para resetar senha reenviado!",
          "O email para resetar sua senha foi enviado, verifique sua caixa de entrada!",
          "success"
        );
      } else if (to.query.invalidentity == "true") {
        Swal.fire(
          "Grupo ou Instituição que gerou seu convite não foi encontrado(a)",
          "A entidade que enviou o seu convite não foi encontrada. É possível que ela tenha sido deletada da plataforma.",
          "error"
        );
      }
    },
  },
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style lang="scss">
@import "./assets/css/uicons-regular-rounded.css";
@import "./assets/css/uicons-solid-rounded.css";
@import "./assets/scss/_variables.scss";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  overflow-x: hidden; /* //! TODO: isso não pode ficar assim */
}
body {
  padding-right: 0 !important;
  font-family: "Hind", sans-serif;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(
    to bottom,
    #edf0f7,
    #e8ecf5,
    #e3e8f4,
    #dee5f2,
    #d9e1f0
  );
  background: linear-gradient(180deg, #f1f6f9 0%, #f1f6f9 50.52%, #d2e3ea 100%);
  overflow-x: hidden; /* //! TODO: isso não pode ficar assim */
}
h2 {
  color: $azul;
  font-weight: bold;
}
.ml-6 {
  margin-left: 6rem;
}
.boas-praticas h1 {
  /* color: $verdeClaro-eapi; */
}
.materiais-documentos h1 {
  /* color: #eb7071; */
}
.certificacao h1 {
  /* color: $roxo; */
}
.dados h1 {
  /* color: $verdeEscuro-eapi; */
}
.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.035);
}
.itens-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.titulo-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem;
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: bold;
  color: rgb(67, 70, 78);
}
.exit {
  cursor: pointer;
}
.select-personal,
.input-personal {
  border-radius: 8px;
  background: #fcfcfc;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.11);
  font-weight: lighter;
  padding: 0.2rem 0.4rem;
  box-shadow: 1px 2px 6px -3px rgba(0, 0, 0, 0.25);
}
.input-personal {
  padding: 0.2rem 0.4rem;
  font-size: 20px;
}
.form-control,
.input-personal {
  padding: 0.2rem 0.4rem;
  font-size: 15px;
  font-weight: 400;
  border-radius: 8px;
  background: #fcfcfc;
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 0.11);
  font-weight: lighter;
  padding: 0.2rem 0.4rem;

  box-shadow: 1px 2px 6px -3px rgb(0 0 0 / 25%), inset 0px 3px 3px rgba(0, 0, 0, 0.25);
}
.form-control:focus,
.input-personal:focus {
  background: #fcfcfc;
  border: 1px solid rgba(0, 0, 0, 0.11);
  /* box-shadow: 1px 2px 6px -3px rgb(0 0 0 / 25%); */

  box-shadow: 1px 2px 6px -3px rgb(0 0 0 / 25%), inset 0px 3px 3px rgba(0, 0, 0, 0.25);
}

form .form-control,
form .input-personal {
  //background-image: url("~@/assets/img/Backgrounds/branco.webp");
  background-repeat: repeat;
  background-size: 256px;
}

form .form-control:focus,
form .input-personal:focus {
  //background-image: url("~@/assets/img/Backgrounds/branco.webp");
  background-repeat: repeat;
  background-size: 256px;
}

.question-style {
  font-weight: bold;
}
form.container, form .container {
  background-color: transparent !important;
}
.radio-personal {
  height: 18px;
  width: 18px;
}
.btn-router-link,
.btn-router-link:hover,
.btn-router-link:focus {
  text-decoration: none;
  word-break: normal !important;
}
.cool-link {
  display: inline-block;
}
.cool-link::after {
  content: "";
  display: block;
  width: 0;
  height: 0.1rem;
  background-color: #252525;
  transition: width 0.4s;
  margin-top: 0.1rem;
}
.cool-link:hover::after {
  width: 100%;
  transition: width 0.4s;
}
.error-input {
  color: #ff0000;
  background-color: #fff;
  border-color: #ec1111;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(255 0 0 / 25%);
}
a:hover {
  text-decoration: none;
}
/* ESTILOS DAS LISTAGENS */
.box-lista {
  background-color: #fcfcfc;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.11);
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  min-height: 15vh;
  transition: all ease-in-out 0.3s;
  margin: 1rem;
  padding: 0.4rem 1rem;
}

.box-lista > * {
  margin: 0 auto;
  flex: 1;
  text-align: center;
  word-break: break-all;
}

.box-header {
  display: flex;
  justify-content: space-around;
  margin: 1rem 2rem 0 2rem;
}

.box-header > span {
  flex: 1;
  font-weight: 500;
  text-align: center;
}

.title-style-box-lista {
  font-size: 25px;
  color: #656565;
  margin: 0;
  text-align: center;
  font-weight: 500;
}
.img-info-box-lista {
  width: auto;
  max-height: 72px;
  margin: 0.2rem;
}
.second-info-fixed {
  width: 20%;
  text-align: center;
  overflow: hidden;
}
.second-info-fixed-small {
  width: 12%;
  text-align: center;
  overflow: hidden;
}
.third-info-box-lista {
  color: #676bd6;
  cursor: pointer;
  user-select: none;
  font-weight: 500;
}
.last-info-box-lista {
  color: #eb3e3e;
  cursor: pointer;
  user-select: none;
  transition: all ease 0.3s;
  font-weight: 500;
  text-align: center;
}
.pink-img {
  filter: hue-rotate(26deg) brightness(0.9);
  transition: all ease-in-out 0.3s;
}
.pink-img-multiple {
  filter: hue-rotate(27deg) brightness(1.09);
  transition: all ease-in-out 0.3s;
}
.black-img {
  filter: saturate(0);
  transition: all ease-in-out 0.3s;
}
.controlGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  word-break: keep-all !important;
}
.select-info {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  word-break: keep-all;
}
.select-info-blocked {
  display: flex;
  align-items: center;
  cursor: default;
  justify-content: center;
  word-break: keep-all;
}
.span-label-selected {
  width: max-content;
  user-select: none;
}
.box-select-info {
  width: 1.2rem;
  height: 1.2rem;
  background-color: transparent;
  border-radius: 4px;
  border: 3px solid #7647ac;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
}
.selected-info {
  background-color: #7647ac;
  transition: all ease-in-out 0.3s;
}
.list-item-selected-pink {
  background-color: #e4ccfe;
  border: 1.8px solid #7647ac;
  transition: all ease-in-out 0.3s;
}
.pink-text {
  color: #4d2b74;
}
.pink-text:hover {
  color: #27133d;
}
.title-style-box-lista {
  font-size: 25px;
  color: #656565;
  margin: 0;
  text-align: center;
  font-weight: 500;
}
@media screen and (min-width: 901px) {
  .first-info-box-lista {
    flex-basis: 35%;
    text-align: left;
  }
}
@media screen and (max-width: 680px) {
  .first-info-box-lista {
    text-align: center;
    width: -webkit-fill-available;
  }
  .box-lista span,
  .box-lista a {
    width: 100%;
    text-align: center;
    margin-top: 0.5rem;
  }
  .box-lista {
    place-content: center;
  }
}
.box-img-box-lista {
  flex-basis: 5%;
}
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(-40px);
  opacity: 0;
}
.fa-info-circle {
  color: #ffac30;
  font-size: 1.1rem;
  cursor: pointer;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

 .font-weight-semibold {
   font-weight: 600;
 }

 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;
 }

 .text-img-clipper {
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }
</style>
