<template>
<div class="list-data bg">
    <ModalCentral
        componentName="ShowCampoInfo"
        title="DESCRIÇÃO DO CAMPO"
        idName="showCampoInformacao"
        :data="dataToShowCampoInfo"
        />

    <!--
        <ModalCentral
        componentName="LoadIframe"
        title="DADOS DO CAMPO"
        idName="iframeModal"
        :data="{ power_bi: powerbi }"
        />
    -->

    <div class="title-tree">
        <Breadcrumb :crumbs="crumbs" section="instituicao-devolutivas"/>
    </div>

    <InstitutionInfo class="title-style" :title="nomeInstituicao" section="instituicao-cadastrar"/>

    <p class="text-info-personal text-img-clipper bg-color">Nesta seção, você encontrará todas as Devolutivas da Instituição. Caso necessário, utilize o filtro abaixo para localizar a Devolutiva desejada.</p>
    <div class="itens-box">
        <!-- <div class="filter-box"> -->
        <!--     <\!-- INICIO DO FORM DE FILTRAGEM -\-> -->
        <!--     <form @submit.prevent="" class="form-inline"> -->
        <!--         <div class="form-group"> -->
        <!--             <\!-- DROPDOWN DE SELECAO DE FILTRO -\-> -->
        <!--             <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomSelect">Filtrar por: </label> -->
        <!--             <Filter ref="filtersearchname" @change="setSelectedValue()" :optionsSelect="options" nameSelect="filtro_instituicoes" id="inlineFormCustomSelect"/> -->
        <!--         </div> -->
        <!--     </form> -->
        <!-- </div> -->
        <div class="filter-box">
          <!-- INICIO DO FORM DE FILTRAGEM -->
          <form @submit.prevent="" class="form-inline custom-form">
            <div class="form-group">

              <!-- DROPDOWN DE SELECAO DE FILTRO -->
              <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomSelect">Filtrar por: </label>
              <Filter ref="filtersearchname" @change="setSelectedValue()" :optionsSelect="options" nameSelect="filtro_instituicoes" id="inlineFormCustomSelect"/>
            </div>

            <!-- SE A SELECAO FOR NOME (2) ENTAO EXIBIRA OS SUBFILTROS DE NOME -->
            <div class="form-group" v-if="selectedValue == 2">
              <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomName">Digite o nome: </label>
              <input class="form-control input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_instituicoes" id="inlineFormCustomName" autocomplete="off">
            </div>

          </form>
        </div>
    </div>
    <hr class="separator">

    <div class="list-box">
        <transition-group name="slide-fade" v-if="campos?.length > 0">
            <div class="box-header">
                <span></span>
                <span>Nome</span>
                <span>Status</span>
                <span>Controle</span>
            </div>

            <div class="box-lista" v-for="campo in campos" :key="campo.counter" :class="{'list-item-selected': campo.isativo == 'ON'}">
                <div class="box-img-box-lista" >
                    <img
                        class="img-info-box-lista"
                        :class="{ 'turning-grey': campo.isativo == 'ON' }"
                        src="@assets/img/Cards/Campo.svg"
                        alt="Ícone de campo" />
                </div>
                <span class="first-info-short d-flex align-items-center">{{campo.titulo}}<i @click="sendInfosToShowCampoInfo(campo?.descricao)" data-toggle="modal" data-target="#showCampoInformacao" class="fa fa-info-circle ml-2"></i></span>
                <span v-if="campo.isativo != 'ON'" class="second-info">Criado em {{campo.ano}}</span>
                <span v-else class="second-info">Campo Ativo</span>

                <div class="controlGroup">
                    <router-link
                        :to="{
                            name: 'CampoDashboard',
                            params: { idCampo: campo?.idamostra },
                        }"
                        v-if="userPerm.permissao != 'APLICADOR' && campo?.power_bi.length">
                            <!-- @click="powerbi = campo.power_bi" -->
                            <!-- data-toggle="modal" -->
                            <!-- data-target="#iframeModal" -->
                        <Button
                            class="btn"
                            section="instituicao-cadastrar"
                            value="Visualizar dados"
                            :isOutline="true"
                            />
                    </router-link>

                    <Button
                      class="btn"
                      section="instituicao-cadastrar"
                      @click="downloadData(campo.idamostra)"
                      value="Baixar dados"
                      :isOutline="true"
                      />

                    <Button
                        @click="downloadPdf(campo)"
                        v-if="(campo?.hora_off && campo?.hora_off !== 'null' && campo?.hora_off !== 'undefined') && campo.isativo == 'OFF' && userPerm.permissao != 'APLICADOR'"
                        class="btn"
                        section="instituicao-cadastrar"
                        value="Baixar PDF"
                        :isOutline="true"
                        />
                </div>
            </div>
        </transition-group>
        <transition-group v-else>
            <h2 class="title-style-box-lista">Nenhum campo encontrado.</h2>
        </transition-group>
    </div>

</div>
</template>

<script>
import Filter from '@components/Filter'
import Breadcrumb from '@components/Breadcrumb'
import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
import Button from '@components/Button'
import ModalCentral from '@components/ModalCentral'
import parsers from '@middleware/parsers'

import { Notyf } from "notyf";
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: "left",
    y: "bottom",
  }
});

export default {
  name: 'CampoListaFormularios',
  data (){
    return {
      nomeInstituicao: '',
      descricao: '',
      selectedValue: '',
      searchByName: '',
      searchByCode: '',
      searchWithOrder: '',
      dataToShowCampoInfo: {},
      powerbi: [],
      baixando: false,
      options: [
        {
          id:1,
          value: 'Todos'
        },
        {
          id:2,
          value: 'Nome'
        }
      ],
      crumbs: [
        {
          id:1,
          value: '',
          iconName: 'th',
          name_route: 'GerenciarInstituicoes'
        },
        {
          id:2,
          value: 'Instituição',
          iconName: 'university',
          name_route: 'InstituicaoMenu'
        },
        {
          id:4,
          value: 'Devolutivas',
          iconName: 'chart-bar',
          name_route: ''
        }

      ]
    }
  },
  components: {
    Filter,
    Button,
    Breadcrumb,
    InstitutionInfo,
    ModalCentral,
  },
  methods: {
      downloadData(idAmostra) {
          if (this.baixando) return;
          this.baixando = true;

          const notiLoad = notyf.open({
              type: "info",
              background: "#40c9a2",
              message: "Carregando dados...",
              duration: 0,
          });

        this.$store
            .dispatch("loadAmostraRespostas", {
              idInstituicao: this.$route.params.idInstituicao,
              idAmostra: idAmostra,
            })
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([typeof(response.data) === 'string' ? response.data : JSON.stringify(response.data)]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'amostras.json');
              document.body.appendChild(link);
              link.click();
            })
            .catch(err => {
              notyf.open({
                type: "info",
                ...(err?.status != 500 ? { background: "#40c9a2" } : {}),
                message: err?.status != 500 ? err?.response?.data?.message?.message : "Erro ao baixar o arquivo!",
              });
            })
            .finally(() => {
              notyf.dismiss(notiLoad);
              this.baixando = false;
            });
      },
      addWorkDays(date, numDays) {
          const dow = date.getDay();
          if (dow == 0) numDays++;
          else if (dow == 6) numDays += 2;

          if (dow + numDays >= 6) {
              let remaining = numDays - (5 - dow);
              numDays += 2;
              if (numDays > 5) {
                  numDays += 2*Math.floor(remaining/5);
                  if (remaining % 5 == 0)
                      numDays -= 2;
              }
          }

          date.setDate(date.getDate() + numDays); return date;
      },
    downloadPdf(campo) {
        if (!campo?.devolutiva || campo?.devolutiva == 'null' || campo?.devolutiva == 'undefined') {
            notyf.open({
              type: "error",
              message: `Os dados estarão disponíveis no dia ${parsers.convertUTC(this.addWorkDays(new Date(campo.hora_off), 4))}`,
            });
            return;
        }

      this.$store
          .dispatch("loadPdfDownload", {
            idInstituicao: this.$route.params.idInstituicao,
            idamostra: campo.idamostra,
            devolutiva: campo.devolutiva,
          })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'devolutiva.pdf');
            document.body.appendChild(link);
            link.click();
          })
          .catch(err => {
            console.log(err);
            notyf.open({
              type: "error",
              message: "Erro ao obter arquivo!",
            });
          });

    },
    sendInfosToShowCampoInfo(descricao) {
      this.dataToShowCampoInfo = {
        descricao: descricao
      }
    },
    setSelectedValue() {
      this.selectedValue = this.$refs.filtersearchname.value;
        },
    },
    created() {
        this.teams = this.$store.getters.getListTeams
        this.$store.dispatch('setListCampos', this.$route.params.idInstituicao);
    },
    mounted: function() {
        window.scrollTo(0, 0);
        let idTeamSelected = this.$route.params.idInstituicao

        if (typeof idTeamSelected == "undefined"){
            this.nomeInstituicao = "Nome da Instituição"
        }
        else {
            let teamsSelected
            let teamSelected

            teamsSelected = this.teams.filter(team => 
                team.idinstituicao == idTeamSelected
            )

            teamSelected = [...teamsSelected].shift()

            this.nomeInstituicao = teamSelected.name
            this.descricao = teamSelected.descricao
        }
    },
    computed: {
        userPerm() {
            return this.$store.getters.getUserPerm
        },
        totalCampos() {
            return (this.$store.getters?.getListCampos || []).length || 0;
        },
        campos() {
            var campos = this.$store.getters.getListCampos || []
            // VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
            var query = campos;
            //CASO SEJA SELECIONADO TODOS, EXIBE TODOS OS ITENS DO ARRAY
            if (this.selectedValue == 1) {
                query = campos;
            }
            //CASO SEJA SELECIONADO NOME, FILTRA POR NOME
            if (this.selectedValue == 2) {
                //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
                if (this.searchByName.trim() == '') {
                    query = campos;
                }
                //SENAO ELE FILTRA PELO O QUE FOR DIGITADO
                else {
                    var filtered = campos?.filter(campo =>campo.titulo.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
                    if(filtered?.length != 0){
                        query = filtered
                    }

                    else{
                        query = false
                    }
                }
            }
            return query;
        }
    }
}
</script>

<style scoped>
 .bg-color {
     background-image: url("~@/assets/img/Backgrounds/marromEscuro.webp");
 }

.text-info-personal {
  color: #656565;
  font-size: 1.1rem;
  text-align: justify;
  padding: 0rem;
  margin: 0 3rem 0 3rem;
}

.list-data {
    min-height: 90vh;
    padding-top: 2rem;
}
.label-filtro {
    font-weight: 600;
}

.filter-box {
    margin: 1rem 3rem;
}

.list-box {
    margin: 2rem;
}

.itens-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-tree {
    margin: 1rem 3rem;
    font-weight: bold;
    display: flex;
}

.title-style{
    margin: 0 0 0 3rem;
}

.lista-data-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 2rem 3rem;
}

.title-tree h1 {
    font-size: 1.2rem;
    font-weight: 700;
}

.main-link {
    outline:none;
    text-decoration: none;
    cursor: pointer;
    margin-right: .3rem;
}

.sub-link {
    margin-left: .3rem;
}

.separator {
    border: none;
    height: 1px;
    margin: 0.8rem 3rem;
    background-color: rgba(51, 51, 51, 0.178);
}

a {
    color: #212529;
    text-decoration: none;
    transition: all ease-in-out 0.2s
}

a:hover {
    color: #4d4f50;
}

@media screen and (max-width: 1010px) {
    .lista-data-info {
        justify-content: center;
    }
}


@media screen and (max-width: 1012px) {
    .filter-box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 680px) {
    .itens-box{
        flex-direction: column;
        align-items: flex-start;
    }

    .filter-box{
        margin: 0 3rem;
    }

    .lista-data-info{
        margin: 0 3rem;
    }
}
    
</style>
