
<template>
<div class="container offset">
  <form @submit.prevent="updateTurma" ref="formClass" class="customForm">

    <div class="form-group">
      <label class="question-style pt-1">Nome da turma: *</label>
      <input
        v-model.trim="turma.nome"
        type="name"
        class="form-control"
        placeholder="Insira aqui o nome da turma..."
        required
        />
    </div>

    <div class="form-group">
      <label class="question-style pt-1">Idade da turma: *</label>
      <select v-model.trim="turma.idade" class="form-control" required>
        <option hidden class="question-style"> Selecione aqui o tipo da idade da turma</option>
        <option
          v-for="idade of Object.keys(idades)"
          :key="idades[idade]"
          class="question-style"
          :value="idades[idade]">
          {{ idade }}
        </option>
      </select>
    </div>

    <div class="form-group">
      <label class="question-style pt-1">Tipo da turma: *</label>
      <select v-model.trim="turma.tipoTurma" class="form-control" required>
        <option hidden class="question-style"> Selecione aqui o tipo da turma</option>
        <option
          v-for="tipo of Object.keys(tipos)"
          :key="tipo"
          class="question-style"
          :value="tipo">
          {{ tipos[tipo] }}
        </option>
      </select>
    </div>

    <div class="form-group">
      <label class="question-style pt-1">Período: *</label>
      <select v-model.trim="turma.periodo" class="form-control" required>
        <option hidden class="question-style"> Selecione aqui o tipo da idade da turma</option>
        <option
          v-for="periodo of Object.keys(periodos)"
          :key="periodos[periodo]"
          class="question-style"
          :value="periodos[periodo]">
          {{ periodo }}
        </option>
      </select>
    </div>

    <div class="formButtons">
      <div class="button-div pt-4">
        <Button
          section="instituicao-cadastrar"
          value="Atualizar Turma"
          :isOutline="true"
          :hasIcon="true"
          iconName="save"
          />
      </div>
    </div>

  </form>
</div>
</template>

<script>
import Button from '@components/Button'
import { Notyf } from "notyf";
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: "left",
    y: "bottom",
  }
});

export default {
  name: "UpdateTurma",
  components: {
    Button,
  },
  data: () => ( {
    id: null,
    turma: {},
    idades: {
      "CRECHE (2A - 2A E 11M)": "5",
      "CRECHE (3A - 3A E 11M)": "6",
      "PRE-ESCOLA (4A - 4A E 11M)": "7",
      "PRE-ESCOLA (5A - 5A E 11M)": "8",
    },
    periodos: {
      "MANHA": "19",
      "TARDE": "20",
      "NOTURNO": "21",
      "INTEGRAL": "22",
      "INTERMEDIÁRIO": "23",
      "VESPERTINO": "24",
    },
    tipos: {
      "25": "Regular",
      "26": "Mista/Multisseriada",
    }
  }),
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  watch: {
    data(updated) {
      this.id = updated.idTurma;
      this.loadTurma();
    },
  },
  methods: {
    async updateTurma() {
      await this.$store.dispatch(
        'setUpdateTurma',
        {
          idTurma: this.id,
          idInstituicao: this.$route.params.idInstituicao,
          idUnidade: this.$route.params.idUnidade,
          ...this.turma,
        }
      );

      if (!this.$store.getters.getIsTurmaUpdated) {
        notyf.open({
          type: "error",
          message: "Erro ao atualizar turma!",
        });
        return;
      }

      notyf.open({
        type: "success",
        message: "Turma atualizada com sucesso",
      });

      this.$store.dispatch('setListTurmas', {
        idInstituicao: this.$route.params.idInstituicao,
        idUnidade: this.$route.params.idUnidade,
      });

      this.$emit('closeModal');
    },
    loadTurma() {
      this.turma = { ...((this.$store.getters.getListTurma || []).find(t => t.idturma == this.id) || {}) };
      this.turma.idade = this.idades[this.turma.idade];
      this.turma.tipoTurma = this.turma.tipo_turma;
      this.turma.periodo = this.periodos[this.turma.periodo];
    },
  },
  computed: {
  },
};
</script>

<style scoped>
.formButtons {
    display: flex;
    flex-direction: row-reverse;
}
</style>
