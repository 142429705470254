<template>
  <div class="container offset pb-2">
    <form @submit.prevent="createMember">
      <div class="custom-form">
        <img
          class="image-style"
          src="../../assets/img/Dados/ListarMembros/icon-add-user.png"
        />

        <div class="form-group">
          <label class="question-style">E-mail:</label>
          <input
            @input="verifyValidadeEmail()"
            v-model.trim="email"
            type="email"
            class="form-control"
            placeholder="Digite o e-mail da pessoa que você quer convidar..."
          />

          <div class="input-file">
            <label class="question-style">CSV de e-mail(s):</label>
            <div
              v-cloak
              @click="openDialog"
              @drop.prevent="addFile"
              @dragover.prevent
              @dragover.capture="isDragOverActions()"
              @dragleave.capture="isDragLeaveActions()"
              class="drag-and-drop"
              :class="{'drag-style': isDragOver}"
            >
              <div v-if="arquivo == null" class="text-center infos">
                <img class="img-fluid img-center-input-file" :src="require('../../assets/img/AddUser/'+name_image+'.png')">
                <p class="label-center-input-file">{{label}}</p>
              </div>
              <div class="file-uploaded" v-else>
                <img class="img-icon-uploaded" src="../../assets/img/Dados/ExportarDevolutiva/img-icon.png" alt="">
                  {{ arquivo.name.substr(0, 7)+'...'}} {{(arquivo.size/1000).toFixed(1)+ ' KB'}}
              </div>
            </div>
            <button v-if="arquivo != null" class="remove-file" @click="removeFile()" title="Remove">Remover Arquivo</button>
            <input tabindex="-1" @change="addFileViaInput" type="file" ref="uploadFile" placeholder="Escolha ou arraste um arquivo para gerar a devolutiva" hidden>
          </div>

          <div class="footerFile">
            <a :href="require('@/assets/examples/emails.csv')" download>Modelo de arquivo</a>
          </div>

          <span
            v-if="isValidEmail !== 'true' && validateEmail(email)"
            class="text-alert-valid-email"
            ><b>Convidando usuário cadastrado na plataforma.</b></span
          >
          <span
            v-else-if="isValidEmail === 'true' && validateEmail(email)"
            class="text-alert-valid-email"
            ><b>Convidando usuário fora da plataforma.</b></span
          >

          <label class="question-style">Permissão:</label>
          <select
            v-model="perm"
            class="form-control"
          >
            <option value="APLICADOR">Aplicador</option>
            <option value="COORDENADOR">Coordenador</option>
          </select>
        </div>
      </div>
      <div class="button-div pt-2">
        <!-- <Button 
                section="instituicao-cadastrar"
                value="Importar Lista"
                :isOutline="true"
                :hasIcon="true"
                iconName="save"
            />  -->
        <Button
          section="instituicao-cadastrar"
          value="Convidar Membro"
          :isOutline="true"
          :hasIcon="true"
          iconName="paper-plane"
        />
      </div>
    </form>
  </div>
</template>

<script>
import Button from "@components/Button";
import { Notyf } from "notyf";
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: "left",
    y: "bottom",
  },
});

export default {
  name: "AddUser",
  data() {
    return {
      email: "",
      perm: "",
      csv_file: '',
      arquivo: null,
      isDragOver: false,
      name_image: "icone-upload",
      label: "Arraste um arquivo CSV de emails"
    };
  },
  computed: {
    isValidEmail() {
      return this.$store.getters.getIsValidEmail;
    },
  },
  methods: {
    // METODOS DO FILE INPUT
    isDragOverActions: function() {
      this.isDragOver = true
      this.label = "Solte o arquivo aqui!"
      this.name_image = "icone-upload-happy"
    },
    isDragLeaveActions: function() {
      this.isDragOver = false
      this.label = "Escolha ou arraste um arquivo para gerar a devolutiva"
      this.name_image = "icone-upload"
    },
    removeFile(){
      this.arquivo = null;
    },
    openDialog: function() {
      this.$refs.uploadFile.value = ''
      this.$refs.uploadFile.click()
    },
    addFileViaInput: function() {
        let droppedFiles = this.$refs.uploadFile.files;
        for (let f of droppedFiles) {
          if (f.type == "text/csv" || f.type == "application/vnd.ms-excel") {
            this.arquivo = f;
          }
          else {
            notyf.open({
              type: "error",
              message: "Tipo inválido de arquivo. Favor tente novamente com um arquivo de extensão '.csv'."
            });
          }
      }
    },
    addFile: function(e) {
      let droppedFiles = e.dataTransfer.files;
      this.isDragLeaveActions()
      for (let f of droppedFiles) {
        if (f.type == "text/csv" || f.type == "application/vnd.ms-excel") {
          this.arquivo = f;
        } else {
          notyf.open({
            type: "error",
            message:
              "Tipo inválido de arquivo. Favor tente novamente com um arquivo de extensão '.csv'.",
          });
        }
      }
    },

    async createMembersFromFile() {
      notyf.open({
        type: "info",
        message: "Extraindo lista de emails..."
      });

      let reader = new FileReader();
      reader.onload = () => {
        let rawText = reader.result,
            emailList = rawText.split(',').map(e => e.trim()),
            errorList = [];
        console.log("Lista: ", emailList);

        // TODO perguntar melhor jeito de mostrar erros
        // TODO falar que eu peguei de exemplo do addclassbyfile
        emailList.forEach(async (e) => {
          if (this.validateEmail(e)) {
            console.log("Tratando: ", e);
            const payload = {
              idInstituicao: this.$route.params.idInstituicao,
              userMails: JSON.parse(JSON.stringify([e])),
              ...(this.perm.trim() != "" && { permissao: this.perm }),
            };
            await this.$store.dispatch("setNotifyInstiInviteUser", payload);

            if (this.$store.getters.getIsSentRequestInstiUser) {
              // notyf.open({
              //   type: "success",
              //   message: `Convite enviado com sucesso para ${e}!`,
              // });
              console.log(`Convite enviado com sucesso para '${e}'!`);
            } else {
              notyf.open({
                type: "error",
                message: `Email já enviado para ${e}, aguarde sua resposta`,
              });
            }

            await this.$store.dispatch(
              "setListMembros",
              this.$route.params.idInstituicao
            );
          } else {
            errorList.push(e);
          }
        });

        if (errorList.length > 0) {
          notyf.open({
            type: "error",
            message: `Erro no envio de ${errorList.length} ${errorList.length > 1 ? 'solicitação' : 'soliciatações'}, cheque o console para mais informações`
          });
          let txtErrorList = "";
          errorList.forEach(e => { txtErrorList += '\t' + e + '\n' });
          console.log("Lista de emails com erro:", txtErrorList);
        } else {
          notyf.open({
            type: "success",
            message: "Solicitação enviada com sucesso para todos os emails da lista!"
          });
        }

        this.label = "Arraste um CSV de emails";
        this.arquivo = null;
      };

      reader.onerror = () => {
        notyf.open({
          type: "error",
          message: "Erro ao abrir arquivo! Confira o CSV selecionado e tente novamente"
        });
      };

      reader.readAsText(this.arquivo);
    },

    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    async verifyValidadeEmail() {
      if (this.validateEmail(this.email)) {
        await this.$store.dispatch("setIsValidEmail", this.email);
      }
    },

    async createMember() {
      if (this.validateEmail(this.email)) {
        console.log(this.isValidEmail);
        const payload = {
          idInstituicao: this.$route.params.idInstituicao,
          userMails: JSON.parse(JSON.stringify([this.email])),
          ...(this.perm.trim() != "" && { permissao: this.perm }),
        };
        await this.$store.dispatch("setNotifyInstiInviteUser", payload);

        if (this.$store.getters.getIsSentRequestInstiUser) {
          notyf.open({
            type: "success",
            message: "Convite enviado com sucesso!",
          });
          this.email = "";
        } else {
          notyf.open({
            type: "error",
            message:
              "Você já enviou um convite para esse usuário. Aguarde ele responder.",
          });
        }

        await this.$store.dispatch(
          "setListMembros",
          this.$route.params.idInstituicao
        );
      } else if (this.arquivo !== null) this.createMembersFromFile();
    },
  },

  components: {
    Button,
  },
};
</script>

<style scoped>
/* INPUT FILE STYLES */

.input-file {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}

.input-file input {
    max-width: 30rem;
}

.input-file h1 {
    font-size: 1.6rem;
    font-weight: bold;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    color: #0d9f9f;
}

.input-file button {
    border: 1px solid #0d9f9f;
    color: #0d9f9f;
    background-color: white;
    border-radius: 4px;
    padding: 0.4rem 1.2rem 0.4rem 0.4rem;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    transition: all ease-in-out 0.2s;
    -webkit-box-shadow: 10px 10px 5px -1px rgba(0,0,0,0.51);
    -moz-box-shadow: 10px 10px 5px -1px rgba(0,0,0,0.51);
    box-shadow: 6px 6px 5px -1px rgba(0,0,0,0.2);
}

.input-file button:hover {
    color: white;
    background-color: #0d9f9f;
}

.drag-and-drop {
    height: 14rem;
    width: 27rem;
    border: 4px dashed #0d9f9f;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    padding: 0 1.5rem;
    margin: 0 2rem;
}

.drag-and-drop img {
    max-height: 5rem;
}

.drag-and-drop p {
    color: #656565;
    font-weight: 400;
    user-select: none;
}

.drag-style {
    filter: blur(0.7px);
}

.file-uploaded {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -2.5rem;
}

.input-file .remove-file {
    border: 1px solid #fd5454;
    color: #fd5454;
    padding: 0.4rem;
    margin-top: 0.2rem;
    font-size: 0.9rem;
    position: absolute;
    bottom: 24%;
}

.input-file .remove-file {
    border: 1px solid #fd5454;
    color: #fd5454;
    padding: 0.4rem;
    margin-top: 0.2rem;
    font-size: 0.9rem;
    position: absolute;
    bottom: 24%;
}

.container {
  width: 100%;
  background-color: white;
}

.title-style {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: #656565;
}

.question-style {
  width: 100%;
  font-size: 1rem;
  color: #656565;
}

.image-style {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding: 1rem;
}

.button-div {
  text-align: right;
  display: flex;
  justify-content: space-around;
}

.text-alert-match-passwords {
  color: rgb(224, 53, 53);
  font-weight: 100;
  font-size: 0.9rem;
}

.text-alert-valid-email {
  color: rgb(104, 158, 60);
  font-weight: 100;
  font-size: 0.9rem;
}

.footerFile {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    gap: 10px;
    text-align: right;
    margin-bottom: 10px;
}

.footerFile > a {
    flex: 1 0 max-content;
    color: #656565;
    text-decoration: underline;
}
</style>
