<!--
|---------------------------------------------
| Map Component 
|---------------------------------------------
| @usage Import the component and use, no params
| Map loaded using map.js library
-->

<template>
    <div class='hero-img col-md-6'>
        <div id="br_mine"></div>
    </div>
</template>

<script>
import MapLib from '@assets/js/map';

export default {
    name: 'Map',
    mounted: function() {
        MapLib.BrMap.Draw({
			wrapper: '#br_mine', 
			selectStates: ['sp','ce','rr'],
            cssFill: {
                shape: "#AED6FF",
                icon_state: "#99bde0",
                selected: "#5CCA81",
                label_icon_state: "#525252",
                hover: "#9bf3b8"
            },
            showStatesLabel: false,
            responsive: true
		});
    }
}
</script>

<style scoped>
</style>
