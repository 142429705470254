<template>

    <div class="bg">
        <div class="list-data">

            <ModalCentral
                componentName="AddEducationalUnitByFileAmostra"
                title="ADICIONAR UNIDADES EDUCACIONAIS NA AMOSTRA"
                idName="AddUEbyFile"
            />

            <ModalCentral
              componentName="UpdateUE"
              title="ATUALIZAR UNIDADES EDUCACIONAL"
              idName="updateUE"
              :data="{ idUE: ueEditing, }"
            />

            <div class="title-tree">
                <Breadcrumb :crumbs="crumbs" section="instituicao-campo"/>
            </div>

            <InstitutionInfo class="title-style" :title="nomeCampo" section="instituicao-cadastrar"/>

            <p class="text-info-personal">Nesta seção, você encontrará todas as Unidades Educacionais relacionadas ao Campo. Caso necessário, utilize o filtro abaixo para localizar a Unidade Educacional desejada. Para visualizar as Turmas pertencentes a cada Unidade Educacional, clique no botão "Ver UE".</p>

            <div class="itens-box">
                <div class="filter-box">
                    <!-- INICIO DO FORM DE FILTRAGEM -->
                    <form @submit.prevent="" class="form-inline">
                        <div class="form-group">

                            <!-- DROPDOWN DE SELECAO DE FILTRO -->
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomSelect">Filtrar por: </label>
                            <Filter ref="filtersearchname" @change="setSelectedValue()" :optionsSelect="options" nameSelect="filtro_instituicoes" id="inlineFormCustomSelect"/>
                        </div>

                        <!-- SE A SELECAO FOR NOME (2) ENTAO EXIBIRA OS SUBFILTROS DE NOME -->
                        <div class="form-group" v-if="selectedValue == 2">
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomName">Digite o nome: </label>
                            <input class="input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_instituicoes" id="inlineFormCustomName" autocomplete="off">
                        </div>

                    </form>
                </div>
                <div v-if="userPerm.permissao != 'APLICADOR'" class="lista-data-info">
                    <!-- <Button
                         v-if="campoStatus == 'INATIVO' && userPerm.permissao != 'APLICADOR'"
                         class="btn"
                         section="instituicao-campo"
                         value="Marcar Unidades e Turmas"
                         :isOutline="true"
                         :hasIcon="true"
                         iconName="upload"
                         data-toggle="modal"
                         data-target="#AddEducationalClassInField"
                         /> -->
                    <Button
                        v-if="campoStatus != 'ENCERRADO'"
                        @click="setSelectAllItems"
                        class="btn ml-2 btnCtrl"
                        section="instituicao-campo"
                        value="Marcar Todos"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="check-square"
                    />
                    <Button
                        v-if="campoStatus != 'ENCERRADO'"
                        @click="setUnselectAllItems"
                        class="btn ml-2 btnCtrl"
                        section="instituicao-campo"
                        value="Desmarcar Todos"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="square"
                    />
                    <Button
                        v-if="campoStatus != 'OFF'"
                        @click="applyChanges"
                        class="btn ml-2 btnCtrl"
                        section="instituicao-campo"
                        value="Aplicar Alterações"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="check"
                    />
                    <Button
                        class="btn ml-2 btnCtrl"
                        section="instituicao-campo"
                        value="Importar Lista de UE"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="clipboard-list"
                        data-toggle="modal"
                        data-target="#AddUEbyFile"
                    />
                </div>
            </div>
            <hr class="separator">

            <!-------------------------------- LISTANDO OS MEMBROS ----------------------------------------------->

            <div v-if="isLoadedInfo">
                <MemberQnt term="unidades" :totalQnt="totalUnidades" :filteredQnt="unidades_filtered?.length || 0" icon="fa fa-university" />

                <transition-group name="slide-fade" v-if="unidades_filtered?.length > 0">
                    <div class="box-header">
                        <span></span>
                        <span>Nome</span>
                        <span>Macroárea</span>
                        <span>Cidade</span>
                        <span>Controle</span>
                    </div>

                    <div class="box-lista" v-for="unidade in unidades_filtered" :key="unidade.counter" :class="{'list-item-selected-pink': unidade.isSelect.isSelected}">
                        <div>
                            <img class="img-info-box-lista" :class="{'active-img': unidade.isSelect.isSelected, 'black-img': !unidade.isSelect.isSelected}" src="@assets/img/Dados/ListarUE/icon-ue.png" alt="">
                        </div>
                        <span>{{unidade.nome}}</span>
                        <span>{{unidade.macroarea || '-'}}</span>
                        <span>{{unidade.cidade}}</span>
                        <div class="controlGroup">
                            <router-link v-if="unidade.isSelect.isSelected && userPerm?.idPermissao != '1'" :to="{name: 'CampoListaTurmas', params: { idUnidade: unidade.idue }}" class="btn-router-link">
                                <Button
                                    value="Ver UE"
                                    class="btnLista"
                                    section="instituicao-campo"
                                    :isOutline="true"
                                />
                            </router-link>

                            <template v-if="userPerm?.idPermissao != '1'">
                                <span v-if="unidade.isLocked">Selecionado</span>
                                <template v-else>
                                  <Button
                                    v-if="campoStatus != 'OFF'"
                                    @click="ueEditing = unidade.idue"
                                    value="Editar UE"
                                    data-toggle="modal"
                                    data-target="#updateUE"
                                    class="btnLista"
                                    section="instituicao-campo"
                                    :isOutline="true"
                                    />

                                    <div v-if="['ON', 'INATIVO'].includes(campoStatus)" @click="setIsSelected(unidade.idue)" class="select-info">
                                        <div :class="['box-select-info mr-2 ',{'selected-info': unidade.isSelect.isSelected}]"></div>
                                        <span class="span-label-selected" v-if="unidade.isSelect.isSelected">Selecionado</span>
                                        <span class="span-label-selected" v-else>Selecionar</span>
                                    </div>
                                    <div v-else class="select-info-blocked">
                                        <span class="span-label-selected" v-if="unidade.isSelect.isSelected">Selecionado</span>
                                    </div>
                                </template>
                            </template>
                        </div>
                    </div>
                </transition-group>
                <transition-group v-else>
                    <h2 class="title-style-box-lista" key="educationalUnitKey">Nenhuma Unidade Educacional encontrada.</h2>
                </transition-group>

            </div>

            <div v-else>
                <SkeletonList/>
            </div>

        </div>
    </div>

</template>

<script>
 import Filter from '@components/Filter'
 import Button from '@components/Button'
 import ModalCentral from '@components/ModalCentral'
 import Breadcrumb from '@components/Breadcrumb'
 import Swal from 'sweetalert2';
 import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
 import Amostra from '@middleware/amostra.js'
 import SkeletonList from '@components/SkeletonUI/SkeletonList'
 import MemberQnt from '@dados/VisualizarInstituicao/MemberQnt'

 export default {
     name: 'CampoListaFormularios',
     data (){
         return {
           ueEditing: null,
             nomeCampo: '',
             descricao: '',
             selectedValue: '',
             searchByName: '',
             searchByCode: '',
             searchWithOrder: '',
             isLoadedInfo: false,
             teste: [],
             unidades_amostra: [],
             unidades: [],
             options: [
                 {
                     id:1,
                     value: 'Todos'
                 },
                 {
                     id:2,
                     value: 'Nome'
                 },
                 {
                     id:3,
                     value: 'Selecionados'
                 },
             ],
             crumbs: [
                 {
                     id:1,
                     value: '',
                     iconName: 'th',
                     name_route: 'GerenciarInstituicoes'
                 },
                 {
                     id:2,
                     value: 'Instituição',
                     iconName: 'university',
                     name_route: 'InstituicaoMenu'
                 },
                 {
                     id:3,
                     value: 'Campos',
                     iconName: 'th-large',
                     name_route: 'ListarCampos'
                 },
                 {
                     id:4,
                     value: 'Gerenciar Campo',
                     iconName: 'chart-pie',
                     name_route: 'InstituicaoMenuCampo'
                 },
                 {
                     id:5,
                     value: 'Unidades Educacionais',
                     iconName: 'graduation-cap',
                     name_route: ''
                 }
             ]
         }
     },
     watch: {
         rawListCampoUEs() { this.setData() },
         rawListUE() { this.setData() },
     },
     computed: {
         campoStatus() {
             return this.$store.getters.getAmostraStatusAtiva
         },
         userPerm() {
             return this.$store.getters.getUserPerm
         },
         totalUnidades() {
             return this.unidades?.length || 0;
         },
         unidades_filtered() {
             // VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
             var query = this.unidades;
             //CASO SEJA SELECIONADO TODOS, EXIBE TODOS OS ITENS DO ARRAY
             if (this.selectedValue == 1) {
                 query = this.unidades;
             }
             //CASO SEJA SELECIONADO NOME, FILTRA POR NOME
             if (this.selectedValue == 2) {
                 //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
                 if (this.searchByName.trim() == '') {
                     query = this.unidades;
                 }
                 //SENAO ELE FILTRA PELO O QUE FOR DIGITADO
                 else {
                     var filtered = this.unidades?.filter(unidade =>unidade.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
                     if(filtered?.length != 0){
                         query = filtered
                     }

                     else{
                         query = false
                     }
                 }
             } else if (this.selectedValue === 3) {
                 query = this.unidades.filter(unidade => unidade.isSelect.isSelected);
             }
             return query.sort((a, b) => parseInt(a?.idue || 0) - parseInt(b?.idue));
         },
         rawListUE() {
             return this.$store.getters.getListUE || [];
         },
         rawListCampoUEs() {
             return this.$store.getters.getListCampoUEs || [];
         }
     },
     async created() {

         this.campos = this.$store.getters.getListCampos

         this.isLoadedInfo = false
         this.loadData()

         const payload = {
             idInstituicao: this.$route.params.idInstituicao
         }
         await this.$store.dispatch('setUserPerm', payload)

         await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)

         const all_campos = this.$store.getters.getListCampos
         const campo = all_campos.filter((value) => {
             return value.idamostra == this.$route.params.idCampo
         }).map((value) => {return value.isativo})

         const campoStatus = campo[0]

         this.$store.commit('setAmostraStatusAtiva', campoStatus)
     },
     components: {
         Filter,
         Button,
         Breadcrumb,
         InstitutionInfo,
         ModalCentral,
         SkeletonList,
         MemberQnt,
     },
     methods: {
         setIsSelected(id) {
             this.unidades = Amostra.setIsSelectedItem(this.unidades, 'unidade', id)
         },
         setSelectAllItems() {
             this.unidades = Amostra.setSelectAllItems(this.unidades, 'selectUnidades')

             Amostra.setAllSelectedItems(this.unidades, 'unidade')
         },
         setUnselectAllItems() {
             this.unidades = Amostra.setUnselectAllItems(this.unidades, 'selectUnidades')

             Amostra.setAllSelectedItems(this.unidades, 'unidade')
         },
         setData() {
             this.unidades = this.rawListUE
             this.unidades_amostra = this.rawListCampoUEs

             this.unidades.forEach(unidade => {
                 unidade.isLocked = this.unidades_amostra.find(unidade_amostra => unidade_amostra.idue == unidade.idue)?.isLocked || false
                 unidade.isSelect = {
                     isSelected: this.unidades_amostra.some(unidade_amostra => unidade_amostra.idue == unidade.idue),
                     isSaved: true
                 }
             });

             this.isLoadedInfo = true
         },
         async loadData() {
             const payload = {
                 idInstituicao: this.$route.params.idInstituicao,
                 idAmostra: this.$route.params.idCampo
             }
             const payload2 = {
                 idInstituicao: this.$route.params.idInstituicao
             }

             await this.$store.dispatch('setListCampoUEs', payload)
             await this.$store.dispatch('setListUE', payload2)
             this.setData();
         },
         async applyChanges() {
             if (this.$store.getters.getAmostraStatusAtiva == 'ON' || this.$store.getters.getAmostraStatusAtiva == 'INATIVO') {
                 const result = await Swal.fire({
                     title: "Gostaria de adicionar todas as Turmas pertencentes a esta Unidade Educacional?",
                     text: "Você está em um campo ativo, os elementos inseridos não poderão ser modificados.",
                     icon: "question",
                     showCancelButton: true,
                     confirmButtonColor: "#3085d6",
                     cancelButtonColor: "#d33",
                     cancelButtonText: "Cancelar",
                     confirmButtonText: "Sim",
                 })

                 if (result.isConfirmed) {
                     Amostra.setAllSelectedItems(this.unidades, 'unidade')
                     const payloadManageUnidades = this.$store.getters.getPayloadManageUnidades
                     const payload = {
                         idInstituicao: this.$route.params.idInstituicao,
                         idAmostra: this.$route.params.idCampo,
                         ids: JSON.parse(JSON.stringify(payloadManageUnidades))
                     }
                     await this.$store.dispatch('setManageUnidades', payload)
                     await this.$store.dispatch('setSelectAllTurmas', payload)

                     this.loadData()
                     Swal.fire(
                         'Alterações aplicadas!',
                         'As seleções foram aplicadas com sucesso.',
                         'success'
                     )
                 }

             } else {
                 Amostra.setAllSelectedItems(this.unidades, 'unidade')
                 const payloadManageUnidades = this.$store.getters.getPayloadManageUnidades
                 const payload = {
                     idInstituicao: this.$route.params.idInstituicao,
                     idAmostra: this.$route.params.idCampo,
                     ids: JSON.parse(JSON.stringify(payloadManageUnidades))
                 }
                 await this.$store.dispatch('setManageUnidades', payload)

                 this.loadData()
                 Swal.fire(
                     'Alterações aplicadas!',
                     'As seleções foram aplicadas com sucesso.',
                     'success'
                 )
             }
         },
         setSelectedValue: function() {
             this.selectedValue = this.$refs.filtersearchname.value
         }
     },
     mounted: function() {
         window.scrollTo(0, 0);
         let idTeamSelected = this.$route.params.idCampo

         if (typeof idTeamSelected == "undefined"){
             this.nomeInstituicao = "Nome do Campo"
         }
         else {
             let teamsSelected
             let teamSelected

             teamsSelected = this.campos.filter(team =>
                 team.idamostra == idTeamSelected
             )

             teamSelected = [...teamsSelected].shift()

             this.nomeCampo = teamSelected.titulo
             this.descricao = teamSelected.descricao
         }
     }
 }
</script>

<style scoped>
 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;
 }

 .imgText {
     background-image: url("~@/assets/img/Backgrounds/roxoEapi.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .box-lista {
     background-repeat: repeat;
     background-size: 256px;
     background-image: url("~@/assets/img/Backgrounds/roxoClaro.webp");

     border: 1px solid #958db1;
     box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.3);
 }

 :deep(.box-lista span) {
     font-size: 1.1em;
 }

 .box-lista span {
     color: #fcfcfc;
 }

 .text-info-personal {
     color: #7647ac;
     font-size: 1.1rem;
     text-align: justify;
     padding: 0rem;
     margin: 0 3rem 0 3rem;
 }

 .list-data {
     min-height: 90vh;
     padding-top: 2rem;
 }
 .label-filtro {
     font-weight: 600;
 }

 .filter-box {
     margin: 1rem 3rem;
 }

 .list-box {
     margin: 2rem;
 }

 .itens-box {
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .title-tree {
     margin: 1rem 3rem;
     font-weight: bold;
     display: flex;
 }


 .lista-data-info {
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;
     margin: 2rem 3rem;
 }

 .title-style{
     margin: 0 0 0 3rem;
 }

 .title-tree h1 {
     font-size: 1.2rem;
     font-weight: 700;
 }

 .main-link {
     outline:none;
     text-decoration: none;
     cursor: pointer;
     margin-right: .3rem;
 }

 .sub-link {
     margin-left: .3rem;
 }

 .separator {
     border: none;
     height: 1px;
     margin: 0.8rem 3rem;
     background: url("~@/assets/img/Backgrounds/roxoEapi.webp");
 }

 a {
     color: #212529;
     text-decoration: none;
     transition: all ease-in-out 0.2s
 }

 a:hover {
     color: #4d4f50;
 }

 @media screen and (max-width: 1010px) {
     .lista-data-info {
         justify-content: center;
     }
 }


 @media screen and (max-width: 1012px) {
     .filter-box {
         display: flex;
         align-items: center;
         justify-content: center;
     }
 }

 @media screen and (max-width: 680px) {
     .itens-box{
         flex-direction: column;
     }
 }

 .span-label-selected {
     width: min-content;
 }

 .list-item-selected-pink {
     background-image: url("~@/assets/img/Backgrounds/roxoEapi.webp");
 }

 .active-img {
     filter: brightness(0) invert(1) contrast(0.9);
 }

.controlGroup .select-info {
    padding: 10px;
}
</style>
