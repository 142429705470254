<template>
    <div>
        <ModalCentral
            componentName="InviteUserGroup"
            :data="{ id: idGrupo }"
            title="Adicionar Membro"
            idName="idInvite"
        />

        <div class="title-info">
            <h1 class="title">Membros</h1>
            <p class="text-info-personal">Membros cadastrados no grupo</p>
        </div>

        <div class="container-view">
            <div @click="closeMiniDropdown()" class="overlay-invisible" :class="{'overlay-visible': isActiveMiniDropdown}"></div>

            <div class="buttons-wrapper">
                <button @click="gotoMembro" class="button-menu">MEMBROS</button>
                <button class="button-menu active">SOLICITAÇÕES</button>
            </div>

        </div>
        
        <div class="itens-box">
            <div class="filter-box">
                <!-- INICIO DO FORM DE FILTRAGEM -->
                <form @submit.prevent="" class="form-inline">
                    <div class="form-group">

                        <!-- DROPDOWN DE SELECAO DE FILTRO -->
                        <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomSelect">Filtrar por: </label>
                        <Filter ref="filtersearchname" @click="setSelectedValue()" :optionsSelect="options" nameSelect="filtro_instituicoes" id="inlineFormCustomSelect"/>
                    </div>

                    <!-- SE A SELECAO FOR NOME (2) ENTAO EXIBIRA OS SUBFILTROS DE NOME -->
                    <div class="form-group" v-if="selectedValue == 2">
                        <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomName">Digite o nome: </label>
                        <input class="input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_instituicoes" id="inlineFormCustomName" autocomplete="off">

                        <input class="radio-personal mr-sm-2" v-model="searchWithOrder" value="cres" type="radio" name="filtro_nome_ordenacao" id="inlineFormCustomRadioAZ" checked>
                        <label class="mr-sm-2 text-nowrap label-filtro mr-sm-4" for="inlineFormCustomRadioAZ">A - Z</label>

                        <input class="radio-personal mr-sm-2" v-model="searchWithOrder" value="desc" type="radio" name="filtro_nome_ordenacao" id="inlineFormCustomRadioZA">
                        <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomRadioZA">Z - A</label>
                    </div>

                    <!-- SE A SELECAO FOR POR GRUPOS (3) ENTAO EXIBIRA OS SUBFILTROS DOS GRUPOS -->
                    <div class="form-group" v-if="selectedValue == 3">
                        <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomCodigo">Selecione o grupo: </label>
                        <Filter ref="filtersearchgroup" @click="setSelectedGroup()" :optionsSelect="group_options" nameSelect="filtro_grupo_instituicoes" id="inlineFormCustomCodigo"/>
                    </div>

                </form>
            </div>
            <div class="lista-data-info">
                <Button
                    v-if="isAdmin"
                    class="btn mr-2 menuButton"
                    section="dados-outline"
                    value="Convidar membro"
                    :isOutline="true"
                    :hasIcon="true"
                    data-target="#idInvite"
                    data-toggle="modal"
                    iconName="envelope" />
            </div>
        </div>
        <hr class="separator">

        <div class="list-box" v-if="loaded">
            <ListagemElements
                :infos="solicitacoesPendentes"
                listaType="listarSolicitacoesGrupo"/>
        </div>
        <SkeletonList v-else />

    </div>
</template>


<script>
 import Filter from '@components/Filter'
 import Button from '@components/Button'
 import ListagemElements from '@dados/VisualizarInstituicao/ListagemElement'
 import ModalCentral from '@components/ModalCentral'
 import SkeletonList from '@components/SkeletonUI/SkeletonList'

 export default {
     name: "GrupoMenu",
     data () {
         return {
             idGrupo: null,
             loaded: false,
             groupPerm: {},
             isActiveMiniDropdown: false,
             selectedValue: '',
             searchByName: '',
             searchByCode: '',
             searchWithOrder: '',
         }
     },
     methods: {
         showMiniDropdown: function() {
             this.isActiveMiniDropdown = true
         },
         closeMiniDropdown: function() {
             this.isActiveMiniDropdown = false
         },
         setSelectedValue: function() {
             //! TODO: só pra parar de dar erro no console
         },
         gotoMembro() {
             this.$router.push({name: 'GrupoMembros'})
         },
     },
     async created() {
         this.idGrupo = this.$route.params.idGrupo;
         const payload = {
             idGrupo: this.idGrupo,
             filter: "GRUPO_USUARIO",
         }
         await this.$store.dispatch('setListMembrosGrupo', this.$route.params.idGrupo)
         await this.$store.dispatch("groupPerm", { idGrupo: this.idGrupo })
         this.groupPerm = this.$store.getters.getGroupPerm(this.idGrupo)
         await this.$store.dispatch('setListGroupRequests', payload)
         this.loaded = true
     },
     computed: {
         solicitacoesPendentes() {
             const toShow = [ "PENDENTE" ];
             return this.$store.getters.getListGroupRequests.filter(invite => toShow.includes(invite?.aceito));
         },
         isAdmin() {
             return this.$store.getters.getUserInfo?.data?.isAdmin || false;
         },
     },
     components: {
         Filter,
         Button,
         ListagemElements,
         ModalCentral,
         SkeletonList,
     }
 }
</script>


<style scoped lang="scss">
 @import "../../assets/scss/_variables.scss";

 .overlay-invisible {
     position: fixed;
     top: 0; right: 0; bottom: 0; left: 0;
     background-color: rgba(22, 22, 22, 0.0);
     z-index: 2;
     transition: all ease-in-out 0.3s;
     opacity: 0;
     pointer-events: none;
 }

 .overlay-visible {
     opacity: 1;
     pointer-events: all;
     cursor: default;
 }

 .container-view {
     display: flex;
     align-items: center;
     justify-content: flex-start;
     flex-direction: column;
 }
 .banner-sup {
     display: flex;
     width: 80%;
     max-width: 1200px;
     min-width: 300px;
 }
 .img-banner {
     height: 100%;
     width: 100%;
     border-radius: 0.8rem  0rem  0rem 0.8rem;
 }
 .left-side {
     flex: 30%;
 }
 .right-side {
     display: flex;
     flex-direction: column;
     justify-content: center;
     padding: 2rem;
     background-color: white;
     flex: 70%;
     border-radius: 0 0.8rem 0.8rem 0;
     position: relative;
 }
 .right-side h1 {
     font-size: 1rem;
     font-weight: bold;
 }
 .right-side p {
     font-size: 0.9rem;
     font-weight: lighter;
     text-align: justify;
     margin: 0;
 }

 .fa-cog {
     cursor: pointer;
     font-size: 1.4rem;
     position: absolute;
     top: 8%;
     right: 4%;
 }

 .item-minidropdown {
     color: #656565;
 }

 .item-minidropdown:hover {
     color: rgb(58, 58, 58);
 }

 .mini-triangle{
     margin: 0rem;
     display: center;
     align-items: center;
     justify-items: center;
     position: absolute;
     top: -16%;
     left: 80%;

 }
 .dropdown-box {
     position: absolute;
     top: 188%;
     left: -480%;
     z-index: 2;
     opacity: 0;
     pointer-events: none;
     cursor: default;
     transition: all 0.4s ease;
 }

 .dropdown-box-visible{
     opacity: 1;
     cursor: pointer;
     pointer-events: all;
 }

 .mini-dropdown {
     position: relative;
 }

 .mini-pop-up{
     height: 5.5rem;
     width: 8rem;
     background-color:white;
     color:grey;
     border-radius: 1rem;
     transition: color ease-in-out 0.2s;
     font-size: 1rem;
     font-weight: 300;
     font-family: 'Hind', sans-serif;
 }

 .item-menu-dropdown {
     margin: 1rem 0;
 }

 .buttons-wrapper {
     width: 30rem;
     height: 3.5rem;
     border: 0.2rem solid $verdeEscuro-eapi;
     border-radius: 1.1rem;
     display: flex;
     align-items: center;
     justify-content: space-evenly;
 }

 .button-menu {
     color: $verdeEscuro-eapi;
     background-color: transparent;
     border: none;
     font-size: 1.3rem;
     font-weight: 700;
     cursor: pointer;
     outline: none;
     flex: 1;
     height: 100%;
     transition: all ease-in-out .3s;
 }

 .button-menu:first-child {
     border-radius: .4rem 0 0 .4rem;
 }

 .button-menu:last-child {
     border-radius: 0 .4rem .4rem 0;
 }

 .button-menu:hover {
     color: white;
     background-color: $verdeEscuro-eapi;
 }

 .active {
     color: white;
     background-color: $verdeEscuro-eapi;
 }



 .label-filtro {
     font-weight: 600;
 }

 .filter-box {
     margin: 1rem 3rem;
 }

 .list-box {
     margin: 2rem;
 }

 .itens-box {
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .title-tree {
     margin: 1rem 3rem;
     font-weight: bold;
     display: flex;
 }


 .lista-data-info {
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;
     margin: 2rem 3rem;
 }

 .title-tree h1 {
     font-size: 1.2rem;
     font-weight: 700;
 }

 .main-link {
     outline:none;
     text-decoration: none;
     cursor: pointer;
     margin-right: .3rem;
 }

 .sub-link {
     margin-left: .3rem;
 }

 .separator {
     border: none;
     height: 1px;
     margin: 0.8rem 3rem;
     background-color: rgba(51, 51, 51, 0.178);
 }

 a {
     color: #212529;
     text-decoration: none;
     transition: all ease-in-out 0.2s
 }

 a:hover {
     color: #4d4f50;
 }

 @media screen and (max-width: 1010px) {
     .lista-data-info {
         justify-content: center;
     }
 }


 @media screen and (max-width: 1012px) {
     .filter-box {
         display: flex;
         align-items: center;
         justify-content: center;
     }
 }

 @media screen and (max-width: 680px) {
     .itens-box{
         flex-direction: column;
     }

     .img-banner{
         border-radius: 1rem 1rem 0 0;
     }

     .banner-sup{
         flex-direction: column;
     }

     .right-side{
         border-radius: 0 0 1rem 1rem;
     }
 }

 .title-info {
     padding: 0 3rem;
     box-sizing: border-box;
     margin: 20px auto;
     margin: 20px auto 0 auto;
 }

 .title-info > p {
     margin: 0;
 }

 .title-info > .title {
     font-weight: 600;
     font-size: 1.6rem;
     color: #535353;
     margin: 0;
     margin-top: 0.8rem;
 }

 .text-info-personal {
     color: #656565;
     font-size: 1.1rem;
     text-align: justify;
     padding: 1rem 0rem 0rem 0rem;
 }

</style>
