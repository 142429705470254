<!--
|---------------------------------------------
| GroupCardsBG Component 
|---------------------------------------------
| @usage Import the component and use
| <GroupCardsBG :name="team.name" 
|   :nr_members="team.nr_members" 
|   :nr_application="team.nr_application" 
|   thumbnail="img-escola.png"/>
-->

<template>
  <div class="">
    <!-- MODAL CENTRAL DO 'INFORMAÇÕES' -->
    <ModalCentral
      componentName="UpdateGrupo"
      title="Editar informações"
      :idName="`edit${idGroup}`"
      :data="{ idGrupo: idGroup }"
    />

    <ModalCentral
      title="Sair"
      componentName="LeaveGrupo"
      :idName="`leave${idGroup}`"
      :data="{ idGrupo: idGroup }"
    />

        <div @click="closeMiniDropdown()" class="overlay-invisible" :class="{'overlay-visible': isActiveMiniDropdown}"></div>

        <!-- CARDS DOS GRUPOS -->
        <div class="card custom-rounded">
            <router-link :to="{name: 'GrupoMenu', params: { idGrupo: idGroup }}">

                <!-- TODO (Resolvido) José - Cog fora de eixo -->

                <div @mouseenter="turnOnCog()" @mouseleave="turnOffCog()" class="box-img-card">
                    <img class="card-img-top" :src="(path_thumbnail)" alt="Card image cap "/>
                    <!-- <img class="card-img-top" :src="require('../../../assets/img/Dados/ListarInstituicao/Grupos/'+ thumbnail)" alt="Card image cap "/> -->
                </div>
            </router-link>
            <div @click="showMiniDropdown()" class="institution-actions">

              <i class="fas fa-cog text-secondary" :class="{'turning-cog': isTurningCog, 'turning-instead-cog': isTurningInsteadCog}" ></i>
              <div class="dropdown-box" :class="{'dropdown-box-visible': isActiveMiniDropdown}">
                <div class="mini-dropdown">

                  <div class="mini-triangle">
                    <svg class="triangulo-1" width="26" height="30" viewBox="0 0 36 33" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.6699 2.5C15.5944 -0.833332 20.4056 -0.833334 22.3301 2.5L35.3205 25C37.245 28.3333 34.8394 32.5 30.9904 32.5H5.00962C1.16062 32.5 -1.24501 28.3333 0.679492 25L13.6699 2.5Z" fill="white"/>
                    </svg>
                  </div>


                  <div class="mini-pop-up shadow-lg pt-3 pr-1 pb-3 pl-2">
                    <div
                      :data-target="`#leave${idGroup}`"
                      data-toggle="modal"
                      class="row-md-12 font-weight-light pl-0 pr-0 item-minidropdown">
                      <i class="fas fa-sign-out-alt mr-1"></i>&nbsp;
                      Deixar Grupo
                    </div>
                    <div v-if="groupPerm?.idPermissao == '3'">
                      <div
                        class="row-md-12 font-weight-light pl-0 pr-0 item-minidropdown"
                        :data-target="`#edit${idGroup}`"
                        data-toggle="modal">
                        <i class="fas fa-info-circle mr-1"></i>&nbsp;
                        Editar Informações
                      </div>
                      <div
                        @click="deleteGroup"
                        class="row-md-12 font-weight-light pl-0 pr-0 item-minidropdown">
                        <i class="fas fa-trash mr-1"></i>&nbsp;
                        Excluir
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body pt-1 pl-4 pr-4 pb-1">
                <p class="card-text mb-0"><strong>{{name}}</strong></p>
                <p class="text-secondary mb-0"> <i class="fas fa-user-friends mb-0 mr-1"></i>{{nr_members}} pessoas pertencem a esse grupo</p>
                <p class="text-secondary mb-0"> <i class="fas fa-layer-group mb-0 mr-1"></i>{{nr_institutions}} instituições fazem parte desse grupo</p>
            </div>
        </div>
    </div>
</template>

<script>
import ModalCentral from '@components/ModalCentral';
import Swal from 'sweetalert2';
import { Notyf } from "notyf";
const notyf = new Notyf({
    duration: 5000,
    position: {
        x: "left",
        y: "bottom",
    },
});


export default {
    name: 'GroupCardsBG',
    data() {
        return {
            groupPerm: "",
            idGroup: this.id,
            isActiveMiniDropdown: false,
            isTurningCog: false,
            isTurningInsteadCog: false,
            path_thumbnail: "",
        }
    },
    props: {
        id: Number,
        name: String,
        nr_members: Number,
        nr_institutions: Number,
        thumbnail: String
    },
    async created() {
        try {
            this.path_thumbnail = require('../../../../'+this.thumbnail)
        } catch (e) {
            this.path_thumbnail = require('@assets/img/Dados/ListarInstituicao/Grupos/group1.png')
        }
      await this.$store.dispatch("groupPerm", { idGrupo: this.idGroup })
      this.groupPerm = this.$store.getters.getGroupPerm(this.idGroup)
    },
    methods: {
        showMiniDropdown: function() {
            this.isActiveMiniDropdown = true
        },
        closeMiniDropdown: function() {
            this.isActiveMiniDropdown = false
        },
        turnOnCog: function() {
            this.isTurningCog = true
            this.isTurningInsteadCog = false
        },
        turnOffCog: function() {
            this.isTurningCog = false
            this.isTurningInsteadCog = true
        },
      async deleteGroup() {
        const result = await Swal.fire({
          title: "Tem certeza que quer deletar o grupo?",
          text: "Essa ação não é reversível.",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sim",
        });

        if (result.isConfirmed) {
          await this.$store.dispatch('deleteGrupo', { idGrupo: this.idGroup })
          if (this.$store.getters.getGroupDeleted) {
            notyf.open({
              type: "success",
              message: "Grupo excluído com sucesso",
            })
          } else {
            notyf.open({
              type: "error",
              message: "Erro na exclusão do grupo! Tente novamente",
            })
          }
        }
      }
    },
    components: {
      ModalCentral
    }
}
</script>

<style scoped>
.overlay-invisible {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(22, 22, 22, 0);
  z-index: 2;
  transition: all ease-in-out 0.3s;
  opacity: 0;
  pointer-events: none;
}

.overlay-visible {
  opacity: 1;
  pointer-events: all;
  cursor: default;
}

.card {
  border: none !important;
  background-image: url("~@/assets/img/Backgrounds/laranjaClaroEAPI.png");
  background-repeat: repeat;
  background-size: 256px;
}
.card.custom-rounded {
  border-radius: 20px;
  box-shadow: 12px 12px 23px -7px rgba(158, 146, 158, 0.671);
}

.box-img-card {
  display: inline-block;
  overflow: hidden;
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
  height: 10rem;
}

.card-img-top {
  cursor: pointer;
  display: block;
  transition: transform 0.4s;
}

.card-img-top:hover {
  transform: scale(1.1);
  transform-origin: 50% 50%;
}

.fa-cog {
  color: rgb(221, 221, 221);
  font-size: 1rem;
}

.turning-cog {
  animation: turningCogAnimation;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
}

.turning-instead-cog {
  animation: turningCogInsteadAnimation;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
}

@keyframes turningCogAnimation {
  to {
    transform: rotate(360deg);
  }
}

@keyframes turningCogInsteadAnimation {
  to {
    transform: rotate(-360deg);
  }
}

.card-body {
  font-size: 0.9rem;
  min-height: 8rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.mini-triangle {
  margin: 0rem;
  display: center;
  align-items: center;
  justify-items: center;
  position: absolute;
  top: -16%;
  left: 71%;
}
.dropdown-box {
  position: absolute;
  top: 150%;
  right: -41%;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  cursor: default;
  transition: all 0.4s ease;
}

.dropdown-box-visible {
  opacity: 1;
  cursor: pointer;
  pointer-events: all;
}

.mini-dropdown {
  position: relative;
}

.institution-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  background: #fff;
  border-radius: 100%;
  width: 32px;
  height: 30px;
  box-shadow: 1px 2px 6px -3px rgb(0 0 0 / 25%);
  padding: 5px;
}

.mini-pop-up {
  height: 6.5rem;
  width: 10rem;
  background-color: white;
  color: grey;
  border-radius: 1rem;
  transition: color ease-in-out 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.item-minidropdown {
  cursor: pointer
}

.item-minidropdown:hover {
  color: rgb(58, 58, 58);
}
</style>
