<template>

    <div class="container">
        <h1 class="title-style mt-5 mb-2">Oops! Página não encontrada!</h1>
        <img src="@assets/img/error-404.png" alt="page-not-found" class="img-style">

    </div>

</template>

<script>

export default {
    name: 'Error404',
    components: {
        
    }
}
</script>

<style scoped>

.container{
    align-items: center;
    text-align: center;
}

.title-style{
    color: #FF6366;
}

.img-style{
    width: 70%;
    mix-blend-mode: multiply;
}


</style>