<!--
|---------------------------------------------
| Navbar Component 
|---------------------------------------------
| @usage Import the component and use, no params
| Main Navbar component
-->

<template>
  <div>
    <div @click="closeMiniDropdown()" class="overlay-invisible" :class="{ 'overlay-visible': isActiveMiniDropdown }"></div>
    <ModalLateral ref="ModalLogin" component="Login" title="Entrar"/>
    <ModalLateral ref="ModalSignUp" component="SignUp" title="Cadastre-se"/>

    <!-- MODAL CENTRAL DO 'PERFIL' -->
      <ModalCentral
        componentName="Profile"
        title="Perfil"
        idName="Profile"
      />

    <!-- MODAL CENTRAL DO 'PERFIL' -->
      <ModalCentral
        componentName="UserInvites"
        title="Convites Recebidos"
        idName="UserInvites"
      />

    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container">
        <router-link to="/" class="navbarLogo">
          <!-- <div>
               <span>Qualidade</span>
               <br />
               <span class="logoSmaller">inspirada em</span>
               <br />
               <span>evidências</span>
               </div> -->
          <img id="logo" src="@assets/img/App/Navbar/logo-navbar.webp"/>
        </router-link>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">

            <li class="nav-item dropdown">
              <a @click="showDropdownMenuQualidadeEI" class="nav-link nav-item" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Qualidade na EI
              </a>
              <DropdownMenu
                ref="DropdownMenuQualidadeEI"
                menuDropdown="qualidadeEi"
              />
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              
                <router-link class="dropdown-item" to="/boaspraticas/qualidade">
                  <div class="navbar-box-mobile">
                    <div class="box-mobile-img">
                      <img src="@assets/img/App/Navbar/icone-oquee.webp" alt=""/>
                    </div>
                    <div class="box-mobile-text">
                      <h1 class="secao-titulo-dropdown">
                        O que é?
                      </h1>
                      <p class="secao-texto-dropdown">
                        Entenda sobre a importância da qualidade na Educação Infantil
                      </p>
                    </div>
                  </div>
                </router-link>

                <router-link class="dropdown-item" to="/boaspraticas/docoficiais">
                  <div class="navbar-box-mobile">
                    <div class="box-mobile-img">
                      <img src="@assets/img/App/Navbar/icone-acervo.webp" alt=""/>
                    </div>
                    <div class="box-mobile-text">
                      <h1 class="secao-titulo-dropdown">
                        Documentos oficiais
                      </h1>
                      <p class="secao-texto-dropdown">
                        Conheça os documentos oficiais em relação à qualidade na Educação Infantil
                      </p>
                    </div>
                  </div>
                </router-link>

                <router-link class="dropdown-item" to="/boaspraticas/oquee">
                  <div class="navbar-box-mobile">
                    <div class="box-mobile-img">
                      <img src="@assets/img/App/Navbar/icone-projeto.webp" alt=""/>
                    </div>
                    <div class="box-mobile-text">
                      <h1 class="secao-titulo-dropdown">
                        Boas Práticas
                      </h1>
                      <p class="secao-texto-dropdown">
                        Conheça boas práticas na Educação Infantil
                      </p>
                    </div>
                  </div>
                </router-link>
                
              </div>
            </li>

            <li class="nav-item dropdown">
              <a @click="showDropdownMenuMateriais" class="nav-link nav-item" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Avaliação da qualidade
              </a>
              <DropdownMenu
                ref="DropdownMenuMateriais"
                menuDropdown="materiais"
              />
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <router-link to="/materiaisedocumentos/avaliacao" class="dropdown-item">
                    <div class="navbar-box-mobile">
                    <div class="box-mobile-img">
                      <img src="@assets/img/App/Navbar/icone-oquee.webp" alt=""/>
                    </div>
                    <div class="box-mobile-text">
                      <h1 class="secao-titulo-dropdown">
                        O que é?
                      </h1>
                      <p class="secao-texto-dropdown">
                        Entenda a seção de Materiais e Documentos.
                      </p>
                    </div>
                  </div>
                </router-link>

                <router-link to="/materiaisedocumentos/instrumento" class="dropdown-item">
                    <div class="navbar-box-mobile">
                    <div class="box-mobile-img">
                      <img src="@assets/img/App/Navbar/icone-projeto.webp" alt=""/>
                    </div>
                    <div class="box-mobile-text">
                      <h1 class="secao-titulo-dropdown">
                        Instrumentos
                      </h1>
                      <p class="secao-texto-dropdown">
                        Entenda os instrumentos da plataforma e seu uso.
                      </p>
                    </div>
                  </div>
                </router-link>

                <div class="dropdown-item menu-disabled">
                    <div class="navbar-box-mobile">
                    <div class="box-mobile-img">
                      <img src="@assets/img/App/Navbar/icone-lupa.webp" alt=""/>
                    </div>
                    <div class="box-mobile-text">
                      <h1 class="secao-titulo-dropdown">
                        Procedimentos
                      </h1>
                      <p class="secao-texto-dropdown">
                        Em breve!
                      </p>
                    </div>
                  </div>
                </div>

                <a href="http://certificacao.observatorioei.org.br/" class="dropdown-item" target="_blank">
                    <div class="navbar-box-mobile">
                    <div class="box-mobile-img">
                      <img src="@assets/img/App/Navbar/icone-gerenciar.webp" alt=""/>
                    </div>
                    <div class="box-mobile-text">
                      <h1 class="secao-titulo-dropdown">
                        Certificação
                      </h1>
                      <p class="secao-texto-dropdown">
                        Certificação no uso dos instrumentos da plataforma.
                      </p>
                    </div>
                  </div>
                </a>

                <router-link to="/materiaisedocumentos/realizadas" class="dropdown-item">
                    <div class="navbar-box-mobile">
                    <div class="box-mobile-img">
                      <img src="@assets/img/App/Navbar/icone-mapa.webp" alt=""/>
                    </div>
                    <div class="box-mobile-text">
                      <h1 class="secao-titulo-dropdown">
                        Avaliações Realizadas
                      </h1>
                      <p class="secao-texto-dropdown">
                        Acompanhe as avaliações realizadas pela plataforma.
                      </p>
                    </div>
                  </div>
                </router-link>

              </div>
            </li>

            <li class="nav-item">
              <router-link to="/cursogestores" class="nav-link">
                Curso para gestores
              </router-link>
            </li>

            <li class="nav-item dropdown">
              <a @click="showDropdownMenuImplementacao" class="nav-link nav-item" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Implementação da avaliação
              </a>
              <DropdownMenu
                ref="DropdownMenuImplementacao"
                menuDropdown="implementacao"
              />
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">

                <a class="dropdown-item" href="https://play.google.com/store/apps/details?id=com.LEPES.EAPI" target="_blank">
                  <div class="navbar-box-mobile">
                    <div class="box-mobile-img">
                      <img src="@assets/img/App/Navbar/icone-projeto.webp" alt=""/>
                    </div>
                    <div class="box-mobile-text">
                      <h1 class="secao-titulo-dropdown">
                        Baixe o app
                      </h1>
                      <!-- <p class="secao-texto-dropdown">
                           Entenda a seção de Boas Práticas.
                           </p> -->
                    </div>
                  </div>
                </a>

                <div class="dropdown-item menu-disabled">
                  <div class="navbar-box-mobile">
                    <div class="box-mobile-img">
                      <img src="@assets/img/App/Navbar/icone-gerenciar.webp" alt=""/>
                    </div>
                    <div class="box-mobile-text">
                      <h1 class="secao-titulo-dropdown">
                        Base de dados e análises
                      </h1>
                      <p class="secao-texto-dropdown">
                        Em breve!
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </li>

            <!-- <li class="nav-item dropdown">
                 <router-link to="/faq" class="nav-link">
                 FAQ
                 </router-link>
                 </li> -->

            <li class="nav-item dropdown">
              <a @click="showDropdownMenuSobre" class="nav-link nav-item" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Sobre nós
              </a>
              <DropdownMenu
                ref="DropdownMenuSobre"
                menuDropdown="sobre"
              />
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">

                <router-link class="dropdown-item" to="/sobre/oquee">
                  <div class="navbar-box-mobile">
                    <div class="box-mobile-img">
                      <img src="@assets/img/App/Navbar/icone-oquee.webp" alt=""/>
                    </div>
                    <div class="box-mobile-text">
                      <h1 class="secao-titulo-dropdown">
                        O que é a plataforma?
                      </h1>
                      <p class="secao-texto-dropdown">
                        Entenda mais sobre o contexto da plataforma
                      </p>
                    </div>
                  </div>
                </router-link>

                <router-link class="dropdown-item" to="/faq">
                  <div class="navbar-box-mobile">
                    <div class="box-mobile-img">
                      <img src="@assets/img/App/Navbar/icone-lupa.webp" alt=""/>
                    </div>
                    <div class="box-mobile-text">
                      <h1 class="secao-titulo-dropdown">
                        FAQ
                      </h1>
                      <p class="secao-texto-dropdown">
                        Perguntas frequentes sobre a plataforma.
                      </p>
                    </div>
                  </div>
                </router-link>

                <a class="dropdown-item" href="http://lepes.fearp.usp.br/" target="_blank">
                  <div class="navbar-box-mobile">
                    <div class="box-mobile-img">
                      <img src="@assets/img/App/Navbar/icone-gerenciar.webp" alt=""/>
                    </div>
                    <div class="box-mobile-text">
                      <h1 class="secao-titulo-dropdown">
                        Contato
                      </h1>
                      <p class="secao-texto-dropdown">
                        Entre em contato conosco.
                      </p>
                    </div>
                  </div>
                </a>

              </div>
            </li>

            <li class="nav-item dropdown">
              <a @click="showDropdownMenuDados" class="nav-link nav-item" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Gestão da avaliação
              </a>
              <DropdownMenu ref="DropdownMenuDados" menuDropdown="dados" />
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <router-link class="dropdown-item" to="/implementacao/gestao">
                  <div class="navbar-box-mobile">
                    <div class="box-mobile-img">
                      <img src="@assets/img/App/Navbar/icone-acervo.webp" alt=""/>
                    </div>
                    <div class="box-mobile-text">
                      <h1 class="secao-titulo-dropdown">
                        Gestão da Avaliação
                      </h1>
                      <p class="secao-texto-dropdown">
                        Entenda a seção Gestão da Avaliação
                      </p>
                    </div>
                  </div>
                </router-link>

                <router-link class="dropdown-item" :class="{ 'menu-disabled': !isLogged }" to="/dados/gerenciar/instituicao">
                  <div class="navbar-box-mobile">
                    <div class="box-mobile-img">
                      <img src="@assets/img/App/Navbar/icone-gerenciar.webp" alt=""/>
                    </div>
                    <div class="box-mobile-text">
                      <h1 class="secao-titulo-dropdown">
                        Gerenciar Instituição
                      </h1>
                      <p class="secao-texto-dropdown">
                        Crie, edite ou visualize suas instituições.
                      </p>
                    </div>
                  </div>
                </router-link>
                <router-link class="dropdown-item" :class="{ 'menu-disabled': !isLogged }" to="/dados/gerenciar/grupos">
                  <div class="navbar-box-mobile">
                    <div class="box-mobile-img">
                      <img src="@assets/img/App/Navbar/icone-lupa.webp" alt=""/>
                    </div>
                    <div class="box-mobile-text">
                      <h1 class="secao-titulo-dropdown">Grupos de Pesquisa</h1>
                      <p class="secao-texto-dropdown">
                        Crie, edite ou visualize equipes de Pesquisa.
                      </p>
                    </div>
                  </div>
                </router-link>
                <!-- <a class="dropdown-item" href="/dados/exportardevolutiva">
                     <div class="navbar-box-mobile">
                     <div class="box-mobile-img">
                     <img src="@assets/img/App/Navbar/icone-mapa.webp" alt=""/>
                     </div>
                     <div class="box-mobile-text">
                     <h1 class="secao-titulo-dropdown">Exportar Devolutiva</h1>
                     <p class="secao-texto-dropdown">
                     Gere uma devolutiva a partir de dados externos. Saiba
                     Mais.
                     </p>
                     </div>
                     </div>
                     </a>
                     <a class="dropdown-item" href="/dados/baixardadosnacionais">
                     <div class="navbar-box-mobile">
                     <div class="box-mobile-img">
                     <img src="@assets/img/App/Navbar/icone-exportar.webp" alt=""/>
                     </div>
                     <div class="box-mobile-text">
                     <h1 class="secao-titulo-dropdown">
                     Baixar Dados Nacionais
                     </h1>
                     <p class="secao-texto-dropdown">
                     Baixe a base nacional de dados coletados pela nossa
                     equipe.
                     </p>
                     </div>
                     </div>
                     </a> -->
              </div>
            </li>

          </ul>

          <div class="div-logged" v-if="isLogged">
            <!-- <div class="notification-wrapper">
              <div class="notification-icon">
                <i class="fa fa-bell icon-login"></i>
              </div>
              <div class="red-circle"></div>
            </div> -->
            <span class="user-name">Seja bem-vindo,<span>{{ " " + getUserInfo }}</span></span>

            <i @click="showMiniDropdown()" class="logged-user-icon fa fa-user-circle icon-login ml-2"></i>

            <div class="dropdown-box" :class="{ 'dropdown-box-visible': isActiveMiniDropdown }">
              <div class="mini-dropdown">
                <!----------------- TRIÂNGULO DO POP-UP PEQUENO -------------------------------------------->
                <div class="mini-triangle">
                  <svg class="triangulo-1" width="26" height="30" viewBox="0 0 36 33" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.6699 2.5C15.5944 -0.833332 20.4056 -0.833334 22.3301 2.5L35.3205 25C37.245 28.3333 34.8394 32.5 30.9904 32.5H5.00962C1.16062 32.5 -1.24501 28.3333 0.679492 25L13.6699 2.5Z" fill="white"/>
                  </svg>
                </div>

                <!------------------ POP-UP PEQUENO --------------------------------------------------------->
                <div class="mini-pop-up shadow-lg pt-3 pr-1 pb-3 pl-2">
                  <div @click="loadProfile()" class="row-md-12 font-weight-light pl-0 pr-0 item-minidropdown" data-target="#Profile" data-toggle="modal">
                    <i class="fas fa-address-card mr-1"></i>
                    Perfil
                  </div>
                  <div @click="loadInvites()" class="row-md-12 font-weight-light pl-0 pr-0 item-minidropdown" data-target="#UserInvites" data-toggle="modal">
                    <i class="fas fa-envelope mr-1"></i>
                    Convites
                  </div>
                  <div @click="logout()" class="row-md-12 font-weight-light pl-0 pr-0 item-minidropdown">
                    <i class="fas fa-sign-out-alt mr-1"></i>
                    Sair
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="login-register-buttons">
            <p @click="openLogin" id="acesse">Entrar</p>
            <Button
              class="btn signButton"
              @click="openSignUp"
              section="main"
              value="Registrar"
            />
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import Button from "@components/Button";
import DropdownMenu from "@components/DropdownMenu";
import ModalLateral from "@components/ModalLateral";
import ModalCentral from "@components/ModalCentral";
import Swal from "sweetalert2";
import { Notyf } from "notyf";
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: "left",
    y: "bottom",
  },
  types: [
    {
      type: "info",
      background: "#18a0fb",
      icon: {
        className: "fa fa-info-circle",
        tagName: "i",
        color: "white",
      },
    },
  ],
});

export default {
  name: "Navbar",
  data() {
    return {
      isActiveMiniDropdown: false,
    };
  },
  components: {
    Button,
    DropdownMenu,
    ModalLateral,
    ModalCentral
  },
  async created() {
    this.$store.dispatch("setUserInfo");
  },
  computed: {
    isLogged() {
      return this.$store.getters.getIsLogged;
    },
    getUserInfo() {
      if (this.$store.getters.getUserInfo) {
        return this.$store.getters.getUserInfo?.data?.nome?.split(" ")[0];
      } else {
        return "Visitante";
      }
    },
  },
  methods: {
    loadProfile() {
      this.emitter.emit('loadDataProfile');
    },
    loadInvites() {
      this.$store.dispatch('setListUserRequests', {
        notiTypes: [ "INSTITUICAO_USUARIO", "GRUPO_USUARIO" ],
      })
    },
    openLogin() {
      this.$refs.ModalLogin.showModalLateral();
    },
    closeLogin() {
      this.$refs.ModalLogin.closeModalLateral();
    },
    openSignUp() {
      this.$refs.ModalSignUp.showModalLateral();
    },
    closeSignUp() {
      this.$refs.ModalSignUp.closeModalLateral();
    },
    showDropdownMenuQualidadeEI() {
      this.$refs.DropdownMenuQualidadeEI.getScreenSizeforOverlay();
      this.$refs.DropdownMenuQualidadeEI.showDropdownMenu();
    },
    showDropdownMenuMateriais() {
      this.$refs.DropdownMenuMateriais.getScreenSizeforOverlay();
      this.$refs.DropdownMenuMateriais.showDropdownMenu();
    },
    showDropdownMenuDados() {
      this.$refs.DropdownMenuDados.getScreenSizeforOverlay();
      this.$refs.DropdownMenuDados.showDropdownMenu();
    },
    showDropdownMenuImplementacao() {
      this.$refs.DropdownMenuImplementacao.getScreenSizeforOverlay();
      this.$refs.DropdownMenuImplementacao.showDropdownMenu();
    },
    showDropdownMenuSobre() {
      this.$refs.DropdownMenuSobre.getScreenSizeforOverlay();
      this.$refs.DropdownMenuSobre.showDropdownMenu();
    },
    showMiniDropdown: function () {
      this.isActiveMiniDropdown = true;
    },
    closeMiniDropdown: function () {
      this.isActiveMiniDropdown = false;
    },
    logout() {
      Swal.fire({
        title: "Tem certeza que deseja sair?",
        text: "Ao sair, todos os dados não salvos serão perdidos.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, tenho certeza",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$cookies
            .keys()
            .forEach((cookie) => this.$cookies.remove(cookie));
          this.$store.commit("setIsLogged", false);
          this.$store.dispatch("setUserInfo");
          this.$store.commit('setExpireCookieDate', null)
          this.$store.commit('setIsAlreadyShowedExpNotyf', false)
          notyf.open({
            type: "info",
            message: "Você efetuou logoff.",
          });
          this.$router.push("/");
        }
      });
      this.closeMiniDropdown();
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/_variables.scss";
/*
 |-----------------------------------------------------
 |--ESTILIZANDO A PRÓPRIA NAVBAR (TAMANHO HORIZONTAL,
 |--ITENS DO MENU, ETC)
 |-----------------------------------------------------
 */
.navbar {
  /* -webkit-box-shadow: 0px 10px 38px -12px rgba(96, 133, 236, 0.425);
     -moz-box-shadow: 0px 10px 38px -12px rgba(96, 133, 236, 0.425);
     box-shadow: 0px 10px 38px -12px rgba(96, 133, 236, 0.425); */
  background-image: url("~@/assets/img/Backgrounds/texturaNavbar.webp");
  background-repeat: repeat;
  background-size: 256px;
  border-bottom: 1px solid #65656552;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}

 @media screen and (min-width: 992px) {
   .navbar-nav.mr-auto {
     width: 100%;
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     gap: 5px;
   }

   .navbar-nav.mr-auto li {
     flex: 1;
   }
 }

#logo {
  max-height: 3.8rem;
}

#logo2 {
  max-height: 3.8rem;
  width: auto;
  cursor: pointer;
}

ul {
  font-size: 16px;
  text-transform: uppercase;
  color: #766565;
  font-weight: bold;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

ul li {
  margin: 0.5rem 0 0;
  cursor: pointer;
}

#ou {
  font-size: 16px;
  font-weight: bold;
  color: #635a5a;
  text-transform: uppercase;
  margin-bottom: 0;
  cursor: default;
}

#acesse {
  font-size: 16px;
  font-weight: bold;
  /* color: #18a0fb; */
  text-transform: uppercase;
  cursor: pointer;
  transition: all ease-in-out 0.26s;
  margin: 0;

  background-clip: text;
  text-fill-color: transparent;
  background-image: url("~@/assets/img/Backgrounds/azulEapi.webp");
  background-repeat: repeat;
  background-size: 256px;
  color: transparent;
}

.login-register-buttons {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 15px;
}

.arrow {
  display: inline-block;
  border-top: 6px solid #766565;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin-left: 5px;
  vertical-align: middle;
}

.nav-item {
  text-align: center;
}

.dropdown-menu {
  margin: 0rem 0rem 0.8rem 0rem;
  border: none;
  padding: 0.05rem;
  background-color: #e0e0e0;
}

.dropdown-menu .dropdown-item {
  background-color: #f8f7f7;
  padding: 0.4rem;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  text-transform: none;
  text-align: center;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link {
  transition: all ease-in-out 0.2s;
}

.div-logged {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.icon-login {
  color: rgb(124, 124, 124);
  cursor: pointer;
  font-size: 1.2rem;
}

.notification-wrapper:hover > .notification-icon .icon-login  {
  animation: bellAnimation 0.8s ease-in-out;
  transform-origin: center;
}

@keyframes bellAnimation {
  0% {
    transform: rotateZ(0deg);
  }
  25% {
    transform: rotateZ(25deg);
  }
  75% {
    transform: rotateZ(-25deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

.user-name {
  margin-left: 1.4rem;
  text-transform: none;
  font-size: 1.1rem;
  font-size: 1rem;
  color: #766565;
  cursor: default;
  user-select: none;
}

.user-name span {
  text-transform: capitalize;
  cursor: default;
  user-select: none;
}

.notification-wrapper {
  position: relative;
  width: 2rem;
  height: 2rem;
  background: white;
  border-radius: 100%;
  padding: 0.3rem;
  box-shadow: 1px 2px 6px -3px rgb(0 0 0 / 25%);
  cursor: pointer;
}

.red-circle {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(235, 67, 67);
  height: 0.55rem;
  width: 0.55rem;
  border-radius: 50%;
}

.notification-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -45%);
}

.logged-user-icon{
  font-size: 1.5rem;
  transition: all 0.3s ease;
}

.logged-user-icon:hover {
  color: #4b4646;
}

.overlay-invisible {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(22, 22, 22, 0);
  z-index: 2;
  transition: all ease-in-out 0.3s;
  opacity: 0;
  pointer-events: none;
}

.overlay-visible {
  opacity: 1;
  pointer-events: all;
  cursor: default;
}

.mini-triangle {
  margin: 0rem;
  display: center;
  align-items: center;
  justify-items: center;
  position: absolute;
  top: -16%;
  left: 67%;
}
.dropdown-box {
  position: absolute;
  top: 146%;
  right: -4%;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  cursor: default;
  transition: all 0.4s ease;
}

.dropdown-box-visible {
  opacity: 1;
  cursor: pointer;
  pointer-events: all;
}

.mini-dropdown {
  position: relative;
}

.mini-pop-up {
  height: 5.8rem;
  width: 6rem;
  background-color: white;
  color: grey;
  border-radius: 1rem;
  transition: color ease-in-out 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.item-minidropdown:hover {
  color: rgb(58, 58, 58);
}

/*-----------------------------------------------------*/

/*
 |-----------------------------------------------------
 |--ADICIONANDO PSEUDO-CLASSE :FOCUS PARA EXIBIR DROPDOWN
 |-----------------------------------------------------
 */
#acesse:hover {
  /* color: #1a85cc; */
  filter: brightness(1.3);
}
/*-----------------------------------------------------*/

/*
 |-----------------------------------------------------
 |--ADICIONANDO @MEDIA QUERIES
 |-----------------------------------------------------
*/
@media screen and (max-width: 991px) {
  #ou {
    margin: 0.4rem 0rem;
  }
}

@media screen and (min-width: 992px) {
  .dropdown-menu {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

/* @mMEDIA PARA RESPONSIVIDADE DO NAVBAR */
@media screen and (max-width: 993px) {
  .navbar-collapse{
    width: 100%;
  }
  
  .nav-item {
    text-align: left;
    width: 100%;
  }

  .navbar-collapse ul {
    align-items: flex-start;
  }

  .div-logged {
    margin-top: 1rem;
  }

  .navbar-box-mobile {
    display: grid;
    grid-template-columns: 4rem 1fr;
    gap: 1rem;
  }

  .box-mobile-text{
    justify-self: flex-start;
  }

  .box-mobile-img {
    align-self: center;
    max-width: 100%;
  }

  .box-mobile-img img{
    max-width: 3rem;
  }

  box-mobile-text {
    text-align: left;
  }

  .secao-titulo-dropdown {
    font-size: 1rem;
    font-weight: 800;
    color: #655d5d;
    text-align: left;
    margin: 0;
  }

  .secao-texto-dropdown {
    font-size: 0.9rem;
    color: #655d5d;
    margin: 0;
    white-space: initial;
    text-align: left;
  }
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgb(0 0 0 / 60%);
}

.sair-button {
  padding: 0.2rem 1.2rem;
  margin-left: 1rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 5px;
  background-color: white;
  border: 0.1rem solid #0d9f9f;
  color: #0d9f9f;
  cursor: pointer;
  outline: none;
  transition: all ease-in-out 0.3s;
}

.sair-button:hover {
  background-color: #0d9f9f;
  color: white;
}

.signButton {
     background-image: url("~@/assets/img/Backgrounds/azulEapi.webp");
     background-repeat: repeat;
     background-size: 256px;
     box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
 }
 :deep(.signButton div) {
     background-clip: text;
     text-fill-color: transparent;
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
 }

/*-----------------------------------------------------*/

 .navbarLogo {
   width: max-content;
   display: flex;
   text-transform: uppercase;
   color: rgba(0, 0, 0, 0.5);
   font-weight: bold;
   align-items: center;
   /* padding-right: 1em; */
   text-align: right;
 }

 .navbarLogo span {
   white-space: nowrap;
 }

 .logoSmaller {
   font-size: 0.8em;
 }

 .navbar > .container {
   max-width: 100%;
 }

 .menu-disabled {
     filter: grayscale(1);
     cursor: default;
     background-color: #ebebeb;
 }
</style>
