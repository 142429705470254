<!--
|---------------------------------------------
| Button Component 
|---------------------------------------------
| @usage Import the component and use <Button value='Button Text' section='Section Name'/>
| Sections main colors: 
|   main => #18A0FB
|   boas_praticas => #41B3A3
|   materiais_documentos => #EB7071
|   certificacao => #F498C0
|   dados =>  #0d9f9f;
|
|   outline: dados #0d9f9f w/ border
-->

<template>
  <button v-if="!isOutline && !isDisabled" :class="'button grow ' + section">
    {{ value }}
  </button>
  <button v-else-if="!isOutline && isDisabled" :class="'button grow ' + section" disabled>
    {{ value }}
  </button>
  <button
    v-else
    :class="['button-outline ' + section, { 'shadow-button': hasShadow }]"
    :disabled="isDisabled"
  >
    <div v-if="!isReverse">
      <i v-if="hasIcon" :class="'fas fa-' + iconName + ' mr-1'"></i>
      <span class="m-0">{{ value }}</span>
    </div>

    <div v-else>
      <span class="m-0">{{ value }}</span>
      <i v-if="hasIcon" :class="'fas fa-' + iconName + ' ml-1'"></i>
    </div>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    section: String,
    value: String,
    isOutline: Boolean,
    hasIcon: Boolean,
    hasShadow: Boolean,
    iconName: String,
    isReverse: Boolean,
    isDisabled: Boolean
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";
/* BOTAO OUTLINE */

.button-outline {
  border-radius: 4px;
  padding: 0.4rem 0.8rem;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  transition: all ease-in-out 0.2s;
}

.shadow-button {
  -webkit-box-shadow: 10px 10px 5px -1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 10px 10px 5px -1px rgba(0, 0, 0, 0.15);
  box-shadow: 6px 6px 5px -1px rgba(0, 0, 0, 0.15);
}

/* PADRAO DE CORES PARA BOTOES OUTLINE */
.dados-outline {
  border: 1px solid $verdeEscuro-eapi;
  color: $verdeEscuro-eapi;
  background-color: white;
}

.dados-outline:hover {
  color: white;
  background-color: $verdeEscuro-eapi;
}

.materiais-documentos-outline {
  border: 1px solid #EB7071;
  color: #EB7071;
  background-color: white;
}

.materiais-documentos-outline:hover {
  color: white;
  background-color: #EB7071;
}

.painel-outline {
  border: 1px solid $verdeEscuro-eapi;
  color: $verdeEscuro-eapi;
  background-color: white;
}

.painel-outline:hover {
  color: white;
  background-color: $verdeEscuro-eapi;
}

.instituicao-cadastrar {
  border: 1px solid #676bd6;
  color: #676bd6;
  background-color: white;
}

.instituicao-cadastrar:hover {
  color: white;
  background-color: #676bd6;
}

.instituicao-campo {
  border: 1px solid #7647ac;
  color: #7647ac;
  background-color: white;
}

.instituicao-campo:hover {
  color: white;
  background-color: #7647ac;
}

.instituicao-aplicacoes {
  border: 1px solid #ffac30;
  color: #ffac30;
  background-color: white;
}

.instituicao-aplicacoes:hover {
  color: white;
  background-color: #ffac30;
}

.descartar {
  border: 1px solid #d40707;
  color: #d40707;
  background-color: white;
}

.descartar:hover {
  color: white;
  background-color: #d40707;
}

.salvar {
  border: 1px solid #038b25b4;
  color: #038b25b4;
  background-color: white;
}

.salvar:hover {
  color: white;
  background-color: #038b25b4;
}

.instituicao-csv {
  border: 1px solid #2edb53;
  color: #2edb53;
  background-color: white;
}

.instituicao-csv:hover {
  color: white;
  background-color: #2edb53;
}

/* ----------------- BOTAO NORMAL ----------------- */

.button {
  color: white;
  max-height: 2.5rem;
  width: auto;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.6rem 0.6rem 0.6rem 0.6rem;
  outline: none;
  border: none;
  cursor: pointer;
}

.main {
  border-radius: 6px;
  background-color: #18a0fb;
  box-shadow: -1px 2px 10px 2px rgba(24, 160, 251, 0.3);
  transition: all ease-in-out 0.26s;
}

.main:hover {
  background-color: #1a85cc;
  box-shadow: -1px 2px 10px 2px rgba(61, 138, 189, 0.384);
}

.main-confirm {
  border-radius: 6px;
  background-color: rgb(137, 197, 87);
  box-shadow: -1px 2px 10px 2px rgba(137, 197, 87, 0.3);
  transition: all ease-in-out 0.26s;
}

.main-confirm:hover {
  background-color: rgb(103, 150, 64);
  box-shadow: -1px 2px 10px 2px rgba(139, 199, 90, 0.384);
}


.boas-praticas {
  border-radius: 20px;
  background-color: white;
  color: rgb(139, 199, 90);
  /* color: $verdeClaro-eapi; */
  transition: all ease-in-out 0.26s;
}

.boas-praticas:hover {
  /* background-color: #6C7E63; */
  color: white;
  background-color: rgb(139, 199, 90);
}

.materiais-documentos {
  border-radius: 20px;
  background-color: #74c5f6;
  /* background-color: #eb7071; */
  transition: all ease-in-out 0.26s;
}

.materiais-documentos:hover {
  /* background-color: #ac4242; */
  background-color: white;
  color: #74c5f6;
}

.certificacao {
  border-radius: 20px;
  /* background-color: $roxo; */
  color: #7C7C7C;
  background-color: white;
  transition: all ease-in-out 0.26s;
}

.certificacao:hover {
  /* background-color: #7B76BC; */
  background-color: #7C7C7C;
  color: white;
}

.dados {
  border-radius: 20px;
  /* background-color: $verdeEscuro-eapi; */
  background-color: #9f9cce;
  color: white;
  transition: all ease-in-out 0.26s;
}

.dados:hover {
  /* background-color: #154747; */
  color: #9f9cce;
  background-color: white;
}

button:disabled,
button[disabled] {
  background-color: #7c8185;
  box-shadow: -1px 2px 10px 2px rgba(136, 138, 139, 0.3);
  cursor: default;
  pointer-events: none;
}

button:disabled:hover,
button[disabled]:hover {
  background-color: #7c8185;
  box-shadow: -1px 2px 10px 2px rgba(136, 138, 139, 0.3);
  cursor: default;
  transform: none;
}
</style>
