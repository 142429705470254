<template>

    <div>
        <div>
            <SectionElement title="Entenda o instrumento EAPI"
                text="As Escalas de Avaliação dos Ambientes de Aprendizagem dedicados à Primeira
                    Infância/EAPIs (uma para crianças de 2 a 3 anos, outra para pré-escola) são uma
                    adaptação à realidade brasileira do instrumento MELE (“Avaliação dos Ambientes de
                    Aprendizagem”, tradução livre), módulo do instrumento MELQO (“Medindo Qualidade
                    e Resultados na Educação Infantil”, tradução livre), publicado pela Unesco, em parceria
                    com Unicef, Banco Mundial e Brookings Institution, no ano de 2017. "
                imageName="MateriaiseDocumentos/entenda-instrumento.png"
                :hasButton="true"
                section="materiais-documentos"
                buttonIcon="download"
                buttonText="Baixar Instrumento EAPI"
                direction="default"
                :hasShadow="true"
            />
            <SectionElement title="Como estudar para a certificação?"
                text="É importante estabelecer um cronograma diário de estudos e organizar sua rotina conforme
                    a necessidade. Entenda que para estudar de forma eficaz é necessário ter foco e disciplina.  
                    Reserve um tempo para a leitura no seu cronograma de estudos.Dedique meia hora a essa tarefa, 
                    já que leitura demanda concentração."
                imageName="MateriaiseDocumentos/como-estudar.png"
                :hasButton="true"
                section="materiais-documentos"
                buttonIcon="copy"
                buttonText="Acesse os materiais disponíveis"
                direction="reverse"
            />
            <SectionElement title="Sobre os materiais"
                text="Os materiais apresentam referências e dados pertinentes ao estudo da Qualidade da Educação
                    Infantil no Brasil, com o objetivo de auxiliar e impulsionar a pesquisa sobre a área. 
                    Os materiais apresentados são livres para consulta e estudo. "
                imageName="MateriaiseDocumentos/sobre.png"
                :hasButton="false"
                section="materiais-documentos "
                buttonIcon=""
                buttonText=""
                direction="default"
            />

        </div>

    </div>
</template>

<script>
import SectionElement from '@components/SectionElement';

export default {
    name: 'OQueE',
    components: {
        SectionElement
    }
}
</script>

<style scoped>
    
</style>