<template>
    <div class="container offset">
        <div class="custom-form">

            <p>Uma notificação será enviada ao Administrador do Grupo solicitando sua entrada.</p>
            <input 
                type="name"
                class="form-control input-personal"
                placeholder="Digite o nome da instituição..."
                v-model="searchByName"
                id="inlineFormCustomName"
                autocomplete="off"
            />

            <hr class="separator">

            <transition-group tag="div" v-if='filterGroup' class="" name="listanimate">
                <div v-for="info in filterGroup" :key="info.idGrupo">
                    <div class="institution-row-style">
                        <div>
                            <img class="img-style ml-1 mr-2" :src="require('@assets/img/Dados/ListarInstituicao/Instituicoes/instituicao1.jpg')" alt="Card image cap "/>
                            <label class="text-style"> {{ info.nome }} </label>
                        </div>
                        <Button 
                            @click="sendSolicitacao(info.idGrupo)"
                            section="painel-outline"
                            value="Enviar"
                            :isOutline="true"
                            :hasIcon="true"
                            iconName="paper-plane"
                            class="button-style"
                        />  
                    </div> 
                </div>
            </transition-group>

            <transition-group v-else>
                <p>Sem resultados.</p>
            </transition-group> 

        </div>   
    </div>
</template>

<script>

 import Button from '@components/Button'
 import Swal from 'sweetalert2';

 export default {
     name: "SearchGroup",
     data() {
         return {
             searchByName: '',
             infosData: [
                 // {
                 //     id: 1,
                 //     name: 'Anglo RJ'
                 // },
                 // {
                 //     id: 2,
                 //     name: 'Anglo VV'
                 // },
                 // {
                 //     id: 3,
                 //     name: 'LEPES RP'
                 // }
             ]
         }
     },
     components: {
         Button
     },
     async created() {
         this.$store.dispatch('setListGroups')
         this.$store.dispatch('setListAllGroups')
     },
     computed: {
         filterGroup() {
             //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
             var curIds = this.$store.getters?.getListGroups?.map(group => group.idgrupo),
                 allTeams = this.$store
                                .getters
                                ?.getListAllGroups
                                ?.filter(group =>
                                    !curIds.includes(group?.idGrupo)
                                );

             var query = allTeams
             query = query?.sort((a, b) => a?.nome?.localeCompare(b?.nome));


             if (this.searchByName.trim() == '')
                 query = allTeams
             else
                 query = allTeams?.filter(team => team?.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))

             return query;
         }
     },
     methods: {
         async sendSolicitacao(idGroup) {
             console.log(idGroup)
             const payload = {
                 idGrupo: idGroup
             }
             await this.$store.dispatch('setNotifyUserJoinGrupo', payload)

             if (await this.$store.getters.getIsSentRequestUserGroup) {
                 Swal.fire(
                     'Solicitação enviada!',
                     // 'Você pode conferir o andamento das suas solicitações em Usuário -> Solicitações, no canto superior direito. ',
                     'Aguarde o administrador ou os coordenadores do grupo responderem sua solicitação.',
                     'success'
                 )
             } else {
                 Swal.fire(
                     'Atenção!',
                     'Você já enviou uma solicitação para essa instituição. Aguarde sua solicitação ser respondida para fazer outra novamente.',
                     'warning'
                 )
             }


         }
     },

 }

</script>

<style scoped>

 .container{
     padding: 2%;
     width: 100%;
     background-color: white;
 }

 .title-style{
     font-size: 1.5rem;
     font-weight: 600;
     color: #656565;
 }

 .image-style{
     display: block;
     margin-left: auto;
     margin-right: auto;
     width: 50%;
     padding: 1rem;
 }

 .button-div{
     text-align: right;
 }

 .institution-row-style{
     border-bottom: 1px solid #ddd;
     margin: 0;
     height: 3rem;
     padding: 0;
     text-align: left;
     display: flex;
     justify-content: space-between;
 }

 .img-style{
     height: 2.5rem;
     border-radius: 5rem;
     width: 2.6rem;
 }

 .text-style{
     padding-top: 0.8rem;
     font-weight: 500;
 }

 .separator {
     border: none;
     height: 1px;
     margin: 1 0 0 0;
     margin-bottom: 0;
     background-color: rgba(51, 51, 51, 0.178);
 }

 .button-style {
     height: 2.2rem;
     margin-top: 0.35rem;
 }

 .input-personal {
     padding: 0.2rem 0.4rem;
     font-size: 15px;
     font-weight: 400;
 }

 .select-personal, .input-personal {
     border-radius: 8px;
     background: #FCFCFC;
     outline: none;
     border: 1px solid rgba(0, 0, 0, 0.11);
     font-weight: lighter;
     padding: 0.2rem 0.4rem;
     box-shadow: 1px 2px 6px -3px rgb(0 0 0 / 25%);
 }

</style>
