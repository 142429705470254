<template>

    <div class="bg">
        <div class="list-data">

            <!-- <ModalCentral
                 componentName=""
                 title="ADICIONAR FORMULÁRIO"
                 idName="AddFormulario"
                 /> //* TODO: descomentar quando o gerador estiver pronto
            -->

            <div class="title-tree">
                <Breadcrumb :crumbs="crumbs" section="instituicao-cadastrar"/>
            </div>

            <InstitutionInfo class="title-style" :title="nomeInstituicao" section="instituicao-cadastrar"/>

            <p class="text-info-personal imgText">Nesta seção, você encontrará todos os formulários da Instituição. Caso necessário, utilize o filtro abaixo para localizar o formulário desejado.</p>

            <div class="itens-box">
                <div class="filter-box">
                    <!-- <pre>{{ teste }}</pre> -->
                    <!-- INICIO DO FORM DE FILTRAGEM -->
                    <form @submit.prevent="" class="form-inline">
                        <div class="form-group">

                            <!-- DROPDOWN DE SELECAO DE FILTRO -->
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomSelect">Filtrar por: </label>
                            <Filter ref="filtersearchname" @change="setSelectedValue()" :optionsSelect="options" nameSelect="filtro_instituicoes" id="inlineFormCustomSelect"/>
                        </div>

                        <!-- SE A SELECAO FOR NOME (2) ENTAO EXIBIRA OS SUBFILTROS DE NOME -->
                        <div class="form-group" v-if="selectedValue == 2">
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomName">Digite o nome: </label>
                            <input class="input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_formularios" id="inlineFormCustomName" autocomplete="off">
                        </div>

                    </form>
                </div>
                <div class="lista-data-info">

                    <!-- <Button
                         class="btn"
                         section="instituicao-cadastrar"
                         value="Adicionar Formulários"
                         :isOutline="true"
                         :hasIcon="true"
                         iconName="file-alt"
                         data-toggle="modal"
                         data-target="#modalCentralExemplo"
                         /> -->
                </div>
            </div>
            <hr class="separator">

            <!-- <div class="list-box">
                 <ListagemElements :infos="formularios" listaType="addForms"/>
                 </div> -->

            <!-------------------------------- LISTANDO OS FORMULÁRIOS ----------------------------------------------->

            <div v-if="isLoadedInfo">
                <MemberQnt term="formulários" :totalQnt="totalFormularios" :filteredQnt="formularios?.length || 0" icon="fal fa-file-alt" />

                <transition-group v-if="formularios?.length > 0" name="slide-fade">
                    <div class="box-header">
                        <span></span>
                        <span>Nome</span>
                        <span>Modelo</span>
                    </div>

                    <div class="box-lista" v-for="formulario in formularios" :key="formulario.idformulario">
                        <div>
                            <img  class="img-info-box-lista" src="@assets/img/Cards/Documento.svg" alt="">
                        </div>
                        <span>{{formulario.nome}}</span>
                        <span>{{formulario.modelo}}</span>
                        <!-- <span class="third-info">Editar Formulário</span> -->
                        <!-- <span class="last-info">Excluir Formulário</span> -->
                    </div>
                </transition-group>
                <transition-group v-else>
                    <h2 class="title-style-box-lista">Nenhum formulário encontrado.</h2>
                </transition-group>

            </div>

            <div v-else>
                <SkeletonList/>
            </div>

        </div>
    </div>

</template>

<script>
 import Filter from '@components/Filter'
 // import Button from '@components/Button'
 // import ListagemElements from '@components/Dados/VisualizarInstituicao/ListagemElement'
 // import ModalCentral from '@components/ModalCentral' //* TODO: descomentar quando o gerador estiver pronto
 import Breadcrumb from '@components/Breadcrumb'
 import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
 import SkeletonList from '@components/SkeletonUI/SkeletonList'
 import MemberQnt from '@dados/VisualizarInstituicao/MemberQnt'

 export default {
     name: 'ListarFormularios',
     data () {
         return {
             nomeInstituicao: '',
             descricao: '',
             selectedValue: '',
             searchByName: '',
             isLoadedInfo: false,
             teste: [],
             options: [
                 {
                     id:1,
                     value: 'Todos'
                 },
                 {
                     id:2,
                     value: 'Nome'
                 }
             ],
             crumbs: [
                 {
                     id:1,
                     value: '',
                     iconName: 'th',
                     name_route: 'GerenciarInstituicoes'
                 },
                 {
                     id:2,
                     value: 'Instituição',
                     iconName: 'university',
                     name_route: 'InstituicaoMenu'
                 },
                 {
                     id:3,
                     value: 'Cadastrar Informações',
                     iconName: 'search-plus',
                     name_route: 'InstituicaoMenuCadastrar'
                 },
                 {
                     id:4,
                     value: 'Formulários',
                     iconName: 'file-alt',
                     name_route: ''
                 }
             ]
         }
     },
     async created() {
         this.teams = this.$store.getters.getListTeams
         var idInstituicao = this.$route.params.idInstituicao

         this.isLoadedInfo = false
         await this.$store.dispatch('setListFormularios', idInstituicao)
         this.isLoadedInfo = true
     },
     computed: {
         totalFormularios() {
             return this.$store.getters?.getListFormularios.length || 0;
         },
         formularios() {
             var formularios = this.$store.getters.getListFormularios

             // VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
             var query = formularios;
             //CASO SEJA SELECIONADO TODOS, EXIBE TODOS OS ITENS DO ARRAY
             if (this.selectedValue == 1) {
                 query = formularios;
             }
             //CASO SEJA SELECIONADO NOME, FILTRA POR NOME
             if (this.selectedValue == 2) {
                 //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
                 if (this.searchByName.trim() == '') {
                     query = formularios;
                 }
                 //SENAO ELE FILTRA PELO O QUE FOR DIGITADO
                 else {
                     var filtered = formularios?.filter(formulario =>formulario.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
                     // console.log(filtered);
                     if(filtered?.length != 0){
                         query = filtered
                     }

                     else{
                         query = false
                     }
                 }
             }
             // console.log(this.query)
             return query;
         }
     },
     components: {
         Filter,
         // Button,
         // ListagemElements,
         Breadcrumb,
         InstitutionInfo,
         SkeletonList,
         MemberQnt,
         //ModalCentral //* TODO: descomentar quando o gerador estiver pronto
     },
     methods: {
         setSelectedValue: function() {
             this.selectedValue = this.$refs.filtersearchname.value
         }

     },
     mounted: function() {
         window.scrollTo(0, 0);
         let idTeamSelected = this.$route.params.idInstituicao

         if (typeof idTeamSelected == "undefined"){
             this.nomeInstituicao = "Nome da Instituição"
         }
         else {
             let teamsSelected
             let teamSelected

             teamsSelected = this.teams.filter(team =>
                 team.idinstituicao == idTeamSelected
             )

             teamSelected = [...teamsSelected].shift()

             this.nomeInstituicao = teamSelected.name
             this.descricao = teamSelected.descricao
         }
     }
 }
</script>

<style scoped>
 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;
 }

 .imgText {
     background-image: url("~@/assets/img/Backgrounds/azulEapi.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .box-lista {
     background-repeat: repeat;
     background-size: 256px;
     background-image: url("~@/assets/img/Backgrounds/azulMaisClaro.webp");
     box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
     border: 1px solid #952713;
 }

 :deep(.box-lista span) {
     font-size: 1.1em;
 }

 .box-lista span {
     background-image: url("~@/assets/img/Backgrounds/marromEscuro.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .text-info-personal {
     color: #656565;
     font-size: 1.1rem;
     text-align: justify;
     padding: 0rem;
     margin: 0 3rem 0 3rem;
 }

 .list-data {
     min-height: 90vh;
     padding-top: 2rem;
 }
 .label-filtro {
     font-weight: 600;
 }

 .filter-box {
     margin: 1rem 3rem;
 }

 .list-box {
     margin: 2rem;
 }

 .itens-box {
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .title-tree {
     margin: 1rem 3rem;
     font-weight: bold;
     display: flex;
 }

 .lista-data-info {
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;
     margin: 2rem 3rem;
 }

 .title-style{
     margin: 0 0 0 3rem;
 }

 .title-tree h1 {
     font-size: 1.2rem;
     font-weight: 700;
 }

 .main-link {
     outline:none;
     text-decoration: none;
     cursor: pointer;
     margin-right: .3rem;
 }

 .sub-link {
     margin-left: .3rem;
 }

 .separator {
     border: none;
     height: 1px;
     margin: 0.8rem 3rem;
     background: url("~@/assets/img/Backgrounds/azulEapi.webp");
 }

 a {
     color: #212529;
     text-decoration: none;
     transition: all ease-in-out 0.2s
 }

 a:hover {
     color: #4d4f50;
 }

 @media screen and (max-width: 1010px) {
     .lista-data-info {
         justify-content: center;
     }
 }


 @media screen and (max-width: 1012px) {
     .filter-box {
         display: flex;
         align-items: center;
         justify-content: center;
     }
 }

 @media screen and (min-width: 901px) {
     .first-info {
         flex-basis: 35%;
         text-align: left;
     }
 }

 @media screen and (max-width: 680px) {
     .itens-box{
         flex-direction: column;
         align-items: flex-start;
     }
     .filter-box{
         margin: 0 3rem;
     }
     .lista-data-info{
         margin: 0 3rem;
     }
     .first-info {
         text-align: center;
         width: -webkit-fill-available;
     }
     .box-lista span, .box-lista a{
         width: 100%;
         text-align: center;
         margin-top: .5rem;
     }
     .box-lista{
         place-content: center;
     }
 }

 .img-info-box-lista {
     padding: 10px;
 }

</style>
