<template>
    <h2 v-if="isLogged" class="erro-cadastro">
        Você já está registrado no sistema. Caso esteja tentando registrar um novo usuário,
        por favor saia da conta atual antes.
    </h2>
    <h2 v-else-if="!isValid" class="erro-cadastro">
        Erro na URL de cadastro dada. Por favor confira o link recebido por email e tente novamente.
    </h2>
    <form v-else class="formulario-confirmacao" @submit.prevent="" autocomplete="off">
        <h2 class="title-info">Confirmação de cadastro</h2>
        <div class="form-group">
            <label for="email">Email</label>
            <input type="text"
                   v-model.trim="formData.email"
                   class="form-control"
                   id="email"
                   readonly
                   placeholder="Email dado..." required />
        </div>
        <div class="form-group">
            <label for="email">Nome</label>
            <input type="text"
                   v-model.trim="formData.nome"
                   class="form-control"
                   id="nome"
                   placeholder="Insira seu nome..." required />
        </div>

        <div class="form-group">
            <label for="dtnasc">Data de Nascimento</label>
            <input
                v-model="formData.dtnasc"
                type="date"
                class="form-control"
                id="dtnasc"
                name="dtnasc"
                placeholder="Insira sua data de nascimento..."
                required/>
        </div>

        <label for="genero">Gênero</label>
        <div class="form-inline">
            <div class="form-check mr-3">
                <input v-model="formData.genero" class="form-check-input" type="radio" name="genero" id="masculino" value="MASCULINO" required>
                <label class="form-check-label">Masculino</label>
            </div>

            <div class="form-check mr-3">
                <input v-model="formData.genero" class="form-check-input" type="radio" name="genero" id="feminino" value="FEMININO">
                <label class="form-check-label">Feminino</label>
            </div>

            <div class="form-check">
                <input v-model="formData.genero" class="form-check-input" type="radio" name="genero" id="outro" value="OUTRO">
                <label class="form-check-label">Outro</label>
            </div>
        </div>

        <br/>

        <div class="form-group">
            <label for="profissao">Profissão</label>
            <input v-model.trim="formData.profissao" type="text" class="form-control" id="profissao" placeholder="Insira sua profissão..." required/>
        </div>

        <div class="form-group">
            <label for="organizacao">Organização</label>
            <input v-model.trim="formData.organizacao" type="text" class="form-control" id="organizacao" placeholder="Insira a organização que você participa..." required/>
        </div>

        <div class="form-group">
            <label for="atuacao">Atuação</label>
            <input v-model.trim="formData.atuacao" type="text" class="form-control" id="atuacao" placeholder="Insira sua área de atuação..." required/>
        </div>

        <div class="double-grid">
            <div class="form-group">
                <label for="estado">Estado</label>
                <select
                    @change="getCities()"
                    v-model="formData.estado"
                    class="form-control"
                    id="estado"
                    name="estado"
                    required>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                </select>
            </div>

            <div class="from-group">
                <label class="text-align-left" for="cidade">Cidade</label>
                <select v-model="formData.cidade" class="form-control" id="cidade" required>
                    <option
                        v-for="city in cities"
                        :key="city.idCidade"
                        :value="city.nome">
                        {{city.nome}}
                    </option>
                </select>
            </div>
        </div>

        <div class="double-grid">
            <div class="form-group">
                <label for="senha">Senha</label>
                <input v-model="formData.senha" type="password" class="form-control" id="senha" placeholder="Insira uma senha..." required/>
            </div>

            <div class="form-group">
                <label for="senha-repeat">Repita a senha</label>
                <input v-model="formData.senha_repeat" type="password" class="form-control" id="senha_repeat" placeholder="Repita a senha..." required/>
            </div>
        </div>

        <span v-if="!isEqualPassword" class="text-alert-match-passwords"><b>As senhas precisam ser iguais.</b></span>
        <span v-if="senhaDigitada && !validateSenha" class="text-alert-match-passwords">
            <ul>A senha precisa ter:
            <li :class="{'green-text': thereIsLower}">Pelo menos um caractere minúsculo</li>
            <li :class="{'green-text': thereIsUpper}">Pelo menos um caractere maiúsculo</li>
            <li :class="{'green-text': thereIsSpecial}">Pelo menos um caractere especial</li>
            <li :class="{'green-text': thereIsNumber}">Pelo menos um caractere numérico</li>
            <li :class="{'green-text': minimumLength}">Pelo menos oito caracteres</li>
            </ul>
        </span>

        <br/>

        <div class="d-flex flex-column justify-content-center align-items-center" v-if="contaCriada">
            <img class="img-success mb-3" src="../assets/img/check.png">
            <h1 class="success-msg-h1">Parabéns, sua conta foi criada!</h1>
            <span class="success-msg-span text-justify">
                Um link para validação da conta foi enviado para seu email.
                Para poder acessar a plataforma, é <b>obrigatório a validação da conta.</b>
            </span>
            <span @click="resendVerificationMail" class="link-resend-email" v-if="!jaReenviado">Não recebeu o email? Clique aqui para reenviar.</span>
            <span class="feedback-msg-verification-mail" v-else>O email foi enviado novamente!</span>
        </div>

        <Button
            v-else
            class="confirmar-conta"
            @click="setSignUp()"
            type="submit"
            value="Confirmar"
            :hasIcon="true"
            iconName="check"
            :isOutline="true" />
    </form>
</template>

<script>
import Button from '@components/Button';
import Regex from "@middleware/regex"
import { Notyf } from "notyf";
const notyf = new Notyf({
     duration: 5000,
     position: {
         x: "left",
         y: "bottom",
     },
});

export default {
    name: 'UserConfirmation',
    components: {
        Button
    },
    data() {
        return {
            token: this.$route.query.token || null,
            email: this.$route.query.email || null,
            criadoSucesso: false,
            resent: false,
            formData: {
                email: this.$route.query.email,
                nome: "",
                dtnasc: "",
                genero: "",
                profissao: "",
                organizacao: "",
                atuacao: "",
                senha: "",
                senha_repeat: ""
            }
        }
    },
    computed: {
        /**
        * Retorna true se o campo da senha não está vazio.
        */
        senhaDigitada() {
            return this.formData.senha.length != 0;
        },

        jaReenviado() {
            return this.resent;
        },

        /**
        * Retorna true se a senha digitada contemplar as diretrizes da senha
        * minímo: 1 minúsculo, 1 maiúsculo, 1 caracter especial, 1 caracter numérico, e 8 caracteres.
        */
        validateSenha() {
            return new RegExp(Regex('validatePwd')).exec(this.formData.senha) ? true : false;
        },

        /**
            * Retorna true se as senhas digitadas forem iguais de acordo com a regra de senha
            */
        isEqualPassword(){
            return this.formData.senha === this.formData.senha_repeat;
        },

        /**
        * Retorna true se a senha digitada contém pelo menos um caracter minúsculo
        */
        thereIsLower() {
            return new RegExp(Regex('lower')).exec(this.formData.senha) ? true : false;
        },

        /**
        * Retorna true se a senha digitada contém pelo menos um caracter maiúsculo
        */
        thereIsUpper() {
            return new RegExp(Regex('upper')).exec(this.formData.senha) ? true : false;
        },

        /**
        * Retorna true se a senha digitada contém pelo menos um caracter numérico
        */
        thereIsNumber() {
            return new RegExp(Regex('number')).exec(this.formData.senha) ? true : false;
        },

        /**
        * Retorna true se a senha digitada contém pelo menos um caracter especial
        */
        thereIsSpecial() {
            return new RegExp(Regex('special')).exec(this.formData.senha) ? true : false;
        },

        /**
        * Retorna true se a senha digitada contém pelo menos uma quantidade predefinida de caracteres
        */
        minimumLength() {
            return new RegExp(Regex('length')).exec(this.formData.senha) ? true : false;
        },

        isLogged() {
             return this.$store.getters.getIsLogged;
        },

        isValid() {
            return this.email !== null && this.token !== null;
        },

        contaCriada() {
            return this.criadoSucesso;
        },

        /**
        * Retorna todas as cidades atuais
        */
        cities(){
            return this.formData.estado != '' ? this.$store.getters.getCidades : [];
        },
    },
    methods: {
        /**
         * Obtém todas as cidades com base no estado selecionado
         */
        async getCities() {
            await this.$store.dispatch('setCidades', this.formData.estado);
            this.formData.cidade = '';
        },

        /**
        * Reenvia email de confirmação caso necessário
        */
        async resendVerificationMail() {
            await this.$store.dispatch('setResendVerificationMail', this.formData.email)
        },

        /**
        * Envia os campos preenchidos do formulário para API, solicitando a varificação
        */
        async setSignUp(){
            const payload = {
                token: this.token,
                ...this.formData
            };
            await this.$store.dispatch('setFinishRegistration', payload);
            const checkRegistration = await this.$store.getters.getIsUserVerified;
            if (checkRegistration) {
                this.$store.commit('setResendVerificationMail', null);
                notyf.open({
                    type: "success",
                    message: "Conta criada com sucesso!"
                });
                this.criadoSucesso = true;
            } else {
                notyf.open({
                    type: "error",
                    message: "Erro na criação da conta, por favor tente novamente."
                });
            }
        }
    }
}
</script>

<style scoped>
.title-info {
    font-weight: bold;
    font-size: 2em;
    display: block;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    padding-top: 10px;
}

.formulario-confirmacao {
    padding: 0rem 2rem 2rem 2rem;
    width: 100%;
}

.confirmar-conta {
    margin: 0 auto;
    display: block;
}

.invite-text {
    width: 90%;
    margin: 0 auto;
    font-size: 1.5em;
    text-align: center;
}

.text-alert-match-passwords {
    color: rgb(224, 53, 53);
    font-weight: 100;
    font-size: 0.9rem;
}

.double-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
}

.green-text {
    color: rgb(104, 158, 60);
}

.erro-cadastro {
    padding-top: 10px;
    display: block;
    width: calc(100% - 20px);
    margin: 0 auto;
    text-align: center;
}

.success-msg-h1 {
    font-size: 1.4rem;
}

.success-msg-span {
    font-size: 1rem;
    line-height: 1.4rem;
    color: rgb(90, 88, 88);
}

.feedback-msg-verification-mail {
    font-size: 0.8rem;
}

.link-resend-email {
    margin-top: 1rem;
    color: #18a0fb;
    text-decoration: underline;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
}

.img-success {
    max-width: 3em;
}
</style>
