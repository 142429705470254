<template>
    <svg class="triangulo-1" width="36" height="33" viewBox="0 0 36 33" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.6699 2.5C15.5944 -0.833332 20.4056 -0.833334 22.3301 2.5L35.3205 25C37.245 28.3333 34.8394 32.5 30.9904 32.5H5.00962C1.16062 32.5 -1.24501 28.3333 0.679492 25L13.6699 2.5Z" fill="white"/>
    </svg>
</template>

<script type="text/javascript">
 export default {
     name: "DisplayTriangle",
 };
</script>

<style type="text/css" media="screen" scoped>
 .triangulo-1 {
     position: absolute;
     left: 30px;
     top: -18px;
 }
</style>
