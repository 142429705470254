<template>
    <div class="bg">
        <h2 class="emBreve">
            Em breve...
        </h2>
    </div>
</template>

<script type="text/javascript">
 export default {
 };
</script>

<style type="text/css" media="screen" scoped>
 .bg {
     padding: 1rem 1rem;
     width: 100%;
     background: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     min-height: 85vh;
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .emBreve {
     display: block;
     font-style: italic;
     background-clip: text;
     text-fill-color: transparent;
     background-image: url("~@/assets/img/Backgrounds/marsala.webp");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
 }
</style>
