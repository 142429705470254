//---------------------------------------------
// Modal Lateral Module 
//---------------------------------------------
// Módulo para gerenciar as ações do Modal Lateral

// STATES
const state = () => ({
    isActiveModalLateralLogin: true,
    isActiveModalLateralSignUp: true,
})

// MUTATIONS
const mutations = {
    //-- MUTATION PARA ALTERAR ESTADO DO MODALLATERAL
    setIsActiveModalLateralLogin(state, payload) {
        state.isActiveModalLateralLogin = payload
    },

    //-- MUTATION PARA ALTERAR ESTADO DO MODALLATERAL
    setIsActiveModalLateralSignUp(state, payload) {
        state.isActiveModalLateralSignUp = payload
    },
}

// ACTIONS
const actions = {

}

// GETTERS
const getters = {
    getIsActiveModalLateralLogin(state) {
        return state.isActiveModalLateralLogin
    },

    getIsActiveModalLateralSignUp(state) {
        return state.isActiveModalLateralSignUp
    },
}

export default {
    state,
    mutations,
    actions,
    getters
}
