<template>

    <div class="modal fade" :id="idNum" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title titulo-modal"></h5>
                <div data-dismiss="modal" class="close-container">
                    <div class="leftright"></div>
                    <div class="rightleft"></div>
                </div>                
            </div>
            <div class="modal-body">
                <div class="pdf-style">
                    <embed                        
                        :src="pdfLink"
                        type="application/pdf"
                        width="100%"
                        height="100%"
                    />                    
                </div>
            </div>
            </div>
        </div>
    </div> 
        
</template>

<script>

export default {
    name: "PDFModal",
    data() {
        return {
            linkPDF: this.pdfLink,
            idNum: this.id,
        }
    },
    props: {
        pdfLink: String,
        id: String
    }
}

</script>

<style scoped>

    .container{
        overflow: hidden;
        height: 100%;
    }

    .pdf-style{
        height: 100%;
    }

    .modal {
        padding-right: 0px !important;
    }

    .modal-dialog {
        max-width: 1000px;
    }

    .modal-content{
        width: 60rem;
        height: 40rem;
    }

    .titulo-modal{
        margin: 0;
    }

    .close-container{
        position: relative;
        margin-right: 0;
        width: 30px;
        height: 30px;
        cursor: pointer;
    }

    .leftright{
        height: 2.5px;
        width: 20px;
        position: absolute;
        margin-top: 12px;
        background-color: #414141;
        border-radius: 2px;
        transform: rotate(45deg);
        transition: all .3s ease-in;
    }

    .rightleft{
        height: 2.5px;
        width: 20px;
        position: absolute;
        margin-top: 12px;
        background-color: #414141;
        border-radius: 2px;
        transform: rotate(-45deg);
        transition: all .3s ease-in;
    }

    .close-container:hover .leftright{
        transform: rotate(-45deg);
        background-color: #F25C66;
    }
    .close-container:hover .rightleft{
        transform: rotate(45deg);
        background-color: #F25C66;
    }

    .modal-body{
        overflow: overlay;
    }
    .modal-body::-webkit-scrollbar {
        width: 0.5rem;
    }
    
    .modal-body::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
        border-radius: 10px;
    }
    
    .modal-body::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: var(--dark); 
    }
</style>
