<template>
    <form @submit.prevent clas="container offset">
        <label class="question-style" for="justification">Justificativa:</label>
        <textarea v-model="justif" name="justification" rows="4" tabindex="-1" />

        <Button
            @click="leave"
            section="dados-outline"
            :value="`Sair do grupo`"
            :isOutline="true"
            :hasIcon="true"
            iconName="house-leave"
        />
    </form>
</template>

<script>
 import Button from '@components/Button';
 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: "left",
         y: "bottom",
     },
 });

 export default {
     name: "LeaveGroup",
     components: {
         Button,
     },
     props: {
         data: {
             type: Object,
             required: true
         }
     },
     data() {
         return {
             justif: "",
         };
     },
     methods: {
         async leave() {
             await this.$store.dispatch('leaveGroup', {
                 idGrupo: this.data.idGrupo,
                 justification: this.justif,
             });

             if (this.$store.getters.getLeftGroup) {
                 notyf.open({
                     type: "success",
                     message: "Você saiu do grupo efetivamente"
                 });
             } else {
                 notyf.open({
                     type: "error",
                     message: "Erro ao sair do grupo, tente novamente"
                 });
             }
         },
     }
 };
</script>

<style scoped>
 .container{
     padding: 1rem;
     width: 100%;
     background-color: white;
     margin: 0;
 }

 .question-style{
     width: 100%;
     font-size: 1rem;
     color: #656565;
 }

 textarea {
     width: calc(100% - 20px);
     margin: 0 auto;
     display: block;
     border: 0;
     border-radius: 8px;
     padding: 10px;
     box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.4);
     resize: none;
     width: 100%;
     margin-bottom: 10px;
     cursor: pointer;
 }
</style>
