<template>

    <div class="bg">
      <ModalCentral
        componentName="UpdateTurma"
        title="Editar turma"
        idName="updateTurma"
        :data="{ idTurma: turmaEditing, }"
        />

        <div class="list-data">

            <div class="title-tree">
                <Breadcrumb :crumbs="crumbs" section="instituicao-campo"/>
            </div>

            <InstitutionInfo class="title-style" :title="nomeCampo" section="instituicao-cadastrar"/>

            <p class="text-info-personal">Nesta seção, você encontrará todas as Turmas pertencentes à Unidade Educacional. Caso necessário, utilize o filtro abaixo para localizar a Turma desejada.</p>

            <div class="itens-box">
                <div class="filter-box">
                    <!-- <pre>{{ turmas }}</pre> -->
                    <!-- INICIO DO FORM DE FILTRAGEM -->
                    <form @submit.prevent="" class="form-inline">
                        <div class="form-group">

                            <!-- DROPDOWN DE SELECAO DE FILTRO -->
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomSelect">Filtrar por: </label>
                            <Filter ref="filtersearchname" @change="setSelectedValue()" :optionsSelect="options" nameSelect="filtro_instituicoes" id="inlineFormCustomSelect"/>
                        </div>

                        <!-- SE A SELECAO FOR NOME (2) ENTAO EXIBIRA OS SUBFILTROS DE NOME -->
                        <div class="form-group" v-if="selectedValue == 2">
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomName">Digite o nome: </label>
                            <input class="input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_instituicoes" id="inlineFormCustomName" autocomplete="off">
                        </div>

                    </form>
                </div>
                <div v-if="userPerm.permissao != 'APLICADOR'" class="lista-data-info">
                    <Button
                        v-if="campoStatus != 'ENCERRADO'"
                        @click="setSelectAllItems"
                        class="btn btnCtrl"
                        section="instituicao-campo"
                        value="Marcar Todos"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="check-square"
                    />
                    <Button
                        v-if="campoStatus != 'ENCERRADO'"
                        @click="setUnselectAllItems"
                        class="btn ml-2 btnCtrl"
                        section="instituicao-campo"
                        value="Desmarcar Todos"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="square"
                    />
                    <Button
                        v-if="campoStatus != 'OFF'"
                        @click="applyChanges"
                        class="btn ml-2 btnCtrl"
                        section="instituicao-campo"
                        value="Aplicar Alterações"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="check"
                    />
                </div>
            </div>
            <hr class="separator">

            <!-------------------------------- LISTANDO AS TURMAS DOS CAMPOS --------------------------------------------->

            <div v-if="isLoadedInfo">
                <MemberQnt term="turmas" :totalQnt="totalTurmas" :filteredQnt="turmas_filtered?.length || 0" icon="fa fa-graduation-cap" />

                <transition-group name="slide-fade" v-if="turmas_filtered?.length > 0">
                    <div class="box-header">
                        <span></span>
                        <span>Nome</span>
                        <span>Período</span>
                        <span>Código</span>
                        <span>Controle</span>
                    </div>

                    <div class="box-lista" v-for="turma in turmas_filtered" :key="turma.counter" :class="{'list-item-selected-pink': turma.isSelect.isSelected}">
                        <div>
                            <img  class="img-info-box-lista" :class="{'active-img': turma.isSelect.isSelected, 'black-img': !turma.isSelect.isSelected}" src="@assets/img/Dados/ListarUE/icon-turmas.png" alt="">
                        </div>
                        <span>{{turma.nome}}</span>
                        <span>{{turma.periodo}}</span>
                        <span>{{turma.codigo || '-'}}</span>

                        <div class="controlGroup" v-if="userPerm.idPermissao != '1'">
                            <span v-if="turma.isLocked">Selecionado</span>
                            <template v-else>
                              <Button
                                v-if="campoStatus != 'OFF'"
                                class="btn ml-2 btnCtrl"
                                section="instituicao-campo"
                                value="Editar turma"
                                :isOutline="true"
                                :hasIcon="true"
                                iconName="pen"
                                @click="turmaEditing = turma.idturma"
                                :data-target="`#updateTurma`"
                                data-toggle="modal"
                                />

                                <div v-if="['ON', 'INATIVO'].includes(campoStatus)" @click="setIsSelected(turma.idturma)" class="select-info">
                                    <div :class="['box-select-info mr-2 ',{'selected-info': turma.isSelect.isSelected}]"></div>
                                    <span class="span-label-selected" v-if="turma.isSelect.isSelected">Selecionado</span>
                                    <span class="span-label-selected" v-else>Selecionar</span>
                                </div>
                                <div v-else class="select-info-blocked">
                                    <span class="span-label-selected" v-if="turma.isSelect.isSelected">Selecionado</span>
                                </div>
                            </template>
                        </div>
                        <div v-else>
                            -
                        </div>
                    </div>
                </transition-group>
                <transition-group v-else>
                    <h2 class="title-style-box-lista" key="classesKey">Nenhuma Turma encontrada.</h2>
                </transition-group>

            </div>

            <div v-else>
                <SkeletonList/>
            </div>
        </div>
    </div>

</template>

<script>
 import Filter from '@components/Filter'
 import Button from '@components/Button'
 import ModalCentral from '@components/ModalCentral'
 import Breadcrumb from '@components/Breadcrumb'
 import Swal from 'sweetalert2';
 import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
 import Amostra from '@middleware/amostra.js'
 import SkeletonList from '@components/SkeletonUI/SkeletonList'
 import MemberQnt from '@dados/VisualizarInstituicao/MemberQnt'

 export default {
     name: 'CampoListaFormularios',
     data (){
         return {
           turmaEditing: null,
             nomeCampo: '',
             descricao: '',
             selectedValue: '',
             searchByName: '',
             searchByCode: '',
             searchWithOrder: '',
             isLoadedInfo: false,
             teste: [],
             turmas_amostra: [],
             turmas: [],
             options: [
                 {
                     id:1,
                     value: 'Todos'
                 },
                 {
                     id:2,
                     value: 'Nome'
                 },
                 {
                     id:3,
                     value: 'Selecionados'
                 },
             ],
             crumbs: [
                 {
                     id:1,
                     value: '',
                     iconName: 'th',
                     name_route: 'GerenciarInstituicoes'
                 },
                 {
                     id:2,
                     value: 'Instituição',
                     iconName: 'university',
                     name_route: 'InstituicaoMenu'
                 },
                 {
                     id:3,
                     value: 'Campos',
                     iconName: 'th-large',
                     name_route: 'ListarCampos'
                 },
                 {
                     id:4,
                     value: 'Gerenciar Campo',
                     iconName: 'chart-pie',
                     name_route: 'InstituicaoMenuCampo'
                 },
                 {
                     id:5,
                     value: 'Unidades Educacionais',
                     iconName: 'graduation-cap',
                     name_route: 'CampoListaUnidadesEducacionais'
                 },
                 {
                     id:6,
                     value: 'Turmas',
                     iconName: 'graduation-cap',
                     name_route: ''
                 }
             ]
         }
     },
     watch: {
         rawListTurma() { this.setData() },
         rawListCampoTurmas() { this.setData() },
     },
     computed: {
         campoStatus() {
             return this.$store.getters.getAmostraStatusAtiva
         },
         userPerm() {
             return this.$store.getters.getUserPerm
         },
         totalTurmas() {
             return this.turmas?.length || 0;
         },
         turmas_filtered() {
             // VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
             var query = this.turmas;

             //CASO SEJA SELECIONADO TODOS, EXIBE TODOS OS ITENS DO ARRAY
             if (this.selectedValue == 1) {
                 query = this.turmas;
             }

             //CASO SEJA SELECIONADO NOME, FILTRA POR NOME
             if (this.selectedValue == 2) {
                 //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
                 if (this.searchByName.trim() == '') {
                     query = this.turmas;
                 }
                 //SENAO ELE FILTRA PELO O QUE FOR DIGITADO
                 else {
                     var filtered = this.turmas.filter(turma =>turma.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
                     if(filtered?.length != 0){
                         query = filtered
                     }

                     else{
                         query = false
                     }
                 }
             } else if (this.selectedValue === 3) {
                 query = this.turmas.filter(turma => turma.isSelect.isSelected);
             }
             return query.sort((a, b) => (parseInt(a?.idturma) || 0) - (parseInt(b?.idturma) || 0));

         },
         rawListTurma() { return this.$store.getters.getListTurma || [] },
         rawListCampoTurmas() { return this.$store.getters.getListCampoTurmas || [] },
     },
     async created() {
         this.isLoadedInfo = false

         this.campos = this.$store.getters.getListUE

         this.loadData()

         const payload = {
             idInstituicao: this.$route.params.idInstituicao
         }
         await this.$store.dispatch('setUserPerm', payload)
         await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)

         const all_campos = this.$store.getters.getListCampos
         const campo = all_campos.filter((value) => {
             return value.idamostra == this.$route.params.idCampo
         }).map((value) => {return value.isativo})

         const campoStatus = campo[0]

         this.$store.commit('setAmostraStatusAtiva', campoStatus)
     },
     components: {
         Filter,
         Button,
         Breadcrumb,
         InstitutionInfo,
         SkeletonList,
         MemberQnt,
       ModalCentral,
     },
     methods: {
         setIsSelected(id) {
             this.turmas = Amostra.setIsSelectedItem(this.turmas, 'turma', id)
         },
         setSelectAllItems() {
             this.turmas = Amostra.setSelectAllItems(this.turmas, 'selectTurmas')

             Amostra.setAllSelectedItems(this.turmas, 'turma')
         },
         setUnselectAllItems() {
             this.turmas = Amostra.setUnselectAllItems(this.turmas, 'selectTurmas')

             Amostra.setAllSelectedItems(this.turmas, 'turma')
         },
         setData() {
             this.turmas = this.rawListTurma
             this.turmas_amostra = this.rawListCampoTurmas

             this.turmas.forEach(turma => {
                 turma.isLocked = false
                 this.turmas_amostra.forEach(turma_amostra => {
                     if (turma_amostra.idturma == turma.idturma) {
                         turma.isLocked = turma_amostra.isLocked
                     }
                 })
                 turma.isSelect = {
                     isSelected: this.turmas_amostra.some(turma_amostra => turma_amostra.idturma == turma.idturma),
                     isSaved: true
                 }
             });

             this.isLoadedInfo = true
         },
         async loadData() {
             const payload = {
                 idInstituicao: this.$route.params.idInstituicao,
                 idAmostra: this.$route.params.idCampo,
                 idUnidade: this.$route.params.idUnidade
             }
             const payload2 = {
                 idInstituicao: this.$route.params.idInstituicao,
                 idUnidade: this.$route.params.idUnidade
             }

             await this.$store.dispatch('setListCampoTurmas', payload)
             await this.$store.dispatch('setListTurmas', payload2)
         },
         async applyChanges() {
             if (this.$store.getters.getAmostraStatusAtiva == 'ON') {
                 const result = await Swal.fire({
                     title: "Tem certeza que deseja aplicar as alterações?",
                     text: "Você está em um campo ativo, os elementos inseridos não poderão ser modificados.",
                     icon: "question",
                     showCancelButton: true,
                     confirmButtonColor: "#3085d6",
                     cancelButtonColor: "#d33",
                     cancelButtonText: "Cancelar",
                     confirmButtonText: "Sim, tenho certeza",
                 })

                 if (result.isConfirmed) {
                     Amostra.setAllSelectedItems(this.turmas, 'turma')
                     const payloadManageTurmas = this.$store.getters.getPayloadManageTurmas
                     const payload = {
                         idInstituicao: this.$route.params.idInstituicao,
                         idUnidade: this.$route.params.idUnidade,
                         idAmostra: this.$route.params.idCampo,
                         ids: JSON.parse(JSON.stringify(payloadManageTurmas))
                     }
                     await this.$store.dispatch('setManageTurmas', payload)

                     this.loadData()
                     Swal.fire(
                         'Alterações aplicadas!',
                         'As seleções foram aplicadas com sucesso.',
                         'success'
                     )
                 }

             } else {
                 Amostra.setAllSelectedItems(this.turmas, 'turma')
                 const payloadManageTurmas = this.$store.getters.getPayloadManageTurmas
                 const payload = {
                     idInstituicao: this.$route.params.idInstituicao,
                     idUnidade: this.$route.params.idUnidade,
                     idAmostra: this.$route.params.idCampo,
                     ids: JSON.parse(JSON.stringify(payloadManageTurmas))
                 }
                 await this.$store.dispatch('setManageTurmas', payload)

                 this.loadData()
                 Swal.fire(
                     'Alterações aplicadas!',
                     'As seleções foram aplicadas com sucesso.',
                     'success'
                 )
             }
         },
         setSelectedValue() {
             this.selectedValue = this.$refs.filtersearchname.value
         },

     },
     mounted: function() {
         window.scrollTo(0, 0);
         let idTeamSelected = this.$route.params.idUnidade

         if (typeof idTeamSelected == "undefined"){
             this.nomeInstituicao = "Nome da Unidade"
         }
         else {
             let teamsSelected
             let teamSelected

             teamsSelected = this.campos.filter(team =>
                 team.idue == idTeamSelected
             )

             teamSelected = [...teamsSelected].shift()

             this.nomeCampo = teamSelected.nome
             this.descricao = teamSelected.descricao
         }
     }
 }
</script>

<style scoped>
 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;
 }

 .imgText {
     background-image: url("~@/assets/img/Backgrounds/roxoEapi.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .box-lista {
     background-repeat: repeat;
     background-size: 256px;
     background-image: url("~@/assets/img/Backgrounds/roxoClaro.webp");

     border: 1px solid #958db1;
     box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.3);
 }

 :deep(.box-lista span) {
     font-size: 1.1em;
 }

 .box-lista span {
     color: #fcfcfc;
 }

 .text-info-personal {
     color: #7647ac;
     font-size: 1.1rem;
     text-align: justify;
     padding: 0rem;
     margin: 0 3rem 0 3rem;
 }

 .list-data {
     min-height: 90vh;
     padding-top: 2rem;
 }
 .label-filtro {
     font-weight: 600;
 }

 .filter-box {
     margin: 1rem 3rem;
 }

 .list-box {
     margin: 2rem;
 }

 .title-style{
     margin: 0 0 0 3rem;
 }

 .itens-box {
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .title-tree {
     margin: 1rem 3rem;
     font-weight: bold;
     display: flex;
 }


 .lista-data-info {
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;
     margin: 2rem 3rem;
 }

 .title-tree h1 {
     font-size: 1.2rem;
     font-weight: 700;
 }

 .separator {
     border: none;
     height: 1px;
     margin: 0.8rem 3rem;
     background: url("~@/assets/img/Backgrounds/roxoEapi.png");
 }

 a {
     color: #212529;
     text-decoration: none;
     transition: all ease-in-out 0.2s
 }

 a:hover {
     color: #4d4f50;
 }

 @media screen and (max-width: 1010px) {
     .lista-data-info {
         justify-content: center;
     }
 }


 @media screen and (max-width: 1012px) {
     .filter-box {
         display: flex;
         align-items: center;
         justify-content: center;
     }
 }

 @media screen and (max-width: 680px) {
     .itens-box{
         flex-direction: column;
     }
 }

 .span-label-selected {
     width: min-content;
 }

 .list-item-selected-pink {
     background-image: url("~@/assets/img/Backgrounds/roxoEapi.webp");
 }

 .active-img {
     filter: brightness(0) invert(1) contrast(0.9);
 }

</style>
