<template>
  <div class="container">
     <div>
      <!-- MODAL CENTRAL DO PRIMEIRO BOTÃO -->
      <ModalCentral
        :componentName="`Search${infos.type}`"
        :title="`Buscar ${infos.type == 'Group' ? 'Grupo' : 'Instituição'}`"
        :idName="`Search${infos.type}`"
      />
       <!-- MODAL CENTRAL DO SEGUNDO BOTÃO -->
      <ModalCentral
        :componentName="`Create${infos.type}`"
        :title="`Criar ${infos.type == 'Group' ? 'Grupo' : 'Instituição'}`"
        :idName="`Create${infos.type}`"
        :textured="true"
      />
    </div>


    <!-- SE A PESSOA PERTENCE A ALGUM GRUPO DE PESQUISA/INSTITUIÇÃO -->
    <div class="row">
      <div class="col">
        <h1 class="title-style pb-3 pt-4">{{ infos.title }}</h1>
      </div>
    </div>
    
    <!----------- PRIMEIRO BOTÃO ------------>
    <div class="row">
      <!-- BOTAO CORRETO COMENTADO -->
      <div class="col-md mb-3 grow" :data-target="`#Search${infos.type}`" data-toggle="modal">
      <!-- <div class="col-md mb-3 "> -->
        <div class="d-flex btn btn-primary btn-block px-3 py-3 shadow-light buttonGrid">
          <div class="text-left">
            <p class="font-weight-semibold mb-1">{{ infos.firstButtonTitle }}</p>
            <p>{{ infos.firstButtonText }}</p>
          </div>
          <div class="ml-3 my-auto buttonIcon">
            <i class="fas fa-search-plus fa-2x"></i>
          </div>
        </div>
      </div>

      <!----------- SEGUNDO BOTÃO ------------>
      <div class="col-md mb-3 grow" :data-target="`#Create${infos.type}`" data-toggle="modal">
        <div class="d-flex btn btn-primary btn-block px-3 py-3 shadow-light buttonGrid">
          <div class="text-left">
            <p class="font-weight-semibold mb-1">{{ infos.secondButtonTitle }}</p>
            <p>{{ infos.secondButtonText }}</p>
          </div>
          <div class="ml-3 my-auto buttonIcon">
            <i class="fas fa-plus-circle fa-2x"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- SE A PESSOA NÃO PERTENCE A NENHUM GRUPO DE PESQUISA/INSTITUIÇÃO -->
    <div v-if="Object.keys(teams ?? []).length == 0">
      <div class="row mb-3">
        <div class="col">

          <h3 v-if="infos.type == 'Institution'" class="title-style font-weight-light mt-4">
            Você não pertence a
            <span class="font-weight-bold">nenhuma</span> instituição.
          </h3>

          <h3 v-else class="title-style font-weight-light mt-4">
            Você não pertence a
            <span class="font-weight-bold">nenhum</span> grupo.
          </h3>

        </div>
      </div>

      <div class="row">
        <div class="col d-flex justify-content-center">
          <img
            class="img-max-size-40vw"
            src="@assets/img/not-found-image.png"
            alt="Fundo"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCentral from '@components/ModalCentral';

export default {
  name: "Panel",
  methods: {
    async createGroup(){
      const payload = {
          nome: 'blablabla',
          descricao: 'blablabla',
          atuacao: 'blablabla',
          rua: 'blablabla',
          numero: 777,
          complemento: 'blablabla',
          bairro: 'blablabla',
          idCidade: 1,
          imagem: null
      }
      await this.$store.dispatch('setCreateGroup', payload)
    }
  },

  props: {
    infos: Object,
    teams: Object,
  },

  components: {
    ModalCentral,
  },

  
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/_variables.scss";
 /* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  * {
    font-family: 'Montserrat', sans-serif;
    } */

 .fa, .far, .fas {
   font-family: "Font Awesome 5 Free";
 }

p {
  margin-bottom: 1rem;
}

button {
  padding: 1rem;
  text-align: left;
  /* background: aliceblue; */
  border: none;
  border-radius: 10px;
}

.card-panel {
  display: grid;
  grid-template-columns: minmax(300px, 1fr) minmax(300px, 1fr);
}

.title-style {
  font-size: 2rem;
  font-weight: 700;
  color: $verdeEscuro-eapi;
}

 h1.title-style {
  background-clip: text;
  text-fill-color: transparent;
  background-image: url("~@/assets/img/Backgrounds/verdeEscuroEAPI.webp");
  background-repeat: repeat;
  background-size: 256px;
  color: transparent;
 }

.teste {
  align-items: center;
}

p {
  white-space: break-spaces !important;
}

.btn-primary {
  border-radius: 10px;
}
.img-btn img {
  max-width: 48px;
}

.btn-primary {
  /* background: $verdeEscuro-eapi !important; */
  /* border: 1px solid $verdeEscuro-eapi !important; */
}
.btn-primary:hover {
  /* background: $verdeEscuro-eapi !important;
     border: 1px solid $verdeEscuro-eapi !important; */
}
.btn-light {
  /* background: #f5f5f5 !important;
     border: 1px solid #626262 !important; */
  box-sizing: border-box !important;
  border-radius: 10px !important;
}
.shadow-light{
  box-shadow: 4px 6px 6px -2px rgb(0 0 0 / 25%);
}
.btn p {
  margin: 0;
  background-clip: text;
  text-fill-color: transparent;
  background-image: url("~@/assets/img/Backgrounds/branco.webp");
  background-repeat: repeat;
  background-size: 256px;
  color: transparent;
}
.btn {
  padding: 0.375rem 0.75rem !important;
  color: #fcfcfc;
  background-image: url("~@/assets/img/Backgrounds/verdeEscuroEAPI.png");
  background-repeat: repeat;
  background-size: 256px;
  border: none !important;
}

.img-max-size-25vw {
  width: 25vw;
  max-width: 21rem;
  position: absolute;
  transform: translateY(-20%);
}

.img-max-size-40vw {
  max-width: 40vw;
}

.disabled {
  background-color: #afc9c9;
}

 .buttonGrid {
   display: grid !important;
   grid-template-columns: 1fr auto;
   grid-template-rows: auto;
 }

 .buttonIcon {
   padding: 5px;
 }

@media (max-width: 767px) {
  .hide-on-mobile {
    display: none;
  }
}

 .font-weight-semibold {
   font-weight: 600;
 }
</style>
