<template>
    <div>
        <SectionElement title="Montando o CSV"
                 text="Para gerar o arquivo em formato CSV, baixe o arquivo modelo clicando no botão 
                    'Baixar modelo para arquivo' e realize as adequações necessárias. Em seguida, anexe o arquivo
                    no campo pontilhado arrastando-o ou escolhendo o local onde se encontra em seu
                    computador. Em seguida, basta clicar no botão 'Gerar Devolutiva' 
                    para iniciar o download do seu arquivo em formato CSV."
                 imageName="ilustra3-section.png"
                 :hasButton="true"
                 section="dados"
                 buttonIcon="download"
                 buttonText="Baixar modelo para arquivo"
                 direction="default"
        />
    <InputFile/>
    </div>
</template>

<script>
import SectionElement from '@components/SectionElement';
import InputFile from '@dados/ExportarDevolutiva/InputFile'

export default {
    name: 'ExportarDevolutiva',
    components: {
        SectionElement,
        InputFile
    }
}
</script>

<style scoped>

</style>