<template>

  <div class="container">
 
    <div>
      <h1 class="materiais-documentos title-info">Tópicos da Seção</h1>
      <p class="text-info-personal">
        Nesta seção, você poderá consultar vídeos, artigos científicos e publicações relacionadas ao
        monitoramento e estudo da qualidade da Educação Infantil no Brasil. Clique no card desejado para
        acessar o tipo de material que deseja consultar:
      </p>
    </div>
    <div class="cardsful-bg">
    <router-link to="/materiaisedocumentos/apresentacao" class="btn-router-link" >
      <ColorfulCardsBG
                  class="cardful-single"
                  :hasIcon="false"
                  thumbnail="" 
                  :hasImage="true"
                  title="Apresentações"
                  palette="salmon-section"
                  :imagePath="require('@assets/img/MateriaiseDocumentos/apresentacoes.svg')"  
                  :hoverImagePath="require('@assets/img/MateriaiseDocumentos/apresentacoes-hover.svg')"              
      />
      </router-link> 
      <router-link to="/materiaisedocumentos/videostreino" class="btn-router-link" >
      <ColorfulCardsBG
                  class="cardful-single"
                  :hasIcon="false"
                  thumbnail="" 
                  :hasImage="true"
                  title="Vídeos para Treino"
                  palette="salmon-section"
                  :imagePath="require('@assets/img/MateriaiseDocumentos/videos.svg')"  
                  :hoverImagePath="require('@assets/img/MateriaiseDocumentos/videos-hover.svg')"                
      />
      </router-link>
       <router-link to="/materiaisedocumentos/materialcampo" class="btn-router-link" > 
      <ColorfulCardsBG
                  class="cardful-single"
                  :hasIcon="false"
                  thumbnail="" 
                  :hasImage="true"
                  title="Material de Campo"
                  palette="salmon-section"
                  :imagePath="require('@assets/img/MateriaiseDocumentos/material-campo.svg')"    
                  :hoverImagePath="require('@assets/img/MateriaiseDocumentos/material-campo-hover.svg')"             
      />
      </router-link>
      <router-link to="/materiaisedocumentos/docoficiais" class="btn-router-link" > 
      <ColorfulCardsBG
                  class="cardful-single"
                  :hasIcon="false"
                  thumbnail="" 
                  :hasImage="true"
                  title="Documentos Oficiais"
                  palette="salmon-section"
                  :imagePath="require('@assets/img/MateriaiseDocumentos/documentos-oficiais.svg')"   
                  :hoverImagePath="require('@assets/img/MateriaiseDocumentos/documentos-oficiais-hover.svg')"               
      />
      </router-link>
    </div>
  </div>
</template>

<script>
import ColorfulCardsBG from '@components/ColorfulCardsBG'

export default {
    name: 'Materiais',
       components: {
          ColorfulCardsBG
    }
}
</script>

<style scoped>

.container{
  margin: 2.5rem auto;
}

.title-info {
  font-weight: 600;
  font-size: 1.6rem;
  color: #535353;
  margin: 0;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.materiais-documentos {
  color: #EB7071;
}
 
.title-tree {
  margin: 0;
  font-weight: bold;
  display: flex;
}

.title-tree h1 {
  font-size: 1.2rem;
  font-weight: 700;
}

.text-info-personal {
  color: #656565;
  font-size: 1.1rem;
  text-align: justify;
  padding: 0rem;
}

.cardful-single {
  margin: 1rem;
}

.cardsful-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

</style>