import store from '@source/store';

const amostra = {

    //METODO QUE RETORNA O ID E NOME DA MUTATION
    //DE ACORDO COM A ENTIDADE UTILIZADA
    amostraEntities(entity) {
        const entities = {
            usuario: {
                id: 'idusuario',
                mutation: 'setPayloadManageAplicadores'
            },
            aplicador: {
                id: 'idmembroinstituicao',
                mutation: 'setPayloadManageAplicadores'
            },
            formulario: {
                id: 'idformulario',
                mutation: 'setPayloadManageFormularios'
            },
            unidade: {
                id: 'idue',
                mutation: 'setPayloadManageUnidades'
            },
            turma: {
                id: 'idturma',
                mutation: 'setPayloadManageTurmas'
            }
        }

        return entities[entity] ? entities[entity] : null
    },

    //METODO QUE SETA NO ESTADO TODOS OS ITENS SELECIONADOS
    setAllSelectedItems(infos, entity) {
        let payload = infos.filter((value) => {
            return value.isSelect.isSelected
        }).map((value) => {
            if (value.isSelect.isSelected) { 
                return value[this.amostraEntities(entity)['id']]
            }
        })
        
        store.commit(`${this.amostraEntities(entity)['mutation']}`, payload)
    },

    //METODO QUE SETA O VALOR DE ISSELECTED
    setIsSelectedItem(infos, entity, id){
        infos.forEach((value) => {
            if (value[this.amostraEntities(entity)['id']] == id){
                value.isSelect.isSelected = !value.isSelect.isSelected
                value.isSelect.isSaved = false
            }
        })

        return infos;
    },

    //METODO QUE MARCA TODOS OS ITENS
    setSelectAllItems(infos, listaType) {
        if (listaType == 'selectMembros' || listaType == 'selectTurmas' || listaType == 'selectFormularios' || listaType == 'selectUnidades') {
            infos.forEach((value) => {
                if (!value.isLocked) {
                    value.isSelect.isSelected = true
                    value.isSelect.isSaved = false
                }
            })
        }
        else {
            infos.forEach((value) => {
                if (value.isSelect.isSaved == false || value.isSelect.isSelected == false) {
                    value.isSelect.isSelected = true
                    value.isSelect.isSaved = false
                }
            })
        }

        return infos
    },

    //METODO QUE DESMARCA TODOS OS ITENS
    setUnselectAllItems(infos, listaType) {
        if (listaType == 'selectMembros' || listaType == 'selectTurmas' || listaType == 'selectFormularios' || listaType == 'selectUnidades') {
            infos.forEach((value) => {
                if (!value.isLocked) {
                    value.isSelect.isSelected = false
                    value.isSelect.isSaved = false
                }
            })
        }
        else {
            infos.forEach((value) => {
                if (value.isSelect.isSaved == false || value.isSelect.isSelected == false) {
                    value.isSelect.isSelected = false
                    value.isSelect.isSaved = false
                }
            })
        }
        return infos
    },
}

export default amostra;