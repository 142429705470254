<template>
    <div class="popup">
        <h2>AVALIE ESSA PRÁTICA</h2>
        <p>Sua opinião é muito importante para a gente! Submeta sua avaliação para ajudar-nos a melhorar cada vez mais.</p>
    </div>

    <div class="rating">
        <img
            v-for="idx in [...Array(5).keys()]"
            :key="idx"
            @click="setRating(idx)"
            :src="idx + 1 <= rating ? fullImg : emptyImg"
            :class="{ 'starSelected': idx + 1 <= rating }"
            alt="Estrela" />
    </div>
    <div class="ratingMsg">
        {{ ratingMsgs[ rating - 1 ] }}
    </div>

    <textarea
        v-model="ratingTxt"
        name="comment"
        placeholder="Conte-nos um pouco mais..."
        rows="3"
        tabindex="-1"></textarea>

    <Button
        @click="ratePratica"
        class="popupButton"
        :isOutline="false"
        value="Enviar" />
</template>

<script type="text/javascript">
 import Button from "@components/Button";
 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: "left",
         y: "bottom",
     },
 });

 export default {
     name: "PopupRating",
     props: {
         data: {
             type: Object,
             required: true,
         }
     },
     components: {
         Button,
     },
     data() {
         return {
             idPratica: this.data.idPratica,
             rating: 3,
             ratingMsgs: [
                 "Não foi clara",
                 "Poderia ter sido melhor",
                 "Ok",
                 "Muito boa!",
                 "Excelente!",
             ],
             ratingTxt: "",
             fullImg: require('../../assets/img/BoasPraticas/FullStar.svg'),
             emptyImg: require('../../assets/img/BoasPraticas/EmptyStar.svg'),
         };
     },
     methods: {
         setRating(value) {
             this.rating = value + 1;
         },
         async ratePratica() {
             await this.$store.dispatch('ratePratica', {
                 idPratica: this.idPratica,
                 rating: this.rating.toString(),
                 ...(this.ratingTxt != "" && { ratingTxt: this.ratingTxt }),
             });

             if (this.$store.getters.getPraticaRated) {
                 this.$store.dispatch('loadListBoasPraticas');
                 notyf.open({
                     type: "success",
                     message: "Prática avaliada com sucesso!"
                 });
                 this.$emit('closeModal');
             } else {
                 notyf.open({
                     type: "error",
                     message: "Erro na avaliação da prática!"
                 });
             }
         },
     },
 };
</script>

<style type="text/css" media="screen" scoped>
 h2 {
     color: #414141;
     width: 100%;
     padding: 5px;
     text-align: center;
 }

 .rating {
     width: 100%;
     padding: 3px;
     text-align: center;
 }

 .rating > img {
     max-width: 4em;
     margin: 5px;
     transition: 0.4s;
     cursor: pointer;
 }

 .rating > img:hover, .starSelected {
     transform: scale(1.1);
     transform-origin: center center;
 }

 .ratingMsg {
     width: 100%;
     padding: 5px;
     color: #E3A753;
     text-align: center;
     font-size: 1.1em;
 }

 .popupButton {
     display: block;
     background-color: #41B3A3;
     border-radius: 30px;
     margin: 0 auto;
     min-width: 150px;
 }

 textarea {
     border: 0;
     border-radius: 8px;
     padding: 5px;
     resize: none;
     width: calc(100% - 20px);
     box-shadow: 1px 2px 6px -3px rgba(0, 0, 0, 0.25);
     margin: 10px auto;
     display: block;
 }

</style>
