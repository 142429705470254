<template>
    <div class="divEdicao">
        <div class="headerCategoria">
            <p class="categoria">Categoria 001</p>
            <h5 class="dadosInstituicao">Dados da Instituição</h5>
        </div>

        <div class="divPergunta">
            <h2 class="tituloPergunta">Pergunta 001</h2>

            <textarea class="areaPergunta"></textarea>

            <p class="tituloMenor">Notas (Opcional)</p>
            <textarea class="areaNotas"></textarea>

            <p class="tituloMenor tituloMedio">Modelo da Resposta</p>
            <div class="divCheckBox">
                <label>
                    <input type="checkbox" value="dia"><span>Dia</span>
                </label>
                <br>

                <label>
                    <input type="checkbox" value="mes"><span>Mês</span>
                </label>
                <br>

                <label>
                    <input type="checkbox" value="ano"><span>Ano</span>
                </label>
            </div>
            
            <div class="divPadrao">
            <p class="tituloMenor tituloMedio">Resposta Padrão</p>
            <input type="date" class="inputRespPadrao">
            </div>

        </div>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
    .divEdicao{
        width: 100%;
        height: 100%;
    }

    .headerCategoria{
        margin-bottom: 3%;
    }

    .categoria{
        margin-bottom: 0;
        font-size: 18px;
    }

    .dadosInstituicao{
        font-weight: bold;
        color: #4C545F;
    }

    .divPergunta{
        height: 100%;
    }
    
    .tituloPergunta{
       color: #1F2937;
       font-weight: 100;
    }

    textarea{
        width: 100%;
        padding: 10px;
    }

     input{
        width: 100%;
    }

    .inputRespPadrao{
        height: 12%;
        padding: 10px;
    }

    .areaPergunta{
        height: 40%;
        margin-bottom: 15px;
    }

    .areaNotas{
        height: 12%;
        margin-bottom: 15px;
    }

    .areaRespPadrao{
        height: 12%;
    }

    .tituloMenor{
        margin-bottom: 5px;
    }

    .tituloMedio{
        font-size: 18px;
    }

    label > input[type="checkbox"] {
        display: inline-block;
        vertical-align: middle;
        width: 1rem;
        height: 1rem;
        margin-right: 5px;
    }

    .divCheckBox{
        margin-bottom: 15px;;
    }

</style>
