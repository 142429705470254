<template>

    <div class="">
        <h1>Práticas em destaque</h1>
        <PraticaDestaque
            title="Título da Prática"
            autor="Marizete Suzano"
            summary="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            idade="4-5"
        />
        
        <div class="praticaTodas mt-5 container">

            <div class="filtros d-flex justify-content-around w-50 mb-4">
                Filtrar por:
                <select class="filterSelect">
                    <option>Eixo Temático</option>
                </select>

                <select class="filterSelect">
                    <option>Faixa Etária</option>
                </select>
            </div>

            <hr>

            <div class="praticasRepertorio">
                <div class="ampliacaoRepertorio mb-4">
                    <span class="mr-5">Ampliação do repertório por meio de brincadeiras e jogos propostos pelas crianças</span>
                    <label>Amplicação de repertório</label>
                </div>

               <!------------------- LISTAGEM DOS MODAIS ---------------------------------------------------------->
            
                <div class="boxes-style">
				<MaterialBox
					class="box-style"
					id="modal"
					title="Título da prática"
                    autor="João lavinas"
                    idade="4 a 5 anos"
                    categoria="Apliamção do repertório"
				/>

                <MaterialBox
					class="box-style"
					id="modal"
					title="Título da prática"
                    autor="Camila Martins"
                    idade="4 a 5 anos"
                    categoria="Apliamção do repertório"
				/>

                <MaterialBox
					class="box-style"
					id="modal"
					title="Título da prática"
                    autor="Marizete Suzano"
                    idade="4 a 5 anos"
                    categoria="Apliamção do repertório"
				/>

                <MaterialBox
					class="box-style"
					id="modal"
					title="Título da prática"
                    autor="Michell Alencar"
                    idade="4 a 5 anos"
                    categoria="Apliamção do repertório"
				/>

                
		</div>
            
            </div>

        </div>

    </div>
</template>

<script>
import PraticaDestaque from '@components/BoasPraticas/PraticaDestaque'
import MaterialBox from '@components/BoasPraticas/materialBox'
export default {
    name: 'ArcevoDePraticas',
    components: {
        PraticaDestaque,
        MaterialBox
    }
}
</script>

<style scoped>
h1{
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    color: #41B3A3;
    text-align: center;
}

.ampliacaoRepertorio span{
    color: #3F3FB8;
    font-size: 1.4rem;
    font-weight: lighter;
}

.ampliacaoRepertorio label{
    border-radius: 15px;
    color: white;
    height: 22px;
    width: 18%;
    background-color: #3F3FB8;
    text-align: center;
}   

.filtros{
    align-items: center;
}

.filterSelect{
    border: 1px solid rgba(0, 0, 0, 0.11);
    background-color: #fcfcfc;
    border-radius: 10px;
    padding: 0.5%;
    width: 33%;
}

.boxes-style {
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-wrap: wrap;
}
    
</style>