<template>

    <container>
        <div>
            <SectionElement title="O que são esses dados?"
                text="Os dados disponibilizados são coletados por pessoas certificadas pelo LEPES. A partir desses dados é possível produzir análises sobre a qualidade do Ensino Infantil no Brasil."
                imageName="ilustra1-section.png"
                :hasButton="true"
                section="dados"
                buttonIcon="download"
                buttonText="Baixar dados da Base Nacional"
                direction="default"
                :hasShadow="true"
            />
            <SectionElement title="Como utilizar os Dados?"
                text="Os dados contém as respostas dos instrumentos aplicados em cada Campo, com o intuito de contribuir com a análise, pesquisa e monitoramento da qualidade da Educação Infantil no Brasil. Os dados apresentados possuem finalidade acadêmica e científica, portanto, não são destinados a fins monetários."
                imageName="ilustra2-section.png"
                :hasButton="false"
                section="dados"
                buttonIcon=""
                buttonText=""
                direction="reverse"
            />

        </div>
        <div>
            <h1 class="title">{{"Contribua para a base nacional"}}</h1>

            <div class="cards-coloridos">

                <ColorfulCard thumbnail="icone-certifique.png" 
                    title="Certifique-se"
                />

                <ColorfulCard thumbnail="icone-estrela.png" 
                    title="Já sou certificado!"
                />

            </div>
        </div>

    </container>
</template>

<script>
import SectionElement from '@components/SectionElement';
import ColorfulCard from '@components/ColorfulCard';

export default {
    name: 'BaixarDadosNacionais',
    components: {
        SectionElement,
        ColorfulCard
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/_variables.scss";

    .title{
        font-size: 1.6rem;
        font-weight: bold;
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        color: $verdeEscuro-eapi;
    }

    .cards-coloridos{
        justify-content: center;
        justify-content: space-evenly;
        display: flex;
        margin: 1rem 20%;
        flex-wrap: wrap;
    }
    
</style>