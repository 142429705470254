<template>
  <div>
      <div class="painelFuncoesWrapper">
          <h1 class="painelTitulo">Funções</h1>

          <button class="btn btn-painelFuncoes"><i class="fas fa-folder-plus"></i> Adicionar Categoria</button>

          <button class="btn btn-painelFuncoes"><i class="fas fa-copy"></i> Duplicar Pergunta</button>

          <button class="btn btn-painelFuncoes"><i class="fas fa-plus-circle"></i> Adicionar Pergunta</button>

          <button class="btn btn-painelFuncoes"><i class="far fa-window-maximize"></i> Minimizar tudo</button>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.painelFuncoesWrapper{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.painelTitulo{
    padding: 1rem;
    font-size: 26.56px;
    border-bottom: 1px solid var(--cinza-40);
    margin: 0;
    color: var(--cinza);
}

.btn-painelFuncoes{
    border-bottom: 1px solid var(--cinza-40);
    border-radius: 0;
    text-align: left;
    padding: 2rem 1rem;
    background: var(--branco);
    color: var(--cinza);
    display: flex;
    align-items: center;
    font-size: 20px;
}

.btn-painelFuncoes i{
    font-size: 2.5rem;
    margin-right: 1rem;
    color: var(--azul);
}
</style>