<template>

    <div class="bg">
        <div class="list-data">

            <!-------------- MODAL CENTRAL PARA ADICIONAR USUÁRIO -------------------->
            <ModalCentral
                componentName="AddUser"
                title="ADICIONAR USUÁRIO"
                idName="AddUsuario"
            />

            <!-------------- MODAL CENTRAL PARA ALTERAR PERMISSÃO DO USUÁRIO -------------------->
            <ModalCentral
                componentName="ChangePermission"
                title="ALTERAR PERMISSÃO"
                idName="AlterarPermissao"
                :data="dataToChangePermission"
            />

            <div class="title-tree">
                <Breadcrumb :crumbs="crumbs" section="instituicao-cadastrar"/>
            </div>

            <InstitutionInfo class="title-style" :title="nomeInstituicao" section="instituicao-cadastrar"/>

            <p class="text-info-personal imgText">Nesta seção, você encontrará todos os membros da Instituição. Caso necessário, utilize o filtro abaixo para localizar o membro desejado e o botão "Adicionar Membros" para convidar um novo integrante.</p>

            <div class="itens-box">
            <div class="filter-box">
                <!-- INICIO DO FORM DE FILTRAGEM -->
                <div class="form-inline">
                    <div class="form-group">
                        <input class="input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_membros" id="inlineFormCustomName" placeholder="Nome..." autocomplete="off">
                    </div>
                </div>
            </div>
            <div class="lista-data-info">

                <!-- INSERINDO @CLICK APENAS PARA TESTAR O POST CRIAR MEMBRO, RETIRAR DEPOIS!!!!!!!!!!!!!!! -->
                <Button
                    v-if="userPerm.permissao != 'APLICADOR'"
                    class="btnInvite"
                    value="Convidar Membros"
                    :isOutline="true"
                    :hasIcon="true"
                    iconName="user-plus"
                    data-toggle="modal"
                    data-target="#AddUsuario"
                />
            </div>
            </div>
            <hr class="separator">

            <!-------------------------------- LISTANDO OS MEMBROS ----------------------------------------------->
            <div v-if="isLoadedInfo">
                <MemberQnts term="membros" :totalQnt="totalMembros" :filteredQnt="membros?.length || 0" />

                <transition-group tag="div" v-if='membros' name="slide-fade">
                    <!-- <ListagemElements :infos="membros" listaType="addMembros"/>  -->
                    <div class="box-header">
                        <span></span>
                        <span>Nome (Email)</span>
                        <span>Permissão</span>
                        <span>(Controle)</span>
                    </div>

                    <div class="box-lista" v-for="membro in membros" :key="membro.idusuario">
                        <div>
                            <img v-if="membro.permissao == 'ADMINISTRADOR'" class="img-info-box-lista" src="@assets/img/Cards/Coroa.svg" alt="">
                            <img v-else-if="membro.permissao == 'COORDENADOR'" class="img-info-box-lista" src="@assets/img/Cards/Membro.svg" alt="">
                            <img v-else class="img-info-box-lista" src="@assets/img/Cards/Lapis.svg" alt="">
                        </div>
                        <span>{{membro.nome}} ({{membro.email}})</span>
                        <span>{{membro.permissao}}</span>
                        <div class="controlGroup">
                            <Button
                                :disabled="(Number(membro?.idPermissao) > Number(userPerm?.idPermissao)) || (userPerm?.idPermissao == '1') || (userInfo?.data?.idUsuario == membro?.idusuario)"
                                @click="sendInfosToChangePermission(membro.idusuario, membro.permissao,membro.nome)"
                                data-toggle="modal"
                                data-target="#AlterarPermissao"
                                class="third-info-box-lista btnLista"
                                value="Alterar Permissão"
                                :isOutline="true"
                            />
                            <Button
                                :disabled="(Number(membro?.idPermissao) > Number(userPerm?.idPermissao)) || (userPerm?.idPermissao == '1') || (userInfo?.data?.idUsuario == membro?.idusuario)"
                                @click="deleteUser(membro.idusuario, membro.nome)"
                                class="last-info-box-lista btnLista"
                                value="Desvincular Membro"
                                :isOutline="true"
                            />
                        </div>
                    </div>
                </transition-group>

                <transition-group v-else>
                    <h2 class="title-style-box-lista">Nenhum resultado encontrado.</h2>
                </transition-group>

            </div>

            <div v-else>
                <SkeletonList/>
            </div>

            <!----------------------------------------------------------------------------------------------------->
        </div>
    </div>
    
</template>

<script>
import Button from '@components/Button'
import Swal from 'sweetalert2';
import ModalCentral from '@components/ModalCentral'
import Breadcrumb from '@components/Breadcrumb'
import SkeletonList from '@components/SkeletonUI/SkeletonList'
import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
import MemberQnts from '@dados/VisualizarInstituicao/MemberQnt'

export default {
    name: 'ListarMembros',
    data (){
        return {
            nomeInstituicao: '',
            descricao: '',
            dataToChangePermission: {},
            searchByName: '',
            isLoadedInfo: false,
            teams: [],
            crumbs: [
                {
                    id:1,
                    value: '',
                    iconName: 'th',
                    name_route: 'GerenciarInstituicoes'
                },
                {
                    id:2,
                    value: 'Instituição',
                    iconName: 'university',
                    name_route: 'InstituicaoMenu'
                },
                {
                    id:3,
                    value: 'Cadastrar Informações',
                    iconName: 'search-plus',
                    name_route: 'InstituicaoMenuCadastrar'
                },
                {
                    id:4,
                    value: 'Membros',
                    iconName: 'user-friends',
                    name_route: ''
                }
            ]
        }
    },
    async created() {

        this.teams = this.$store.getters.getListTeams

        window.scrollTo(0, 0);
        const payload = {
            idInstituicao: this.$route.params.idInstituicao
        }
        await this.$store.dispatch('setUserPerm', payload)

        this.isLoadedInfo = false
        await this.$store.dispatch('setListMembros', this.$route.params.idInstituicao)
        this.isLoadedInfo = true
    },
    computed: {
        totalMembros() {
            return this.$store.getters.getListMembros?.length;
        },
        membros(){
            var membros = this.$store.getters.getListMembros
            membros?.forEach((value) => {
                if (value.permissao == 'ADMINISTRADOR') {
                    value.idPermissao = 3
                }
                if (value.permissao == 'COORDENADOR') {
                    value.idPermissao = 2
                }
                if (value.permissao == 'APLICADOR') {
                    value.idPermissao = 1
                }
            })

            // VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
            var query = membros;

            if (this.searchByName.trim() != '') {
                var filtered = membros.filter(membro =>membro.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))

                if(filtered?.length != 0){
                    query = filtered
                } else{
                    query = false
                }
            }

            return query;
        },
        
        userPerm() {
            return this.$store.getters.getUserPerm
        },
        userInfo() {
            return this.$store.getters.getUserInfo
        }
    },
   
    components: {
        Button,
        ModalCentral,
        Breadcrumb,
        InstitutionInfo,
        SkeletonList,
        MemberQnts,
    },
    methods: {
        setSelectedValue() {
            this.selectedValue = this.$refs.filtersearchname.value
        },
        
        //METODO PARA ALTERAR PERMISSAO DO MEMBRO
        sendInfosToChangePermission: function(idusuario, permissao, nome) {
            this.dataToChangePermission = {
                id: idusuario,
                permissao: permissao,
                nome: nome,
                modalName: "AlterarPermissao"
            }
        },

        //METODO PARA DESVINCULAR MEMBRO
        async deleteUser(idusuario, name) {
            const result = await Swal.fire({
                title: 'Desvincular membro!',
                text: 'Tem certeza que deseja desvincular '+ name + '?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sim, tenho certeza'
                })
                
                if (result.isConfirmed) {
                    console.log(idusuario)
                    const payload = {
                        idInstituicao: this.$route.params.idInstituicao,
                        idUsuario: idusuario
                    }
                    await this.$store.dispatch('deleteMember', payload)
                    Swal.fire(
                        'Desvinculado!',
                        name + ' não faz mais parte dessa instituição.',
                        'success'
                    )  
                    await this.$store.dispatch('setListMembros', this.$route.params.idInstituicao)
                }
        },
    },
    mounted: function() {
        window.scrollTo(0, 0);
        let idTeamSelected = this.$route.params.idInstituicao

        if (typeof idTeamSelected == "undefined"){
            this.nomeInstituicao = "Nome da Instituição"
        }
        else {
            let teamsSelected
            let teamSelected

            teamsSelected = this.teams.filter(team => 
                team.idinstituicao == idTeamSelected
            )

            teamSelected = [...teamsSelected].shift()

            this.nomeInstituicao = teamSelected.name
            this.descricao = teamSelected.descricao
        }
    }
}
</script>

<style scoped>
 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;
 }

 .imgText {
     background-image: url("~@/assets/img/Backgrounds/azulEapi.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .box-lista {
     background-repeat: repeat;
     background-size: 256px;
     background-image: url("~@/assets/img/Backgrounds/azulMaisClaro.webp");
     border: 1px solid #952713;
     box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
 }

 :deep(.box-lista span) {
     font-size: 1.1em;
 }

 .box-lista span {
     background-image: url("~@/assets/img/Backgrounds/marromEscuro.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

.text-info-personal {
  color: #656565;
  font-size: 1.1rem;
  text-align: justify;
  padding: 0rem;
  margin: 0 3rem 0 3rem;
}

.list-data {
    min-height: 90vh;
    padding-top: 2rem;
}

.label-filtro {
    font-weight: 600;
}

.filter-box {
    margin: 1rem 3rem;
}

.itens-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list-box {
    margin: 2rem;
}

.title-tree {
    margin: 1rem 3rem;
    font-weight: bold;
    display: flex;
}

.title-style{
    margin: 0 0 0 3rem;
}

.lista-data-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 2rem 3rem;
}

.title-tree h1 {
    font-size: 1.2rem;
    font-weight: 700;
}

.separator {
    border: none;
    height: 1px;
    margin: 0.8rem 3rem;
    background-image: url("~@/assets/img/Backgrounds/azulEapi.webp");
}

a {
    color: #212529;
    text-decoration: none;
    transition: all ease-in-out 0.2s
}

a:hover {
    color: #4d4f50;
}

@media screen and (min-width: 901px) {
    .first-info {
        flex-basis: 35%;
        text-align: left;
    }
}

@media screen and (max-width: 1010px) {
    .lista-data-info {
        justify-content: center;
    }
}


@media screen and (max-width: 1012px) {
        .filter-box {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    
@media screen and (max-width: 680px) {
    .itens-box{
        flex-direction: column;
        align-items: flex-start;
    }
    .first-info {
        text-align: center;
        width: -webkit-fill-available;
    }
    .filter-box{
        margin: 0 3rem;
    }
    .lista-data-info{
        margin: 0 3rem;
    }
    .box-lista span, .box-lista a{
        width: 100%;
        text-align: center;
        margin-top: .5rem;
    }
    .box-lista{
        place-content: center;
    }
}

 .img-info-box-lista {
     padding: 10px;
 }

 .btnInvite {
     border: 1px solid #0e80c2;
 }

 :deep(.btnInvite div) {
     background-image: url("~@/assets/img/Backgrounds/azulEapi.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
     transition: 0.4s;
 }

 .btnLista {
     border: 1px solid #952713;
     word-break: break-all !important;
 }

 :deep(.btnLista div) {
     background-image: url("~@/assets/img/Backgrounds/marromEscuro.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
     word-break: break-all !important;
 }

 .btnInvite, .btnLista {
     background-color: white;
 }
</style>
