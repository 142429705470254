<template>
    <div class="bg">
        <div class="list-data">

            <ModalCentral
                componentName="AddEducationalUnit"
                title="ADICIONAR UNIDADE EDUCACIONAL"
                idName="AddUE"
            />

            <ModalCentral
                componentName="AddEducationalUnitByFile"
                title="ADICIONAR UNIDADES EDUCACIONAIS"
                idName="AddUEbyFile"
            />

            <div class="title-tree">
                <Breadcrumb :crumbs="crumbs" section="instituicao-cadastrar"/>
            </div>

            <InstitutionInfo class="title-style" :title="nomeInstituicao" section="instituicao-cadastrar"/>

            <p class="text-info-personal imgText">Nesta seção, você encontrará todas as Unidades Educacionais da Instituição. Caso necessário, utilize o filtro abaixo para localizar a Unidade Educacional desejada.</p>

            <div class="itens-box">
                <div class="filter-box">
                    <!-- <pre>{{ teste }}</pre> -->
                    <!-- INICIO DO FORM DE FILTRAGEM -->

                    <form @submit.prevent="" class="form-inline">
                        <div class="form-group">

                            <!-- DROPDOWN DE SELECAO DE FILTRO -->
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomSelect">Filtrar por: </label>
                            <Filter ref="filtersearchname" @change="setSelectedValue()" :optionsSelect="options" nameSelect="filtro_instituicoes" id="inlineFormCustomSelect"/>
                        </div>

                        <!-- SE A SELECAO FOR NOME (2) ENTAO EXIBIRA OS SUBFILTROS DE NOME -->
                        <div class="form-group" v-if="selectedValue == 2">
                            <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomName">Digite o nome: </label>
                            <input class="input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_instituicoes" id="inlineFormCustomName" autocomplete="off">
                        </div>

                    </form>
                </div>
                <div class="lista-data-info">
                    <div>

                        <Button
                            v-if="userPerm.permissao != 'APLICADOR'"
                            class="btn mr-2 mb-mobile btnCtrl"
                            value="Adicionar UE"
                            :isOutline="true"
                            :hasIcon="true"
                            iconName="graduation-cap"
                            data-toggle="modal"
                            data-target="#AddUE"
                        />

                        <Button
                            v-if="userPerm.permissao != 'APLICADOR'"
                            class="btn btnCtrl"
                            value="Importar Lista de UE"
                            :isOutline="true"
                            :hasIcon="true"
                            iconName="clipboard-list"
                            data-toggle="modal"
                            data-target="#AddUEbyFile"
                        />
                    </div>
                </div>
            </div>
            <hr class="separator">

            <!-------------------------------- LISTANDO AS UNIDADES EDUCACIONAIS ---------------------------------------->

            <div v-if="isLoadedInfo">
                <MemberQnt term="unidades" :totalQnt="totalUes" :filteredQnt="unidades_educacionais?.length || 0" icon="fa fa-university" />

                <transition-group v-if="unidades_educacionais?.length > 0" name="slide-fade">
                    <div class="box-header">
                        <span></span>
                        <span>Nome</span>
                        <span>Marcoárea</span>
                        <span>Cidade</span>
                        <span>Controle</span>
                    </div>

                    <div class="box-lista" v-for="unidade_educacional in unidades_educacionais" :key="unidade_educacional.counter">
                        <div>
                            <img  class="img-info-box-lista" src="@assets/img/Dados/ListarUE/icon-ue.png" alt="">
                        </div>
                        <span>{{unidade_educacional.nome}}</span>
                        <span>{{unidade_educacional.macroarea || '-'}}</span>
                        <span>{{unidade_educacional.cidade}}</span>
                        <div class="controlGroup">
                            <router-link
                                v-if="userPerm.permissao != 'APLICADOR'"
                                :to="{name: 'ListarTurmas', params: { idUnidade: unidade_educacional.idue }}"
                                class="btn-router-link">
                                <Button
                                    class="third-info-box-lista btnLista"
                                    value="Ver UE"
                                    :isOutline="true"
                                />
                            </router-link>
                        </div>
                        <!-- <span class="last-info">Excluir Unidade Educacional</span> -->
                    </div>
                </transition-group>
                <transition-group v-else>
                    <h2 class="title-style">Nenhuma Unidade Educacional encontrada.</h2>
                </transition-group>
            </div>

            <div v-else>
                <SkeletonList/>
            </div>

            <br />
        </div>
    </div>

</template>

<script>
 import Filter from '@components/Filter'
 import Button from '@components/Button'
 import ModalCentral from '@components/ModalCentral'
 import Breadcrumb from '@components/Breadcrumb'
 import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
 import SkeletonList from '@components/SkeletonUI/SkeletonList'
 import MemberQnt from '@dados/VisualizarInstituicao/MemberQnt'

 export default {
     name: 'ListarUnidadesEducacionais',
     data (){
         return {
             nomeInstituicao: '',
             descricao: '',
             selectedValue: '',
             searchByName: '',
             isLoadedInfo: false,
             teste: [],
             options: [
                 {
                     id:1,
                     value: 'Todos'
                 },
                 {
                     id:2,
                     value: 'Nome'
                 }
             ],
             crumbs: [
                 {
                     id:1,
                     value: '',
                     iconName: 'th',
                     name_route: 'GerenciarInstituicoes'
                 },
                 {
                     id:2,
                     value: 'Instituição',
                     iconName: 'university',
                     name_route: 'InstituicaoMenu'
                 },
                 {
                     id:3,
                     value: 'Cadastrar Informações',
                     iconName: 'search-plus',
                     name_route: 'InstituicaoMenuCadastrar'
                 },
                 {
                     id:4,
                     value: 'Unidades Educacionais',
                     iconName: 'graduation-cap',
                     name_route: ''
                 }
             ]
         }
     },
     async created(){
         this.teams = this.$store.getters.getListTeams
         const payload = {
             idInstituicao: this.$route.params.idInstituicao
         }
         await this.$store.dispatch('setUserPerm', payload)

         this.isLoadedInfo = false
         await this.$store.dispatch('setListUE', payload)
         this.isLoadedInfo = true
     },
     computed: {
         totalUes() {
             return this.$store.getters?.getListUE.length || 0;
         },
         unidades_educacionais(){
             var unidades_educacionais = this.$store.getters.getListUE

             // VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
             var query = unidades_educacionais;
             //CASO SEJA SELECIONADO TODOS, EXIBE TODOS OS ITENS DO ARRAY
             if (this.selectedValue == 1) {
                 query = unidades_educacionais;
             }
             //CASO SEJA SELECIONADO NOME, FILTRA POR NOME
             if (this.selectedValue == 2) {
                 //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
                 if (this.searchByName.trim() == '') {
                     query = unidades_educacionais;
                 }
                 //SENAO ELE FILTRA PELO O QUE FOR DIGITADO
                 else {
                     var filtered = unidades_educacionais?.filter(unidades_educacional =>unidades_educacional.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
                     // console.log(filtered);
                     if(filtered?.length != 0){
                         query = filtered
                     }

                     else{
                         query = false
                     }
                 }
             }
             // console.log(this.query)
             return query;
         },
         userPerm() {
             return this.$store.getters.getUserPerm
         }
     },
     components: {
         Filter,
         Button,
         ModalCentral,
         Breadcrumb,
         InstitutionInfo,
         SkeletonList,
         MemberQnt
     },
     methods: {
         setSelectedValue: function() {
             //! TODO: só pra parar de dar erro no console
             this.selectedValue = this.$refs.filtersearchname.value
         },
         async createUE(){
             const payload = {
                 nome: 'Silverio',
                 bairro: 'Santa Tereza',
                 macroarea: 'Sei la bro, São jose?',
                 inep: 12666556,
                 localizacao: 'RURAL',
                 anoInicioAtividades: 2010,
                 municipio: 'São José Do rio Pardo',
                 rua: 'Dos Bandeirantes',
                 numero: 890,
                 complemento: 'Em frente à praça',
                 modalidade: 'PRIVADA'
             }
             await this.$store.dispatch('setCreateUE', payload)
         },
         async sendFile(){
             const payload = {
                 id_instituicao: 3,
                 file: 'filePathHere'
             }
             await this.$store.dispatch('setSendFile', payload)
         }
     },
     mounted: function() {
         window.scrollTo(0, 0);
         let idTeamSelected = this.$route.params.idInstituicao

         if (typeof idTeamSelected == "undefined"){
             this.nomeInstituicao = "Nome da Instituição"
         }
         else {
             let teamsSelected
             let teamSelected

             teamsSelected = this.teams.filter(team =>
                 team.idinstituicao == idTeamSelected
             )

             teamSelected = [...teamsSelected].shift()

             this.nomeInstituicao = teamSelected.name
             this.descricao = teamSelected.descricao
         }
     }
 }
</script>

<style scoped>
 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;
 }

 .imgText {
     background-image: url("~@/assets/img/Backgrounds/azulEapi.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .box-lista {
     background-repeat: repeat;
     background-size: 256px;
     background-image: url("~@/assets/img/Backgrounds/azulMaisClaro.webp");
     box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
     border: 1px solid #952713;
 }

 :deep(.box-lista span) {
     font-size: 1.1em;
 }

 .box-lista span {
     background-image: url("~@/assets/img/Backgrounds/marromEscuro.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .text-info-personal {
     color: #656565;
     font-size: 1.1rem;
     text-align: justify;
     padding: 0rem;
     margin: 0 3rem 0 3rem;
 }

 .list-data {
     min-height: 90vh;
     padding-top: 2rem;
 }

 .label-filtro {
     font-weight: 600;
 }

 .filter-box {
     margin: 1rem 3rem;
 }

 .itens-box {
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .title-tree {
     margin: 1rem 3rem;
     font-weight: bold;
     display: flex;
 }

 .title-style{
     font-size: 25px;
     color: #656565;
     margin: 0;
     text-align: center;
     font-weight: 500;
 }

 .lista-data-info {
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;
     margin: 2rem 3rem;
 }

 .title-tree h1 {
     font-size: 1.2rem;
     font-weight: 700;
 }

 .title-style{
     margin: 0 0 0 3rem;
 }

 .separator {
     border: none;
     height: 1px;
     margin: 0.8rem 3rem;
     background: url("~@/assets/img/Backgrounds/azulEapi.webp");
 }

 a {
     color: #212529;
     text-decoration: none;
     transition: all ease-in-out 0.2s
 }

 a:hover {
     color: #4d4f50;
 }

 @media screen and (max-width: 1010px) {
     .lista-data-info {
         justify-content: center;
     }
 }


 @media screen and (max-width: 1012px) {
     .filter-box {
         display: flex;
         align-items: center;
         justify-content: center;
     }
 }

 @media screen and (min-width: 901px) {
     .first-info {
         flex-basis: 35%;
         text-align: left;
     }
 }

 @media screen and (max-width: 680px) {
     .itens-box{
         flex-direction: column;
         align-self: start;
         align-items: flex-start;
     }
     .first-info {
         text-align: center;
         width: -webkit-fill-available;
     }
     .filter-box{
         margin: 0 3rem;
     }
     .lista-data-info{
         margin: 0 3rem;
     }
     .mb-mobile{
         margin-bottom: 1rem;
     }
     .box-lista span, .box-lista a{
         width: 100%;
         text-align: center;
         margin-top: .5rem;
     }
     .box-lista{
         place-content: center;
     }
 }

 .img-info-box-lista {
     padding: 10px;
 }

 .btnCtrl {
     border: 1px solid #0e80c2;
 }

 :deep(.btnCtrl div) {
     background-image: url("~@/assets/img/Backgrounds/marromEscuro.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
     transition: 0.4s;
 }

 .btnCtrl {
     border: 1px solid #952713;
 }

 .btnLista {
     border: 1px solid #952713;
 }

 .btnCtrl, .btnLista{
     background-color: white;
 }

 :deep(.btnLista div) {
     background-image: url("~@/assets/img/Backgrounds/marromEscuro.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

</style>
