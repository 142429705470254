<!--
     |---------------------------------------------
     | Footer Component
     |---------------------------------------------
     | @usage Import the component and use, no params
     | Main Footer Component
-->


<template>
    <div class="footer">
        <div class="footer-text footer-container">
            <div class="footer-section">
                <span>Realização e implementação</span>
                <div class="logoCollection logos-footer">
                    <a href="http://lepes.fearp.usp.br/" target="_blank"><img class='' src="@assets/img/App/Footer/lepesGrande.webp"></a>
                </div>
            </div>

            <div class="footer-section">
                <span>Desenvolvimento</span>
                <div class="logoCollection logos-footer">
                    <a href="http://move.fearp.usp.br/" target="_blank"><img class='' src="@assets/img/App/Footer/move.webp"></a>
                </div>
            </div>

            <div class="footer-section">
                <span>Apoio</span>
                <div class="logoCollection logos-footer">
                    <a href="https://www.fmcsv.org.br/" target="_blank"><img class='' src="@assets/img/App/Footer/mariaCecilia.webp"></a>
                    <a href="https://movimentobemmaior.org.br/" target="_blank"><img class='' src="@assets/img/App/Footer/bemMaior.webp"></a>
                    <a href="https://www.itausocial.org.br/" target="_blank"><img class='' src="@assets/img/App/Footer/itau.webp"></a>
                </div>
            </div>
        </div>
        <h3>© Plataforma Nacional - 2022</h3>
    </div>
</template>

<script>
 export default {

 }
</script>

<style scoped>
 #footer {
     width: auto;
     padding: 5rem 2rem 2rem;
     display: grid;
     grid-template-columns: repeat(2, minmax(250px, 1fr));
     gap: 2rem 0;
 }

 .footer-section span {
     font-size: 1rem;
     font-weight: 600;
     width: 100%;
     color: #656565;
     text-align: center;
 }

 .footer-section div{
     justify-self: center;
 }

 .logos-footer img {
     transition: all .3s ease-in-out;
     max-height: 4rem;
     box-sizing: border-box;
     padding: 0 10px;
     margin: 10px 0;
 }

 .move-icon {
     height: 3.5rem;
     width: 5rem;
 }

 .logos-footer img:hover {
     transform: scale(1.06);
 }

 .img-fluid {
     cursor: pointer;
 }

 h3 {
     text-align: center;
     font-size: 0.9rem;
     color: #878787;
     width: 100%;
     text-align: center;
     padding: 5px;
     background-color: transparent;
     margin: 0;
 }

 .footer-text {
     color: black;
 }

 .footer-text b {
     font-weight: 600;
 }

 .footer {
     width: 100%;
     background-image: url("~@/assets/img/Backgrounds/texturaNavbar.webp");
     background-repeat: repeat;
     background-origin: border-box;
     background-size: 256px;
     box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.2);
 }

 .footer-container {
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     justify-content: center;
 }

 .footer-section {
     margin: 1em 20px 0 20px;
     padding: 8px;
     display: grid;
     grid-template-columns: 1fr;
     grid-template-rows: auto auto;
     box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);

     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-origin: border-box;
     background-size: 256px;
     border-radius: 1em;
 }

 .footer-section > .logoCollection {
     width: 100%;
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     justify-content: center;
 }

 .logoCollection a {
     display: block;
     width: min-content;
     cursor: pointer;
     text-align: center;
 }

 h3 {
     display: block;
     box-sizing: border-box;
     padding: 2em 0 1em 0;
 }
</style>
