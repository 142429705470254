<template>
    <div class="container offset">
        <div class="custom-form ">
            
            <!-- INPUTS DESABILITADOS CASO O USUÁRIO NÃO TENHA PERMISSÃO PARA ALTERAR -->
            <div v-if="userPerm == 'APLICADOR'">
                <div class="form-group">
                    <label class="question-style pt-1">Nome:</label>
                    <input
                        v-model.trim="nome"
                        type="name"
                        class="form-control input-personal disabled-style"
                    />
                </div>

                <div class="form-group">
                    <label class="question-style pt-1">Descrição:</label>
                    <input
                        v-model.trim="descricao"
                        type="name"
                        class="form-control input-personal disabled-style"
                    />
                </div>
                <div class="form-group">
                    <label class="question-style pt-1">Órgão:</label>
                    <input
                        v-model.trim="orgao"
                        type="name"
                        class="form-control input-personal disabled-style"
                    />
                </div>
                <div class="form-group">
                    <label class="question-style pt-1">Cidade:</label>
                    <input
                        v-model="nomeCidade"
                        type="name"
                        class="form-control input-personal disabled-style"
                    />
                </div>
            </div>

            <!-- INPUTS HABILITADOS CASO O USUÁRIO TENHA PERMISSÃO PARA ALTERAR -->
            <div v-else>
                <div class="form-group">
                    <label class="question-style pt-1">Nome:</label>
                    <input
                        v-model.trim="nome"
                        type="name"
                        class="form-control input-personal"
                    />
                </div>

                <div class="form-group">
                    <label class="question-style pt-1">Descrição:</label>
                    <input
                        v-model.trim="descricao"
                        type="name"
                        class="form-control input-personal"
                    />
                </div>
                <div class="form-group">
                    <label class="question-style pt-1">Órgão:</label>
                    <input
                        v-model.trim="orgao"
                        type="name"
                        class="form-control input-personal"
                    />
                </div>
                <div class="form-group">
                    <label class="question-style pt-1">Cidade:</label>
                    <input
                        v-model="nomeCidade"
                        type="name"
                        class="form-control input-personal"
                    />
                </div>
                <div class="button-div d-flex pt-2 pb-2">
                <Button 
                    section="descartar"
                    value="Descartar"
                    :isOutline="true"
                    :hasIcon="true"
                    iconName="times"
                /> 
                <Button 
                    section="salvar"
                    value="Salvar"
                    :isOutline="true"
                    :hasIcon="true"
                    iconName="save"
                /> 
            </div>
            </div>
        </div>
    </div>
</template>

<script>

import Button from '@components/Button'

export default {
    name: "Information",
    props: {
        data: Object
    },
    components: {
        Button
    },
    data (){
        return {
            nome: 'testestes',
            descricao: 'A Instituição testestes foi fundada xxxxxxxxxxxxxxxxxx',
            orgao: 'orgao',
            nomeCidade: 'Ribeirao Preto',
            id: this.data.idInstituicao
        }
    },
    async created(){
        const payload = {
            idInstituicao: this.id
        }
        this.$store.dispatch('setUserPerm', payload)
    },
     computed: {
         userPerm() {
             return this.$store.getters.getUserPerm?.permissao
         }
     }
}

</script>

<style scoped>

    .container{
        width: 100%;
        background-color: white;
    }

    .title-style{
        font-size: 1.5rem;
        font-weight: 600;
        color: #656565;        
    }

    .question-style{
        width: 100%;
        font-size: 1rem;
        color: #656565;
    }

    .image-style{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        padding: 1rem;
    }

    .input-personal {
        padding: 0.2rem 0.4rem;
        font-size: 15px;
        font-weight: 400;
        border-radius: 8px;
        background: #FCFCFC;
        outline: none;
        border: 1px solid rgba(0, 0, 0, 0.11);
        font-weight: lighter;
        padding: 0.2rem 0.4rem;
        box-shadow: 1px 2px 6px -3px rgb(0 0 0 / 25%);
    }

    .button-div{
        justify-content: space-between;
    }

    .disabled-style{
        background-color: #D0D0D0 !important;
        cursor: default;
        pointer-events: none;
    }

</style>
