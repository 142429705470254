<template>
<div>
    <ModalCentral 
        componentName="InstiRequests"
        title="Solicitações Recebidas"
        idName="InstiRequests"
    />

    <div @click="closeMiniDropdown()" class="overlay-invisible" :class="{'overlay-visible': isActiveMiniDropdown}"></div>
    <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" :section="section"/>
    <div class="institution-info-personal">
        <div class="container-infos">
            <h1 class="title-info">{{title}}</h1>
            <i v-if="showCog && userPerm.permissao != 'APLICADOR'" @click="showMiniDropdown()" class="cog-personal fas fa-cog ml-2">
                <div class="dropdown-box" :class="{'dropdown-box-visible': isActiveMiniDropdown}">
                    <div class="mini-dropdown">
                        <!-- TRIÂNGULO DO POP-UP PEQUENO -->
                        <div class="mini-triangle"> 
                            <svg class="triangulo-1" width="26" height="30" viewBox="0 0 36 33" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.6699 2.5C15.5944 -0.833332 20.4056 -0.833334 22.3301 2.5L35.3205 25C37.245 28.3333 34.8394 32.5 30.9904 32.5H5.00962C1.16062 32.5 -1.24501 28.3333 0.679492 25L13.6699 2.5Z" fill="white"/>
                            </svg>
                        </div>
                        
                        <!-- POP-UP PEQUENO -->
                        <div class="mini-pop-up shadow-lg pt-3 pr-1 pb-3 pl-2">
                            <div data-target="#InstiRequests" data-toggle="modal" class="row-md-12 font-weight-light pl-0 pr-0 mb-1 item-minidropdown"><i class="fas fa-sign-out-alt mr-1"></i>Solicitações</div>
                            <!-- <div @click="deixarEquipe(title)" class="row-md-12 font-weight-light pl-0 pr-0 mb-1 item-minidropdown"><i class="fas fa-sign-out-alt mr-1"></i>Deixar Instituição</div> -->
                            <!-- <div @click="deixarEquipe(title)" class="row-md-12 font-weight-light pl-0 pr-0 mb-1 item-minidropdown"><i class="fas fa-link mr-1"></i>Vincular a grupo</div> -->
                            <!-- <div class="row-md-12 font-weight-light pl-0 pr-0 mb-1 item-minidropdown"><i class="fas fa-trash mr-1"></i>Excluir</div> -->
                        </div>
                    </div>
                </div>
            </i>
        </div>
        <p class="text-info-personal">{{text}}</p>
    </div>
</div>
</template>

<script>
import Breadcrumb from "@components/Breadcrumb";
import Swal from "sweetalert2";
import ModalCentral from '@components/ModalCentral';

export default {
  name: "InstitutionInfo",
  data() {
    return {
      isActiveMiniDropdown: false,
      isTurningCog: false,
      isTurningInsteadCog: false,
    };
  },
  props: {
    title: String,
    text: String,
    section: String,
    crumbs: Object,
    showCog: {
      type: Boolean,
      default: false
    } 
  },
  components: {
    Breadcrumb,
    ModalCentral
  },
  computed: {
    userPerm() {
            return this.$store.getters.getUserPerm
        },
  },
  async created(){
    const payload = {
        idInstituicao: this.$route.params.idInstituicao
    }
    await this.$store.dispatch('setUserPerm', payload)
  },
  methods: {
    async deixarEquipe(name) {
      const { value: justificativaInput } = await Swal.fire({
        title: "Deseja mesmo sair da instituição?",
        text:
          "Tem certeza que deseja sair da instituição " +
          name +
          "? Se sim, justifique sua saída.",
        icon: "warning",
        input: "textarea",
        inputPlaceholder: "Digite sua justificativa aqui..",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, tenho certeza",
        inputValidator: (value) => {
          if (!value) {
            return "Você precisa escrever a justificativa.";
          }
        },
      });

      if (justificativaInput) {
        const payload = {
          idInstituicao: this.$route.params.idInstituicao,
          justificativa: justificativaInput,
        };
        await this.$store.dispatch("deixarInsti", payload);
        await this.$store.dispatch("setListTeams");
        this.$router.push('/dados/gerenciar/instituicao')
      }
    },
    showMiniDropdown: function () {
      this.isActiveMiniDropdown = true;
    },
    closeMiniDropdown: function () {
      this.isActiveMiniDropdown = false;
    },
    turnOnCog: function () {
      this.isTurningCog = true;
      this.isTurningInsteadCog = false;
    },
    turnOffCog: function () {
      this.isTurningCog = false;
      this.isTurningInsteadCog = true;
    },
  },
};
</script>

<style scoped>
.container-infos {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.overlay-invisible {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(22, 22, 22, 0);
  z-index: 2;
  transition: all ease-in-out 0.3s;
  opacity: 0;
  pointer-events: none;
}

.overlay-visible {
  opacity: 1;
  pointer-events: all;
  cursor: default;
}

.institution-info-personal {
  position: relative;
}

.title-info {
  font-weight: 600;
  font-size: 1.6rem;
  color: #535353;
  margin: 0;
}

.text-info-personal {
  color: #656565;
  font-size: 1.1rem;
  text-align: justify;
  padding: 1rem 0rem 0rem 0rem;
}

.cog-personal {
  position: relative;
  font-size: 1.3rem;
  color: #535353;
  cursor: pointer;
}

.item-minidropdown {
  color: #656565;
}

.item-minidropdown:hover {
  color: rgb(58, 58, 58);
}

.mini-triangle {
  margin: 0rem;
  display: center;
  align-items: center;
  justify-items: center;
  position: absolute;
  top: -16%;
  left: 80%;
}
.dropdown-box {
  position: absolute;
  top: 188%;
  left: -628%;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  cursor: default;
  transition: all 0.4s ease;
}

.dropdown-box-visible {
  opacity: 1;
  cursor: pointer;
  pointer-events: all;
}

.mini-dropdown {
  position: relative;
}

.mini-pop-up {
  height: 5.5rem;
  width: 7.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  color: grey;
  border-radius: 1rem;
  transition: color ease-in-out 0.2s;
  font-size: 1rem;
  font-weight: 300;
  font-family: "Hind", sans-serif;
}

.item-menu-dropdown {
  margin: 1rem 0;
}

.breadcrumb-style {
  margin: 0 0 0.8rem 0;
}
</style>
