<template>

    <div class="modal modal-lg descriptionModal"
         :id="idName"
         :ref="idName"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
        <div class="descriptionContainer">
            <div class="header">
                <div ref="elementCloseModalCentral" data-dismiss="modal" class="close-container">
                    <div class="leftright"></div>
                    <div class="rightleft"></div>
                </div>
            </div>
            <div class="innerContainer">
                <div class="infoGrid" :style="{'color': fontColor, 'background': `url(${bg})`}">
                    <div class="descriptionTitle">
                        <span>{{ title }}</span>
                    </div>
                    <div class="descriptionInner">
                        <h4>DESCRIÇÃO</h4>
                        <div v-html="inner" />
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
 export default {
     name: "CategoryDescription",
     components: {},
     props: {
         data: {
             required: true,
             type: Object,
         },
         idName: {
             required: true,
             type: String
         },
     },
     watch: {
         data(updated) {
             this.title = updated.title;
             this.inner = updated.text;
             this.bg = updated.background || '#8fbce5';
             this.fontColor = updated.fontColor || 'white';
         }
     },
     data() {
         return {
             categories: {
                 "Parcerias com as famílias e comunidade escolar": {
                     color: "#8fbce5",
                 },
                 "Reconhecimento e valorização da diversidade cultural": {
                     color: "#95c590",
                 },
                 "Escuta infantil, observação, investigação": {
                     color: "#cb6ce6",
                 },
                 "Acolhimento e gestão de sentimentos": {
                     color: "#e86c71",
                 },
                 "Valorização e reconhecimento da importância do ecossistema e do meio ambiente": {
                     color: "#ffde59",
                 },
                 "Práticas de formação da equipe de profissionais": {
                     color: "#f7b05c",
                 },
                 "Cuidado, autonomia e atenção as crianças": {
                     color: "#ffb58a",
                 },
                 "Práticas de gestão": {
                     color: "#f7b05c",
                 },
             },
             title: this.data.title,
             inner: this.data.text,
             bg: this.data.background || '#8fbce5',
             fontColor: this.data.fontColor || 'white',
             listBoasPraticas: [],
             modalWatcher: null,
         };
     },
     async created() {
         await this.$store.dispatch('loadListBoasPraticas');
         this.listBoasPraticas = this.$store.getters.getListBoasPraticas;
     },
     mounted() {
         this.modalWatcher = new MutationObserver(mutation => {
             for (const mutated of mutation) {
                 if (mutated.target.classList.contains('show')) this.$nextTick(() => { document.documentElement.style.overflow = 'hidden' })
                 else this.$nextTick(() => { document.documentElement.style.overflow = 'auto' })
             }
         });

         this.modalWatcher.observe(this.$refs[this.idName], {
             attributes: true
         });
     },
     beforeUnmount() { this.modalWatcher.disconnect() },
     computed: {
         filteredPraticas() {
             return this.listBoasPraticas
                        .filter(pratica => pratica.tematicas?.includes(this.title))
                        .map(pratica => {
                            let url = pratica.link.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
                            pratica.link = (url[2] !== undefined) ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
                            return pratica;
                        });
         }
     }
 }
</script>

<style scoped>

 .descriptionModal {
     background: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     margin: 10px auto;
     padding-left: 6px;
     height: max-content !important;
     border-radius: 8px;
 }

 .descriptionContainer {
     border-radius: 0.5rem;
     width: 100%;
     padding: 15px;
 }

 .header {
     display: flex;
     flex-direction: row-reverse;
     width: 100%;
 }

 .descriptionTitle {
     width: 100%;
     height: 100%;
     display: flex;
     align-items: center;

     font-weight: bold;
     font-size: 1.4em;
     text-align: left;
 }

 .descriptionTitle span {
     width: 100%;
 }

 .close-container{
     margin: 5px;
     position: relative;
     width: 30px;
     height: 30px;
     cursor: pointer !important;
 }

 .leftright{
     height: 2.5px;
     width: 20px;
     position: absolute;
     margin-top: 12px;
     background-color: #414141;
     border-radius: 2px;
     transform: rotate(45deg);
     transition: all .3s ease-in;
 }

 .rightleft{
     height: 2.5px;
     width: 20px;
     position: absolute;
     margin-top: 12px;
     background-color: #414141;
     border-radius: 2px;
     transform: rotate(-45deg);
     transition: all .3s ease-in;
 }

 .close-container:hover .leftright{
     transform: rotate(-45deg);
     background-color: #F25C66;
 }

 .close-container:hover .rightleft{
     transform: rotate(45deg);
     background-color: #F25C66;
 }

 /* .innerContainer {
    border-radius: 0.5rem 0.5rem 0 0;
    } */

 .infoGrid {
     background-color: #3a3ab6;
     border-radius: 0.5rem;
     display: grid;
     grid-template-columns: 1fr 1fr;
     grid-template-rows: auto;
     padding: 10px;
     row-gap: 10px;
     column-gap: 10px;
     background-repeat: repeat;
     background-size: 256px;
 }

 .titleExamples {
     width: 100%;
     padding: 5px;
     text-align: center;
     color: #47b994;
 }

 .examples {
     width: 100%;
     overflow-x: auto;
     display: flex;
     flex-direction: row;
 }

 .example {
     cursor: pointer;
     width: 100%;
     max-width: 200px;
     margin: 5px;
     border: double 3px transparent;
     border-radius: 1em;
     background-origin: border-box;
     background-clip: content-box, border-box;
 }

 .exampleInner {
     color: currentColor !important;
     width: 100%;
     padding: 10px;
     display: flex;
     flex-direction: column;
 }

 .exampleInner > img {
     width: 100%;
     padding: 5px;
 }

 .exampleTitle {
     width: 100%;
     text-align: center;
     font-weight: bold;
 }

 .exampleAuthor {
     width: 100%;
     text-align: center;
     font-size: 0.8em;
 }

 .emptyList {
     width: 100%;
     text-align: center;
     font-weight: bold;
     font-size: 1.2em;
 }

 @media screen and (max-width: 900px) {
     .infoGrid {
         grid-template-columns: 1fr;
         grid-template-rows: auto auto;
     }

    .descriptionTitle {
        text-align: center;
    }
 }
</style>
