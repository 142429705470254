<template>
  <div>
    <div class="menuTop">
      <MenuTopo />
    </div>
    <div class="contentWrapper">
      <div class="painelLateral">
        <PainelLateralPrincipal />
      </div>
      <div class="areaEdicao">
        <CardPergunta />
        <CardPergunta />
        <CardPergunta />
      </div>
    </div>
  </div>
</template>

<script>
import MenuTopo from "@components/GeradorDeFormularios/MenuTopo/MenuTopo.vue";
import PainelLateralPrincipal from '@components/GeradorDeFormularios/PainelLateral/PainelLateralPrincipal.vue'
import CardPergunta from '@components/GeradorDeFormularios/EditarPergunta/CardPergunta.vue'

export default {
  components: {
    MenuTopo,
    PainelLateralPrincipal,
    CardPergunta
  }
};
</script>

<style>
:root {
  --azul: #8287fe;
  --azul-80: #9b9ffe;
  --azul-40: #cdcfff;
  --cinza: #1f2937;
  --cinza-80: #4c545f;
  --cinza-40: #a5a9af;
  --cinza-20: #d2d4d7;
  --cinza-10: #e9eaeb;
  --branco: #fff;
  --vermelho: #ef4444;
  --amarelo: #f59e0b;
  --verde: #10b981;
}

.menuTop {
  background: var(--cinza);
  width: 100%;
}

.contentWrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 3fr;
  min-height: 100vh;
}

.painelLateral {
  background: var(--branco);
}
.areaEdicao {
  background: var(--cinza-10);
  padding: 2rem;
  max-height: 100vh;
  overflow-y: scroll;
}
</style>