<template>
    <div class="divEdicao">
        <div class="headerCategoria">
            <p class="categoria">Categoria 001</p>
            <h5 class="dadosInstituicao">Dados da Instituição</h5>
        </div>

        <div class="divPergunta">
            <h2 class="tituloPergunta">Pergunta 001</h2>

            <textarea class="areaPergunta"></textarea>


            <p class="tituloMenor">Notas (Opcional)</p>
            <textarea class="areaNotas"></textarea>



            <div class="divPadrao">
                 <p class="tituloMedio">Opções</p>

                <div>
                    <div class="opcaoContainer">
                        <i class="fas fa-ellipsis-v"></i>
                        <input type="text" class="inputRespPadrao">
                    </div>

                    <div class="edicaoOpcao">
                        <i class="fas fa-plus"></i>
                        <i class="fas fa-link"></i>
                        <i class="fas fa-trash"></i>
                    </div>
                </div>
            </div>

            <div class="respExtra">
                <input type="text" class="extraTextoInput" >

                <div class="extraRespConfig">
                    <!-- Opção Tipo de resposta -->
                    <label class="opcaoExtra">
                        <span>Tipo de resposta Extra</span>
                        <select>
                            <option>Texto</option>
                            <option>Número</option>
                            <option>Data</option>
                            <option>Hora</option>
                        </select>
                    </label>

                    <label class="opcaoExtra">
                        <input type="checkbox" class="extraCheck">
                        <span>Limite de caracteres</span>
                        <input type="number" min="0" class="extraTexto">
                    </label>

                    <label class="opcaoExtra">
                        <input type="checkbox" class="extraCheck">
                        <span>Resposta Padrão</span>
                        <input type="text" class="extraTexto">
                    </label>

                </div>
            </div>

        </div>
    
    
    </div>

        
</template>

<script>
export default {};
</script>

<style scoped>

    .divEdicao{
        width: 100%;
        height: 100%;
    }

    .headerCategoria{
        margin-bottom: 3%;
    }

    .categoria{
        margin-bottom: 0;
        font-size: 18px;
    }

    .dadosInstituicao{
        font-weight: bold;
        color: #4C545F;
    }

    .divPergunta{
        height: 100%;
    }
    
    .tituloPergunta{
       color: #1F2937;
       font-weight: 100;
    }

    textarea{
        width: 100%;
        padding: 10px;
    }

     input{
        width: 100%;
    }

    .inputRespPadrao{
        height: 12%;
        padding: 10px;
    }

    .areaPergunta{
        height: 40%;
        margin-bottom: 15px;
    }

    .areaNotas{
        height: 12%;
        margin-bottom: 15px;
    }

    .areaRespPadrao{
        height: 12%;
    }

    .tituloMedio{
        margin-bottom: 10px;
        font-size: 18px;
    }

    .opcaoContainer{
        display: flex;
    }

    .edicaoOpcao{
        display: flex;
        justify-content: space-around;
        width: 20%;
        margin-left: auto;

    }

    .fas{
        font-size: 20px;
    }

    .fa-ellipsis-v{
        font-size: 24px;
        margin-right: 2%;
    }

    .divPadrao{
        display: flex;
        flex-direction: column;
    }

    .opcaoContainer{
        display: flex;
        align-items: center;
        margin-bottom: 1%;
    }

    .respExtra{
        margin: 3% 0;
        width: 85%;
        margin-left: auto;

    }

    .extraTextoInput{
        margin-bottom: 3% !important;
    }

    .extraRespConfig{
        display: flex;
        justify-content: space-around;

    }

    .opcaoExtra{
        display: flex;
        width: 34%;
        height: 15%;
        align-items: center;
    }

    .opcaoExtra span{
        margin-right: 5px ;
    }

    .extraCheck{
        width: 15%;
    }

    .extraTexto{
        width: 25%;
    }


</style>
